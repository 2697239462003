import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import { AuthAppWrapper } from "./Auth-app-wanager";
import "./utils/i18n";

/*const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');*/
const rootElement = document.getElementById('root');

const root = createRoot(rootElement);
root.render(

  <BrowserRouter basename={"/"}>
    <AuthAppWrapper />
    <div id="recaptcha-container"></div>
  </BrowserRouter>

);
