import React from "react";
import { ManageAccommodation } from "./ManageAccommodation";

import { useLocation } from 'react-router-dom';

export const EditAccommodation = () => {

  const location = useLocation();
  const { itemState } = location.state;
  const { isEditable } = location.state;

  return (
    <div className={"md:p-4 pt-5 mx-auto"}>
      <ManageAccommodation data={itemState} isEditable={isEditable} />
    </div>
  );

};