import React, { useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../buttons/tertiary-button';

const ModalCreate = ({
  externalControl,
  className,
  status,
  title,
  icon,
  subtitle,
  body,
  primaryButton,
  buttonLabel,
  buttonClassName,
  bodyButton,
  bodyButtonLabel,
  onClose,
  onOpen,
  bodyButtonClassName,
}) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    if (onClose != null) {
      onClose();
    }
  }

  function openModal() {
    setIsOpen(true);
    if (onOpen != null) {
      onOpen();
    }
  }
  useEffect(() => {
    if (externalControl == true) {
      status ? openModal() : closeModal();
    }
  });

  return (
    <>
      

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">

            <div className="flex items-center justify-center min-h-full p-4 text-center">

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >

                <Dialog.Panel
                  className={
                    className +
                    " p-[20px] min-w-lg xl:w-[20%] w-[80%] text-left bg-white shadow-xl rounded-[10px]"
                  }


                >
                  
                  <Dialog.Title
                    as="h2"
                    className="flex justify-end"
                  >
                      
                    
                  
                  </Dialog.Title>
                    <div className="flex justify-between items-center">{icon}<span className=" mt-[3px]"><h2 className="flex items-center justify-center text-xl font-semibold leading-6 text-petroil">{title}</h2></span>
                    <div className="">
                          <Button
                                        id={"closeModal"}
                                        className={" text-petroil-1"}
                                        onClick={closeModal}
                                        content={<FontAwesomeIcon icon={faTimes} size='lg' />}
                                        isDisabled={false}
                                    />
                      </div>
                    </div>
                  {subtitle && (
                    <div className="mt-4">
                      <p className="text-sm font-semibold text-blue text-center">
                        {subtitle}
                      </p>
                    </div>
                  )}
                  <div className="mt-6">
                    <div className="text-sm text-gray-500">{body}</div>
                  </div>
                  {/* {bodyButton && (
                    <div className={bodyButtonClassName + " mt-4 w-full"}>
                      <button
                        type="button"
                        className={"bg-petroil text-white inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md  hover:brightness-95 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        }
                        onClick={closeModal}
                      >
                        {bodyButtonLabel}
                      </button>
                    </div>
                  )} */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalCreate;
