import React from "react";
import { useLocation } from 'react-router-dom';

import { ManageTransaction } from "./ManageTransaction";

export const EditTransaction = () => {

  const location = useLocation();
  const { itemState } = location.state;
  const { isEditable } = location.state;


  return (
    <div className={"md:p-4 pt-5 mx-auto"}>

      <ManageTransaction data={itemState} isEditable={isEditable} />
    </div>

  );
};