import React, { useState } from 'react';
import CompanyData from './CompanyData';
import Plan from './Plan-old';
import Roles from './Roles';
import BillingAddresses from './BillingAddress';
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch } from 'react-router-dom';
const CompanyIndex = () => {

  const { t } = useTranslation();
  const navigator = useNavigate();

  
  const isMobileTablet = window.matchMedia("(max-width: 991px)").matches;
  const isCompanyData = useMatch("company/company-data/");
  const isPlan = useMatch("company/plan/");
  const isRoles = useMatch("company/roles/");
  const isBillingAddress = useMatch("company/billing-address");

  return (
    <>
      <div className='md:max-w-[82vw]'>
        <h1 className='ml-[3vw] text-xl font-semibold'>{t("CompanySettings")}</h1>
        <div className={!isMobileTablet ? 'flex' : ''}>
          <div className='sidebar-company mt-16'>
            <div
              className={`sidebar-item-company ${isCompanyData ? "active" : ""} flex justify-between hover:text-[#0A6D67]`}
              onClick={() => navigator("../company/company-data")}
            >
              <div className={`flex ${!isCompanyData ? "xl:ml-5" : ""}`}>
                {isCompanyData ? (<div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>)
                  :
                  ("")}
                {t("CompanyData")}
              </div>
              <div className="">
                {">"}
              </div>
            </div>
            <div
              className={`sidebar-item-company ${isPlan ? "active" : ""} flex justify-between hover:text-[#0A6D67]`}
              onClick={() => navigator("../company/plan")}
            >
              <div className={`flex  ${!isPlan ? "xl:ml-5" : ""}`}>
                {isPlan ? (<div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>)
                  :
                  ("")}
                {t("Plans")}
              </div>
              <div className="">
                {">"}
              </div>
            </div>
            <div
              className={`sidebar-item-company ${isRoles ? "active" : ""} flex justify-between hover:text-[#0A6D67] pointer-events-none`}
              onClick={() => navigator("../company/roles")}
            >
              <div className={`flex  ${!isRoles ? "xl:ml-5" : ""}`}>
                {isRoles ? (<div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>)
                  :
                  ("")}
                {t("Roles")}
              </div>
              <div className="">
                {/* {">"} */}
              </div>
            </div>
            <div
              className={`sidebar-item-company ${isBillingAddress ? "active" : ""} flex justify-between hover:text-[#0A6D67]`}
              onClick={() => navigator("../company/billing-address")}
            >
              <div className={`flex  ${!isBillingAddress ? "xl:ml-5" : ""}`}>
                {isBillingAddress ? (<div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>)
                  :
                  ("")}
                {t("BillingAddress")}
              </div>
              <div className="">
                {">"}
              </div>
            </div>
          </div>
          {isCompanyData && (
            <CompanyData />
          )}
          {isPlan && (
            <Plan />
          )}
          {isRoles && (
            <Roles />
          )}
          {isBillingAddress && (
            <BillingAddresses />
          )}

        </div>
      </div>
    </>
  );
};

export default CompanyIndex;
