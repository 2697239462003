import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { HiArrowLeft, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Tab } from "@headlessui/react";
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { toast } from "react-toastify";
import { CurrencyField } from "../../components/input/fields/currency-field";
import ReactPaginate from "react-paginate";
import { GeneralOptions } from "../../resources/selectOptions";
import { create, update } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/primary-button";

export const ManageOwner = (data, isEditable) => {
  const itemToUpdate = data.data;
  const { i18n, t } = useTranslation();
  const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
  const Id = itemToUpdate && (itemToUpdate.id ?? null);
  const [index, setIndex] = useState(0);
  const context = useContext(DataContext);
  const accommodationsContext = context.accommodations;
  const [fullNameCompanyName, setFullNameCompanyName] = useState((itemToUpdate && itemToUpdate.fullNameCompanyName) ?? null);
  const [vatNumber, setVatNumber] = useState((itemToUpdate && itemToUpdate.vatNumber) ?? null);
  const [accommodationPayments, setAccommodationPayments] = useState((itemToUpdate && itemToUpdate.accommodationPayments) ?? []);
  const [currentPage, setCurrentPage] = useState(0);

  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  const [filter, setFilter] = useState("");
  const itemsPerPage = 5;
  const navigator = useNavigate();
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const [filteredData, setFilteredData] = useState([]);
  const [formValues, setFormValues] = useState({
    id: Id,
    fullNameCompanyName: fullNameCompanyName,
    vatNumber: vatNumber,
    accommodationPayments: accommodationPayments,
  });


  const otaOptions = GeneralOptions("otaCommission");
  const paymentTypeOptions = GeneralOptions("paymentType");

  const getAccommodationName = (id) => {
    return accommodationsContext.find(acc => acc.id === id).name;

  };

  const checkFields = () => {
    if (!formValues.fullNameCompanyName || formValues.fullNameCompanyName === "") {
      toast.error(t("FullNameCompanyNameRequired"));
      return false;
    }
    return true;
  };


  const handleCreate = () => {
    if (checkFields()) {
      create("Owner", context.idOrganization, formValues).then(async () => {
        context.updateData("owners");
        navigator("../owners");
      });
    }
  };

  const handleUpdate = () => {
    if (checkFields()) {
      update("Owner", context.idOrganization, formValues).then(async () => {
        context.updateData("owners");
        navigator("../owners");
      });
    }
  };

  const handleAccommodationPaymentChange = (index, field, value) => {
    const updatedPayments = accommodationPayments.map((payment, i) =>
      i === index ? { ...payment, [field]: value } : payment
    );
    setAccommodationPayments(updatedPayments);
    setFormValues((prevValues) => ({
      ...prevValues,
      accommodationPayments: updatedPayments,
    }));
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };




  const searchAccommodation = (item) => {
    setFilter(item.toLowerCase());
  };


  const filteredPayments = useMemo(() => {
    return accommodationPayments.filter((payment) => {
      const accommodation = context.accommodations.find((item) => item.id === payment.idAccommodation && item.idOwner === Id);
      return accommodation?.name.toLowerCase().includes(filter.toLowerCase());
    });
  }, [filter, accommodationPayments]);

  const [displayPayments, setDisplayPayments] = useState([])
    




  const totalPages = Math.ceil(filteredPayments.length / itemsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };


  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const renderPayments = (data) => {

    return data.map((item, index) => (
      <div className="md:grid md:grid-cols-12 md:gap-x-4 mb-4" key={index}>
        
        <div className="col-span-1 mb-3 md:mb-0">
          {/* Aggiungi la numerazione qui */}
          <span className="font-semibold">
            {currentPage * itemsPerPage + index + 1}.
          </span>
        </div>
        <div className="col-span-3 flex relative">
          <div className="col-span-12 xl:col-span-6 md:max-w-[30vw] w-full mb-3 md:mb-0">
            <TextField
              label={t("Accommodation")}
              inputClassName={"py-3 px-1 bg-white-border"}
              placeholder={t("Insert")}
              value={getAccommodationName(item.idAccommodation)}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-span-2 mb-3 md:mb-0">
          <SelectMenu
            label={t("PaymentType")}
            value={paymentTypeOptions.find((x) => x.value === item.paymentType)}
            options={paymentTypeOptions}
            onChange={(value) => handleAccommodationPaymentChange(currentPage * itemsPerPage + index, 'paymentType', value.value)}
          />
        </div>
        <div className="col-span-2 mb-3 md:mb-0">
          <CurrencyField
            label={t("Amount")}
            placeholder={"0"}
            className={"w-full"}
            value={item.amount}
            currency={item.paymentType !== "percentage" ? (context.organizationInfo?.currency) : null}
            onChange={(e) => handleAccommodationPaymentChange(currentPage * itemsPerPage + index, 'amount', Number(e.target.value))}
            inputClassName={"py-3"}
          />
        </div>
        <div className="col-span-2 w-full mb-3 md:mb-0">
          <SelectMenu
            label={t("Ota")}
            className={"md:max-w-xs"}
            value={otaOptions.find((x) => x.value === item.otaIncluded) || null}
            options={otaOptions}
            onChange={(value) => handleAccommodationPaymentChange(currentPage * itemsPerPage + index, 'otaIncluded', value.value)}
          />
        </div>
        <div className="col-span-2 w-full">
          <SelectMenu
            label={t("ExtraServiceFee")}
            className={"md:max-w-xs"}
            isSearchable={true}
            value={otaOptions.find((x) => x.value === item.extraServiceFeeIncluded)}
            options={otaOptions}
            onChange={(value) => handleAccommodationPaymentChange(currentPage * itemsPerPage + index, 'extraServiceFeeIncluded', value.value)}
          />
        </div>
      </div>
    ))
  }

  useEffect(() => {
    const accommodations = displayPayments.map((payment, index) => {
      const accommodation = context.accommodations.find((item) => item.id === payment.idAccommodation && item.idOwner === Id);
      return { 
        ...payment, 
        name: accommodation?.name 
      };
    });
    setFilteredData(accommodations);
  }, [context.accommodations]);

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      accommodationPayments: filteredPayments
    }));
  }, [filteredPayments]);

  useEffect(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    setDisplayPayments(filteredPayments.slice(start, end));
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
  }, [displayPayments]);


  


  return (
    <div className="w-full">
      <div onClick={() => navigator("../owners")} className="mb-10 flex cursor-pointer max-w-[250px]">
        <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
        <h1 className="text-2xl font-bold">{t("Owners")}</h1>
      </div>

      <div className="w-full md:pl-24 mt-16">
        <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
          {!isMobile && (<Tab.List className="flex px-1 space-x-1 text-blue">
            {/** Headers */}
            <div className="tab-manage-container">
              {index === 0 ?
                (
                  <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>

                )
                :
                (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>)
              }
              <Tab
                className={({ selected }) =>
                  classNames(
                    "px-8 ml-8 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                    selected
                      ? "selected"
                      : ""
                  )
                }
              >
                <div className="grid grid-cols-12">
                  <span className="pt-2 col-span-12 font-semibold text-xl">{t("OwnerDetails")}</span>
                </div>
              </Tab>
            </div>
            <div className="tab-manage-container">
              {index == 1 ?
                (
                  <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>

                )
                :
                (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>)
              }
              <Tab
                className={({ selected }) =>
                  classNames(
                    "px-8 ml-1 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                    selected
                      ? "selected"
                      : ""
                  )
                }
              >
                <div className="grid grid-cols-12">
                  <span className="pt-2 col-span-12 font-semibold text-xl">{t("Accommodations")}</span>
                </div>

              </Tab>
            </div>
          </Tab.List>)}
          {isMobile && (<Tab.List className="flex px-1 space-y-2 text-blue flex-col mb-2">
            {/** Headers */}
            <div className="tab-manage-container">
              {index === 0 ?
                (
                  <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                )
                :
                (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
              }
              <Tab
                className={({ selected }) =>
                  classNames(
                    "  pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                    selected
                      ? "selected"
                      : ""
                  )
                }
              >
                <div className="grid grid-cols-12">
                  <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("OwnerDetails")}</span>
                </div>
              </Tab>
            </div>
            <div className="tab-manage-container">
              {index == 1 ?
                (
                  <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                )
                :
                (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
              }
              <Tab
                className={({ selected }) =>
                  classNames(
                    " pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                    selected
                      ? "selected"
                      : ""
                  )
                }
              >
                <div className="grid grid-cols-12">
                  <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("Accommodations")}</span>
                </div>

              </Tab>
            </div>
          </Tab.List>)}
          {/** Tabs */}
          <Tab.Panels className=" min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7 md:px-12 px-1">
            {/** Details */}
            <Tab.Panel
              key={0}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
              )}
            >
              <h1 className={"ml-1 text-lg font-semibold text-blue w-full"}>
                {t("General")}
              </h1>
              <div className="md:grid md:grid-cols-12 md:gap-5 md:gap-x-12">
                <div className="col-span-12 xl:col-span-6 mt-1 md:max-w-[30vw]">
                  <TextField
                    label={t("FullNameCompanyName")}
                    inputClassName={"py-3 px-1"}
                    placeholder={t("Insert")}
                    defaultValue={fullNameCompanyName}
                    onChange={(e) => {
                      setFullNameCompanyName(e.target.value);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        fullNameCompanyName: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 mt-1 md:max-w-[30vw]">
                  <TextField
                    label={t("VatNumber")}
                    inputClassName={"py-3 px-1"}
                    placeholder={t("Insert")}
                    defaultValue={vatNumber}
                    onChange={(e) => {
                      setVatNumber(e.target.value);
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        vatNumber: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </Tab.Panel>

            {/** Costs */}
            <Tab.Panel
              key={1}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
              )}
            >
              <div className="flex justify-between">
                <h1 className={"ml-1 text-lg font-semibold text-blue w-full"}>
                  {t("AccommodationsInfo")}
                </h1>
                {!isMobile && <div className="mb-4">
                  <TextField
                    filterInput={true}
                    inputClassName={"py-1 px-1 min-w-[15vw]"}
                    placeholder={t("SearchAccommodation")}
                    onChange={(e) => {
                      searchAccommodation(e.target.value)
                    }}
                  />
                </div>}
              </div>
              {isMobile && <div className="mb-4">
                <TextField
                  filterInput={true}
                  inputClassName={"py-1 px-1 min-w-[15vw]"}
                  placeholder={t("SearchAccommodation")}
                  onChange={(e) => {
                    searchAccommodation(e.target.value)
                  }}
                />
              </div>}

              {renderPayments(displayPayments)}

              {displayPayments.length > 0 && <div className="flex justify-between mt-4 align-items-center">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 0}
                  className={`px-4  rounded ${currentPage === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <HiChevronLeft className={"w-6 h-auto hover:text-blue mr-2"} />
                </button>
                <span>{`Page ${currentPage + 1} of ${totalPages }`}</span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage >= totalPages - 1}
                  className={`px-4  rounded ${currentPage >= totalPages + 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <HiChevronRight className={"w-6 h-auto hover:text-blue mr-2"} />
                </button>
              </div>}

              {/* {filteredPayments.length > itemsPerPage && (
      <ReactPaginate
        previousLabel={<HiChevronLeft className={"w-6 h-auto hover:text-blue mr-2"} />}
        nextLabel={<HiChevronRight className={"w-6 h-auto hover:text-blue mr-2"} />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(filteredPayments.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination flex justify-center space-x-4"}
        activeClassName={"font-bold"}
      />
    )} */}
            </Tab.Panel>
            <div className="flex justify-end w-full pr-3 pb-10">
              {IsEditable && (
                Id ? (
                  <PrimaryButton
                    content={t("Save")}
                    className={"mt-10 p-3 bg-blue w-[7vw]"}
                    onClick={handleUpdate}
                  />
                ) : (
                  <PrimaryButton
                    content={t("Save")}
                    className={"mt-10 p-3 bg-blue w-[7vw]"}
                    onClick={handleCreate}
                  />
                )
              )}
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
