import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, useMatch, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/shared/notFound";
import { 
    getAuth
} from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "./Auth-app-wanager";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import {
  readAll,
  getOrganizationById,
  getUserById
} from "./utils/functions";
import Layout from "./components/layout/layout/layout";
import { ExternalLayout } from "./components/layout/externalLayout/externalLayout";
import { AppRoutes, AppRoutesExternal } from "./appRoutes";
import LoadingApp from "./pages/shared/loadingApp";
import { useTranslation } from "react-i18next";
import Plan from "./pages/company/Plan-old";

export const DataContext = createContext({
  loading: null,
  accommodations: null,
  owners: null,
  suppliers: null,
  transactions: null,
  otas: null,
  bookingRules: null,
  costCategories: null,
  bookings: null,
  organizationInfo: null,
  accountExtraInfo: null,
  idOrganization: null,
  costCenters: null
});

function App() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [accommodations, setAccommodations] = useState([]);
  const [owners, setOwners] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [otas, setOtas] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [bookingRules, setBookingRules] = useState([]);
  const [costCategories, setCostCategories] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [organizationInfo, setOrganizationInfo] = useState();
  const [idOrganization, setOrganizationId] = useState();
  const [accountExtraInfo, setaccountExtraInfo] = useState();
  const auth = getAuth();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#c3f73a",
      },
      secondary: {
        main: "#0a6d67",
      },
    },
  });


  useEffect(() => {


    const getUser = async () => {
     await getUserById(auth.currentUser.uid).then((user) => {
        setaccountExtraInfo(user);
        i18n.changeLanguage(user.language);
        fetchData(user.idOrganization)
      })
   
    }

    if(auth.currentUser){
      getUser();
    }

    // Fetch all initials data informations
    const fetchData = async (idOrganization) => {
      setLoading(true);
      setOrganizationId(idOrganization);
      setOrganizationInfo(await getOrganizationById(idOrganization));
      setAccommodations(await readAll("Accommodation", idOrganization));
      setBookings(await readAll("Booking", idOrganization));
      setTransactions(await readAll("Transaction", idOrganization));
      setBookingRules(await readAll("BookingRule", idOrganization));
      setOwners(await readAll("Owner", idOrganization));
      setSuppliers(await readAll("Supplier", idOrganization));
      setCostCategories(await readAll("CostCategory", idOrganization));
      setCostCenters(await readAll("CostCenter", idOrganization));
      setOtas(await readAll("Ota", idOrganization));
      setaccountExtraInfo(await getUserById(auth.currentUser.uid));
      setLoading(false);
    };
  }, []);

  const onChangeUpdate = async (key) => {
    setLoading(true);
    switch (key) {
      case "accommodations":
        setAccommodations(await readAll("Accommodation", idOrganization));
        break;
      case "bookings":
        setBookings(await readAll("Booking", idOrganization));
        break;
      case "transactions":
        setTransactions(await readAll("Transaction", idOrganization));
        break;
      case "booking-rules":
        setBookingRules(await readAll("BookingRule", idOrganization));
        break;
      case "cost-categories":
        setCostCategories(await readAll("CostCategory", idOrganization));
        break;
      case "cost-centers":
        setCostCategories(await readAll("CostCenter", idOrganization));
        break;
      case "owners":
        setOwners(await readAll("Owner", idOrganization));
        break;
      case "suppliers":
        setSuppliers(await readAll("Supplier", idOrganization));
        break;
      case "booking-providers":
        setOtas(await readAll("Ota", idOrganization));
        break;
      case "organization-info":
        setOrganizationInfo(await getOrganizationById(idOrganization));
        break;
      case "user-info":
        setaccountExtraInfo(await getUserById(auth.currentUser.uid));
        break;
    }
    setLoading(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="min-h-[80vh]">

          {!auth.currentUser ?
            (
              <ExternalLayout>
                <Routes>
                  {AppRoutesExternal.map((route, index) => {
                    const { element, ...rest } = route;
                    if (!route.isActive) {
                      return <Route key={index} {...rest} element={<NotFound />} />;
                    } else {
                      return <Route key={index} {...rest} element={element} />;
                    }
                  }
                  )}
                </Routes>
              </ExternalLayout>

            )

            : (
              <Layout>
                <DataContext.Provider
                  value={{
                    loading: loading,
                    accommodations: accommodations,
                    owners: owners,
                    suppliers: suppliers,
                    transactions: transactions,
                    bookingRules: bookingRules,
                    costCategories: costCategories,
                    costCenters: costCenters,
                    bookings: bookings,
                    organizationInfo: organizationInfo,
                    accountExtraInfo: accountExtraInfo,
                    idOrganization: idOrganization,
                    otas: otas,
                    updateData: onChangeUpdate,
                  }}
                >
                 
                  {!loading ? (
                    organizationInfo 
                     && new Date(organizationInfo.subscription.expired) > new Date()
                      ? (
          
                      <Routes>
                      {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                          if (!route.isActive) {
                            return <Route key={index} {...rest} element={<NotFound />} />;
                          }
                          else {
                            return <Route key={index} {...rest} element={element} />;
                          }

                      })}
                      </Routes>
                    )                     
                      :   <Plan/>    
                  ) : (
                    <LoadingApp />
                  )}

                </DataContext.Provider>
              </Layout>
            )


          }


          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

        </div>

      </ThemeProvider>
    </>
  );
}

export default App;
