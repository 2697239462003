import React from "react";
import { ManageBookingRule } from "./ManageBookingRule";

export const NewBookingRule = () => {


    return (

        <>
            <div className={"md:p-4 pt-5 mx-auto"}>
                <div className={"flex"}>
                    <ManageBookingRule data={"0"} />
                </div>
            </div>
        </>

    );
}; 