import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/dialogs/dialog";
import { TermsAndConditions } from "../../../../resources/termsAndConditions";
import SecondaryButton from "../../../../components/buttons/secondary-button";

const TermsAndConditionsStep = ({ onComplete, formValues, onBack }) => {
  const { i18n, t } = useTranslation();
  const [termsAndConditionsContent, setTermsAndConditionsContent] = useState("");

  useEffect(() => {
    const handleChangeLanguage = () => {
      i18n.changeLanguage(formValues.language ?? "en");
    }
    setTermsAndConditionsContent(TermsAndConditions[formValues.language ?? "en"]);
    handleChangeLanguage();
  }, [formValues.language]);
  
const submit = () => {
  onComplete({
    termsAndConditions: {
      dateAccept: new Date(),
      accepted: true,
    }
  });
}


  return (
    <div className="big-screen:ml-[10rem] m-10 container-tablet-signup">
      <div className="flex flex-col w-full my-1 space-y-2 container-terms">
        <h2 className="text-lg font-bold text-blue"> {t("TermsAndConditions")} </h2>
        <p className="text-lg font-semibold text-blue"> {t("TermsAndConditionsText")} </p>

        <div className="flex row  bg-white rounded-lg  p-5 terms-container" >
          <div className="col-md-7 col-12 h-[30vh] overflow-hidden overflow-y-auto" id="scroller">
            <div className="px-6">
              <p className="text-base mb-5" dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }} />
            </div>
          </div>
        </div>


        <div className="flex justify-between items-center pt-10">
          <button
            className="flex items-center bg-transparent border-none cursor-pointer p-2 text-sm"
            onClick={() => onBack(4)}>
            {"< " + t("Previous")}
          </button>

          <div className="flex justify-end ">
            <SecondaryButton
              content={t("JoinBiLemon")}
              className="p-2 w-[5vw]"
              onClick={submit}
            />
          </div>
        </div>




      </div>
    </div>
  );
};

export default TermsAndConditionsStep;
