import { useState, useEffect, useContext } from "react";
import { create } from "../../../utils/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { SelectMenu } from "../../../components/input/select/select-menu";
import { UnitFieldSymbols } from "../../../components/input/fields/unit-field-with-symbols";
import { CurrencyField } from "../../../components/input/fields/currency-field";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import ModalCreate from "../../../components/dialogs/dialogCreate";
import { DataContext } from "../../../App";
import { NewSupplier } from "../../../components/modals/new-supplier";
import { NewSubCategoryForm } from "../../../components/modals/new-subCategory";
import { NewCategoryForm } from "../../../components/modals/new-category";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NewDatePicker } from "../../../components/input/fields/date-pickers/newDatePicker";
import { FaCheck } from 'react-icons/fa';
import { getTime } from "date-fns";
import { toast } from "react-toastify";
import { GeneralOptions } from "../../../resources/selectOptions";
import PrimaryButton from "../../../components/buttons/primary-button";

const ProceedsStep = ({ onComplete, values, onGoBack }) => {

    const { i18n, t } = useTranslation();
    const context = useContext(DataContext);
    const [paymentType, setPaymentType] = useState((values && values.paymentType === "percentage") ? { label: "Percentage", value: "Percentage" } : { label: "Fixed", value: "Fixed" });
    const [amount, setAmount] = useState((values && values.amount) ?? 0);
    const [modal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState();
    const [recurrency, setRecurrency] = useState((values && values.recurrency) ?? null);
    const [recurrencyValue, setRecurrencyValue] = useState((values && values.recurrencyValue) ?? 0);
    const [idBooking, setIdBooking] = useState((values && values.idBooking) ?? 0);
    const [idSupplier, setIdSupplier] = useState();
    const [date, setDate] = useState((values && values.date) ?? null);
    const [endDate, setEndDate] = useState((values && values.endDate) ?? null);
    const [startDate, setStartDate] = useState((values && values.startDate) ?? null);
    const [paymentDate, setPaymentDate] = useState((values && values.paymentDate) ?? null);
    const bookingList = context.bookings &&
        context.bookings.map((item) => {
            const accommodation = context.accommodations.find(acc => acc.id === item.idAccommodation);
            const accommodationName = accommodation.name;
            const bookingDate = new Date(item.bookingDate);
            const formattedDate = bookingDate.toISOString().split('T')[0];

            return { label: formattedDate + " " + accommodationName, value: item.id };
        });
    
    const onCloseModal = () => {
        setOpenModal(false)
    };
    const costCenterOptions = context.costCenters.map((item) => {
        return { label: item.name, value: item.id };
    });
    const [costCenter, setCostCenter] = useState((values && values.costCenter) ? costCenterOptions.find(costCenter => costCenter.label === values.costCenter) : null);
    const bookingCategoryOptions = context.costCategories.filter((item) => item.typology === "booking").map((item) => {
        return { label: item.category, value: item.id, subCategory: item.subCategory };
    });
    const [category, setCategory] = useState((values && values.category) ? bookingCategoryOptions.find(cat => cat.label === values.category) : null);
    let subCategoryOptions = category && category.subCategory && Array.isArray(category.subCategory) ? category.subCategory.map((item) => {
        return { label: item, value: item }
    }) : [];
    const [subCategory, setSubCategory] = useState((values && values.subCategory) ? subCategoryOptions.find(subCat => subCat.value === values.subCategory) : null);
    useEffect(() => {
        subCategoryOptions = category && category.subCategory && Array.isArray(category.subCategory) ? category.subCategory.map((item) => {
            return { label: item, value: item }
        }) : [];
    }, [category])
    const paymentTypeOptions = GeneralOptions("paymentType");
    const recurrencyOptions = GeneralOptions("recurrency");

    const [formValues, setFormValues] = useState({
        idBooking: idBooking,
        costCenter: costCenter,
        category: category,
        subCategory: subCategory,
        idSupplier: idSupplier,
        date: date,
        paymentDate: paymentDate,
        paymentType: paymentType,
        amount: amount,
        idAccommodation: null,
        recurrency: recurrency,
        startDate: startDate,
        endDate: endDate,
        recurrencyCustom: null
    });

    const checkValues = () => {

        if (values && values.typology == "booking" && !formValues.idBooking) {
            toast.error(t("BookingIsRequired"));
            return false;
        }

        if (values && values.typology == "accommodation" && !formValues.idAccommodation) {
            toast.error(t("AccommodationIsRequired"));
            return false;
        }

        if (!formValues.category) {
            toast.error(t("CategoryIsRequired"));
            return false;
        }

        if (!formValues.date) {
            toast.error(t("DateIsRequired"));
            return false;
        }

        if (!formValues.amount) {
            toast.error(t("AmountIsRequired"));
            return false;
        }


        if (values.typology && formValues.category && formValues.amount && formValues.date) {
            onComplete(formValues)
        }

        return true;
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center ">
                <div className="text-petroil mb-24 w-full px-14">
                    <div className="flex items-center justify-center text-petroil">
                        <div className="">
                            <div className="bg-white rounded-full items-center">
                                <div className="flex justify-center mb-4 mt-8">
                                    <FaCheck className="text-white bg-petroil rounded-full w-9 h-9 p-1" />
                                </div>
                                <div className="">
                                    <p className={`font-bold text-center text-base text-petroil max-w-[100px]`}>{t("Typology")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-[2px] w-[200px] bg-blue mb-8"></div>
                        <div className="text-center items-center justify-center">
                            <div className="flex justify-center mb-4 mt-8">
                                <FaCheck className="text-white bg-petroil rounded-full w-9 h-9 p-1" />
                            </div>
                            <div className="">
                                <p className={`font-bold text-center text-base text-petroil max-w-[100px]`}>{t("Details")}</p>
                            </div>

                        </div>
                    </div>
                    <div className='mt-20 ml-1 '>

                        <div className="grid xl:grid-cols-2 gap-x-20 grid-cols-1">
                            {values.typology && values.typology === 'booking' && (
                                <SelectMenu
                                    label={t("Booking") + " *"}
                                    placeholder={t("Select")}
                                    className={"min-w-[16vw] mb-3 m-0"}
                                    inputClassName={"p-1"}
                                    options={bookingList}
                                    onChange={(item) => {
                                        setIdBooking(item);
                                        setFormValues(prevValues => ({
                                            ...prevValues,
                                            idBooking: item.value
                                        }));

                                    }}
                                    value={idBooking ?? null}
                                />
                            )}
                            <SelectMenu
                                label={<div className="flex justify-between cursor-pointer">
                                    <div>
                                        {t("Category") + " *"}
                                    </div>
                                    <div
                                        className="text-sm mt-1"
                                        onClick={() => {
                                            setOpenModal(true)
                                            setModalType("Category")
                                        }
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} size='sm' />
                                        {t("AddCategory")}
                                    </div>

                                </div>
                                }
                                placeholder={t("Select")}
                                className={"min-w-[16vw] mb-3 m-0"}
                                inputClassName={"p-1"}
                                options={bookingCategoryOptions}
                                onChange={(item) => {
                                    setCategory(item)
                                    setFormValues(prevValues => ({
                                        ...prevValues,
                                        category: item.label
                                    }));
                                }}
                                defaultValue={category}
                            />
                            <SelectMenu
                                label={<div className="flex justify-between cursor-pointer">
                                    <div>
                                        {t("SubCategory")}
                                    </div>
                                    <div
                                        className="text-sm mt-1"
                                        onClick={() => {
                                            setOpenModal(true)
                                            setModalType("SubCategory")
                                        }
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} size='sm' />
                                        {t("AddSubCategory")}
                                    </div>

                                </div>
                                }
                                placeholder={t("Select")}
                                className={"min-w-[16vw] mb-3 m-0"}
                                inputClassName={"p-1"}
                                options={subCategoryOptions}
                                onChange={(item) => {
                                    setSubCategory(item)
                                    setFormValues(prevValues => ({
                                        ...prevValues,
                                        subCategory: item
                                    }));
                                }}
                                defaultValue={subCategory}
                            />
                            <SelectMenu
                                label={t("CostCenter")}
                                placeholder={t("SelectPlaceholder")}
                                className={"min-w-[16vw] mb-3 m-0"}
                                inputClassName={"p-1"}
                                options={costCenterOptions}
                                onChange={(item) => {
                                    setCostCenter(item);
                                    setFormValues(prevValues => ({ ...prevValues, costCenter: item.label }))
                                }}
                                value={costCenter && costCenterOptions ? costCenterOptions.find(x => x.label === costCenter.label) : null}
                            />
                            
                            <NewDatePicker
                                label={t("TransactionDate") + " *"}
                                className={"mb-4"}
                                defaultValue={
                                    (date && getTime(new Date(date)))
                                }
                                onCompleted={(el) => {
                                    setDate(el);
                                    setFormValues(prevValues => ({
                                        ...prevValues,
                                        date: el
                                    }));
                                }}
                            />
                            <NewDatePicker
                                label={t("PaymentDate")}
                                defaultValue={
                                    (paymentDate && getTime(new Date(paymentDate)))
                                }
                                onCompleted={(el) => {
                                    setPaymentDate(el);
                                    setFormValues(prevValues => ({
                                        ...prevValues,
                                        paymentDate: el
                                    }));
                                }}
                            />
                            {values.typology && values.typology === 'booking' && (
                                <SelectMenu
                                    label={t("PaymentType")}
                                    placeholder={t("Select")}
                                    className={"min-w-[16vw] mb-3 m-0 mt-2 xl:mt-0"}
                                    inputClassName={"p-1"}
                                    options={paymentTypeOptions}
                                    onChange={(item) => {
                                        setPaymentType(item);
                                        setFormValues(prevValues => ({
                                            ...prevValues,
                                            paymentType: item
                                        }));
                                    }}
                                    value={paymentType}
                                />
                            )}
                            {values.recurrencyInternal === 'regular' && (
                                <>
                                    <div className="flex justify-between">
                                        <SelectMenu
                                            label={t("Recurrency") + " *"}
                                            placeholder={t("Select")}
                                            className={"max-w-[13vw] mb-3 m-0 mr-10"}
                                            inputClassName={"p-1"}
                                            options={recurrencyOptions}
                                            onChange={(item) => {
                                                setRecurrency(item);
                                                setFormValues(prevValues => ({
                                                    ...prevValues,
                                                    recurrency: item
                                                }));
                                                if (item.value !== "custom") {
                                                    setFormValues(prevValues => ({
                                                        ...prevValues,
                                                        recurrencyCustom: null,
                                                        recurrencyValue: 0
                                                    }));
                                                    setRecurrencyValue(0)

                                                }
                                            }}
                                            value={recurrency}
                                        />
                                        <div className="">
                                            <UnitFieldSymbols
                                                label={t("NumberOfDays") + " *"}
                                                disabled={!recurrency || (recurrency && recurrency.value !== "custom")}
                                                className={""}
                                                value={recurrencyValue}
                                                placeholder={"0"}
                                                onChange={(e) => {
                                                    setFormValues(prevValues => ({
                                                        ...prevValues,
                                                        recurrencyCustom: e.target.value
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <NewDatePicker
                                        label={t("StartDate") + " *"}
                                        className={"mb-4"}
                                        defaultValue={
                                            (startDate && getTime(new Date(startDate)))
                                        }
                                        onCompleted={(el) => {
                                            setStartDate(el);
                                            setFormValues(prevValues => ({
                                                ...prevValues,
                                                startDate: el
                                            }));
                                        }}
                                    />
                                    <NewDatePicker
                                        label={t("EndDate") + " *"}
                                        defaultValue={
                                            (endDate && getTime(new Date(endDate)))
                                        }
                                        onCompleted={(el) => {
                                            setEndDate(el);
                                            setFormValues(prevValues => ({
                                                ...prevValues,
                                                endDate: el
                                            }));

                                        }}
                                    />
                                </>
                            )}
                            <CurrencyField
                                label={t("TotalAmount") + " *"}
                                placeholder={"0"}
                                value={amount}
                                className={"min-w-[10vw] xl:mr-12"}
                                currency={paymentType && paymentType.value !== "percentage" ? (context.organizationInfo && context.organizationInfo.currency) : null}
                                onChange={(el) => {
                                    const formattedValue = Number(el.target.value)
                                    setFormValues(prevValues => ({
                                        ...prevValues,
                                        amount: formattedValue
                                    }));
                                    setAmount(formattedValue)
                                }}
                                inputClassName={"px-1 py-3 max-w-[100px]"}
                            />



                        </div>
                        <div className="flex justify-between items-center mt-8">

                            <div
                                className={`font-bold cursor-pointer ${ (values.idSupplier || values.idAccommodation || values.idBooking) ? "opacity-60 pointer-events-none " : ""}`}
                                onClick={() => { onGoBack(formValues) }}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                                {t("Previous")}
                            </div>
                            <PrimaryButton
                                content={t("Save")}
                                className={"p-3 bg-blue w-[7vw]"}
                                onClick={() => {
                                    checkValues()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {modalType === "Category" && <ModalCreate
                externalControl
                status={modal}
                body={
                    <>
                        <NewCategoryForm
                            onCloseModal={onCloseModal}
                            categoryData={{
                                typology: values.typology,
                                category: values.category ?? null,
                                subCategory: null
                            }}
                            isDisabled={true}
                            onComplete={(categoryData) => {
                                create("CostCategory", context.idOrganization, categoryData)
                                    .then(() => {
                                        context.updateData("cost-categories");
                                        setOpenModal(false);
                                    })
                                    .catch((err) => {
                                        toast.error(t("ErrorOccurred") + err);
                                    }
                                    );
                            }}
                        />

                    </>}
                title={t("NewCategory")}
                className={""}
                onClose={() => setOpenModal(false)}
            />
            }
            {modalType === "SubCategory" && <ModalCreate
                externalControl
                status={modal}
                body={
                    <NewSubCategoryForm
                        onCloseModal={onCloseModal}
                        isDisabled={true}
                        categoryData={{
                            typology: values.typology,
                            category: values.category ?? null,
                            subCategory: subCategoryOptions
                        }}
                        onComplete={(categoryData) => {
                            create("CostCategory", context.idOrganization, categoryData)
                                .then(() => {
                                    context.updateData("cost-categories");
                                    setOpenModal(false);
                                })
                                .catch((err) => {
                                    toast.error(t("ErrorOccurred") + err);
                                }
                                );
                        }}
                    />
                }
                title={t("NewSubCategory")}
                className={""}
                onClose={() => setOpenModal(false)}
            />}

        </>
    );
};

export default ProceedsStep;