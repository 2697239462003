import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import { create, update } from "../../utils/functions";
import TransactionTypologyStep from './steps/transactionStep.js';
import CostStep from './steps/costStep.js';
import ProceedsStep from './steps/proceedsStep.js';

import { useNavigate } from "react-router-dom";

export const ManageTransaction = (data, isEditable) => {

  const itemToUpdate = data.data;
  const { i18n, t } = useTranslation();
  const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
  const Id = itemToUpdate.id ?? null;
  const [index, setIndex] = useState(0);
  const context = useContext(DataContext);
  const navigator = useNavigate();
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const [step, setStep] = useState(1);

  const [formValues, setFormValues] = useState({
    id: Id,
    typology: (itemToUpdate && itemToUpdate.typology) || null, // accommodation, booking, company
    type: (itemToUpdate && itemToUpdate.type) || null, // cost or earning
    recurrencyInternal: (itemToUpdate && Id !== null) && !itemToUpdate.recurrency ? "tantum" : null,
    idBookingRule: (itemToUpdate && itemToUpdate.idBookingRule) || null,
    idBooking: (itemToUpdate && itemToUpdate.idBooking) || null,
    category: (itemToUpdate && itemToUpdate.category) || null,
    subCategory: (itemToUpdate && itemToUpdate.subCategory) || null,
    idSupplier: (itemToUpdate && itemToUpdate.idSupplier) || null,
    date: (itemToUpdate && itemToUpdate.date) || null,
    paymentDate: (itemToUpdate && itemToUpdate.paymentDate) || null,
    paymentType: (itemToUpdate && itemToUpdate.paymentType) || null,
    amount: (itemToUpdate && itemToUpdate.amount) || null,
    idAccommodation: (itemToUpdate && itemToUpdate.idAccommodation) || null,
    recurrency: (itemToUpdate && itemToUpdate.recurrency) || null,
    startDate: (itemToUpdate && itemToUpdate.startDate) || null,
    endDate: (itemToUpdate && itemToUpdate.endDate) || null,
    recurrencyCustom: (itemToUpdate && itemToUpdate.recurrencyCustom) || null

  });
 
  useEffect(() => {
    if (Id) {
      setStep(2);
    }
  }, [Id]);

  const handleSubmit = (values) => {
    if (IsEditable && Id) {
      update("Transaction", context.idOrganization, values).then(async () => {
        context.updateData("transactions");
        navigator("../transactions");
      });
    } else {
      create("Transaction", context.idOrganization, values).then(async () => {
        context.updateData("transactions");
        navigator("../transactions");
      });
    }

  }

  return (
    <div className="w-full">
      <div onClick={() => navigator("../transactions")} className="mb-10 flex cursor-pointer max-w-[250px]">
        <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
        <h1 className="text-2xl font-bold">{t("Transactions")}</h1>
      </div>

      <div className="w-full mt-16 xl:ml-24 min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7">
        {step === 1 && (
          <TransactionTypologyStep
            onComplete={(values) => {
              setFormValues({ ...formValues, ...values })
              setStep(2);
            }}
            values={formValues}

          />
        )}
        {step === 2 && formValues.type === 'cost' && (
          <CostStep
            onGoBack={(values) => {
              setFormValues({ ...formValues, ...values });
              setStep(1);
            }}
            onComplete={(values) => {
              setFormValues(values)
              handleSubmit(values);
            }}
            values={formValues}
          />
        )}
        {step === 2 && formValues.type === 'earning' && (
          <ProceedsStep
            onGoBack={(values) => {
              setFormValues({ ...formValues, ...values });
              setStep(1);
            }}
            onComplete={(values) => {
              setFormValues({ ...formValues, ...values })
              handleSubmit();
            }}
            values={formValues}
          />
        )} 

      </div>

    </div>
  );
}