import React, { useContext, useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { DataContext } from "../../App";
import LoadingApp from "../shared/loadingApp";
import SecondaryButton from "../../components/buttons/secondary-button";
import { HiRefresh } from "react-icons/hi";
import { updateAccount } from "../../utils/functions"
import ButtonTertiary from '../../components/buttons/tertiary-button';
import { toast } from "react-toastify";
import { Modal, Box, Button,  Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import SvgIcon from '@mui/material/SvgIcon';

import { AlertTriangle } from 'react-feather';

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Dashboard() {
  const context = useContext(DataContext);
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [param, setParams] = useState();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);


  const [showWelcomeBox, setShowWelcomeBox] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5;
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const CustomBackdrop = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)', 
      }}
    />
  );

  const openMenu = () => {    
    window.dispatchEvent(new Event('openMenu'));
  };

  const closeMenu = () => {
    window.dispatchEvent(new Event('closeMenu'));
  };

  const handleBothOpen = (event) => {
    setOpen(true);
    setShowWelcomeBox(false);
  };

  const handleClose = () => {

    setOpen(false);
    setOpenModal(true);
    setDropdownOpen(false);
    setCurrentStep(1);
    setShowWelcomeBox(true);
  };

  // const handle

  const handleNextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      handleClose();
    }
  };

  const handleCloseBox = () => {
    setOpenModal(true);
    
  };

  const isMobileTablet = window.matchMedia("(max-width: 991px)").matches;

  const details = {
    clientId: "c1875610-5313-4dd3-8a9e-dbadcd4f54a3",
    clientSecret: "7lj8Q~MvTok4Rg~Bo-6clvhyHYxE4fAZDyI5tai3",
    tenant: "e26be971-01ef-43ce-8169-9ac6e2554af0"
  };

  // TODO: da far funzionare il report di PowerBi richiamando il backend
  useEffect(() => {
    if (context.organizationInfo.powerBiWorkspaceId && context.organizationInfo.powerBiReportId && loading) {
      fetch('https://api.bilemon.com/api/token', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(details),
      }).then(async (response) => {
        if (response.ok) {
          let tokenObject = await response.json();
          setParams(await getEmbedParamsForSingleReport(context.organizationInfo.powerBiWorkspaceId, context.organizationInfo.powerBiReportId, tokenObject["access_token"]));
          setLoading(false);
        }
      }).catch(error => toast.error(t("ErrorOccurred") + error));
    }
  }, [context.organizationInfo, loading, details, t]);

  const stepContent = [
    {
      title: t("AccountConfiguration"),
      subtitle: t("AccountConfigurationSubtitle"),
      text: t("AccountSettingsText"),
    },
    {
      title: t("NewAccommodation"),
      subtitle: t("AccomodationSubtitle"),
      text: t("AccomodationText"),

    },
    {
      title: t("NewCostRulesAdd"),
      subtitle: t("NewCostRulesSubTitle"),
      text: t("NewCostRulesText")
    },
    {
      title: t("AddBooking"),
      subtitle: t("NewBookingSubtitle"),
      text: t("NewBookingText")
    },
    {
      title: t("InfoTitle"),
      text: t("InfoText")
    }
  ];

  return (
    <>
      {context.organizationInfo.powerBiWorkspaceId && context.organizationInfo.powerBiReportId ?
        loading ? <LoadingApp /> :
          <>
            {/* <SecondaryButton
              content={
                <>
                  <HiRefresh className={'opacity-50 '} />
                  <div className="premium-required-tooltip color-disabled font-semibold text-lg" data-tooltip={t("PremiumRequired")} >
                    <span className={`opacity-50 ${isMobileTablet ? 'text-sm hidden md:inline' : 'text-base'}`}>{t("Refresh")}</span>
                  </div>
                </>
              }
              className={`absolute top-30 flex items-center justify-center hover:brightness-95 right-12 mx-8 mt-1 p-5 text-white rounded-xl w-6 h-6 cursor-not-allowed`}
            /> */}

            <div className="w-full">
              <h1 className={` font-bold ${isMobileTablet ? 'text-2xl mt-2' : 'text-3xl'}`}>Dashboard</h1>
              <div className={`items-start justify-start mt-10 w-full ${isMobileTablet ? '' : 'px-5 '} minWidth`}>
                <div className="w-full h-full mx-auto">
                  {<PowerBIEmbed
                    embedConfig={{
                      type: 'report',
                      id: param.reportsDetail[0].Id,
                      embedUrl: param.reportsDetail[0].EmbedUrl,
                      accessToken: param.EmbedToken.token,
                      tokenType: models.TokenType.Embed,
                      settings: {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: true,
                        layoutType: isMobileTablet ? models.LayoutType.MobilePortrait : models.DisplayOption.FitToWidth,
                      }
                    }}
                  />}
                </div>
              </div>
            </div>
          </>
        :
        showWelcomeBox && (
          <div className="flex flex-wrap flex-col items-center justify-center mx-auto text-xl font-semibold welcome-box rounded-lg">
            <img className="w-20 h-20" src="/Icon.png" alt="Icon" />
            <h2 className="mt-4 text-base text-center font-bold text-petroil mb-2"> {t("Welcome")}</h2>
            <h5 className="text-sm font-bold text-blue text-center mb-2">{t("AccountSubtitle")}</h5>
            <p className="text-gray-700 block px-4 py-2 text-sm">{t("AcccountText")}</p>

            <Button className="text-capitalize text-md font-bold text-blue text-onclick text-center mb-2 mt-4" onClick={() => {
              handleBothOpen();
              if(!isMobileTablet){

                openMenu();              
              }
            }
              }>
              {t("StartAccount")}
            </Button>
          </div>
        )
      }


      {open && (
        <>
          <div className="fixed inset-0 z-40 bg-black bg-opacity-0" onClick={handleClose}></div>          
          <div className="fixed inset-0 z-50 flex items-center justify-center backdrop red-hat-font">
            {!isMobileTablet && <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"              
              BackdropComponent={CustomBackdrop}
              disableBackdropClick={true} 
              sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  fontFamily: "'Red Hat Display', sans-serif !important",
                },
              }}
            >
              <Box sx={{
                position: 'absolute' ,
                transform: 'translateY(-50%)',
                width: '90%',
                maxWidth: 400,
                bgcolor: '#EEF1F2',
                marginTop: '10rem',
                marginX: '1.5rem',
                p: 4,
                borderRadius: '0.5rem',
                textAlign: currentStep === 1 ? 'center' : 'center',
                fontFamily: "'Red Hat Display', sans-serif !important",
                '@media (min-width: 1920px)': {
                left: currentStep === 1 ? '63%' : currentStep === 2 ? '13%' : currentStep === 3 ? '13%' : currentStep === 4 ? '13%' : currentStep === 5 ? '69.5%' : '50%',
                right: currentStep === 1 ? '5%' : currentStep === 2 ? 'auto' : currentStep === 5 ? '5%' : '20%',
                top: currentStep === 1 ? '10%' : currentStep === 2 ? '17%' : currentStep === 3 ? '27%' : currentStep === 4 ? '33%' : currentStep === 5 ? '11%' : '50%',
                },
                '@media (min-width: 1440px) and (max-width: 1919px)': {
                  left: currentStep === 1 ? '50%' : currentStep === 2 ? '17%' : currentStep === 3 ? '17%' : currentStep === 4 ? '17%' : currentStep === 5 ? '59%' : '50%',
                  right: currentStep === 1 ? '20%' : currentStep === 2 ? 'auto' : currentStep === 5 ? 'auto' : '20%',
                  top: currentStep === 1 ? '10%' : currentStep === 2 ? '18%' : currentStep === 3 ? '28%' : currentStep === 4 ? '35%' : currentStep === 5 ? '12%' : '50%',
                },
                '@media (min-width: 1024px) and (max-width: 1439px)': {
                  left: currentStep === 1 ? '33%' : currentStep === 2 ? '25%' : currentStep === 3 ? '25%' : currentStep === 4 ? '25%' : currentStep === 5 ? '46.5%' : '50%',
                  right: currentStep === 1 ? '20%' : currentStep === 2 ? 'auto' : currentStep === 5 ? '5%' : '20%',
                  top: currentStep === 1 ? '7%' : currentStep === 2 ? '18%' : currentStep === 3 ? '28%' : currentStep === 4 ? '35%' : currentStep === 5 ? '8%' : '50%',
                },
                
              }}>

                {/* Arrow icon */}
                {currentStep === 1 && (
                  <SvgIcon
                    sx={{
                      position: 'absolute',
                      top: '35px',
                      right: '-10px',
                      transform: 'rotate(45deg)',
                      width: '40px',
                      height: '40px',
                      fill: '#EEF1F2',
                      backgroundColor: '#EEF1F2',
                      borderRadius: '2px',
                    }}
                  >
                    <polygon points="0,0 10,0 10,10" />
                  </SvgIcon>
                )}
                {currentStep == 2 && (
                  <SvgIcon
                    sx={{
                      position: 'absolute',
                      top: '30px',
                      left: '-10px',
                      transform: 'rotate(-45deg)',
                      width: '40px',
                      height: '40px',
                      fill: '#EEF1F2',
                      backgroundColor: '#EEF1F2',
                      borderRadius: '2px',
                    }}
                  >
                    <polygon points="0,0 10,0 10,10" />
                  </SvgIcon>
                )}
                {currentStep == 3 && (
                  <SvgIcon
                    sx={{
                      position: 'absolute',
                      top: '55px',
                      left: '-10px',
                      transform: 'rotate(-45deg)',
                      width: '40px',
                      height: '40px',
                      fill: '#EEF1F2',
                      backgroundColor: '#EEF1F2',
                      borderRadius: '2px',
                    }}
                  >
                    <polygon points="0,0 10,0 10,10" />
                  </SvgIcon>
                )}
                {currentStep == 4 && (
                  <SvgIcon
                    sx={{
                      position: 'absolute',
                      top: '80px',
                      left: '-10px',
                      transform: 'rotate(-45deg)',
                      width: '35px',
                      height: '35px',
                      fill: '#EEF1F2',
                      backgroundColor: '#EEF1F2',
                      borderRadius: '2px',
                    }}
                  >
                    <polygon points="0,0 10,0 10,10" />
                  </SvgIcon>
                )}
                {currentStep == 5 && (
                  <SvgIcon
                    sx={{
                      position: 'absolute',
                      top: '-11px',
                      right: '20px',
                      transform: 'rotate(-45deg)',
                      width: '50px',
                      height: '50px',
                      fill: '#EEF1F2',
                      backgroundColor: '#EEF1F2',
                      borderRadius: '2px',
                    }}
                  >
                    <polygon points="0,0 10,0 10,10" />
                  </SvgIcon>
                )}

<div style={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer', color: 'rgb(10, 57, 69)' }} onClick={() => {
  handleClose();

}}>
                  <IoMdClose size={20} />
                </div>
                <Typography id="modal-title" variant="h6" component="h5" sx={{
                  fontWeight: 700,
                  color: '#2E6D67',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  textTransform: currentStep === 5 ? 'uppercase' : 'initial',
                  fontFamily: "'Red Hat Display', sans-serif !important",
                }}>
                  {stepContent[currentStep - 1].title}
                </Typography>
                <Typography id="modal-description" sx={{
                  fontWeight: 700,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginBottom: '1rem',
                  color: '#0A3945',
                  fontFamily: "'Red Hat Display', sans-serif !important",
                }}>
                  {stepContent[currentStep - 1].subtitle}
                </Typography>
                <Typography id="modal-description" sx={{
                  fontSize: '0.875rem;',
                  lineHeight: '1.25rem',
                  textAlign: 'left',
                  marginBottom: '1rem',
                  color: '#5F7E85',
                  fontFamily: "'Red Hat Display', sans-serif !important",
                }}>
                  {stepContent[currentStep - 1].text}
                </Typography>

                {currentStep === totalSteps ? (
                  <Link
                    onClick={handleClose}
                    variant="contained"
                    className="text-sm font-bold text-blue text-center mb-2"
                  >
                    {t("Finish")}
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      handleNextStep()
                      closeMenu()
                    }}
                    variant="contained"
                    className="text-sm font-bold text-blue text-center mb-2"
                  >
                    {t("Continue")}
                  </Link>
                )}

                <Typography sx={{
                  fontSize: '0.875rem;',
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginTop: '1rem',
                  color: '#5F7E85',
                  fontFamily: "'Red Hat Display', sans-serif !important",
                }}>
                  {`${currentStep}/${totalSteps}`}
                </Typography>


              </Box>
            </Modal>}
            {isMobileTablet && <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              disableBackdropClick={true} 
              sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0)',                  
                  fontFamily: "'Red Hat Display', sans-serif !important",
                },
              }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '92%',
                maxWidth: 400,
                bgcolor: '#EEF1F2',
                marginTop: '8.3rem',
                marginLeft: '1rem',
                p: 4,
                borderRadius: '0.5rem',
                textAlign: 'center',
                fontSize: '1.25rem', 
                fontWeight: '600',
                fontFamily: "'Red Hat Display', sans-serif !important",
                
              }}>

{/* <div style={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer', color: 'rgb(10, 57, 69)' }} onClick={() => {
  handleClose();

}}>
                  <IoMdClose size={20} />
                </div> */}
                <Typography id="modal-title" variant="h6" component="h5" sx={{
                  fontWeight: 700,
                  color: '#2E6D67',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  textTransform: currentStep === 5 ? 'uppercase' : 'initial'
                }}>
                  {stepContent[currentStep - 1].title}
                </Typography>
                <Typography id="modal-description" sx={{
                  fontWeight: 700,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginBottom: '1rem',
                  color: '#0A3945'
                }}>
                  {stepContent[currentStep - 1].subtitle}
                </Typography>
                <Typography id="modal-description" sx={{
                  fontSize: '0.875rem;',
                  lineHeight: '1.25rem',
                  textAlign: 'left',
                  marginBottom: '1rem',
                  color: '#5F7E85',

                }}>
                  {stepContent[currentStep - 1].text}
                </Typography>

                {currentStep === totalSteps ? (
                  <Link
                    onClick={handleClose}
                    variant="contained"
                    className="text-sm font-bold text-blue text-center mb-2"
                  >
                    {t("Finish")}
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      handleNextStep()
                      closeMenu()
                    }}
                    variant="contained"
                    className="text-sm font-bold text-blue text-center mb-2"
                  >
                    {t("Continue")}
                  </Link>
                )}

                <Typography sx={{
                  fontSize: '0.875rem;',
                  lineHeight: '1.25rem',
                  textAlign: 'center',
                  marginTop: '1rem',
                  color: '#5F7E85'
                }}>
                  {`${currentStep}/${totalSteps}`}
                </Typography>


              </Box>
            </Modal>}
          </div>
          {/* {openModal && (
                <Modal
                    isOpen={openModal}
                    onRequestClose={closeModal}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            width: "20%",
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            border: '1px solid red'
                        }
                    }}
                >

                    <div className="flex justify-between">
                        <div></div>
                        <div className='mt-2 ml-4'><AlertTriangle size={32} color='red' /></div>
                        <div className="">
                            <ButtonTertiary
                                id={"closeModal"}
                                className={"btn text-petroil-1"}
                                onClick={closeModal}
                                content={<FontAwesomeIcon icon={faTimes} size='lg' />}
                                isDisabled={false}
                            />
                        </div>

                    </div>
                    <div className='d-flex flex-column text-center h-100'>
                        <h4 className='pb-2 mt-3 text-petroil-1 font-semibold'>{t("DeleteQuestionMulti")}</h4>
                        <p className="text-warning font-normal text-petroil-0 mb-4 "><small>{t("DeleteQuestionParagraph")}</small></p>                        
                        <div className="d-flex justify-content-around mt-auto">
                                <ButtonTertiary
                                    id={"deleteSelectedItems"}
                                    className={"btn btn-danger font-bold text-petroil-1"}
                                    onClick={() => {

                                      setShowWelcomeBox(false);
                                      
                                    }}
                                    content={t("DeleteItemsConfirmationModal")}
                                    isDisabled={false}
                                />

                            
                        </div>
                    </div>
                </Modal>
            )} */}
        </>
      )}
      {/* {openModal && (
            <Modal
                isOpen={openModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        width: "20%",
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        border: '1px solid red'
                    }
                }}
            >
  
                <div className="flex justify-between">
                    <div></div>
                    <div className='mt-2 ml-4'><AlertTriangle size={32} color='red' /></div>
                    <div className="">
                        <ButtonTertiary
                            id={"closeModal"}
                            className={"btn text-petroil-1"}
                            onClick={closeModal}
                            content={<FontAwesomeIcon icon={faTimes} size='lg' />}
                            isDisabled={false}
                        />
                    </div>
  
                </div>
                <div className='d-flex flex-column text-center h-100'>
                    <h4 className='pb-2 mt-3 text-petroil-1 font-semibold'>{t("DeleteAccountQuestion")}</h4>
                    <p className="text-warning font-normal text-petroil-0 mb-4 "><small>{t("DeleteAccountQuestionParagraph")}</small></p>
                    <ButtonTertiary
                                    id={"deleteSelectedItems"}
                                    className={"btn btn-danger font-bold text-petroil-1"}
                                    onClick={() => {
                                      updateAccount({ tutorialIsActive : "false"})
                                    }}
                                    content={t("DeleteItemsConfirmationModal")}
                                    isDisabled={false}
                                />
                </div>
            </Modal>
        )} */}


    </>
  );

}


async function setRefreshDataSet(workspaceId, reportId, dataSetId, accessToken) {
  const reportInGroupApi = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/datasets/${dataSetId}/refreshes`;

  const result = await fetch(reportInGroupApi, {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${accessToken}`
    },
    body: {
      type: "full",
      maxParallelism: 10
    }

  })

  if (!result.ok) {
    let error = await result.text();
    throw result;
  }

  const resultJson = await result.json();

  let powerBiDetails = {
    Id: resultJson.id,
    Name: resultJson.name,
    EmbedUrl: resultJson.embedUrl
  }

  let powerBiConfig = {
    reportsDetail: [powerBiDetails]
  };

  // Create list of datasets
  let datasetIds = [resultJson.datasetId];

  // Get Embed token multiple resources
  powerBiConfig.EmbedToken = await getEmbedTokenForSingleReportSingleWorkspace(reportId, datasetIds, workspaceId, accessToken);
  return powerBiConfig;

}

async function getEmbedParamsForSingleReport(workspaceId, reportId, accessToken) {
  const reportInGroupApi = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}`;

  // Get report info by calling the PowerBI REST API
  const result = await fetch(reportInGroupApi, {
    method: 'GET',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${accessToken}`
    },
  })

  if (!result.ok) {
    let error = await result.text();
    throw result;
  }

  // Convert result in json to retrieve values
  const resultJson = await result.json();



  let powerBiDetails = {
    Id: resultJson.id,
    Name: resultJson.name,
    EmbedUrl: resultJson.embedUrl
  }

  let powerBiConfig = {
    reportsDetail: [powerBiDetails]
  };

  // Create list of datasets
  let datasetIds = [resultJson.datasetId];

  // Get Embed token multiple resources
  powerBiConfig.EmbedToken = await getEmbedTokenForSingleReportSingleWorkspace(reportId, datasetIds, workspaceId, accessToken);
  return powerBiConfig;
}


async function getEmbedTokenForSingleReportSingleWorkspace(reportId, datasetIds, targetWorkspaceId, accessToken) {

  // Add report id in the request
  let formData = {
    'reports': [{
      'id': reportId
    }]
  };

  // Add dataset ids in the request
  formData['datasets'] = [];
  for (const datasetId of datasetIds) {
    formData['datasets'].push({
      'id': datasetId
    })
  }

  // Add targetWorkspace id in the request
  if (targetWorkspaceId) {
    formData['targetWorkspaces'] = [];
    formData['targetWorkspaces'].push({
      'id': targetWorkspaceId
    })
  }

  const embedTokenApi = "https://api.powerbi.com/v1.0/myorg/GenerateToken";

  // Generate Embed token for single report, workspace, and multiple datasets. Refer https://aka.ms/MultiResourceEmbedToken
  const result = await fetch(embedTokenApi, {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${accessToken}`
    },
    body: JSON.stringify(formData)
  });

  if (!result.ok) {
    let error = await result.text();
    throw result;
  }
  return result.json();

}



export default Dashboard;
