import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch } from 'react-router-dom';
import Password from './Password';
import TwoStepVerification from './TwoStepVerification';

const SecurityIndex = () => {


  const { t } = useTranslation();
  const navigator = useNavigate();

  const isPassword = useMatch("security/password");
  const isTwoStep = useMatch("security/two-step-verification");

  return (
    <>
      <h1 className='ml-[3vw] text-xl font-semibold'>{t("PasswordSettings")}</h1>
      <div className='md:flex'>
        <div className='sidebar-profile mt-16'>
          <div
            className={`sidebar-item-company ${isPassword ? "active" : ""} flex justify-between hover:text-[#0A6D67]`}
            onClick={() => navigator("../security/password")}
          >

            <div className={`flex  ${!isPassword ? "ml-5" : ""}`}>
              {isPassword && (<div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>)}
              {t("Password")}
            </div>
            <div className="">
              {">"}
            </div>
          </div>
          <div
            className={`sidebar-item-company flex justify-between hover:text-[#0A6D67] pointer-events-none`}
            onClick={() => navigator("../security/two-step-verification")}
          >
            <div className={`flex  ${!isTwoStep ? "ml-5" : ""}`}>
              {isTwoStep ? (<div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>)
                :
                ("")}
              {t("TwoStepVerification")}
            </div>
            <div className="">
              {/* {">"} */}
            </div>
          </div>
        </div>

        {isPassword && (
          <Password />
        )}
        {/* {isTwoStep && (
      <TwoStepVerification/>
    )} */}
      </div>
    </>
  );
};

export default SecurityIndex;
