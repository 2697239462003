import React from "react";
import { ManageBooking } from "./ManageBooking";



export const NewBooking = () => {

    return (
        <div className={"md:p-4 pt-5 mx-auto"}>
            <div className={"flex"}>
                <ManageBooking data={null} />
            </div>
        </div>
    );
};