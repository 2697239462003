import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { getCountries } from "react-phone-number-input";
import { getAuth } from "firebase/auth";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, differenceInDays } from 'date-fns';

import { PayPalButton } from "../../components/buttons/paypal-button"

import { it } from 'date-fns/locale';
import { DataContext } from "../../App";

const Plan = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  
  const [options, setOptions] = useState();
  const context = useContext(DataContext);
  const auth = getAuth();
  const diffTime = differenceInDays(context.organizationInfo.subscription.expired, new Date());
  const namePlan = context.organizationInfo.subscription.plan === "host" ? "Host" : t("PropertyManager");
  const [view, setView] = useState("host"); 

  const formattedDate = format(parseISO(context.organizationInfo.subscription.expired), 'dd/MM/yyyy', { locale: it });
  useEffect(() => {
    setOptions(
      getCountries().map((countryCode) => {
        return { label: countryCode, value: countryCode };
      })
    );
  }, []);

  return (
    <div className='content-company md:ml-5'>
      <h2 className="text-xl ml-1 font-semibold mt-10 md:mt-0">{t("EditSubscription")}</h2>
      <div className='mt-20 ml-1 '>
        <div className='flex justify-center md:justify-left'>
          <div className={`relative min-w-[20vw] min-h-[15vh] rounded-xl md:mr-8 p-6 ${view === "host" ? "selected subscription-plan" : "bg-[rgb(113,167,165)]"}`}>
            {view === "host" && (
              <div className="absolute top-[-2.4vh] right-[-0.9vw] m-2">
                <div className="bg-white rounded-full">
                  <FontAwesomeIcon icon={faCheckCircle} size='xl' color='rgb(68, 202, 68)' />
                </div>
              </div>
            )}
            <div className="flex justify-between mb-2">
              <div className="">
                <h2 className={`font-bold ${view !== "host" && "text-white"}`}>
                  {context.organizationInfo.subscription.plan === "host" ? t("Host") : t("PropertyManager")}
                </h2>
              </div>
              <div className="font-bold">
                <div className={`${view !== "host" && "text-white"}`}>
                  {context.organizationInfo.subscription.amount.toFixed(2)}{context.organizationInfo.currency}/
                  <span className={`${view !== "host" && "text-white opacity-[0.5]"}`}>{t("Month")}</span>
                </div>
                <div className={`${view !== "host" && "text-white opacity-[0.5]"} text-sm`}>
                  {(context.organizationInfo.subscription.amount * 12).toFixed(2)}{context.organizationInfo.currency}/{t("Year")}
                </div>
              </div>
            </div>
            <p className={`${view !== "host" && "text-white"}`}>{t("NumberProperties")}: {context.organizationInfo.subscription.properties}</p>
            <p className={`${view !== "host" && "text-white"}`}>{t("SubscriptionExpireOn")}: {formattedDate}</p>
          </div>
        </div>
        <div className="flex justify-center md:justify-left">
          <PayPalButton
            amount={context.organizationInfo.subscription.amount.toFixed(2)}
            description={"Monthly payment Plan " + namePlan + " - id: " + context.idOrganization}
            subscriptionInfo={context.organizationInfo.subscription}
            idOrganization={context.idOrganization}
          />
        </div>
      </div>
    </div>
  );
};

export default Plan;
