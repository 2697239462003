import firebase from 'firebase/compat/app'
import { getPerformance } from "firebase/performance"
import { getAnalytics } from "firebase/analytics"
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions } from 'firebase/functions';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Firebase config file
const firebaseConfig = {
  apiKey: "AIzaSyDFgaGeeGp7uZnecl83S8KjkVahEFNvS8o",
  authDomain: "bilemon-stage.firebaseapp.com",
  projectId: "bilemon-stage",
  storageBucket: "bilemon-stage.appspot.com",
  messagingSenderId: "149148761144",
  appId: "1:149148761144:web:0196aecea0eac4772ffacd",
  measurementId: "G-7QXYMG8JSQ"
};

// Init firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const firestoreDB = getFirestore(app);
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);

// Init Services
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6Leo4WwpAAAAADsGLUveLR1AV7FEVnrL4CBAMRsu"),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: false,
});

export { app, auth, appCheck, functions, analytics, firestoreDB };