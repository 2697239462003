import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import TransactionTypologyStep from './steps/transactionStep.js';
import CostStep from './steps/costStep.js';
import ProceedsStep from './steps/proceedsStep.js';
import { create, update } from "../../utils/functions";
import { useNavigate } from "react-router-dom";

export const ManageBookingRule = (data, isEditable) => {
  const itemToUpdate = data.data;
  const { i18n, t } = useTranslation();
  const Id = itemToUpdate.id ?? null;
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    id: Id,
    type: (itemToUpdate && itemToUpdate.type) || null,
    name: (itemToUpdate && itemToUpdate.name) || null,
    idAccommodations: (itemToUpdate && itemToUpdate.idAccommodations) || null,
    category: (itemToUpdate && itemToUpdate.category) || null,
    subCategory: (itemToUpdate && itemToUpdate.subCategory) || null,
    idSupplier: (itemToUpdate && itemToUpdate.idSupplier) || null,
    startDate: (itemToUpdate && itemToUpdate.startDate) || null,
    endDate: (itemToUpdate && itemToUpdate.endDate) || null,
    paymentType: (itemToUpdate && itemToUpdate.paymentType) || null,
    trigger: (itemToUpdate && itemToUpdate.trigger) || null,
    amount: (itemToUpdate && itemToUpdate.amount) || null
  });
  const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
  const context = useContext(DataContext);
  const navigator = useNavigate();

  useEffect(() => {
    if (Id) {
      setStep(2);
    }
  }, [Id, formValues]);
   
  const handleSubmit = (values) => {
    if (IsEditable && Id) {
      update("BookingRule", context.idOrganization, values).then(async () => {
        context.updateData("booking-rules");
        navigator("../booking-rules");
      });
    } else {
      create("BookingRule", context.idOrganization, values).then(async () => {
        context.updateData("booking-rules");
        navigator("../booking-rules");
      });
    }

  }

  return (
    <div className="w-full">
      <div onClick={() => navigator("../booking-rules")} className="mb-10 flex cursor-pointer max-w-[350px]">
        <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
        <h1 className="text-2xl font-bold">{t("BookingRules")}</h1>
      </div>

      <div className="w-full mt-16 xl:ml-24 min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7">
        {step === 1 && (
          <TransactionTypologyStep
            onComplete={(values) => {
              setFormValues(prevValues => ({
                ...prevValues, ...values
              }))
              setStep(2);
            }}
            values={formValues}

          />
        )}
        {step === 2 && formValues.type === 'cost' && (
          <CostStep
            onGoBack={(values) => {
              setFormValues({ ...formValues, ...values });
              setStep(1);
            }}
            onComplete={(values) => {
              setFormValues(values);
              handleSubmit(values);
            }}
            values={formValues}
          />
        )}
        {step === 2 && formValues.type === 'earning' && (
          <ProceedsStep
            onGoBack={(values) => {
              setFormValues({ ...formValues, ...values });
              setStep(1);
            }}
            onComplete={(values) => {
              setFormValues({ ...formValues, ...values })
              handleSubmit();
            }}
            values={formValues}
          />
        )} 

      </div>

    </div>
  );
}