import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import {
  getCountryCallingCode,
  getCountries,
  isValidPhoneNumber
} from "react-phone-number-input";
import {
  getAuth
} from "firebase/auth";
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu"; 
import SecondaryButton from "../../components/buttons/secondary-button";
import { updateAccount, deleteAccountRequest } from "../../utils/functions"
import { DataContext } from "../../App";
import { GeneralOptions } from "../../resources/selectOptions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../components/buttons/tertiary-button';
import PrimaryButton from '../../components/buttons/primary-button';

const Profile = () => {
  const { i18n, t } = useTranslation();
  const auth = getAuth();
  const context = useContext(DataContext)
  const account = context.accountExtraInfo;
  const optionsLanguage = GeneralOptions("language");
  const [deleteDialog, setDeleteDialog] = useState(false);

  const navigator = useNavigate();

  const [phone, setPhone] = useState((account && account.phoneNumber && account.phoneNumber.phone));
  const [prefix, setPrefix] = useState((account && account.phoneNumber && account.phoneNumber.prefix));

  const [displayName, setDisplayName] = useState(account && account.displayName);
  const [id, setId] = useState(account && account.id);
  const [email, setEmail] = useState(account && account.email);
  const [language, setLanguage] = useState((account && account.language) ?? "en");



  const [optionsCallingCode, setOptionsCallingCode] = useState();


  const [formValues, setFormValues] = useState({
    id: id,
    displayName: displayName,
    phoneNumber:{
      prefix: prefix,
      phone: phone,
    },
    email: email,
    language: language
  });

  function closeModal() {
    setDeleteDialog(false);
}
  
  const checkFields = () => {

    if (
      !(
        formValues.displayName &&
        formValues.phoneNumber.phone === phone && 
        formValues.phoneNumber.prefix === prefix &&
        formValues.email
      )
    ) {
      toast.error(t("AllFieldsAreRequired"));
      return false;
    }

    const formattedPhoneNumber = prefix + phone.replace(/(\(|\))|\s|-/g, "");

    if (!isValidPhoneNumber(formattedPhoneNumber)) {
      toast.error("InvalidPhoneNumber");
      return false;
    }

    return true;
  }


  const checkChanges = () => {
    if (
      formValues.phoneNumber.phone === phone && 
      formValues.phoneNumber.prefix === prefix &&
      formValues.displayName === displayName &&
      formValues.email === email &&
      formValues.language === language
    ) {
      toast.error(t("NoChangesMade"));
      return false;
    }
    return true;
  }


  const handleUpdate = () => {

    if (checkFields && checkChanges) {
      updateAccount(formValues).then(async () => {
        context.updateData("account-info");
        i18n.changeLanguage(language);
      });  
      };
  }

  const handleDeleteRequest = () => {

    if (checkFields){
      deleteAccountRequest(formValues).then(async () => {
        setDeleteDialog(false)
      })
    }

  }




  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  }

  useEffect(() => {
    const prefixes = getCountries().map((countryCode) => `+${getCountryCallingCode(countryCode)}`);
    const uniquePrefixes = removeDuplicates(prefixes);
    setOptionsCallingCode(uniquePrefixes.map((prefix) => ({ label: prefix, value: prefix })));
  }, []);


  return (
          <div className='content-profile md:ml-5 mt-5 md:mt-0'>
          <h2 className="text-xl ml-1 font-semibold">{t("EditYourProfile")}</h2>
          <div className='mt-5 md:mt-20 ml-1'>
            <div>
              <TextField
                label={t("DisplayName")}
                className={"md:max-w-xs md:mr-10 min-w-[27vw] mb-3"}
                inputClassName={"p-3"}
                placeholder={t("Insert")}
                defaultValue={displayName}
                onChange={(e) => {
                  setDisplayName(e.target.value)
                  setFormValues(prevValues => ({
                    ...prevValues,
                    displayName: (e.target.value)
                  }));
                }}
              />
            </div>
            <div className="">
              <TextField
                label={t("Email")}
                className={"md:max-w-xs md:mr-10 min-w-[27vw] mb-3"}
                inputClassName={"p-3"}
                placeholder={t("Insert")}
                defaultValue={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setFormValues(prevValues => ({
                    ...prevValues,
                    email: (e.target.value)
                  }));
                }}
              />
            </div>
            <div className="flex">
              <SelectMenu
                label={t("Prefix")}
                placeholder={t("Select")}
                className={"w-36 mr-5 mb-3 mt-1"}
                inputClassName={"p-1"}
                options={optionsCallingCode}
                value={prefix && optionsCallingCode ? optionsCallingCode.find((x) => x.value === prefix) : prefix}
                onChange={(item) => {
                  setPrefix(item.value);
                  setFormValues(prevValues => ({
                    ...prevValues,
                    phoneNumber: {...prevValues.phoneNumber, prefix: item.value}
                  }));
                }}

              />

              <TextField
                label={t("PhoneNumber")}
                inputClassName={"p-3 max-w-xs mr-10 min-w-[19.7vw]"}
                className={"mt-1 mb-3"}
                placeholder={t("Insert")}
                onChange={(e) => {
                  setPhone(e.target.value)
                  setFormValues(prevValues => ({
                    ...prevValues,
                    phoneNumber: {...prevValues.phoneNumber, phone: e.target.value}
                  }));
                }}
                value={phone}
              />
            </div>
            <div className="">
              <SelectMenu
                      label={t("Language")}
                      options={optionsLanguage}
                      value={language && optionsLanguage ? optionsLanguage.find(x => x.value === language) : language}
                      inputClassName={"p-1 bg-input"}
                      className={"md:max-w-[27vw] mr-5 mb-3"}
                      onChange={(e) => {
                        setLanguage(e.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          language: (e.value)
                        }));
                      }}
                    />
            </div>
            <div className="flex justify-end">
              <PrimaryButton
                content={t("Save")}
                className={"mt-2 p-3 bg-blue w-[7vw] md:mr-9 mb-6"}
                onClick={() => {
                  handleUpdate();
                }}
              />
            </div>
            <hr className='mt-8 md:mr-10'></hr>
            <h2 className='font-semibold mt-4 mb-1'>{t("DeleteAccount")}</h2>
            <p>{t("DeleteAccountDescription")}</p>
            <p onClick={() => {
              setDeleteDialog(true)}
              } className='text-red underline cursor-pointer mt-4 font-semibold mb-10 md:mb-0'>{t("WantDelete")}</p>
            
          </div>
          {deleteDialog && (
            <Modal
                isOpen={deleteDialog}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        width: "25%",
                        height: "23%",
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        border: '1px solid grey'
                    }
                }}
            >
  
                <div className="flex justify-between">
                    <div></div>
                    {/* <div className='mt-2 ml-4'><AlertTriangle size={32} color='grey' /></div> */}
                    <div className="">
                        <Button
                            id={"closeModal"}
                            className={"btn text-petroil-1"}
                            onClick={closeModal}
                            content={<FontAwesomeIcon icon={faTimes} size='lg' />}
                            isDisabled={false}
                        />
                    </div>
  
                </div>
                <div className='d-flex flex-column text-center h-100'>
                    <h4 className='pb-2 mt-3 text-petroil-1 font-semibold'>{t("DeleteAccountQuestion")}</h4>
                    <p className="text-warning font-normal text-petroil-0 mb-4 "><small>{t("DeleteAccountQuestionParagraph")}</small></p>
                    <PrimaryButton
                      content={t("SendRequest")}
                      className={"mt-2 p-3 bg-blue w-[7vw] mb-4"}
                      onClick={() => {
                        handleDeleteRequest();
                      }}
                    />
                    
                </div>
            </Modal>
        )}
        </div>
  );
};

export default Profile;
