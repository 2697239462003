import React, { useContext, useState } from "react";
import { ManageOwner } from "./ManageOwner";

export const NewOwner = () => {
  return (
    <>
    <div className={"md:p-4 pt-5 mx-auto"}>
      <div className={"flex"}>
        <ManageOwner data={null}/>
      </div>
    </div>
    </>
  );
};
