import React from "react";
import InfoButton from "../buttons/info-button";
import { useTranslation } from "react-i18next";
import DropdownMenu from "../dropdownMenu/dropdownMenu";

export const Header = () => {
    const { i18n, t } = useTranslation();
    return (
        <>
            
            <div className="flex items-center justify-end">
                <div>
                    <InfoButton 
                    nameComponent={'accommodations'}
                    language={i18n.language} 
                    />
                    {/* <NotificationButton nameComponent={'accommodations'} /> */}
                </div>
                <div>
                    <DropdownMenu currentAccount={null} />
                </div>
            </div>
        </>
    )
}