import React, { useContext, useState } from "react";
import { ManageSupplier } from "./ManageSupplier";

export const NewSupplier = () => {
  return (
    <>
    <div className={"md:p-4 pt-5 mx-auto"}>
            <div className={"flex"}>
              <ManageSupplier data={null}/>
            </div>
        </div>
    </>
  );
};
