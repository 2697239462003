import React, { useState } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../../../components/buttons/primary-button";
import TextField from "../../../../components/input/fields/text-field";
import { useTranslation } from "react-i18next";
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { PasswordFieldProgress } from "../../../../components/input/fields/password-field-progress";
import SecondaryButton from "../../../../components/buttons/secondary-button";

const UserStep = ({ onComplete, formValues }) => {
  const { t } = useTranslation();

  const [accountDisplayName, setAccountDisplayName] = useState(formValues.accountDisplayName);
  const [accountEmail, setAccountEmail] = useState(formValues.accountEmail);
  const [accountPassword, setAccountPassword] = useState(formValues.accountPassword);
  const [accountConfirmPassword, setAccountConfirmPassword] = useState(formValues.accountConfirmPassword);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);

  const submit = () => {
    if (!(accountEmail)) {
      toast.error(t("EmailIsRequired"));
      return;
    }

    if (!(accountPassword && accountConfirmPassword)) {
      toast.error(t("PasswordIsRequired"));
      return;
    }

    if (
      !String(accountEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error(t("InvalidEmail"));
      return;
    }
    if (!(accountPassword === accountConfirmPassword)) {
      toast.error(t("PasswordMismatch"));
      return;
    }

    if (!String(accountPassword).match(/[A-Z]/)) {
      toast.error(t("PasswordUppercase"));
      return;
    }

    if (!String(accountPassword).match(/[a-z]/)) {
      toast.error(t("PasswordLowercase"));
      return;
    }

    if (!String(accountPassword).match(/\d/)) {
      toast.error(t("PasswordNumber"));
      return;
    }

    if (!String(accountPassword).match(/[!@#$%^&*().]/)) {
      toast.error(t("PasswordSpecialChar"));
      return;
    }

    if (String(accountPassword).length < 8) {
      toast.error(t("PasswordMinLength"));
      return;
    }

    onComplete({
      accountDisplayName: accountDisplayName,
      accountEmail: accountEmail,
      accountPassword: accountPassword,
      accountConfirmPassword: accountConfirmPassword,
    });
  };

  return (
    <div className="big-screen:ml-[10rem] m-10 container-tablet-signup">
      <div className="flex flex-col w-full my-1 space-y-2">
        <h2 className=" ml-1 mb-6 text-lg font-bold text-blue">{t("PersonalDetails")}</h2>
        <TextField
          label={t("FullName") + " *"}
          inputClassName={"p-3  bg-input"}
          labelClassName={""}
          onChange={(e) => setAccountDisplayName(e.target.value)}
          defaultValue={accountDisplayName}
          placeholder={t("Insert")}
        />
        <TextField
          label={t("Email") + " *"}
          inputClassName={"p-3  bg-input"}
          labelClassName={""}
          onChange={(e) => setAccountEmail(e.target.value)}
          defaultValue={accountEmail}
          placeholder={t("Insert")}
          controlEmail={true}
        />
        <div className="relative w-full">
          <PasswordFieldProgress
            type={isPasswordVisible ? "text" : "password"}
            label={t("Password") + " *"}
            inputClassName="p-3 my-1"
            value={accountPassword}
            onChange={(e) => setAccountPassword(e.target.value)}
          />
          <p className="text-xs">{t("RequirementsPassword")}</p>
        </div>
        <div className="relative w-full">
          <TextField
            label={t("ConfirmPassword") + " *"}
            type={isPasswordConfirmVisible ? "text" : "password"}
            inputClassName={"p-3 bg-input"}
            labelClassName={""}
            className={""}
            placeholder={t("Insert")}
            onChange={(e) => setAccountConfirmPassword(e.target.value)}
            value={accountConfirmPassword}
            showPasswordToggle={true}
            checkPassword={accountPassword}
            isPasswordVisible={isPasswordConfirmVisible}
            togglePasswordVisibility={() => setIsPasswordConfirmVisible(!isPasswordConfirmVisible)}
          />
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <SecondaryButton
          content={t("Next") + " >"}
          className="p-2 max-w-[5vw]"
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default UserStep;