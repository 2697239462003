import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiSearch } from "react-icons/hi";
import { FiAlertCircle } from "react-icons/fi";
import { IoEye, IoEyeOff } from "react-icons/io5";

const TextField = ({
  id,
  label,
  labelClassName,
  inputClassName,
  placeholder,
  onChange,
  value,
  onFocusEffect,
  onFocusEffectColor,
  onClick,
  onMouseOver,
  type,
  maxLength,
  autoComplete,
  name,
  spellCheck,
  className,
  notEditableValue,
  min,
  defaultValue,
  accept,
  filterInput,
  controlEmail,
  checkPassword,
  showPasswordToggle, 
  isPasswordVisible, 
  togglePasswordVisibility,
  disabled
}) => {
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const { t } = useTranslation();

  const handleBlur = (e) => {
    if (controlEmail) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z]{2,8})?$/;
      if (!emailRegex.test(e.target.value)) {
        setIsInvalidEmail(true);
      } else {
        setIsInvalidEmail(false);
      }
    }

    if (checkPassword && e.target.value !== checkPassword) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  const passwordToggleClass = isInvalidEmail || passwordMismatch ? "right-8" : "right-3";

  return (
    <div className={className + " flex flex-col items-start justify-start w-full relative"}>
      {label && (
        <div className="flex">
          <label className={labelClassName + " ml-1 text-lg font-semibold text-blue w-full"}>
            {label}
          </label>

          {passwordMismatch && (
            <p className="text-red text-sm absolute right-0 mt-1">{t("PasswordsDoNotMatch")}</p>
          )}
          {isInvalidEmail && (
            <p className="text-red text-sm absolute right-0 mt-1">{t("InvalidEmail")}</p>
          )}
        </div>
      )}
      <div className="w-full relative">
        <input
          id={id}
          className={
            inputClassName +
            " placeholder-color " +
            (filterInput
              ? "border-slate-300 border-solid border-[1px] pr-10 bg-white text-blue"
              : "bg-input ") +
            " w-full h-fit my-1 outline-none indent-2 text-base text-blue rounded-md " +
            (onFocusEffect
              ? onFocusEffectColor
                ? "focus:border-" + onFocusEffectColor
                : "focus:border-petroil"
              : "") +
            (isInvalidEmail || passwordMismatch ? " border-[red] border" : "")
          }
          placeholder={placeholder}
          defaultValue={defaultValue}
          type={type}
          onChange={onChange}
          onClick={onClick}
          onMouseOver={onMouseOver}
          maxLength={maxLength ? maxLength : null}
          autoComplete={autoComplete}
          value={value}
          name={name}
          spellCheck={spellCheck}
          min={min}
          accept={accept}
          onBlur={handleBlur}
          disabled={disabled ? disabled : false}
        />
        {showPasswordToggle && (
          <button
            className={`absolute ${passwordToggleClass} top-1/2 transform -translate-y-1/2`}
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? (
              <IoEyeOff className="text-base text-petroil" />
            ) : (
              <IoEye className="text-base text-petroil" />
            )}
          </button>
        )}
        {(isInvalidEmail || passwordMismatch) && (
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-red">
            <FiAlertCircle />
          </span>
        )}
        {filterInput && !isInvalidEmail && !passwordMismatch && (
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <HiSearch />
          </span>
        )}
      </div>
    </div>
  );
};

export default TextField;
