import React, { useState } from "react";
import TextField from "../input/fields/text-field";
import TertiaryButton from "../buttons/tertiary-button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export const NewSupplier = ({ onComplete, supplier, onCloseModal }) => {
  const { i18n, t } = useTranslation();
  const [fullNameCompanyName, setFullNameCompanyName] = useState(supplier ? supplier.fullNameCompanyName : null);
  const [vatNumber, setVatNumber] = useState(
    supplier ? supplier.vatNumber : null
  );
  const [category, setCategory] = useState(supplier ? supplier.category : null);
  const regexNumber = /^\+*[0-9]+$/g;
  const checkFields = () => {
    if (!fullNameCompanyName) { 
      toast.error(t("NameRequired"));
      return;
    }
    return true;
  };
  return (
      <div className="flex flex-col justify-start w-full">
        <TextField
          label={t("Name") + " *"}
          placeholder={t("Insert")}
          className={"mb-3"}
          inputClassName={"p-3 w-fit"}
          onChange={(e) => setFullNameCompanyName(e.target.value)}
          defaultValue={supplier ? supplier.fullNameCompanyName : ""}
        />
        <TextField
          label={t("VatNumber")}
          placeholder={t("Insert")}
          inputClassName={"p-3 w-fit "}
          className={"mb-3"}
          onChange={(e) => setVatNumber(e.target.value)}
          defaultValue={supplier ? supplier.vatNumber : ""}
        />
        <TextField
          label={t("Category")}
          className={"mb-3"}
          placeholder={t("Insert")}
          inputClassName={"p-3 w-fit"}
          onChange={(e) => setCategory(e.target.value)}
          defaultValue={supplier ? supplier.category : ""}
        />
      
      <div className="flex justify-end mt-4">
        <TertiaryButton
          content={supplier ? t("Edit") : t("Add")}
          className={"w-fit p-2 bg-blue min-w-[10rem]"}
          onClick={() => {
            if (checkFields())
              onComplete({
                      fullNameCompanyName: fullNameCompanyName,
                      vatNumber: vatNumber ? vatNumber : null,
                      category: category ? category : null,
                    }
              );
          }}
        />
      </div>
      </div>
  );
};
