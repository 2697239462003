import React, { useState } from "react";
import { PasswordField } from "../../components/input/fields/password-field";
import { PasswordFieldProgress } from "../../components/input/fields/password-field-progress";

import {
  getAuth,
  GoogleAuthProvider,
  updateProfile,
  updateEmail,
  sendEmailVerification,
  updatePassword,
  reauthenticateWithCredential,  
  EmailAuthProvider
} from "firebase/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/buttons/primary-button";
import TextField from "../../components/input/fields/text-field";
import { logout } from "../../utils/functions";

const Password = () => {
  const { i18n, t } = useTranslation();
  const auth = getAuth();
  const user = auth.currentUser;
  const googleProvider = new GoogleAuthProvider();

  let isGoogleLinked = false;
  user.providerData.forEach((userInfo) => {
    if (userInfo.providerId == "google.com") {
      isGoogleLinked = true;
      return;
    }
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);
  const handleSave = () => {
    if (newPassword !== newPasswordConfirm) {
      toast.error(t("PasswordMismatch"));
    } 

   const credential = EmailAuthProvider.credential(
    user.email,
    currentPassword
 )
    reauthenticateWithCredential(user, credential).then(
      updatePassword(user, newPassword)
      .then(() => {
        toast.success(t("PasswordChangedSuccess"));
      }).then(() => logout())
      .catch((error) => {
        toast.error(t("ErrorPasswordChanged"));
      })
    );

  };

  return (
    <>
      <div className='flex'>
        <div className='content-profile md:ml-5'>
          <h2 className="text-xl ml-1 font-semibold mt-10 md:mt-0">{t("EditYourPassword")}</h2>
          <div className='mt-5 md:mt-20 ml-1'>
            <div>
              <div className="mt-4 min-w-[20vw] relative">
                <TextField
                  type={isPasswordVisible ? "text" : "password"}
                  label={t("CurrentPassword")}
                  placeholder={t("Insert")}
                  inputClassName={"p-3 bg-input"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  showPasswordToggle={true}
                  isPasswordVisible={isPasswordVisible}
                  togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
                />
              </div>
            </div>
            <div className="mt-4 min-w-[20vw] relative">

              <PasswordFieldProgress
                type={isPasswordVisible ? "text" : "password"}
                label={t("NewPassword")}
                inputClassName="p-3 my-1"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <p className="text-xs">{t("RequirementsPassword")}</p>
            </div>
            <div className="mt-4 min-w-[20vw] relative">
              <TextField
                type={isPasswordConfirmVisible ? "text" : "password"}
                label={t("NewPasswordConfirm")}
                inputClassName={"p-3 bg-input"}
                placeholder={t("Insert")}
                value={newPasswordConfirm}
                showPasswordToggle={true}
                checkPassword={newPassword}
                isPasswordVisible={isPasswordVisible}
                togglePasswordVisibility={() => setIsPasswordConfirmVisible(!isPasswordConfirmVisible)}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
              />
            </div>

            <div className="flex justify-end">
              <PrimaryButton
                content={t("Save")}
                className={"mt-6 p-3 bg-blue w-[7vw] mb-6"}
                onClick={handleSave}
              />
            </div>


          </div>
        </div>
      </div>
    </>
  );
}

export default Password;