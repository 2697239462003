import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";

import Table from "../../components/table/table";


export const CostCategories = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const costCategories = context.costCategories;
  
  const organizationInfo = context.organizationInfo;
  const symbol = organizationInfo && organizationInfo.currency;
  const costCategoryTableStructure = {
    info: {
        urlUpdateData: "cost-categories",
        urlCreateUpdate: "cost-category",
        name: "CostCategory",
        header: [
            {
                field: "typology",
                header: t("Typology"),
                sortable: true,
                type: "Text",
                show: true,
            },
            {
                field: "category",
                header: t("Category"),
                sortable: true,
                type: "Text",
                show: true,
            },            
            {
                field: "subCategory",
                header: t("SubCategory"),
                sortable: true,
                type: "Text",
                show: true,
            }
            
        ],
        body: costCategories
    },
    
    exportable: false,
    importable: false,
    filterType: "filter"
  }
  
  return (
    
    <div className="w-full mt-8">
        <div className="content">
            <div className="table-wrapper">
                <Table 
                    info={costCategoryTableStructure.info}
                    exportable={costCategoryTableStructure.exportable}
                    importable={costCategoryTableStructure.importable}
                    searchable={true}
                />
            </div>
        </div>
    </div>
    
    
  );
};
