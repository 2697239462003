import React from "react";

import { ManageTransaction } from "./ManageTransaction";

export const NewTransaction = () => {


  return (
    <div className={"md:p-4 pt-5 mx-auto"}>
      <div className={"flex"}>
        <ManageTransaction data={"0"} />
      </div>
    </div>

  );
};
