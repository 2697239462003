import React, { useEffect, useState } from "react";

import { useMatch } from "react-router-dom";
import "./DragNDrop.css";
import BookingSample from "../../resources/sampleBookings.xlsx";
import AccommodationSample from "../../resources/sampleAccommodations.xlsx";
import TransactionSample from "../../resources/sampleTransactions.xlsx";
import DownloadButton from "../../components/buttons/download-button";
import Button from "../../components/buttons/primary-button";
import { useTranslation } from "react-i18next";
import ButtonGreen from '../buttons/secondary-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DragNdrop = ({ onFilesSelected, onUpdate, width, height }) => {
  const [files, setFiles] = useState([]);

  const { t } = useTranslation();

  const locationAccommodation = useMatch("accommodations/");
  const locationBooking = useMatch("bookings/");
  const locationTransaction = useMatch("transactions/");
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  return (
    <section className="drag-drop">
      <div
        className={`document-uploader ${files.length > 0 ? "upload-box active" : "upload-box"}`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <div>
              <p>{t("DragDropTitle")}</p>
              <p>{t("DragDropLimit")}</p>
            </div>
          </div>
          <input
            type="file"
            hidden
            id="browse"
            onChange={handleFileChange}
            accept=".pdf,.docx,.pptx,.txt,.xlsx,.csv"
            multiple
          />
          <div className="flex">
            <ButtonGreen
              className={'p-2 mr-4'}
              content={
                <label htmlFor="browse" className="">
                  {t("BrowseFiles")}
                </label>
              }
            />
            <DownloadButton
              className={"bg-blue text-white p-2 w-fit min-w-[170px] "}
              content={t("DownloadSample")}
              download={(locationAccommodation && "AccommodationSample") || (locationBooking && "BookingSample") || (locationTransaction && "TransactionSample")}
              to={(locationAccommodation && AccommodationSample) || (locationBooking && BookingSample) || (locationTransaction && TransactionSample)
              }
            />
          </div>
        </>

        {files.length > 0 && (
          <div className="file-list">
            <div className="file-list__container">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <div className="file-info">
                    <p>{file.name}</p>
                    {/* <p>{file.type}</p> */}
                  </div>
                  <div className="file-actions">
                    <Button onClick={() => handleRemoveFile(index)} content={<FontAwesomeIcon icon={faTimes} size='lg' />} className={'bg-transparent hover:bg-transparent p-2 px-3'} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 0 && (
          <div className="success-file">
            <Button id={"check"} className={'p-2 px-3 mr-2'} onClick={onUpdate} content={t("Upload")} isDisabled={false} />
            <p>{files.length} file(s) {t("Selected")}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragNdrop;
