import { toast } from "react-toastify";
import { firestoreDB } from "./firebaseConfig";
import {
  getAuth,
  signOut,
  sendEmailVerification,
} from "firebase/auth";

import i18next from "i18next";

const db = firestoreDB;
const auth = getAuth();

const create = async (controller, idOrganization, itemToCreate) => {

  if (!controller || !idOrganization || !itemToCreate) return;

  const token = await tokenRetrieve();
  const url = `/${controller}BE/Add`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      itemToCreate: itemToCreate,
      idOrganization: idOrganization,
    }),
  });

  const dataResp = await response.json();
  if (!response.ok) {
    if (dataResp === "ErrorPropertySameName") {
      
      toast.error(i18next.t(dataResp));
    } else {

      toast.error(i18next.t("ErrorAdding"));
    }
  }
  else if (dataResp) {
    toast.success(i18next.t("AddedSuccessfully"));
    return dataResp.id;
  }
};

const Import = async (controller, idOrganization, ImportData) => {

  if (!controller || !idOrganization || !ImportData) return;

  const token = await tokenRetrieve();
  const url = `/${controller}BE/ImportData`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      ImportData: ImportData,
      idOrganization: idOrganization,
    }),
  });

  const dataResp = await response.json();
  if (!response.ok) {
    toast.error(i18next.t("ErrorAdding"));
  }
  else if (dataResp) {
    toast.success(i18next.t("AddedSuccessfully"));
    return dataResp;
  }
};

const update = async (controller, idOrganization, itemToUpdate) => {

  if (!controller || !idOrganization || !itemToUpdate) return;

  const token = await tokenRetrieve();

  const url = `/${controller}BE/Update`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      itemToUpdate: itemToUpdate,
      idOrganization: idOrganization
    }),
  });

  const dataResp = await response.json();

  if (dataResp) {
    toast.success(i18next.t("UpdatedSuccessfully"));
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorUpdating"));
  }

};

const deleteData = async (controller, idOrganization, id) => {

  if (!controller || !id || !idOrganization) return;

  const token = await tokenRetrieve();

  const url = `/${controller}BE/Delete`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      id: id,
      idOrganization: idOrganization,
    }),
  });

  const dataResp = await response.json();
  if (!response.ok) {
    toast.error(i18next.t("ErrorUpdating"));
  }
  else if (dataResp) {
    toast.success(i18next.t("DeletedSuccessfully"));
  }
};


const deleteMultipleData = async (controller, idOrganization, ids) => {
  if (!controller || !idOrganization || !ids || !Array.isArray(ids)) return;

  try {
    const token = await tokenRetrieve();

    const url = `/${controller}BE/DeleteItems`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        ids: ids,
        idOrganization: idOrganization,
      })
    });

    const dataResp = await response.json();

    if (!response.ok) {
      toast.error(i18next.t("ErrorDeletingAll"));
    }

    if (response.ok) {
      toast.success(i18next.t("SuccessingDeletingAll"));
    }
    else {
      toast.info(i18next.t("UnknownResponse"), {
        details: JSON.stringify(dataResp)
      });
    }

  } catch (error) {
    console.error("Error during deleteMultipleData:", error);
    toast.error(i18next.t("ErrorDeletingAll"));
  }
};

const tokenRetrieve = async () => {

  const user = auth.currentUser;
  const token = await user.getIdToken();
  return token;
}

const readAll = async (controller, idOrganization) => {
  if (!controller || !idOrganization) return;

  const token = await tokenRetrieve();

  const url = `/${controller}BE/GetAll`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(idOrganization)
  });

  const dataResp = await response.json();
  if (dataResp) {
    return dataResp;
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorReading"));
  }

};


const createOrganization = async (organizationInfo) => {
  if (!organizationInfo) return;

  const url = 'OrganizationBE/Add';

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({ organization: organizationInfo }),
  });
  const dataResp = await response.json();

  if (response.ok && response.status === 200) {
    toast.success(i18next.t("CreateNewAccountSuccessfully"));
    return dataResp;
  }

  if (!response.ok || response.status !== 200) {
    toast.error(i18next.t("ErrorAddingAccount"));
  }

};

const updateAccount = async (account) => {

  const token = await tokenRetrieve();
  const response = await fetch('/AccountBE/Update', {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      account: account
    })
  })

  const dataResp = await response.json();

  if (dataResp) {
    toast.success(i18next.t("UpdatedSuccessfully"));
    return dataResp.id;
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorUpdating"));
  }
}


const deleteAccountRequest = async (account) => {

  const token = await tokenRetrieve();
  const response = await fetch('/AccountBE/DeleteAccountRequest', {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      account: account
    })
  })

  const dataResp = await response.json();

  if (dataResp) {
    toast.success(i18next.t("RequestSent"));
    return dataResp.id;
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorSendingRequest"));
  }

}

const updateOrganization = async (organization) => {

  const token = await tokenRetrieve();
  const response = await fetch('/OrganizationBE/Update', {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      organization: organization
    })
  })

  const dataResp = await response.json();

  if (dataResp) {
    toast.success(i18next.t("UpdatedSuccessfully"));
    return dataResp.id;
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorUpdating"));
  }
}

const updateBillingData = async (idOrganization, billingData) => {

  if (!idOrganization || !billingData) return;

  const token = await tokenRetrieve();

  const url = `/OrganizationBE/UpdateBilling`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      billingData: billingData,
      idOrganization: idOrganization
    }),
  });

  const dataResp = await response.json();

  if (dataResp) {
    toast.success(i18next.t("UpdatedSuccessfully"));
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorUpdating"));
  }
}

const getUserById = async (idUser) => {
  if (!idUser) return;

  const token = await tokenRetrieve();
  const url = `/AccountBE/GetById?id=${idUser}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
  });

  const dataResp = await response.json();
  if (dataResp) {
    return dataResp;
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorReading"));
  }
};

const logout = async () => {
  return await signOut(auth).then(window.location.replace('/'));
};


const sendVerificationEmail = async () => {

  return sendEmailVerification(auth.currentUser)
    .then(() => toast.success(i18next.t("EmailSent")))
    .catch((e) => {
      toast.error(i18next.t("AnErrorOccurred") + e);
    });
};


const getOrganizationById = async (idUser) => {
  if (!idUser) return;

  const token = await tokenRetrieve();
  const url = `/OrganizationBE/GetById?id=${idUser}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
  });

  const dataResp = await response.json();
  if (dataResp) {
    return dataResp;
  }

  if (!response.ok) {
    toast.error(i18next.t("ErrorReading"));
  }
};

export {
  create,
  update,
  deleteData,
  readAll,
  sendVerificationEmail,
  logout,
  updateAccount,
  deleteAccountRequest,
  getUserById,
  getOrganizationById,
  updateOrganization,
  createOrganization,
  deleteMultipleData,
  Import,
  updateBillingData
};
