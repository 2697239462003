import React, { useContext, useState } from "react";
import { useLocation } from 'react-router-dom';

import { ManageOta } from "./ManageOta";

export const EditOta = () => {
  const location = useLocation();
  const { itemState } = location.state;
  const { isEditable } = location.state;
  return (
    <div className={"md:p-4 pt-5 mx-auto"}>
      <ManageOta  data={itemState} isEditable={isEditable} />
    </div>
  );
};
