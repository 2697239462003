import { React, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import PrimaryButton from "../buttons/primary-button";
import TertiaryButton from "../buttons/tertiary-button";
import TextField from "../input/fields/text-field";
import { useTranslation } from "react-i18next";

import ToggleSwitch from '../../components/toggle/toggle';

export const NewOwnerForm = ({ onComplete, owner, onCloseModal }) => {
  const { i18n, t } = useTranslation();
  const [fullNameCompanyName, setFullNameCompanyName] = useState(owner ? owner.fullNameCompanyName : '');
  const [vatNumber, setVatNumber] = useState(owner ? owner.vatNumber : '');
  const checkValues = () => {
    if (!fullNameCompanyName.trim()) {
      setFullNameCompanyName('');
      toast.error(t("NameRequired"));
      return false;
    }
    return true;
  };
  return (
    <div className="flex flex-col justify-start w-full">
      
        <TextField
          label={t("FullNameCompanyName")}
          defaultValue={fullNameCompanyName}
          placeholder={t("Insert")}
          inputClassName={"p-3 w-fit"}
          className={"mb-3"}
          onChange={(e) => setFullNameCompanyName(e.target.value)}
        />
        
        <TextField
          label={t("VatNumber")}
          defaultValue={vatNumber}
          placeholder={t("Insert")}
          inputClassName={"p-3 w-fit"}
          className={"mb-3"}
          onChange={(e) => setVatNumber(e.target.value)}
        />
        
      
      <div className="flex justify-end mt-4">
       
        <TertiaryButton
          className={"w-fit p-2 bg-blue min-w-[10rem]"}
          content={(owner && (owner.fullNameCompanyName !== '' && owner.vatNumber !== '')) ? t("Edit"): t("Add")}
          
          onClick={() => {
            if (checkValues())
              owner
                ? onComplete({
                  id: owner.id,
                  fullNameCompanyName: fullNameCompanyName,
                  vatNumber: vatNumber
                })
                : onComplete({
                  fullNameCompanyName: fullNameCompanyName,
                  vatNumber: vatNumber
                });
          }}
        />
      </div>
    </div>
  );
};
