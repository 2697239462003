import React, { useState } from 'react';
import { sendVerificationEmail } from "../../../utils/functions";
import { IoWarning } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { SideBar } from '../../sidebar/sidebar';
import { BurgerSidebar } from '../../sidebar/burger-sidebar';
import { Header } from '../../header/header';
import { useTranslation } from "react-i18next";
import { 
    getAuth
} from "firebase/auth";

const Layout = ({ children }) => {
    const { i18n, t } = useTranslation();
    
    const auth = getAuth();
    // const [emailVerificationBanner, setEmailVerificationBanner] = useState(auth.currentUser.emailVerified);
    const [emailVerificationBanner, setEmailVerificationBanner] = useState(true);

    return (
        <>
            {!emailVerificationBanner && (
                <div className="absolute flex z-20 top-0 w-full justify-center h-lg-10 bg-input opacity-70 mb-5 pe-5">
                    <div className="flex items-center justify-center h-full">
                        <IoWarning className="w-5 h-auto text-petroil ms-3 me-3" />
                        <p className='text-petroil'>
                            {t("EmailNotVerify")}{" "}
                            <button
                                className="underline"
                                onClick={() => {
                                    sendVerificationEmail().then(() =>
                                        setEmailVerificationBanner(false)
                                    );
                                }}
                            >
                                {t("Here")}
                            </button>
                            {t("SendAnotherEmail")}
                        </p>
                    </div>
                    <div className="absolute mt-1 right-2 z-50">
                        <button onClick={() => setEmailVerificationBanner(false)}>
                            <XMarkIcon className="w-5 h-auto" />
                        </button>
                    </div>
                </div>
            )}

            <div className="flex">
                <SideBar className={"w-[15rem] sticky top-0 hidden h-fit lg:flex"} />
                <BurgerSidebar className={"fixed top-0 h-fit lg:hidden z-30"} />
                <div className="flex flex-col items-start justify-start w-full min-h-full layout-container xl:p-10 bg-white text-blue">
                    <div className="flex w-full justify-end">
                        <Header />
                    </div>
                    <div className="w-full mt-5">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
