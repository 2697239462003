import React from "react";

export const NeutralButton = ({ id, onClick, className, content }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={
        "w-full min-w-fit h-fit bg-white border text-blue rounded-none text-base font-semibold hover:bg-gray-100 " +
        " " +
        className
      }
    >
      {content}
    </button>
  );
};
