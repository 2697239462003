import React from "react";
import {
    HiOutlineViewGrid,
    HiOutlineHome,
    HiOutlineCalendar,
    HiOutlineCash,
    HiOutlineClipboard,
    HiOutlineUsers,
    HiOutlineMenuAlt1,
    HiAdjustments,
    HiOutlineCube,
    HiOutlineCurrencyEuro,
    HiOutlineChartBar
} from "react-icons/hi";
import { Accommodations } from "./pages/accommodation/AccommodationsIndex";
import { NewAccommodation } from "./pages/accommodation/NewAccommodation";
import { EditAccommodation } from "./pages/accommodation/EditAccommodation";
import { Bookings } from "./pages/booking/BookingsIndex";
import { NewBooking } from "./pages/booking/NewBooking";
import { EditBooking } from "./pages/booking/EditBooking";
import { Transactions } from "./pages/transaction/TransactionsIndex";
import { NewTransaction } from "./pages/transaction/NewTransaction";
import { EditTransaction } from "./pages/transaction/EditTransaction";
import { BookingRules } from "./pages/booking-rule/BookingRulesIndex";
import { NewBookingRule } from "./pages/booking-rule/NewBookingRule";
import { EditBookingRule } from "./pages/booking-rule/EditBookingRule";
import { CostCategories } from "./pages/cost-category/costCategoriesIndex";
import { CostCenters } from "./pages/cost-center/costCenterIndex";
import { NewCostCenter } from "./pages/cost-center/NewCostCenter";
import { EditCostCenter } from "./pages/cost-center/EditCostCenter";
import { NewCostCategory } from "./pages/cost-category/NewCostCategory";
import { EditCostCategory } from "./pages/cost-category/EditCostCategory";
import { Owners } from "./pages/owner/OwnersIndex";
import { NewOwner } from "./pages/owner/NewOwner";
import { EditOwner } from "./pages/owner/EditOwner";
import { Suppliers } from "./pages/supplier/suppliersIndex";
import { NewSupplier } from "./pages/supplier/NewSupplier";
import { EditSupplier } from "./pages/supplier/EditSupplier";
import { Otas } from "./pages/ota/otasIndex";
import { NewOta } from "./pages/ota/NewOta";
import { EditOta } from "./pages/ota/EditOta";
import Faq from "./pages/faq/FAQIndex";
import Account from "./pages/account/AccountIndex";
import Company from "./pages/company/CompanyIndex"; 
import Security from "./pages/security/SecurityIndex";
import { Login } from "./pages/authentication/login/index";
import { SignUp } from "./pages/authentication/signup/index";
import { PasswordResetPage } from "./pages/authentication/signup/steps/passwordResetStep";
import Dashboard from "./pages/dashboard";
import NotFound from "./pages/shared/notFound";
import DynamicPricing from "./pages/dynamic-pricing";
     
const AppRoutes = [
    {
        element: <Dashboard />,
        name: "Dashboard",
        path: "",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineViewGrid  className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <Accommodations />,
        name: "Accommodations",
        path: "accommodations",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineHome className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewAccommodation />,
        name: "NewAccommodation",
        path: "accommodations/new-accommodation",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditAccommodation />,
        name: "EditAccommodation",
        path: "accommodations/edit-accommodation",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Owners />,
        name: "Owners",
        path: "owners",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineUsers className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewOwner />,
        name: "NewOwner",
        path: "owners/new-owner",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditOwner />,
        name: "EditOwner",
        path: "owners/edit-owner",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <BookingRules />,
        name: "BookingRules",
        path: "booking-rules",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineMenuAlt1 className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewBookingRule />,
        name: "NewBookingRule",
        path: "booking-rules/new-booking-rule",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditBookingRule />,
        name: "EditBookingRule",
        path: "booking-rules/edit-booking-rule",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Transactions />,
        name: "Transactions",
        path: "transactions",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineCash className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewTransaction />,
        name: "NewTransaction",
        path: "transactions/new-transaction",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditTransaction />,
        name: "EditTransaction",
        path: "transactions/edit-transaction",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Bookings />,
        name: "Bookings",
        path: "bookings",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineCalendar  className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewBooking />,
        name: "NewBooking",
        path: "bookings/new-booking",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditBooking />,
        name: "EditBooking",
        path: "bookings/edit-booking",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Otas />,
        name: "Ota",
        path: "otas",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineClipboard className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewOta />,
        name: "NewOta",
        path: "otas/new-ota",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditOta />,
        name: "EditOta",
        path: "otas/edit-ota",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <CostCenters />,
        name: "CostCenters",
        path: "cost-centers",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineChartBar  className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewCostCenter />,
        name: "NewCostCenters",
        path: "cost-centers/new-cost-center",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditCostCenter />,
        name: "EditCostCenter",
        path: "cost-centers/edit-cost-center",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },   
    {
        element: <CostCategories />,
        name: "CostCategories",
        path: "cost-categories",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiAdjustments  className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewCostCategory />,
        name: "NewCostCategory",
        path: "cost-categories/new-cost-category",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditCostCategory />,
        name: "EditCostCategory",
        path: "cost-categories/edit-cost-category",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },   
    {
        element: <Suppliers />,
        name: "Suppliers",
        path: "suppliers",
        isInSidebar: true,
        activeLink: false,
        isActive: true,
        icon: <HiOutlineCube className={"w-6 h-auto font-bold hover:text-blue "} />,
    },
    {
        element: <NewSupplier />,
        name: "NewSupplier",
        path: "suppliers/new-supplier",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <EditSupplier />,
        name: "EditSupplier",
        path: "suppliers/edit-supplier",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <DynamicPricing />,
        name: "Dynamic Pricing",
        path: "dynamic-pricing",
        isInSidebar: true,
        isNotReady: true,
        activeLink: false,
        isActive: false,
        icon: <HiOutlineCurrencyEuro  className={"w-6 h-auto font-bold "} />,
    },
    {
        element: <Faq />,
        name: "FAQ",
        path: "faq",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Account />,
        name: "Profile",
        path: "account/profile",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Account />,
        name: "Notifications",
        path: "account/notifications",
        isInSidebar: false,
        activeLink: false,
        isActive: false,
        icon: null,
    },    
    {
        element: <Company />,
        name: "CompanyData",
        path: "company/company-data",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Company />,
        name: "Plan",
        path: "company/plan",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Company />,
        name: "Roles",
        path: "company/roles",
        isInSidebar: false,
        activeLink: false,
        isActive: false,
        icon: null,
    },
    {
        element: <Company />,
        name: "BillingAddress",
        path: "company/billing-address",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Security />,
        name: "Password",
        path: "security/password",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    },
    {
        element: <Security />,
        name: "Password",
        path: "security/two-step-verification",
        isInSidebar: false,
        activeLink: false,
        isActive: false,
        icon: null,
    },
    {
        element: <NotFound />,
        name: "NotFound",
        path: "*",
        isInSidebar: false,
        activeLink: false,
        isActive: true,
        icon: null,
    }
];

const AppRoutesExternal = [
    {
        element: <Login />,
        name: "Login",
        path: "login",
        isActive: true,
    },
    {
        element: <SignUp />,
        name: "Sign Up",
        path: "signup",
        isActive: true,
    }, 
    {
        element: <Login />,
        name: "Login",
        path: "*",
        isActive: true,
    },
    {
        element: <PasswordResetPage />,
        name: "PasswordResetPage",
        path: "password-reset",
        isActive: true,
    },
];


export { AppRoutes, AppRoutesExternal };