import { React, useState, useContext } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import PrimaryButton from "../buttons/primary-button";
import TertiaryButton from "../buttons/tertiary-button";
import TextField from "../input/fields/text-field";
import { useTranslation } from "react-i18next";
import { SelectMenu } from "../../components/input/select/select-menu";
import { GeneralOptions } from "../../resources/selectOptions";
import { DataContext } from "../../App";
import { id } from "date-fns/locale";

export const NewSubCategoryForm = ({ onComplete, categoryData, onCloseModal, isDisabled }) => {
  const { i18n, t } = useTranslation();  
  const context = useContext(DataContext);
  const [typology, setTypology] = useState(categoryData ? categoryData.typology : '');
  const [category, setCategory] = useState(categoryData ? categoryData.category : '');
  const [subCategory, setSubCategory] = useState([]);
  const paymentTypeOptions = GeneralOptions("paymentType");

    const accommodationCategoryOptions = context.costCategories.filter((item) => item.typology === "accommodation").map((item) => {
      return { label: item.category, value: item.id, subCategory: item.subCategory };
  });
  const bookingCategoryOptions = context.costCategories.filter((item) => item.typology === "booking").map((item) => {
      return { label: item.category, value: item.id, subCategory: item.subCategory };
  });
    const companyCategoryOptions = context.costCategories.map((item) => {
        return { label: item.category, value: item.id, subCategory: item.subCategory };
    });
    const costTypologyOptions = GeneralOptions("costTypology");

   let dataOptions = categoryData.typology === "accommodations" ? accommodationCategoryOptions : categoryData.typology === "booking" ? bookingCategoryOptions : categoryData.typology === "company" ? companyCategoryOptions : categoryData.typology === null

  const checkValues = () => {
    if (!category.label.trim()) {
      setCategory('');
      toast.error(t("CategoryIsRequired"));
      return false;
    }
    if (!subCategory.trim()) {
      setSubCategory('');
      toast.error(t("SubCategoryIsRequired"));
      return false;
    }
    return true;
  };
  return (
    <div className="flex flex-col justify-start w-full">

        <SelectMenu
          label={t("Typology")}
          isDisabled={isDisabled}
          className={isDisabled ? "cursor-not-allowed opacity-75"  : ""}
          isSearchable={true}
          options={costTypologyOptions}
          defaultValue={costTypologyOptions && typology ? costTypologyOptions.find((item) => {
            if (typology === item.value) {
              return item;
            }
          }) : typology}
          placeholder={t("Select")}
          onChange={(value) => {
            setTypology(value.value)
          }}
        /> 
      
      <SelectMenu
          label={t("Category")}
          className={""}
          isSearchable={true}
           options={dataOptions}
           defaultValue={dataOptions.find((item) => {
            if (category.label === item.label) {
               return item;
            }
           }) }
          placeholder={t("Select")}
          onChange={(value) => {
            setCategory(value)
          }}
        />
        
        <TextField
          label={t("SubCategory")}
          defaultValue={subCategory}
          placeholder={t("Insert")}
          inputClassName={"p-3 w-fit"}
          className={"mb-3"}
          onChange={(e) => setSubCategory(e.target.value)}
        />
        
      
      <div className="flex justify-end mt-4">
       
        <TertiaryButton
          className={"w-fit p-2 bg-blue min-w-[10rem]"}
          content={t("Add")}
          
          onClick={() => {
            if (checkValues())
              onComplete({
                  id: category.value,
                  typology: typology,
                  category: category.label,
                  subCategory: [...category.subCategory, subCategory]
                });
                
          }}
        />
      </div>
    </div>
  );
};
