import React, { useContext, useState, useEffect, useMemo } from "react";
import TextField from "../../components/input/fields/text-field";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { UnitFieldSymbols } from "../../components/input/fields/unit-field-with-symbols";
import SecondaryButton from "../../components/buttons/secondary-button";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../components/input/select/select-menu";
import { DataContext } from "../../App";
import { toast } from "react-toastify";
import countryList from 'react-select-country-list';
import { create, update } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import {
  HiArrowLeft
} from "react-icons/hi";

import { GeneralOptions } from "../../resources/selectOptions";
import { format, getTime} from "date-fns";
import { Tab } from "@headlessui/react";
import PrimaryButton from "../../components/buttons/primary-button";



export const ManageBooking = (data, isEditable) => {
  const itemToUpdate = data.data;
  const { i18n, t } = useTranslation();
  const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
  const Id = itemToUpdate && (itemToUpdate.id ?? null);
  const [index, setIndex] = useState(0);
  const context = useContext(DataContext);
  const [optionsCountry, setOptionsCountry] = useState(useMemo(() => countryList().getData(), []));
  const [commissionChannelSel, setCommissionChannelSel] = useState((itemToUpdate && itemToUpdate.channel.commissionChannel) ?? null);

  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const accommodationOptions = context.accommodations &&
    context.accommodations.map((item) => {
      return { label: item.name, value: item.id, extraServiceFee: item.extraServiceFee };
    });

    const [bookingDate, setBookingDate] = useState((itemToUpdate && itemToUpdate.bookingDate) ?? null);
    const [checkInDate, setCheckInDate] = useState((itemToUpdate && itemToUpdate.checkInDate) ?? null);
    const [checkOutDate, setCheckOutDate] = useState((itemToUpdate && itemToUpdate.checkOutDate) ?? null);
    const [numberOfGuest, setNumberOfGuest] = useState((itemToUpdate && itemToUpdate.numberOfGuest) ?? null);
    const [amount, setAmount] = useState((itemToUpdate && itemToUpdate.amount) ?? 0);
    const [status, setStatus] = useState((itemToUpdate && itemToUpdate.status) ?? null);
    const [bookingReference, setBookingReference] = useState((itemToUpdate && itemToUpdate.bookingReference) ?? null);
    const [idAccommodation, setidAccommodation] = useState((itemToUpdate && itemToUpdate.idAccommodation) ?? null);
    const [country, setCountry] = useState((itemToUpdate && itemToUpdate.country) ?? null);
    const [commissionChannel, setCommissionChannel] = useState((itemToUpdate && itemToUpdate.channel && itemToUpdate.channel.commissionChannel) ?? null);
    const [commissionAmount, setCommissionAmount] = useState((itemToUpdate && itemToUpdate.channel  && itemToUpdate.channel.commissionAmount) ?? 0);
    const [commissionType, setCommissionType] = useState((itemToUpdate && itemToUpdate.channel  && itemToUpdate.channel.commissionType) ?? null);
    const [accommodationFare, setAccommodationFare] = useState((itemToUpdate && itemToUpdate.accommodationFare) ?? 0);
    const [extraServiceFee, setExtraServiceFee] = useState((itemToUpdate && itemToUpdate.extraServiceFee) ?? 0);
    const [channel, setChannel] = useState((itemToUpdate && itemToUpdate.channel) ?? null)

  const navigator = useNavigate();
  const [formValues, setFormValues] = useState({
    id: Id,
    status: status,
    bookingDate: bookingDate,
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    country: country,
    numberOfGuest: numberOfGuest,
    amount: amount,
    extraServiceFee: extraServiceFee,
    accommodationFare: accommodationFare,
    idAccommodation: idAccommodation,
    channel: channel,
    bookingReference: bookingReference
  }); 

  const bookingStatusOptions = GeneralOptions("bookingStatus");
const BookingChannelOptions = context.otas ? context.otas.map(x => {return { label: x.name, value: x.id }}) : [];

const checkFields = () => {

  if (!formValues.idAccommodation) {
    toast.error(t("CheckAccommodation"));
    return false;
  }

  if (!formValues.bookingDate) {
      toast.error(t("CheckBookingDate"));
      return false;
  }
  
  if (!formValues.checkInDate) {
      toast.error(t("CheckCheckInDate"));
      return false;
  }
  
  if (!formValues.checkOutDate) {
      toast.error(t("CheckCheckOutDate"));
      return false;
  }

  if (formValues.bookingDate > formValues.checkInDate) {
    toast.error(t("BookingDateShorter"));
    return false;
  }
  
  if (formValues.checkInDate > formValues.checkOutDate) {
    toast.error(t("CheckInDateErrorShorter"));
    return false;
}
  
  if (formValues.amount === null) {
      toast.error(t("CheckAmount"));
      return false;
  }

  
  
  
  
  if (formValues.amount === 0) {
      toast.error(t("InvalidAmount"));
      return false;
  }
  
  return true;
}



  const checkChanges = () => {
    if (
      formValues.bookingDate === itemToUpdate.bookingDate &&
      formValues.checkInDate === itemToUpdate.checkInDate &&
      formValues.checkOutDate === itemToUpdate.checkOutDate &&
      formValues.amount === itemToUpdate.amount &&
      formValues.numberOfGuest === itemToUpdate.numberOfGuest &&
      formValues.extraServiceFee === itemToUpdate.extraServiceFee &&
      formValues.accommodationFare === itemToUpdate.accommodationFare &&
      formValues.idAccommodation === itemToUpdate.idAccommodation &&
      formValues.country === itemToUpdate.country &&
      formValues.status === itemToUpdate.status &&
      formValues.bookingReference === itemToUpdate.bookingReference &&
      (formValues.channel && itemToUpdate.channel && (formValues.channel.commissionType === itemToUpdate.channel.commissionType && formValues.channel.commissionChannel === itemToUpdate.channel.commissionChannel && formValues.channel.commissionAmount === itemToUpdate.channel.commissionAmount))
      
    ) {
      toast.error(t("NoChangesMade"));
      return false;
    }
    return true;
  }

  const handleCreate = () => {
    if (checkFields()) {
      create("Booking", context.idOrganization, formValues).then(async () => {
        context.updateData("bookings");
        context.updateData("transactions");
        navigator("../bookings");
      });
    }
  }

  const handleUpdate = () => {
    if (checkFields() && checkChanges()) {
      update("Booking", context.idOrganization, formValues).then(async () => {
        context.updateData("bookings");
        context.updateData("transactions");
        navigator("../bookings");
      });  
      };
    }



  return (
    <div className="w-full">
      <div onClick={() => navigator("../bookings")} className="mb-10 flex cursor-pointer max-w-[250px]">
        <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
        <h1 className="text-2xl font-bold">{t("Bookings")}</h1>
      </div>

      <div className="w-full md:pl-24 mt-16">
        <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        {!isMobile && (<Tab.List className="flex px-1 space-x-1 text-blue">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-8 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("BookingDetails")}</span>
                  </div>
                </Tab>
              </div>
              <div className="tab-manage-container">
                {index == 1 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-1 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("Price")}</span>
                  </div>

                </Tab>
              </div>
            </Tab.List>)}
            {isMobile && (<Tab.List className="flex px-1 space-y-2 text-blue flex-col mb-2">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "  pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("BookingDetails")}</span>
                  </div>
                </Tab>
              </div>
              <div className="tab-manage-container">
                {index == 1 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      " pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("Price")}</span>
                  </div>

                </Tab>
              </div>
            </Tab.List>)}
          {/** Tabs */}
          <Tab.Panels className=" min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7 md:px-12 px-1">
            {/** Details */}
            <Tab.Panel
              key={0}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
              )}
            >
              <h1 className={"ml-1 text-lg font-semibold text-blue w-full"
              }
              >
                {t("General")}
              </h1>
              <div className="md:grid md:grid-cols-4 md:gap-5">
                <div className="w-full ">
                  <TextField
                    label={t("Reference")}
                    inputClassName={"py-3 px-1"}
                    placeholder={t("Insert")}
                    defaultValue={bookingReference}
                    onChange={(e) => {
                      setBookingReference(e.target.value)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        bookingReference: (e.target.value)
                      }));
                    }
                    }
                  />
                </div>
                <div className="w-full col-span-2 ">
                  <SelectMenu
                    label={t("Accommodation") + " *"}
                    defaultValue={idAccommodation && accommodationOptions ?
                      accommodationOptions.find((value) => {

                        if(idAccommodation === value.value){
                          return value;
                        }
                      }): idAccommodation
                    }
                    options={accommodationOptions}
                    onChange={(value) => {
                      setidAccommodation(value.value)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        idAccommodation: (value.value)
                      }));
                      setExtraServiceFee(value.extraServiceFee && value.extraServiceFee || 0)
                    }}
                  />
                </div>
                <div className="w-full">
                  <SelectMenu
                    label={t("Country")}
                    placeholder={t("SelectPlaceholder")}
                    className={"w-full mr-5"}
                    inputClassName={"p-1"}
                    options={optionsCountry}
                    defaultValue={optionsCountry && country ? optionsCountry.find((item) => {
                      if (country === item.label) {
                        return item;
                      }
                    }) : country}
                    onChange={(item) => {
                      setCountry(item.label);
                      setFormValues(prevValues => ({
                        ...prevValues,
                        country: item.label
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="md:grid md:grid-cols-12 md:gap-5 ">
                <div className="col-span-3 mt-3">
                  <NewDatePicker
                    label={t("BookingDate") + " *"}
                    defaultValue={
                      (bookingDate && getTime(new Date(bookingDate)))
                    }
                    onCompleted={(el) => {
                      setBookingDate(el);
                      setFormValues(prevValues => ({
                        ...prevValues,
                        bookingDate: el
                      }));
                    }}
                  />
                </div>
                <div className="col-span-3 mt-3">
                  <NewDatePicker
                    label={t("CheckInDate") + " *"}
                    defaultValue={
                      (checkInDate && getTime(new Date(checkInDate)))
                    }
                    onCompleted={(el) => {
                      setCheckInDate(el);
                      setFormValues(prevValues => ({
                        ...prevValues,
                        checkInDate: el
                      }));
                    }}
                  />
                </div>
                <div className="col-span-3 mt-3">
                  <NewDatePicker
                    label={t("CheckOutDate") + " *"}
                    defaultValue={
                      (checkOutDate && getTime(new Date(checkOutDate)))
                    }
                    onCompleted={(el) => {
                      setCheckOutDate(el);
                      setFormValues(prevValues => ({
                        ...prevValues,
                        checkOutDate: el
                      }));
                    }}
                  />
                </div>
                <div className="col-span-3 mt-3">
                  <UnitFieldSymbols
                    label={t("NumberofGuests")}
                    className={"w-full"}
                    placeholder={"0"}
                    value={numberOfGuest ?? 0}
                    defaultValue={numberOfGuest ?? 0}
                    onChange={(el) => {
                      setNumberOfGuest(Number(el.target.value))
                      setFormValues(prevValues => ({
                        ...prevValues,
                        numberOfGuest: (Number(el.target.value))
                      }));
                    }}
                    inputClassName={""}
                  />
                </div>
                <div className="w-full col-span-2 mt-3 ">
                  <SelectMenu
                    label={t("Status") + " *"}
                    defaultValue={
                      bookingStatusOptions.find((value) => {

                        if(status === value.value){
                          return value;
                        }
                      })
                    }
                    options={bookingStatusOptions}
                    onChange={(value) => {
                      setStatus(value.value)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        status: (value.value)
                      }));
                    }}
                  />
                </div>
              </div>




            </Tab.Panel>

            {/** Costs */}
            <Tab.Panel
              key={1}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
              )}
            >
              <h1 className={"ml-1 text-lg font-semibold text-blue w-full"
              }
              >
                {t("PriceInfo")}
              </h1>
              <div className="flex flex-wrap md:flex-nowrap ">
                <div className="md:mr-12 mb-3 md:mb-0 d-flex md:w-auto w-full">
                  <CurrencyField
                    label={t("TotalAmount") + " *"}
                    value={amount}
                    inputClassName={"px-1 py-3 max-w-[100px]"}
                    currency={context.organizationInfo && context.organizationInfo.currency}
                    onChange={(el) => {
                      const numericValue = Number(el.target.value);
                      setAmount(numericValue)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        amount: numericValue
                      }));
                    }}
                  />
                </div>
                <div className="mb-6 md:w-auto w-full">
                  <CurrencyField
                    className={""}
                    label={t("ExtraServiceFee")}
                    placeholder={"0"}
                    value={extraServiceFee}
                    inputClassName={"px-1 py-3"}
                    currency={context.organizationInfo && context.organizationInfo.currency}
                    onChange={(el) => {
                      const numericValue = Number(el.target.value);
                      setExtraServiceFee(numericValue)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        extraServiceFee: numericValue
                      }));
                    }}
                  />
                </div>
              </div>
              <hr className="w-full" />
              <h1 className={"ml-1 text-lg font-semibold text-blue w-full"
              }
              >
                {t("CommissionDetails")}
              </h1>
              <div className="flex flex-wrap justify-start font-normal md:max-w-[25vw] h-fit">
                <SelectMenu
                  className={"md:mr-16"}
                  label={t("BookingChannel")}
                  isClearable
                  defaultValue={commissionChannelSel && BookingChannelOptions ?
                    BookingChannelOptions.find((value) => {

                      if(commissionChannelSel === value.label){
                        return value;
                      }
                    }): commissionChannelSel
                  }
                  options={BookingChannelOptions}
                  onChange={(el) => {
                    if (el) {
                      setCommissionChannelSel(el);
                      formValues.channel = {};
                      formValues.channel.commissionChannel = el.label;
                      formValues.channel.commissionType = el.value;
                    } else {
                      setCommissionChannelSel(null);
                      formValues.channel = null;
                    }
                  }}
                  inputClassName={"min-w-[250px] p-2"}

                />

              </div>


            </Tab.Panel>
            <div className="flex justify-end w-full pr-3 md:pr-20 pb-10">

              {
                IsEditable && (
                  Id  ? (
                    <PrimaryButton
                      content={t("Save")}
                      className={"mt-10 p-3 bg-blue w-[7vw]"}
                      onClick={() => {
                        handleUpdate();
                      }}
                    />
                  ) : (index == 1 && (
                    <PrimaryButton
                      content={t("Save")}
                      className={"mt-10 p-3 bg-blue w-[7vw]"}
                      onClick={() => {
                        handleCreate();
                      }}
                    />)
                  )
                )
              }
            </div>
          </Tab.Panels>

        </Tab.Group>

      </div>

    </div>
  );
};