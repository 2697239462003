import React, { useContext, useState } from "react";
import { ManageCostCategory } from "./ManageCostCategory";

export const NewCostCategory = () => {
  return (
    <>
      <div className={"md:p-4 pt-5 mx-auto"}>
        <div className={"flex"}>
          <ManageCostCategory data={null} />
        </div>
      </div>
    </>
  );
};
