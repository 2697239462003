import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";

export const Otas = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const otasList = context.otas;
  const accommodations = context.accommodations;
  const organizationInfo = context.organizationInfo;
  const symbol = organizationInfo && organizationInfo.currency;

  const getAccommodationNames = (accommodationIds) => {
    return accommodationIds
      .map(id => {
        const accommodation = accommodations.find(acc => acc.id === id);
        return accommodation ? accommodation.name : id;
      })
      .join(', ');
  };

  const otasListWithAccommodationNames = otasList.map(ota => {
    const accommodationNames = ota && ota.rulesCustom && Array.isArray(ota.rulesCustom) ? ota.rulesCustom.map(rule => getAccommodationNames(rule.accommodations)).join(', ') : [];
    return {
      ...ota,
      accommodationNames
    };
  });



  const otaTableStructure = {
    info: {
      urlUpdateData: "otas",
      urlCreateUpdate: "ota",
      name: "Ota",
      header: [
        {
          field: "name",
          header: t("Ota"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "accommodationNames",
          header: t("Accommodations"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "amount",
          header: t("Amount"),
          sortable: true,
          type: "Text",
          show: true,
          symbol: symbol
        }
      ],
      body: otasListWithAccommodationNames
    },

    exportable: false,
    importable: false,
    filterType: "filter"
  };

  return (
    <div className="w-full mt-8">
      <div className="content">
        <div className="table-wrapper">
          <Table
            info={otaTableStructure.info}
            exportable={otaTableStructure.exportable}
            importable={otaTableStructure.importable}
            searchable={true}
          />
        </div>
      </div>
    </div>
  );
};
