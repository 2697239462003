import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { DataContext } from "../../App";
import { update } from "../../utils/functions";
const paypal = window.paypal;


export const PayPalButton = ({
    amount,
    description,
    subscriptionInfo,
    idOrganization,
}) => {
    const [isEffectAllowed, setIsEffectAllowed] = useState(false);
    const context = useContext(DataContext);
    useEffect(() => {
        if (!isEffectAllowed) {
            setIsEffectAllowed(true);
            return;
        }

        const newExpireDate = new Date(subscriptionInfo.expired);
        newExpireDate.setMonth(newExpireDate.getMonth() + 1);

        function initPayPalButton() {
            paypal.Buttons({
                style: {
                    shape: 'rect',
                    color: 'blue',
                    layout: 'vertical',
                    label: 'paypal',
                },

                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            "amount": {
                                "currency_code": "EUR", "value": amount,
                                "breakdown": {
                                    "item_total": {
                                        "currency_code": "EUR",
                                        "value": amount
                                    }
                                }
                            },
                            "items": [
                                {
                                    "name": "Subscription BiLemon",
                                    "description": description,
                                    "unit_amount": {
                                        "currency_code": "EUR",
                                        "value": amount
                                    },
                                    "quantity": "1"
                                },
                            ]
                        }]
                    });
                },

                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (orderData) {
                        if (orderData && orderData.status === "COMPLETED") {
                            update("Subscription", idOrganization, {subscription: {
                                amount: subscriptionInfo.amount,
                                expired: newExpireDate,
                                plan: subscriptionInfo.plan,
                                properties: subscriptionInfo.properties,
                                recurrency: subscriptionInfo.recurrency,
                                amountExtra: subscriptionInfo.amountExtra,
                                propertiesExtra: 0
                                }
                              }).then(async () => {
                                context.updateData("organization-info");
                                navigator("company/plan");
                                setTimeout(() => {
                                    window.location.replace('company/plan');
                                }, 2000);
                              });  
                              
                            const element = document.getElementById('paypal-button-container');
                            element.innerHTML = '';
                            toast.success("Thank you for your payment!");
                        }
                    });
                },

                onError: function (err) {
                    const element = document.getElementById('paypal-button-container');
                    element.innerHTML = '';
                    toast.error(err);
                }
            }).render('#paypal-button-container');
        }
        initPayPalButton();
    }, [isEffectAllowed]);

    return (
        <>
            <div className="md:mt-[50px] md:w-[25vw] w-auto mt-5">
                <div id="smart-button-container">
                    <div id="paypal-button-container"></div>
                </div>
            </div>
        </>
    );
};
