import React from "react";
import { ManageAccommodation } from "./ManageAccommodation";

export const NewAccommodation = () => {

  return (
      <div className={"md:p-4 pt-5 mx-auto"}>
        <div className={"flex"}>
          <ManageAccommodation data={null}/>
        </div>
      </div>
    );
};
