import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const PORT = "44407";
//const getCurrentHost = `https://localhost:${PORT}`;
 const getCurrentHost = `https://stage.bilemon.com`;
i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${getCurrentHost}/translations/{{lng}}.json`,
    },
  });

export default i18n;