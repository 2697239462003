import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {
  getCountries
} from "react-phone-number-input";
import {
  getAuth
} from "firebase/auth";
import SecondaryButton from "../../components/buttons/secondary-button";
import PrimaryButton from '../../components/buttons/primary-button';

const Roles = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const auth = getAuth();

  const [selectedPrefix, setSelectedPrefix] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [options, setOptions] = useState();

  const formValues = {
    fullName: selectedPrefix,
    phoneNumber: phoneNumber,
  };

  useEffect(() => {
    setOptions(
      getCountries().map((countryCode) => {
        return { label: countryCode, value: countryCode };
      })
    );
  }, []);

  return (
    
    <div className='content-company ml-5'>
          <h2 className="text-xl ml-1 font-semibold">{t("EditCompanyProfile")}</h2>
          <div className='mt-20 ml-1'>
            <div className='flex'></div>
            <div className="flex flex-wrap">

            </div>
            <div className="flex justify-end">
              <PrimaryButton
                content={t("Save")}
                className={"mt-2 p-3 bg-blue min-w-[10rem] mr-16 mb-6"}
                onClick={() => {
                  // handleUpdate();
                }}
              />
            </div>
          </div>
    </div>
  );
};

export default Roles;
