import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { i18n, t } = useTranslation();

  const faqData = [
    { question: t("FAQ1"), answer: t("answer1"), position: 1 },
    { question: t("FAQ2"), answer: t("answer2"), position: 2 },
    { question: t("FAQ3"), answer: t("answer3"), position: 3 },
    { question: t("FAQ4"), answer: t("answer4"), position: 4 },
    { question: t("FAQ5"), answer: t("answer5"), position: 5 },
    { question: t("FAQ6"), answer: t("answer6"), position: 6 },
    { question: t("FAQ7"), answer: t("answer7"), position: 7 },
    { question: t("FAQ8"), answer: t("answer8"), position: 8 }
  ];

  const sortedFaqData = faqData.sort((a, b) => a.position - b.position);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="w-full md:min-w-[700px]">
        <h1 className="text-3xl font-bold">{t("FaqTitle")}</h1>
        <hr className="w-full max-w-5xl mb-6 mt-6" />
        <div className="w-full max-w-[850px] mx-auto mt-16">
          {sortedFaqData.map((faq, index) => (
            <div key={index} className="mb-2">
              <button
                className="w-full p-4 text-left bg-gray-200 hover:bg-gray-300 focus:outline-none focus:bg-gray-300 flex justify-between items-center"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <Icon id={index} open={activeIndex} />
              </button>
              {index === activeIndex && (
                <div className="p-4 bg-white border-t border-gray-200">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
