import { useState } from "react";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import TextField from "../../../components/input/fields/text-field";
import {
  HiLogin,
  HiLogout
} from "react-icons/hi";

import { FaCheck } from 'react-icons/fa';
const TransactionTypologyStep = ({ onComplete, values }) => {
  
  const { i18n, t } = useTranslation();
  const [name, setName] = useState(values.name ?? null);
  const [type, setType] = useState(values.type ?? null);
  const [formValues, setFormValues] = useState({
    name: name,
    type: type
});  

  const checkValues = () => {


    if (!formValues.type) {
      toast.error(t("TransactionTypologyRequired"));
      return false; 
    }

    if (!formValues.name) {
      toast.error(t("BookingRuleNameRequired"));
      return false; 
    }

    if (formValues.name && formValues.type) {

      onComplete(formValues)
    }
    return;


  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center text-petroil">
        <div className="">
          <div className="bg-white rounded-full items-center">

            <div className="flex justify-center mb-4 mt-8">
              <FaCheck className="text-white bg-petroil rounded-full w-9 h-9 p-1" />
            </div>
            <div className="">
              <p className={`font-bold text-center text-base text-petroil max-w-[100px] min-h-[45px]`}>{t("Typology")}</p>
            </div>
          </div>
        </div>
        <div className="h-[2px] w-[100px] md:w-[200px] bg-blue mb-8 "></div>
        <div className="text-center items-center justify-center ml-2">
          <div className="mb-2 md:mb-4 mt-8"><span className="bg-input text-[#728D94] rounded-full px-4 py-2 font-bold">2</span></div>

          <div className="mt-6 md:mt-0 ">
            <p className={`font-bold text-center text-base text-petroil min-h-[45px] max-w-[100px]`}>{t("Details")}</p>
          </div>

        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-24 mb-12">
        <h2 className="font-bold">{t("Typology")}</h2>
        <p>{t("ChooseTypologyDescription")}</p>
      </div>
      <div className="flex">
        <div
          className={`relative credit-card-card-billing max-w-[15rem] md:min-w-[8rem] min-w-[7rem] rounded-xl mr-4 md:mr-8 p-6 pb-2 ${type && type === 'cost' ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
          onClick={() => {
            setType("cost")
            setFormValues(prevValues => ({...prevValues,
              type: "cost"      
          }))
        }}
        >
          {type && type === 'cost' && (
            <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
              <div className="bg-white rounded-full">
                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
              </div>
            </div>
          )}
          <div className='flex justify-center'>
            <HiLogout className={"w-6 h-auto font-bold hover:text-blue "} />
          </div>
          <h2 className="font-semibold mt-2 mb-2 text-center">{t("Cost")}</h2>
        </div>
        <div
          className={`relative credit-card-card-billing md:min-w-[8rem] min-w-[7rem] max-w-[15rem]  rounded-xl  p-6 pb-2 ${type && type === "earning" ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
          onClick={() => {
            setType("earning")
            setFormValues(prevValues => ({...prevValues,
              type: "earning"      
          }))
        }}
        >
          {type && type === "earning" && (
            <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
              <div className="bg-white rounded-full">
                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
              </div>
            </div>
          )}
          <div className='flex justify-center'>
            <HiLogin className={"w-6 h-auto font-bold hover:text-blue "} />
          </div>
          <h2 className="font-semibold mt-2 mb-2 text-center">{t("Earning")}</h2>
        </div>
      </div>
      <div className="mt-8 mb-8">
        <TextField
          label={t("BookingRuleName")}
          className={"max-w-xs  min-w-[27vw] mb-3"}
          inputClassName={"p-3"}
          placeholder={t("Insert")}
          defaultValue={name}
          onChange={(e) => {
            setName(e.target.value);
            setFormValues(prevValues => ({...prevValues,
              name: e.target.value
        
            }))
          }}
        />
      </div>

      <div
        className="mt-8 mb-12 font-bold cursor-pointer"
        onClick={checkValues}
      >
        {t("Next")} <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
      </div>
    </div>
  );
};

export default TransactionTypologyStep;