import React, { useContext, useState, useEffect, useMemo } from "react";
import SecondaryButton from "../../components/buttons/secondary-button";
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { UnitFieldSymbols } from "../../components/input/fields/unit-field-with-symbols";
import countryList from 'react-select-country-list';
import { CurrencyField } from "../../components/input/fields/currency-field";
import { PercentField } from "../../components/input/fields/percent-field";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ModalCreate from "../../components/dialogs/dialogCreate";
import { NewOwnerForm } from "../../components/modals/new-owner";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { create, update } from "../../utils/functions";
import { GeneralOptions } from "../../resources/selectOptions";
import { Tab } from "@headlessui/react";
import {
  HiArrowLeft
} from "react-icons/hi";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from "../../components/buttons/primary-button";

export const ManageAccommodation = (data, isEditable) => {
  const itemToUpdate = data.data;
  const Id = itemToUpdate && (itemToUpdate.id ?? null);
  const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
  const [optionsCountry, setOptionsCountry] = useState(useMemo(() => countryList().getData(), []));
  const { i18n, t } = useTranslation();
  const [index, setIndex] = useState(0);
  const context = useContext(DataContext);
  const [modal, setOpenModal] = useState(false);
  const navigator = useNavigate();  
  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  const [name, setName] = useState((itemToUpdate && itemToUpdate.name) ?? null);
  const [numberRooms, setNumberRooms] = useState((itemToUpdate && itemToUpdate.numberRooms) ?? 0);
  const [maxPeople, setMaxPeople] = useState((itemToUpdate && itemToUpdate.maxPeople) ?? 0);
  const [numberBathroom, setNumberBathroom] = useState((itemToUpdate && itemToUpdate.numberBathroom) ?? 0);
  const [numberDoubleBed, setNumberDoubleBed] = useState((itemToUpdate && itemToUpdate.numberDoubleBed) ?? 0);
  const [numberSingleBed, setNumberSingleBed] = useState((itemToUpdate && itemToUpdate.numberSingleBed) ?? 0);
  const [touristTax, setTouristTax] = useState((itemToUpdate && itemToUpdate.touristTax) ?? null);
  const [city, setCity] = useState((itemToUpdate && itemToUpdate.address && itemToUpdate.address.city) ?? null);
  const [zipCode, setZipCode] = useState((itemToUpdate && itemToUpdate.address && itemToUpdate.address.zipCode) ?? null);
  const [street, setStreet] = useState((itemToUpdate && itemToUpdate.address && itemToUpdate.address.street) ?? null);
  const [country, setCountry] = useState((itemToUpdate && itemToUpdate.address && itemToUpdate.address.country) ?? null);
  const [selectedCountry, setSelectedCountry] = useState((itemToUpdate && itemToUpdate.address && itemToUpdate.address.country) ?? null);
  const [idOwner, setIdOwner] = useState((itemToUpdate && itemToUpdate.idOwner) ?? null);
  const [status, setStatus] = useState((itemToUpdate && itemToUpdate.status) ?? null);
  const [contractTypology, setContractTypology] = useState((itemToUpdate && itemToUpdate.contractTypology) ?? null);
  const [basePrice, setBasePrice] = useState((itemToUpdate && itemToUpdate.basePrice) ?? null);
  const [extraServiceFee, setExtraServiceFee] = useState((itemToUpdate && itemToUpdate.extraServiceFee) ?? null);
  const [occupancyRate, setOccupancyRate] = useState((itemToUpdate && itemToUpdate.occupancyRate) ?? null);
  const [annualRevenue, setAnnualRevenue] = useState((itemToUpdate && itemToUpdate.annualRevenue) ?? null);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const onCloseModal = () => {
    setOpenModal(false)
  };
  const [formValues, setFormValues] = useState({
    id: Id,
    name: name,
    numberRooms: numberRooms,
    maxPeople: maxPeople,
    numberDoubleBed: numberDoubleBed,
    numberSingleBed: numberSingleBed,
    numberBathroom: numberBathroom,
    address: {
      street: street,
      city: city,
      zipCode: zipCode,
      country: country
    },
    touristTax: touristTax,
    basePrice: basePrice,
    extraServiceFee: extraServiceFee,
    occupancyRate: occupancyRate,
    annualRevenue: annualRevenue,
    contractTypology: contractTypology,
    status: status,
    idOwner: idOwner
  });



  const contractOptions = GeneralOptions("contract");
  const statusOptions = GeneralOptions("status");
  const ownersOptions = context.owners && context.owners.map((item) => {
    return {
      label: item.fullNameCompanyName,
      value: item.id,
    };
  });
  
  const checkFields = () => {
    if (!formValues.name || formValues.name === "") {
      toast.error(t("AccommodationNameRequired"));
      return false;
    }
    if (!formValues.status || formValues.status === "") {
      formValues.status = "active";
    }

    if (!formValues.address.city || formValues.address.city === "") {
      toast.error(t("AccommodationCityRequired"));
      return false;
    }

    if (!formValues.idOwner || formValues.idOwner === "") {
      toast.error(t("AccommodationOwnerRequired"));
      return false;
    }

    return true;
  };

  const checkChanges = () => {
    if (
      formValues.name === itemToUpdate.name &&
      formValues.numberRooms === itemToUpdate.numberRooms &&
      formValues.maxPeople === itemToUpdate.maxPeople &&
      formValues.numberDoubleBed === itemToUpdate.doubleBed &&
      formValues.numberSingleBed === itemToUpdate.singleBed &&
      formValues.numberBathroom === itemToUpdate.bathroom &&
      formValues.address.street === itemToUpdate.address.street &&
      formValues.address.city === itemToUpdate.address.city &&
      formValues.address.zipCode === itemToUpdate.address.zipCode &&
      formValues.address.country === itemToUpdate.address.country &&
      formValues.touristTax === itemToUpdate.touristTax &&
      formValues.basePrice === itemToUpdate.basePrice &&
      formValues.extraServiceFee === itemToUpdate.extraServiceFee &&
      formValues.occupancyRate === itemToUpdate.occupancyRate &&
      formValues.annualRevenue === itemToUpdate.annualRevenue &&
      formValues.contractTypology === itemToUpdate.contractTypology &&
      formValues.status === itemToUpdate.status &&
      formValues.idOwner === itemToUpdate.idOwner
    ) {
      toast.error(t("NoChangesMade"));
      return false;
    }
    return true;
  }

  const handleCreate = () => {
    if (checkFields()) {
    create("Accommodation", context.idOrganization, formValues).then(async () => {
    context.updateData("accommodations");
    context.updateData("organization-info");
    navigator("../accommodations");
    });
    }
  }

  const handleUpdate = () => {
    if (checkFields && checkChanges) {
        
      update("Accommodation", context.idOrganization, formValues).then(async () => {
        context.updateData("accommodations");
        navigator("../accommodations");
      });
    
      };
    }




  return (
    <>
      <div className="w-full">
        <div onClick={() => navigator("../accommodations")} className="mb-10 flex cursor-pointer max-w-[250px]">
          <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
          <h1 className="text-2xl font-bold">{t("Accommodations")}</h1>
        </div>

        <div className="w-full xl:pl-24 mt-16">
          <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
             {!isMobile && (<Tab.List className="flex px-1 space-x-1 text-blue">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-8 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("AccommodationDetails")}</span>
                  </div>
                </Tab>
              </div>
              <div className="tab-manage-container">
                {index == 1 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-1 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("PriceManagement")}</span>
                  </div>

                </Tab>
              </div>
            </Tab.List>)}
            {isMobile && (<Tab.List className="flex px-1 space-y-2 text-blue flex-col mb-2">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "  pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("AccommodationDetails")}</span>
                  </div>
                </Tab>
              </div>
              <div className="tab-manage-container">
                {index == 1 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      " pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("PriceManagement")}</span>
                  </div>

                </Tab>
              </div>
            </Tab.List>)}
            <Tab.Panels className=" min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7 md:px-12 px-1">
              <Tab.Panel
                key={0}
                className={classNames(
                  "rounded-xl bg-white p-3",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
                )}
              >

                <h2 className="text-xl ml-1">{t("General")}</h2>

                <TextField
                  label={t("Name") + " *"}
                  placeholder={t("Insert")}
                  defaultValue={name}
                  className={"max-w-xs mr-10 min-w-[27vw]"}
                  inputClassName={"p-3 "}
                  onChange={(e) => {
                    setName(e.target.value)
                    setFormValues(prevValues => ({ 
                      ...prevValues, 
                      name: e.target.value }));
                  }}
                />
                <div className="mb-5 h-fit w-full">
                  <div className="flex flex-wrap md:flex-nowrap">
                    <div className=" xl:me-[5.5rem] mb-8 xl:mb-0 w-full">
                      <UnitFieldSymbols
                        label={t("Rooms")}
                        className={""}
                        value={numberRooms}
                        placeholder={t("Insert")}
                        onChange={(e) => {
                          setNumberRooms(Number(e.target.value))
                          setFormValues(prevValues => ({ 
                            ...prevValues, 
                            numberRooms: Number(e.target.value) }));
                        }}
                      />
                    </div>
                    <div className="xl:me-[5.5rem] mb-8 xl:mb-0 w-full">
                      <UnitFieldSymbols
                        label={t("MaxPeopleNumber")}
                        className={""}
                        placeholder={t("Insert")}
                        value={maxPeople}
                        onChange={(e) => {
                          setMaxPeople(Number(e.target.value));
                          setFormValues(prevValues => ({ 
                            ...prevValues, 
                            maxPeople: Number(e.target.value) }));
                        }}
                      />
                    </div>
                    <div className="xl:me-[5.5rem] mb-8 xl:mb-0 w-full">
                      <UnitFieldSymbols
                        label={t("DoubleBeds")}
                        className={""}
                        placeholder={t("Insert")}
                        value={numberDoubleBed}
                        onChange={(e) => {
                          setNumberDoubleBed(Number(e.target.value));
                          setFormValues(prevValues => ({ 
                            ...prevValues, 
                            numberDoubleBed: Number(e.target.value) }));
                        }}
                      />
                    </div>
                    <div className="xl:me-[5.5rem] mb-8 xl:mb-0 w-full">
                      <UnitFieldSymbols
                        label={t("SingleBed")}
                        className={""}
                        placeholder={t("Insert")}
                        value={numberSingleBed}
                        onChange={(e) => {
                          setNumberSingleBed(Number(e.target.value));
                          setFormValues(prevValues => ({ 
                            ...prevValues, 
                            numberSingleBed: Number(e.target.value) }));
                        }}
                      />
                    </div>
                    <div className="xl:me-[5.5rem] mb-8 xl:mb-0 w-full">
                      <UnitFieldSymbols
                        label={t("Bathrooms")}
                        className={""}
                        placeholder={t("Insert")}
                        value={numberBathroom}
                        onChange={(e) => {
                          setNumberBathroom(Number(e.target.value))
                          setFormValues(prevValues => ({ 
                            ...prevValues, 
                            numberBathroom: Number(e.target.value) }));
                        }}
                      />
                    </div>
                  </div>
                </div>


                <hr className="my-4" />
                <h2 className="text-xl ml-1">{t("AddressInfo")}</h2>
                <div className="flex flex-wrap">

                  <TextField
                    label={t("Street")}
                    className={"max-w-xs xl:mr-10 min-w-[27vw]"}
                    inputClassName={"p-3 "}
                    placeholder={t("Insert")}
                    defaultValue={street}
                    onChange={(e) => {
                      setStreet(e.target.value)
                      setFormValues(prevValues => ({ 
                        ...prevValues, 
                        address: { 
                          ...prevValues.address, 
                          street: e.target.value } }));
                    }}
                  />

                  <TextField
                    label={t("City") + " *"}
                    inputClassName={"p-3"}
                    className={"max-w-xs min-w-[27vw] mt-4 md:mt-0"}
                    placeholder={t("Insert")}
                    defaultValue={city}
                    onChange={(e) => {
                      setCity(e.target.value)
                      setFormValues(prevValues => ({ 
                        ...prevValues, 
                        address: { 
                          ...prevValues.address, 
                          city: e.target.value } }));
                    }}
                  />

                  <div className="d-flex xl:flex md:w-auto w-full">
                    <TextField
                      label={t("ZipPostalCode")}
                      inputClassName={"p-3"}
                      className={"xl:max-w-[11vw] xl:mr-10 md:mt-10 mt-4"}
                      placeholder={t("Insert")}
                      defaultValue={zipCode}
                      onChange={(e) => {
                        setZipCode(e.target.value)
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          address: { 
                            ...prevValues.address, 
                            zipCode: e.target.value } }));
                      }}
                    />

                    <SelectMenu
                      label={t("Country")}
                      placeholder={t("SelectPlaceholder")}
                      className={"xl:min-w-[13.9vw] xl:mr-5 md:mt-10 mt-4"}
                      inputClassName={"p-1"}
                      options={optionsCountry}
                      defaultValue={optionsCountry && selectedCountry ? optionsCountry.find((item) => {
                        if (selectedCountry === item.label) {
                          return item;
                        }
                      }) : selectedCountry}
                      onChange={(item) => {
                        setSelectedCountry(item.label);
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          address: { 
                            ...prevValues.address, 
                            country: item.label } }));
                      }}
                    />
                  </div>

                </div>
              </Tab.Panel>

              <Tab.Panel
                key={1}
                className={classNames(
                  "rounded-xl bg-white p-3",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
                )}
              >
                <h3 className="text-xl">{t("PriceInfo")}</h3>
                <div className="flex flex-wrap mb-4 ml-auto mr-auto h-fit">
                  <div className="flex md:mb-8 flex-wrap md:flex-nowrap">
                    <CurrencyField
                      className={" mt-2 md:mr-12   max-w-[380px]"}
                      placeholder={"0.00"}
                      value={touristTax}
                      label={t("TouristTax")}
                      inputClassName={"w-fit p-3 md:min-w-[150px] bg-input"}
                      currency={context.organizationInfo && context.organizationInfo.currency}
                      onChange={(e) => {
                        setTouristTax(Number(e.target.value));
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          touristTax: Number(e.target.value) }));
                      }
                      }
                    />
                    <CurrencyField
                      className={" mt-2 md:mr-12   max-w-[380px]"}
                      placeholder={"0.00"}
                      value={basePrice}
                      label={t("BasePrice")}
                      inputClassName={"w-fit p-3 md:min-w-[150px] bg-input"}
                      currency={context.organizationInfo && context.organizationInfo.currency}
                      onChange={(e) => {
                        setBasePrice(Number(e.target.value))
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          basePrice: Number(e.target.value) }));
                      }}
                    />
                    <CurrencyField
                      className={" mt-2 md:mr-12   max-w-[380px]"}
                      placeholder={"0.00"}
                      value={extraServiceFee}
                      label={t("ExtraServiceFee")}
                      inputClassName={"w-fit p-3 md:min-w-[150px] bg-input"}
                      currency={context.organizationInfo && context.organizationInfo.currency}
                      onChange={(e) => {
                        setExtraServiceFee(Number(e.target.value))
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          extraServiceFee: Number(e.target.value) }));
                      
                      }
                      }
                    />
                  </div>
                  <div className="flex mb-4 flex-wrap md:flex-nowrap">
                    <PercentField
                      label={t("OccupancyRate")}
                      className={" mt-2 md:mr-12 md:max-w-[215px]"}
                      inputClassName={"w-fit p-3 md:min-w-[130px] bg-input"}
                      value={occupancyRate}
                      placeholder={"0"}
                      onChange={(e) => {
                        setOccupancyRate(Number(e.target.value))
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          occupancyRate: Number(e.target.value) }));
                      }
                      }
                    />
                    <CurrencyField
                      className={" mt-2 md:mr-12   max-w-[380px]"}
                      placeholder={"0.00"}
                      label={t("AnnualRevenue")}
                      value={annualRevenue}
                      inputClassName={"w-fit p-3 md:min-w-[150px] bg-input"}
                      currency={context.organizationInfo && context.organizationInfo.currency}
                      onChange={(e) => {
                        setAnnualRevenue(Number(e.target.value))
                        setFormValues(prevValues => ({ 
                          ...prevValues, 
                          annualRevenue: Number(e.target.value) }));
                      }
                      }
                    />
                  </div>

                </div>

                <hr className="my-4 " />
                <h3 className="text-xl">{t("Ownership")}</h3>

                <div className="flex flex-wrap md:flex-nowrap">
                  <SelectMenu
                    label={t("ContractTypology")}
                    className={"max-w-xs mb-3 md:mb-0 md:mr-10 min-w-[25vw]"}
                    isSearchable={true}
                    options={contractOptions}
                    defaultValue={contractOptions && contractTypology ? contractOptions.find((item) => {
                      if (contractTypology === item.value) {
                        return item;
                      }
                    }) : contractTypology}
                    placeholder={t("SelectPlaceholder")}
                    onChange={(value) => {
                      setContractTypology(value.value)
                      setFormValues(prevValues => ({ 
                        ...prevValues, 
                        contractTypology: value.value }));
                    }}
                  />
                  <SelectMenu
                    label={t("Status")}
                    className={"max-w-xs md:mr-10 min-w-[25vw]"}
                    options={statusOptions}
                    isSearchable={true}
                    defaultValue={statusOptions && status ? statusOptions.find((item) => {
                      if (status === item.value) {
                        return item;
                      }
                    }) : status}
                    placeholder={t("SelectPlaceholder")}
                    onChange={(value) => {
                      setStatus(value.value)
                      setFormValues(prevValues => ({ 
                        ...prevValues, 
                        status: value.value }));
                    }}
                  />
                </div>

                <SelectMenu
                  placeholder={t("SelectPlaceholder")}
                  className={"max-w-xs mr-10 min-w-[25vw]"}
                  label={<div className="flex justify-between cursor-pointer">
                    <div>
                      {t("Owner")+" *"}
                    </div>
                    <div onClick={() => setOpenModal(true)} className="text-sm mt-1">
                      <FontAwesomeIcon icon={faPlus} size='sm' className="mr-1" />
                      {t("AddOwner")}
                    </div>

                  </div>
                  }
                  isSearchable={true}
                  
                  defaultValue={ownersOptions && idOwner ? ownersOptions.find((item) => {
                    if (idOwner === item.value) {
                      return item;
                    }
                  }) : idOwner}
                  options={ownersOptions}
                  onChange={(value) => {
                    setIdOwner(value.value);
                    setFormValues(prevValues => ({ 
                      ...prevValues, 
                      idOwner: value.value }));
                  }}
                />

              </Tab.Panel>
              <div className="flex justify-end w-full md:pr-20 pr-3 pb-10">

                {
                  IsEditable && (
                    Id ? (
                      <PrimaryButton
                        content={t("Save")}
                        className={"mt-10 p-3 w-[7vw]"}
                        onClick={() => {
                           handleUpdate();
                        }}
                      />
                    ) : (index === 1 && (
                      <PrimaryButton
                        content={t("Save")}
                        className={"mt-10 p-3 w-[7vw]"}
                        onClick={() => {
                          handleCreate();
                        }}
                      />)
                    )
                  )
                }
              </div>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>      
      <ModalCreate
        externalControl
        status={modal}
        body={
          <NewOwnerForm
            onCloseModal={onCloseModal}
            onComplete={(owner) => {
              create("Owner", context.idOrganization, owner)
                .then(() => {
                  context.updateData("owners");
                  setOpenModal(false);
                })
                .catch((err) => {
                  toast.error(t("ErrorOccurred") + err);
                }
                );
            }}
          />
        }
        title={t("NewOwner")}
        className={""}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};