import React from "react";
import { useTranslation } from "react-i18next";

export const SubscriptionCard = ({
    plan,
    numberProperty,
    amount,
    onClick,
    value,
    description,
    amountExtra,
    isSelected 
}) => {
    
    const { t } = useTranslation();
    const name = plan === "host" ? t("Host") : t("PropertyManager");
    const priceLabel = plan === "host" ? t("Price") + ":" : t("PriceFrom") + ":";

    const propertyLabel = plan === "host" ? t("MaximumProperties") + ":" : t("MinimumProperties") + ":";


    return (
        <div className={` subscription-card ${isSelected ? 'selected' : ''}`}>
            <div className="col-span-12  card-head rounded-t-xl min-h-[2vh]"></div>
            <div
                onClick={onClick}
                value={value}
                className={`px-8 py-5 cursor-pointer shadow-md bg-white rounded-b-xl border-2 min-w-[350px] min-h-[450px] border-1 `} 
            >
                <div className="border-sub"></div>
                {/* Main Section */}
                <div className="flex flex-col space-y-3">
                    
                    <h1 className="mt-6 ml-1 text-lg font-bold text-blue text-center">{name}</h1>
                </div>
                {/* Info Section */}
                <div className="flex mb-6 space-y-2 text-base justify-center">
                    <h3 className="flex items-center font-semibold text-sm text-blue text-center">
                        {propertyLabel} <p className="ml-1 font-normal">{numberProperty}</p>
                    </h3>
                </div>
                <div className="my-4 space-y-2 text-base">
                    <h3 className="flex items-center">
                        <p className="flex items-center text-sm text-blue text-left">{description}</p>
                    </h3>
                </div>
                <hr className="w-full mt-8" />
                {/* Button Section */}
                <div className="flex items-center justify-between mt-4">
                    <h2 className="flex items-center font-semibold text-sm text-blue text-center">
                        {priceLabel}
                    </h2>
                    <div className="flex items-center font-semibold text-sm text-blue text-center">
                        
                        <h3>
                            €{amount}
                        </h3>
                    </div>
                </div>
                {plan === "propertyManager" && (
                    <>
                        <div className="flex items-center justify-between mt-4">
                            <h2 className="flex items-center font-semibold text-sm text-blue text-center">
                                {t("ExtraPrice") + ":"}
                            </h2>
                            <div className="flex items-center font-semibold text-sm text-blue text-center">
                                <h3>
                                    €{amountExtra} / {t("PerProperty")}
                                </h3>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <style jsx>{`
                
            `}</style>
        </div>
    );
};
