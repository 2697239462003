import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCreditCard, faDollar, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import {
    HiOutlineCash,
    HiLogin,
    HiLogout,
    HiOutlineHome,
    HiOutlineBriefcase,
    HiOutlineTicket
} from "react-icons/hi";
import {
    TbCashBanknote
} from "react-icons/tb";
import { FaCheck } from 'react-icons/fa';

const TransactionTypologyStep = ({ onComplete, values }) => {
    const { i18n, t } = useTranslation();

    const [type, setType] = useState(values.type);
    const [typology, setTypology] = useState(values.typology);
    const [recurrencyInternal, setRecurrencyInternal] = useState(values.recurrencyInternal);


    const [formValues, setFormValues] = useState({
        type: type,
        typology: typology,
        recurrencyInternal: recurrencyInternal,
    });

    const checkValues = () => {

        if (!formValues.type) {
            toast.error(t("TypeIsRequired"));
            return false;
        }

        if (!formValues.typology) {
            toast.error(t("TypologyIsRequired"));
            return false;
        }

        if (!formValues.recurrencyInternal) {
            toast.error(t("RecurrencyInternalIsRequired"));
            return false;
        }

        if (formValues.type && formValues.typology && formValues.recurrencyInternal) {
            onComplete(formValues)
        }

        return false;
    }

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center text-petroil">
                        <div className="">
                            <div className="bg-white rounded-full items-center">
                                <div className="flex justify-center mb-4 mt-8">
                                    <FaCheck className="text-white bg-petroil rounded-full w-9 h-9 p-1" />
                                </div>
                                <div className="">
                                    <p className={`font-bold text-center text-base text-petroil max-w-[100px] min-h-[45px]`}>{t("Typology")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-[2px] md:w-[200px] w-[100px] bg-blue mb-8"></div>
                        
                        <div className="text-center items-center justify-center ml-2">

                    <div className="flex justify-center mb-4 mt-2"><span className="bg-input text-[#728D94] rounded-full px-4 py-2 font-bold">2</span></div>

                    <div className="mt-6 md:mt-0 ">
                        <p className={`font-bold text-center text-base text-petroil max-w-[100px]`}>{t("Details")}</p>
                    </div>

                </div>
                    </div>
            <div className="flex flex-col items-center justify-center mt-24">
                <h2 className="font-bold">{t("Type")}</h2>
                <p>{t("ChooseTypeDescription")}</p>
            </div>
            <div className="flex mt-8">
                <div
                    className={`relative credit-card-card-billing max-w-[15rem] md:min-w-[8rem] min-w-[7rem] rounded-xl mr-4 md:mr-8 p-6 pb-2 ${type && type === 'cost' ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
                    onClick={() => {
                        setType('cost');
                        setFormValues(prevValues => ({ ...prevValues, type: 'cost' }))
                    }

                    }
                >
                    {type && type === 'cost' && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <HiLogout className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Cost")}</h2>
                </div>
                <div
                    className={`relative credit-card-card-billing md:min-w-[8rem] min-w-[7rem] max-w-[15rem]  rounded-xl  p-6 pb-2 ${type && type === "earning" ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
                    onClick={() => {
                        setType('earning');
                        setTypology('booking');
                        setRecurrencyInternal('tantum');
                        setFormValues(prevValues => ({ ...prevValues, type: 'earning', typology: 'booking', recurrencyInternal: 'tantum' }))
                    }
                    }
                >
                    {type && type === "earning" && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <HiLogin className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Earning")}</h2>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-12">
                <h2 className="font-bold">{t("Category")}</h2>
                <p className="text-center">{t("ChooseTransactionCategoryDescription")}</p>
            </div>
            <div className="flex mt-8 flex-col md:flex-row">

                <div
                    className={`relative credit-card-card-billing min-w-[12rem] max-w-[15rem] rounded-xl md:mr-8 mt-3 md:mt-0 p-6 pb-2 ${type && type === "earning" ? "opacity-60 pointer-events-none " : ""} ${typology && typology === "accommodation" ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
                    onClick={() => {
                        setTypology('accommodation');
                        setFormValues(prevValues => ({ ...prevValues, typology: 'accommodation' }))
                    }
                    }
                >
                    {typology && typology === "accommodation" && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <HiOutlineHome className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Accommodation")}</h2>
                </div>
                <div
                    className={`relative credit-card-card-billing min-w-[12rem] max-w-[15rem]  rounded-xl md:mr-8 mt-3 md:mt-0 p-6 pb-2 ${type && type === "earning" ? "opacity-60 pointer-events-none " : ""} ${typology && typology === "company" ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
                    onClick={() => {
                        setTypology('company');
                        setFormValues(prevValues => ({ ...prevValues, typology: 'company' }))
                    }
                    }
                >
                    {typology && typology === "company" && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <HiOutlineBriefcase className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Company")}</h2>
                </div>
                <div
                    className={`relative credit-card-card-billing max-w-[15rem] min-w-[12rem] rounded-xl  mt-3 md:mt-0 p-6 pb-2 ${typology && typology === 'booking' ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
                    onClick={() => {
                        setTypology('booking');
                        setRecurrencyInternal('tantum')
                        setFormValues(prevValues => ({ ...prevValues, typology: 'booking', recurrencyInternal: 'tantum' }))
                    }}

                >
                    {typology && typology === 'booking' && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <HiOutlineTicket className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Booking")}</h2>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-12">
                <h2 className="font-bold">{t("Recurrency")}</h2>
                <p>{t("ChooseRecurrencyDescription")}</p>
            </div>
            <div className="flex mt-8">
                <div
                    className={`relative credit-card-card-billing max-w-[15rem] md:min-w-[8rem] min-w-[7rem] rounded-xl mr-4 md:mr-8 p-6 pb-2 ${recurrencyInternal && recurrencyInternal === 'tantum' ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]'}`}
                    onClick={() => {
                        setRecurrencyInternal('tantum');
                        setFormValues(prevValues => ({ ...prevValues, recurrencyInternal: 'tantum' }))
                    }
                    }
                >
                    {recurrencyInternal && recurrencyInternal === 'tantum' && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw] m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <TbCashBanknote className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Tantum")}</h2>
                </div>
                <div
                    className={`relative credit-card-card-billing md:min-w-[8rem] min-w-[7rem] max-w-[15rem] rounded-xl  p-6 pb-2  ${recurrencyInternal && recurrencyInternal === "regular" ? 'selected subscription-plan' : 'bg-[rgb(113,167,165)]' } ${typology === 'booking' ? "opacity-60 pointer-events-none " : ""}`}
                    onClick={() => {
                        setRecurrencyInternal('regular');
                        setFormValues(prevValues => ({ ...prevValues, recurrencyInternal: 'regular' }))
                    }
                    }
                >
                    {recurrencyInternal && recurrencyInternal === "regular" && (
                        <div className="absolute top-[-2.4vh] right-[-5vw] md:right-[-1vw]  m-2">
                            <div className="bg-white rounded-full">
                                <FontAwesomeIcon icon={faCheckCircle} size='2xl' color='rgb(68, 202, 68)' />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center'>
                        <HiOutlineCash className={"w-6 h-auto font-bold hover:text-blue "} />
                    </div>
                    <h2 className="font-semibold mt-2 mb-2 text-center">{t("Regular")}</h2>
                </div>
            </div>

            <div
                className="mt-8 mb-12 font-bold cursor-pointer"
                onClick={checkValues}
            >
                {t("Next")} <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
            </div>
        </div>
    );
};

export default TransactionTypologyStep;