import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import Notifications from "./Notifications";
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch  } from 'react-router-dom';


const AccountIndex = () => {
  
  const { t } = useTranslation();
  const navigator = useNavigate();

  const isProfile = useMatch("account/profile/");
  // const isNotification = useMatch("account/notifications/");

  const isMobileTablet = window.matchMedia("(max-width: 991px)").matches;
  return (
    <>
    <h1 className='ml-[3vw] text-xl font-semibold'>{t("AccountSettings")}</h1>
    <div className={!isMobileTablet ? 'flex' : ''}>
    <div className='sidebar-profile mt-16'>
          <div
            className={`sidebar-item-profile active flex justify-between`}
            onClick={() => navigator("../account/profile")}
          >

            <div className="flex">
              <div className=" bg-[rgb(10,109,103)] ml-1 mr-3 min-w-[0.5vh]"></div>
              {t("Profile")}
            </div>
            <div className="">
              {">"}
            </div>
          </div>
          <div
            className={`sidebar-item-profile flex justify-between hover:text-[#0A6D67] pointer-events-none`}
            onClick={() => navigator("../account/notifications")}
          >
            <div className="flex ml-5 ">
              {t("Notifications")}
            </div>
            <div className="">
              {/* {">"} */}
            </div>
          </div>
        </div>
    {isProfile && (

      <Profile/>
    )
        }
    {/* {isNotification && (

      
    <Notifications/> 
    )
        } */}
        </div>

    
    </>
  );
}


export default AccountIndex;
