import React from "react";

function LoadingApp() {
  return (
    <div className="flex items-center justify-center w-full min-h-screen bg-white">
      <div className="animate-bounce">
        <img className="w-full h-auto" src="/Icon.png" />
      </div>
    </div>
  );
}

export default LoadingApp;
