// InfoData.js

export const Info = {
  en:[
  {
    id: "accommodations",
    title: "Accommodations",
    content: `<p class="mb-4">The first step in the Business Intelligence data collection process is configuring accommodations.</p>
    <p class="mb-4">Inserting accommodations is a simple task. There are only a few required details and some optional ones that can be important for performance analysis.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do I add a new Accommodation?</h3>
    <p class="mb-4">In the "Accommodations" section, click "Add a new accommodation" to enter the data of the new accommodation, with the essential characteristics.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the mandatory information?</h3>
    <ul class="list-disc pl-6 mb-4 ml-6                                  ">
        <li>Name</li>
        <li>Street</li>
        <li>City</li>
        <li>State or Province</li>
        <li>Zip Postal Code</li>
        <li>Owner</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is meant by Contract Typology?</h3>
    <p class="mb-4">By choosing the "Contract Typology" it is possible to select the form of contract that has been stipulated with the owner of the “Accommodation”.</p>
    <p class="mb-4">This information is crucial because choosing a form of management for the Accommodation determines the business model, cost, and revenue strategies.</p>
    <p class="mb-4">Let's take an example, if we manage an accommodation under a revenue share model, we should create a booking rule that allocates a percentage of each booking's revenue to the owner.</p>
    <p class="mb-4">Maximizing profits while providing fair compensation to owners is essential to running a successful accommodation business under a revenue-sharing model.</p>
    <p class="mb-4">By implementing a booking rule that allocates a percentage of each booking's revenue to the owner, we ensure that everyone benefits from the business's success. Let's create a booking rule that is fair and transparent, giving owners peace of mind and fostering a positive long-term relationship.</p>
    <p class="mb-4">Opting for a fixed rent accommodation? You'll need to set up a booking rule that ensures timely payment of monthly rent.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Below is a list of the main contracts:</h3>
    <p class="mb-4"><span class="text-lg text-petroil">Fixed Rent:</span> The property manager agrees to pay the owner a fixed monthly rent in this arrangement. The manager then tries to earn more than this amount by renting the property out. Any profit above the fixed rent is retained by the manager, but the manager also bears the risk of any shortfall.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Revenue Share:</span> Under a revenue share agreement, the property manager and owner split the rental income based on an agreed-upon percentage. For example, the manager might keep 30% of the revenue as a management fee, while the owner receives 70%.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Commission-Based:</span> The property manager only earns a commission for each booking they secure. This is often common with agencies that list multiple properties. The commission can vary but typically ranges between 10% to 25% of the booking revenue.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Guaranteed Income:</span> This is a hybrid between fixed rent and revenue share. The property manager guarantees the owner a minimum amount each month (less than the fixed rent model) and then shares any revenue above this guaranteed amount based on an agreed percentage.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Only Agency:</span> In this model, the property manager acts as an agent to list and market the property. They do not handle operations like guest services, maintenance, or cleaning. They charge either a flat fee or a commission for each booking they secure.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Fee-Based:</span> The property manager charges the owner for specific services, like cleaning, maintenance, or guest relations. This is more of an à la carte service where the owner selects which services they want the manager to handle.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Hybrid Models:</span> Sometimes, combinations of the above models are used based on the specific needs of the property owner and the expertise and services offered by the property manager.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Tiered Revenue Share:</span> Instead of a flat percentage, the revenue share between the property owner and manager changes based on revenue tiers. For example, for the first $5,000 in monthly revenue, the manager might take a 20% cut, but for revenue between $5,001 and $10,000, the cut might be reduced to 15%.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Performance-Based Bonus:</span> This model can be added to others. Here, the property manager receives a bonus if certain targets or KPIs (Key Performance Indicators) are achieved, like maintaining an average 90% occupancy rate over a quarter or achieving a certain number of 5-star reviews.</p>
    <p class="mb-4"> <span class="text-lg text-petroil">Booking Fee Model:</span> Instead of a commission on the rental price, the manager charges a flat fee for every booking made, irrespective of the rental amount.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Full Service with Variable Fee:</span> The manager handles all aspects of the short-term rental operation, from listing to guest services to maintenance. The fee is variable based on the amount of work in a particular month. For instance, if there's higher occupancy and more maintenance issues, the fee might be higher.</p>
    <p class="mb-4"> <span class="text-lg text-petroil">Retainer Plus Commission:</span> The property manager receives a fixed retainer fee for basic services, and then a commission on top for every booking. This ensures the manager has a stable income while still being incentivized to get bookings.</p>
    <p class="mb-4"> <span class="text-lg text-petroil">Equity Share Model:</span> This is less common, but in some situations, especially with startups or larger property ventures, the manager might take an equity stake in the property or business instead of (or in addition to) cash payments.</p>
    <p class="mb-4"><span class="text-lg text-petroil">Zero-Fee with Upsells:</span> Here, the manager might not charge any upfront fee or commission for the booking but makes money through upselling additional services to guests like tours, transportation, or in-house amenities.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to deactivate an Accommodation?</h3>
    <p>Absolutely yes.</p>
    <p class="mb-4">In case an accommodation is no longer being temporarily managed or its marketing has been closed due to reasons such as renovations, maintenance, or seasonal markets, you can choose the status "Disabled" in the "Accommodation Details" section.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Why deactivate an Accommodation?</h3>
    <p class="mb-4">The reasons why we could change the "Status" of an Accommodation, moving it from Active to Disabled, can be various: for example, because it is a seasonal market where we only work in summer, and therefore we should change the status; It may be necessary to close for a specific period to carry out extraordinary maintenance.</p>
    <p class="mb-4">One possible reason for this could be if you are redesigning your property or if you operate in a market that allows only a limited number of days for rentals.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can the details of an accommodation be modified?</h3>
    <p class="mb-4">Click on "Edit" to modify Accommodation data.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I delete an Accommodation from the list?</h3>
    <p class="mb-4">No, you cannot delete an Accommodation, but you can deactivate it. The reason for this is that there may be costs or bookings associated with the accommodation which cannot be attributed to the cancelled accommodation.</p>
    <p class="mb-4">Therefore, we recommend deactivating it to prevent any potential data loss.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to assign the same owner to more than one Accommodation?</h3>
    <p>Yes, you can do it!</p>
    <p class="mb-4">It is possible to assign the same owner to multiple accommodations if needed. An Accommodation can't have multiple owners. In the case of multiple owners, a double owner can be created in the "Owner" section and then assigned to the Accommodation.</p>`
  },
  {
    id: "costRules",
    title: "Booking Rules",
    content: `<p class="mb-4">The second step in configuring data collection for Business Intelligence is the Booking Rules section. Don't miss out on this crucial step in setting up your system for success! Property Managers can proactively set up automatisms that generate nearly all the costs involved in managing a property. This innovative feature makes BiLemon the go-to solution for efficient property management.</p>
    <p class="mb-4">The configuration of the booking rules is extremely intuitive, considering that the costs are classified into three typologies, Company Booking and Accommodation Costs.</p>
    <p class="mb-4">Once the typology has been selected, the individual cost must be selected from the "CategoryRequired" menu. For each typology, there are specific and related costs, to be able to classify and allocate according to business logic.</p>
    <p class="mb-4">After selecting Typology and Category, choose the Accommodation (not required for "Company Costs") and define variables for booking rule:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>“Recurrency”</li>
        <li>“Start Date”</li>
        <li>“Payment amount”</li>
    </ol>
    <p class="mb-4">In the case of Booking Costs, the "recurrency" becomes a "trigger", but the logic remains the same: identifying the event that generates the cost.</p>
    <p class="mb-4">In the case of Booking Costs, there are three Triggers:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking Date</li>
        <li>Check-in Date</li>
        <li>Check-out Date</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What are booking rules and what are they for?</h3>
    <p>Automated booking rules streamline your workflow by automatically generating costs when specific events occur, saving you time and effort.</p>
    <p>By configuring specific rules, the Property Manager can effortlessly generate the relative cost based on any given event. Streamlining this process not only saves time but also ensures accuracy and consistency in cost calculation.</p>
    <p>Upon receiving a booking, we know that additional costs such as cleaning, check-in, and OTA commissions will be incurred.</p>
    <p>With the booking rules, there's no need to manually enter costs for each booking. The system automatically generates costs based on the event (in this case, the booking).</p>
    <p>Thanks to this innovative approach, it is possible to automate cost entry and management.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do you create a booking rule?</h3>
    <p>To create a new booking rule, follow these steps:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Assign a name to the rule.</li>
        <li>Choose the Cost Typology, between Booking/Accommodation/Company.</li>
        <li>Select one or more Accommodations (only for Booking and Accommodation Costs).</li>
        <li>Choose the Cost Category.</li>
        <li>Assign a Sub-Category (optional).</li>
        <li>Choose the Supplier (optional).</li>
        <li>Define the Trigger (optional) or Recurrency for which the cost must be generated.</li>
        <li>Define a start date.</li>
        <li>Establish the Payment Type (only for Booking Costs).</li>
        <li>Determine the cost amount.</li>
        <li>Define an end date.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What are the required fields for a booking rule?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking rule name.</li>
        <li>Cost typology.</li>
        <li>Accommodations (only for Booking and Accommodation Costs).</li>
        <li>Category.</li>
        <li>Recurrency.</li>
        <li>Payment type (only for Booking Costs).</li>
        <li>Payment amount.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What does the term "Booking rule name" mean?</h3>
    <p>"Booking rule name" refers to the name assigned by the Property Manager to its booking rule.</p>
    <p>An example is the "Clearing Booking Rule," which generates costs for cleaning during check-out. If the rule were only named "Final Cleaning," it wouldn't make any difference.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the "Cost Typology"?</h3>
    <p>In BiLemon we classify all costs into three large typologies:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Accommodation Costs.</li>
        <li>Booking Costs.</li>
        <li>Company Costs.</li>
    </ol>
    <p>Depending on the typology chosen, there will be specific and relative cost categories.</p>
    <p>Let's take an example, if the Property Manager chooses the "Booking" Typology, the system generates a series of cost categories such as Amenities, Check-in/out, Cleaning, Laundry, Ota Commission, etc. That is all those costs that are generated when you receive a booking.</p>
    <p>Let's take another example, if the Property Manager chooses the "Accommodation" typology, the system generates a series of cost categories, such as Community Fee, Owner Payment, PMS, Channel Manager, Furniture, Insurance, Maintenance, supplies, etc.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the "Cost Category"?</h3>
    <p>The cost categories represent the real costs that affect all property management.</p>
    <p>As already explained for the typologies, different categories of costs relate to the typologies.</p>
    <p>Each typology has a set of cost categories associated with it.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is meant by "Sub-Category"?</h3>
    <p>Numerous costs have subcategories, allowing for granularity in cost analysis.</p>
    <p>Let's take an example, Let's say we have to cover maintenance costs for the swimming pool in a villa:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>The Typology will be "Accommodation",</li>
        <li>The Category will be "Maintenance" and,</li>
        <li>The Sub-Category will be "Pool Maintenance".</li>
    </ul>
    <p>Let's take another example, we have a fixed monthly cost for providing internet in the office:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>The Typology will be "Company",</li>
        <li>The Category will be "Office Expenses" and,</li>
        <li>The Sub-Category will be "Wi-Fi Internet connection".</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How does configure the "Recurrency" of a booking rule?</h3>
    <p>The "Recurrency" of a booking rule represents the periodicity with which a cost can be generated.</p>
    <p>Let's consider an example: warehouse rental fees can be charged on a monthly or annual basis. Additionally, there is the option to customize the recurrence if it doesn't occur daily, weekly, monthly, or yearly.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is meant by "custom" in the recurrency setup?</h3>
    <p>If the event that triggers the cost does not occur on a daily, weekly, monthly, or yearly basis, it is possible to customize the frequency.</p>
    <p>In this case, if we pay a supplier every 2 months, we should choose "Custom" for Recurrency and set "Number of days" to 60.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is a Trigger?</h3>
    <p>The term "Trigger" refers to the specific event that causes a Booking cost to be incurred.</p>
    <p>The Trigger only applies to Booking costs and the Recurrency applies to both Accommodation costs and Company costs. The Trigger has three options:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking Date.</li>
        <li>Check-in Date.</li>
        <li>Check-out Date.</li>
    </ol>
    <p>The Trigger is not mandatory information. If not configured, the default system considers the Booking Date.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is "Payment Type"?</h3>
    <p>The "Payment Type" field, available only for Booking Costs, determines whether a cost is calculated as a percentage or fixed amount.</p>
    <p>Let's consider two examples of different payment types. The OTA commission is typically a percentage-based cost calculated on the booking amount.</p>
    <p>However, the cost of cleaning is typically a fixed amount based on the amount of time dedicated to cleaning.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How many booking rules can I create?</h3>
    <p>It is possible to create a detailed and exhaustive cost structure without any limitations.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I create the same booking rule multiple times but with different values?</h3>
    <p>The answer is yes.</p>
    <p>Taking an example, the booking rule generates the commission calculation for different booking channels. The OTA commission rule remains the same, but the percentage values vary depending on the channels.</p>
    <p>Or, calculating cleaning costs can vary based on Accommodation characteristics, requiring different amounts of time.</p>
    <p>For these specific cases, we can create multiple rules with different parameters.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What happens when a booking rule is deleted?</h3>
    <p>The only consequence of deleting a booking rule is that it will stop generating costs based on that rule.</p>
    <p>Please note that canceling the booking rule does not mean that any previously generated costs will also be canceled.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I apply a booking rule to multiple Accommodations at the same time?</h3>
    <p>The answer is yes.</p>
    <p>If we take the OTA Commission example and apply it to Airbnb, the same calculation applies to all accommodations listed on this booking channel.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to insert a booking rule with past dates?</h3>
    <p>No, the booking rules are not retroactive.</p>
    <p>There is a risk of incurring an infinite number of costs for past events.</p>
    <p>If a Property Manager needs to add costs for past data, they can use the "Add a new cost" function under the "Costs" section.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">If I don't choose a cost typology, why am I unable to see any cost category?</h3>
    <p>All costs are classified into three typologies; If you do not select a typology, then the cost category will not be displayed.</p>`
  },
  {
    id: "costs",
    title: "Costs",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">The Costs section integrates the Booking Rules.</h3>
    <p>In this section, we will include all costs that do not follow a specific rule or are generated occasionally or one-off.</p>
    <p>This section provides you with a significant level of detail regarding management control, allowing you to reach a higher level of granularity.</p>
    <p>It is important to classify, allocate, and include the cost of even a very cheap light bulb!</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the difference between Booking Rules and Costs?</h3>
    <p>Booking Rules are automated processes used to calculate costs when specific events occur (e.g. bookings for variable costs) or when costs are known to occur periodically (e.g. fixed costs).</p>
    <p>Costs can be generated through booking rules or created immediately if they don't follow specific rules, can't be automated, or occur irregularly.</p>
    <p>Let's take an example of Cost, the purchase of a pillow, which is not a recurring cost, does not depend on any ordinary event, and is a one-off purchase.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What are the mandatory fields to add a cost?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Cost typology.</li>
        <li>Accommodations (only for Booking and Accommodation Costs).</li>
        <li>Category.</li>
        <li>Cost date.</li>
        <li>Payment type (only for Booking Costs).</li>
        <li>Payment amount.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the difference between “Cost Date” and “Payment Date”? Why is “Cost Date” mandatory and “Payment Date” is not?</h3>
    <p>The Cost Date indicates the date on which a specific cost is generated.</p>
    <p>The Payment date instead indicates the date on which that cost will be paid.</p>
    <p>This differentiation is mainly used to analyze cash flow.</p>
    <p>Let's take an example, on September 1st, 2024, we purchased a new kitchen table for our apartment using our credit card.</p>
    <p>The cost will be charged on the first day of the following month (October 1st, 2024).</p>
    <p>The Cost Date is a mandatory field required for temporal cost assignment.</p>
    <p>The Payment Date is not always required, as this date distinction may not always be applicable.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">When I enter the same cost for two Accommodations, does the amount remain the same for both Accommodations or is it split?</h3>
    <p>The cost for two accommodations is not shared; instead, two separate costs of the same amount are created for each accommodation.</p>
    <p>It's a way to optimize and save time.</p>
    <p>Let's say we purchase two identical chairs for two different accommodations at the same cost. Instead of assigning a cost to each chair, we assign a cost to each accommodation and then select the two accommodations.</p>
    <p>The system will automatically charge the cost of the chair for each Accommodation.</p>
    <p>To do this we did not need to create two distinct costs, but rather a single cost by selecting the two Accommodations.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to enter a cost for future dates? And what about the past ones?</h3>
    <p>Yes, it is possible to enter a cost for both future and past dates.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I make changes or delete a previously entered cost?</h3>
    <p>The answer is yes.</p>
    <p>Costs can be adjusted or canceled depending on the need, and changes may impact other specifications like date and accommodation.</p>`

  },
  {
    id: "bookings",
    title: "Bookings",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">The Bookings section is where reservations are entered, representing the primary source of revenue for Property Managers.</h3>
    <p>This step can be considered as the final step for Business Intelligence data collection.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do I make a booking?</h3>
    <p>To add a new booking, you can use the "Add a new booking" function.</p>
    <p>It is possible to import multiple bookings at once by uploading an Excel file.</p>
    <p>We recommend using the "Download Sample" function to get the correct format, populating it with the reservations to be inserted, and importing it through the "Import" function.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">What is the mandatory information to enter for a Booking?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Booking Date.</li>
        <li>Check-in/Out dates.</li>
        <li>No Guests.</li>
        <li>Amount.</li>
        <li>Accommodation.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I make changes or delete a previously entered booking?</h3>
    <p>Yes, it is always possible to modify or cancel a booking.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">If I make changes to a booking, will the associated costs be updated accordingly?</h3>
    <p>The answer is yes.</p>
    <p>Modifying any information related to booking booking rules will affect generated costs.</p>
    <p>If a booking's check-out date is changed, the date of the cleaning cost will adjust based on the previously set rule.</p>
    <p>The same for the calculation of costs that are based on percentages, that is affected by changes in booking amounts.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How can I enter multiple bookings at the same time?</h3>
    <p>To import multiple reservations at once, use an Excel file.</p>
    <p>BiLemon allows users to download a pre-formatted Excel file.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Why can't I find a Booking Channel? How do I add a Booking Channel?</h3>
    <p>To answer certain questions, we must make a premise: The OTA Commission is a COST and must be managed in the costs section. First, we create booking rules for commission calculations.</p>
    <p>You can configure a Booking Channel in the OTA section, but without creating a booking rule, it will still not be visible.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How can I order the booking table?</h3>
    <p>The bookings table can be sorted by Booking date or Check-in date.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Is it possible to add a Booking retroactively?</h3>
    <p>Yes, it is always possible to add a Booking retroactively.</p>`
  },
  {
    id: "ota",
    title: "OTA",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">The section for OTAs lists all booking channels where Accommodations are marketed.</h3>
    <p>This insertion is helpful when creating the booking rule for the OTA Commission. It allows you to easily find the channel name from the appropriate menu.</p>
    <p>The list of OTAs can also be populated directly when the "OTA Commission" booking rule is created, with the "Add Booking Provider" function.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">How do I manage booking channels in BiLemon?</h3>
    <p>Booking channels are cost generators, as their commission is a cost borne by Property Managers for each new booking.</p>
    <p>It is possible to add all booking channels in the OTA section of the general settings. However, to generate commission costs for these channels, it is necessary to create a booking rule called "OTA Commission". During the creation of this rule, you can use the "Add Booking Provider" function to include the new channel.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Why can't I see new booking channels when entering Booking?</h3>
    <p>To calculate OTA commissions, we need to create booking rules in the costs section first. Only then can we access channel information when inserting a booking in the OTA section.</p>
    <p>Without booking rules, channel information won't be visible.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Can I configure multiple booking rules for the same booking channel?</h3>
    <p>Multiple booking rules can be created for the same channel based on varying calculation percentages for different bookings, even if they are from the same channel.</p>`
  }

],
  it:[
    {
      id: "accommodations",
      title: "Alloggi",
      content: `<p class="mb-4">Il primo passo nel processo di raccolta dati di Business Intelligence è configurare gli alloggi.</p>
      <p class="mb-4">Inserire gli alloggi è un compito semplice. Ci sono solo alcuni dettagli obbligatori e alcuni opzionali che possono essere importanti per l'analisi delle performance.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Come aggiungere un nuovo Alloggio?</h3>
      <p class="mb-4">Nella sezione "Alloggi", fare clic su "Aggiungi un nuovo alloggio" per inserire i dati del nuovo alloggio, con le caratteristiche essenziali.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quali sono le informazioni obbligatorie?</h3>
      <ul class="list-disc pl-6 mb-4 ml-6                                  ">
          <li>Nome</li>
          <li>Via</li>
          <li>Città</li>
          <li>Stato o Provincia</li>
          <li>Codice Postale</li>
          <li>Proprietario</li>
      </ul>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cosa si intende per Tipologia di Contratto?</h3>
      <p class="mb-4">Scegliendo la "Tipologia di Contratto" è possibile selezionare la forma di contratto stipulata con il proprietario dell'Alloggio.</p>
      <p class="mb-4">Questa informazione è cruciale perché la scelta di una forma di gestione per l'Alloggio determina il modello di business, i costi e le strategie di guadagno.</p>
      <p class="mb-4">Ad esempio, se gestiamo un alloggio con un modello di revenue share, dovremmo creare una regola di costo che assegna un percentuale del ricavo di ogni prenotazione al proprietario.</p>
      <p class="mb-4">Massimizzare i profitti fornendo una giusta compensazione ai proprietari è essenziale per gestire con successo un'attività di alloggio con un modello di revenue sharing.</p>
      <p class="mb-4">Implementando una regola di costo che assegna una percentuale del ricavo di ogni prenotazione al proprietario, assicuriamo che tutti beneficino del successo dell'attività. Creiamo una regola di costo equa e trasparente, dando ai proprietari tranquillità e promuovendo una relazione positiva a lungo termine.</p>
      <p class="mb-4">Optiamo per un alloggio a canone fisso? Sarà necessario impostare una regola di costo che garantisca il pagamento tempestivo del canone mensile.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Di seguito è riportato un elenco dei principali contratti:</h3>
      <p class="mb-4"><span class="text-lg text-petroil">Canone Fisso:</span> Il gestore dell'immobile accetta di pagare al proprietario un canone mensile fisso in questo accordo. Il gestore cerca quindi di guadagnare più di questa cifra affittando la proprietà. Eventuali profitti oltre il canone fisso sono trattenuti dal gestore, ma questo assume anche il rischio di eventuali perdite.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Revenue Share:</span> In base a un accordo di revenue share, il gestore dell'immobile e il proprietario dividono il reddito dell'affitto in base a una percentuale concordata. Ad esempio, il gestore potrebbe trattenere il 30% del ricavo come tariffa di gestione, mentre il proprietario riceve il 70%.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Basato su Commissione:</span> Il gestore dell'immobile guadagna solo una commissione per ogni prenotazione che riesce a garantire. Questo è spesso comune con agenzie che elencano molteplici proprietà. La commissione può variare ma di solito va dal 10% al 25% del ricavo della prenotazione.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Guaranteed Income:</span> Questo è un ibrido tra canone fisso e revenue share. Il gestore dell'immobile garantisce al proprietario un importo minimo ogni mese (inferiore al modello di canone fisso) e poi condivide ogni ricavo superiore a questa cifra garantita in base a una percentuale concordata.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Solo Agenzia:</span> In questo modello, il gestore dell'immobile agisce come agente per elencare e commercializzare la proprietà. Non si occupano di operazioni come servizi agli ospiti, manutenzione o pulizia. Addebitano una tariffa fissa o una commissione per ogni prenotazione che riescono a garantire.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Basato su Fee:</span> Il gestore dell'immobile addebita al proprietario per servizi specifici, come pulizia, manutenzione o relazioni con gli ospiti. Si tratta più di un servizio à la carte dove il proprietario seleziona quali servizi il gestore deve gestire.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Modelli Ibridi:</span> A volte, vengono utilizzate combinazioni dei modelli sopra descritti in base alle esigenze specifiche del proprietario della proprietà e alle competenze e servizi offerti dal gestore dell'immobile.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Revenue Share a Tiers:</span> Invece di una percentuale fissa, la revenue share tra il proprietario dell'immobile e il gestore varia in base a fasce di ricavo. Ad esempio, per i primi $5,000 di ricavo mensile, il gestore potrebbe prendere una commissione del 20%, ma per i ricavi tra $5,001 e $10,000, la commissione potrebbe essere ridotta al 15%.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Bonus basato sulle Performance:</span> Questo modello può essere aggiunto ad altri. Qui, il gestore dell'immobile riceve un bonus se vengono raggiunti determinati obiettivi o KPI (Indicatori chiave di performance), come mantenere un tasso di occupazione medio del 90% per un trimestre o raggiungere un certo numero di recensioni a 5 stelle.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Modello di Tariffa di Prenotazione:</span> Invece di una commissione sul prezzo di affitto, il gestore addebita una tariffa fissa per ogni prenotazione effettuata, indipendentemente dall'importo dell'affitto.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Servizio Completo con Tariffa Variabile:</span> Il gestore gestisce tutti gli aspetti dell'operazione di affitto a breve termine, dalla lista ai servizi agli ospiti alla manutenzione. La tariffa è variabile in base alla quantità di lavoro in un determinato mese. Ad esempio, se c'è un'occupazione più alta e più problemi di manutenzione, la tariffa potrebbe essere più alta.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Retainer Plus Commission:</span> Il gestore dell'immobile riceve una tassa fissa di mantenimento per i servizi di base, e poi una commissione aggiuntiva per ogni prenotazione. Questo assicura che il gestore abbia un reddito stabile pur essendo incentivato a ottenere prenotazioni.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Modello di Equity Share:</span> Questo è meno comune, ma in alcune situazioni, specialmente con startup o grandi progetti immobiliari, il gestore potrebbe prendere una quota di equity nella proprietà o nell'attività invece (o oltre) ai pagamenti in contanti.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Zero-Fee con Upsells:</span> Qui, il gestore potrebbe non addebitare alcuna tariffa anticipata o commissione per la prenotazione ma guadagna vendendo servizi aggiuntivi agli ospiti come tour, trasporti o servizi in-house.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">È possibile disattivare un Alloggio?</h3>
      <p>Assolutamente sì.</p>
      <p class="mb-4">Nel caso in cui un alloggio non sia più gestito temporaneamente o la sua commercializzazione sia stata chiusa per motivi come ristrutturazioni, manutenzione o mercati stagionali, è possibile scegliere lo stato "Disattivato" nella sezione "Dettagli Alloggio".</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Perché disattivare un Alloggio?</h3>
      <p class="mb-4">Le ragioni per cui potremmo cambiare lo "Stato" di un Alloggio, passandolo da Attivo a Disattivato, possono essere varie: ad esempio, perché si tratta di un mercato stagionale dove lavoriamo solo d'estate, e quindi dovremmo cambiare lo stato; potrebbe essere necessario chiudere per un periodo specifico per effettuare manutenzioni straordinarie.</p>
      <p class="mb-4">Un possibile motivo potrebbe essere se si sta ridisegnando la proprietà o se si opera in un mercato che consente solo un numero limitato di giorni per le affittanze.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">I dettagli di un alloggio possono essere modificati?</h3>
      <p class="mb-4">Fare clic su "Modifica" per modificare i dati dell'Alloggio.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso eliminare un Alloggio dall'elenco?</h3>
      <p class="mb-4">No, non puoi eliminare un Alloggio, ma puoi disattivarlo. Il motivo è che potrebbero esserci costi o prenotazioni associati all'alloggio che non possono essere attribuiti all'alloggio cancellato.</p>
      <p class="mb-4">Pertanto, consigliamo di disattivarlo per evitare eventuali perdite di dati.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">È possibile assegnare lo stesso proprietario a più di un Alloggio?</h3>
      <p>Sì, puoi farlo!</p>
      <p class="mb-4">È possibile assegnare lo stesso proprietario a più alloggi se necessario. Un Alloggio non può avere più proprietari. Nel caso di più proprietari, è possibile creare un doppio proprietario nella sezione "Proprietario" e poi assegnarlo all'Alloggio.</p>`
  },  
  {
    id: "costRules",
    title: "Regole di Pagamento",
    content: `<p class="mb-4">Il secondo passo per configurare la raccolta dati per Business Intelligence è la sezione Regole di Pagamento. Non perdere questo passo fondamentale per preparare il tuo sistema al successo! Gli Amministratori di Proprietà possono impostare automatismi che generano quasi tutti i costi associati alla gestione di una proprietà. Questa funzione innovativa fa di BiLemon la soluzione ideale per una gestione immobiliare efficiente.</p>
    <p class="mb-4">La configurazione delle Regole di Pagamento è estremamente intuitiva, considerando che i costi sono classificati in tre tipologie: Costi di Prenotazione e Costi di Alloggio.</p>
    <p class="mb-4">Una volta selezionata la tipologia, è necessario scegliere il costo specifico dal menu "CategoriaRichiesta". Per ogni tipologia, ci sono costi specifici e relativi, da poter classificare e allocare secondo la logica aziendale.</p>
    <p class="mb-4">Dopo aver selezionato la Tipologia e la Categoria, scegli l'Alloggio (non richiesto per "Costi Aziendali") e definisci le variabili per la regola di costo:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>“Ricorrenza”</li>
        <li>“Data di Inizio”</li>
        <li>“Importo del Pagamento”</li>
    </ol>
    <p class="mb-4">Nel caso dei Costi di Prenotazione, la "Ricorrenza" diventa un "Trigger", ma la logica resta la stessa: identificare l'evento che genera il costo.</p>
    <p class="mb-4">Nel caso dei Costi di Prenotazione, ci sono tre Trigger:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Data di Prenotazione</li>
        <li>Data di Check-in</li>
        <li>Data di Check-out</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cosa sono le Regole di Pagamento e a cosa servono?</h3>
    <p>Le Regole di Pagamento automatizzate ottimizzano il tuo flusso di lavoro generando automaticamente costi quando si verificano eventi specifici, risparmiando tempo e sforzi.</p>
    <p>Configurando regole specifiche, l'Amministratore di Proprietà può generare senza sforzo il costo relativo basato su qualsiasi evento dato. Ottimizzare questo processo non solo risparmia tempo, ma garantisce anche accuratezza e coerenza nel calcolo dei costi.</p>
    <p>All'arrivo di una prenotazione, sappiamo che si sosterranno costi aggiuntivi come pulizia, check-in e commissioni OTA.</p>
    <p>Con le Regole di Pagamento, non è necessario inserire manualmente i costi per ogni prenotazione. Il sistema genera automaticamente i costi basati sull'evento (in questo caso, la prenotazione).</p>
    <p>Grazie a questo approccio innovativo, è possibile automatizzare l'inserimento e la gestione dei costi.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Come creare una regola di costo?</h3>
    <p>Per creare una nuova regola di costo, segui questi passaggi:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Assegna un nome alla regola.</li>
        <li>Scegli la Tipologia di Costo, tra Prenotazione/Alloggio/Azienda.</li>
        <li>Seleziona uno o più Alloggi (solo per Costi di Prenotazione e di Alloggio).</li>
        <li>Scegli la Categoria di Costo.</li>
        <li>Assegna una Sotto-Categoria (opzionale).</li>
        <li>Scegli il Fornitore (opzionale).</li>
        <li>Definisci il Trigger (opzionale) o la Ricorrenza per cui il costo deve essere generato.</li>
        <li>Definisci una data di inizio.</li>
        <li>Stabilisci il Tipo di Pagamento (solo per Costi di Prenotazione).</li>
        <li>Determina l'importo del costo.</li>
        <li>Definisci una data di fine.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quali sono i campi obbligatori per una regola di costo?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Nome della regola di costo.</li>
        <li>Tipologia di costo.</li>
        <li>Alloggi (solo per Costi di Prenotazione e di Alloggio).</li>
        <li>Categoria.</li>
        <li>Ricorrenza.</li>
        <li>Tipo di pagamento (solo per Costi di Prenotazione).</li>
        <li>Importo del pagamento.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cosa significa il termine "Nome della regola di costo"?</h3>
    <p>"Nome della regola di costo" si riferisce al nome assegnato dall'Amministratore di Proprietà alla sua regola di costo.</p>
    <p>Un esempio è "Regola di Costo Pulizia," che genera costi per la pulizia durante il check-out. Se la regola fosse solo chiamata "Pulizia Finale", non farebbe differenza.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Che cos'è la "Tipologia di Costo"?</h3>
    <p>In BiLemon classifichiamo tutti i costi in tre grandi tipologie:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Costi di Alloggio.</li>
        <li>Costi di Prenotazione.</li>
        <li>Costi Aziendali.</li>
    </ol>
    <p>A seconda della tipologia scelta, ci saranno categorie di costo specifiche e relative.</p>
    <p>Facciamo un esempio: se l'Amministratore di Proprietà sceglie la "Tipologia Prenotazione", il sistema genera una serie di categorie di costo come Servizi, Check-in/out, Pulizia, Lavanderia, Commissione OTA, ecc. Ovvero tutti quei costi che si generano quando si riceve una prenotazione.</p>
    <p>Facciamo un altro esempio: se l'Amministratore di Proprietà sceglie la tipologia "Alloggio", il sistema genera una serie di categorie di costo, come Spese Condominiali, Pagamento Proprietario, PMS, Channel Manager, Arredamento, Assicurazione, Manutenzione, forniture, ecc.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Che cos'è la "Categoria di Costo"?</h3>
    <p>Le categorie di costo rappresentano i costi reali che incidono sulla gestione di una proprietà.</p>
    <p>Come spiegato già per le tipologie, categorie diverse di costi si riferiscono alle tipologie.</p>
    <p>Ogni tipologia ha un insieme di categorie di costo associate.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cosa si intende per "Sotto-Categoria"?</h3>
    <p>Numerosi costi hanno sotto-categorie, permettendo una granularità nell'analisi dei costi.</p>
    <p>Facciamo un esempio, supponiamo di dover coprire i costi di manutenzione della piscina in una villa:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>La Tipologia sarà "Alloggio",</li>
        <li>La Categoria sarà "Manutenzione" e,</li>
        <li>La Sotto-Categoria sarà "Manutenzione Piscina".</li>
    </ul>
    <p>Facciamo un altro esempio, abbiamo un costo fisso mensile per la fornitura di internet in ufficio:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>La Tipologia sarà "Azienda",</li>
        <li>La Categoria sarà "Spese Ufficio" e,</li>
        <li>La Sotto-Categoria sarà "Internet".</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Che cos'è il "Forno"?</h3>
    <p>Il campo "Forno" è opzionale e permette di assegnare il costo a un fornitore specifico.</p>
    <p>Nel caso di un costo fisso mensile per una pulizia, il fornitore potrebbe essere "Pulizie Inc.".</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Che cos'è la "Ricorrenza" e come può essere configurata?</h3>
    <p>La "Ricorrenza" è una caratteristica utile per definire con che frequenza si verificano gli eventi che generano il costo.</p>
    <p>La ricorrenza può essere configurata per vari periodi di tempo come giornaliera, settimanale, mensile o annuale.</p>
    <p>Se il costo non segue uno di questi schemi, puoi scegliere "Personalizzato" e definire l'intervallo specifico di giorni tra gli eventi.</p>
    <p>Ad esempio, se paghi un fornitore ogni 2 mesi, seleziona "Personalizzato" e imposta "Numero di giorni" a 60.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Che cos'è un "Trigger"?</h3>
    <p>Il "Trigger" è l'evento specifico che causa la generazione di un Costo di Prenotazione.</p>
    <p>Il Trigger si applica solo ai Costi di Prenotazione mentre la Ricorrenza è applicabile ai Costi di Alloggio e Costi Aziendali. I Trigger disponibili sono:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Data di Prenotazione.</li>
        <li>Data di Check-in.</li>
        <li>Data di Check-out.</li>
    </ol>
    <p>Se non selezioni un Trigger, il sistema assume la Data di Prenotazione come predefinita.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Che cos'è il "Tipo di Pagamento"?</h3>
    <p>Il "Tipo di Pagamento" definisce se un costo è calcolato come percentuale o come importo fisso.</p>
    <p>Ad esempio, una commissione OTA è solitamente un costo basato su una percentuale dell'importo della prenotazione, mentre un costo di pulizia è di solito un importo fisso.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quante Regole di Pagamento posso creare?</h3>
    <p>Puoi creare un numero illimitato di Regole di Pagamento e costruire una struttura dettagliata e precisa dei costi.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso creare più Regole di Pagamento con valori diversi per lo stesso tipo di costo?</h3>
    <p>Sì, puoi creare più Regole di Pagamento con parametri diversi per costi diversi.</p>
    <p>Ad esempio, puoi avere una regola di costo per una commissione OTA che varia a seconda del canale di prenotazione o una regola di costo per la pulizia che varia in base alle caratteristiche dell'alloggio.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cosa succede se elimino una regola di costo?</h3>
    <p>Eliminare una regola di costo significa che non verranno più generati costi basati su quella regola.</p>
    <p>La cancellazione della regola non influisce sui costi generati in precedenza.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso applicare una regola di costo a più Alloggi contemporaneamente?</h3>
    <p>Sì, puoi applicare una regola di costo a più alloggi allo stesso tempo.</p>
    <p>Per esempio, una commissione OTA applicata a Airbnb si applica a tutti gli alloggi listati su quel canale di prenotazione.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">È possibile inserire una regola di costo con date passate?</h3>
    <p>No, le Regole di Pagamento non sono retroattive.</p>
    <p>Non è possibile inserire Regole di Pagamento per eventi già avvenuti.</p>
    <p>Per aggiungere costi per periodi passati, puoi utilizzare la funzione "Aggiungi un nuovo costo" nella sezione "Costi".</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Perché se non scelgo una tipologia di costo non vedo nessuna categoria di costo?</h3>
    <p>Poiché tutti i costi appartengono a una delle tre tipologie, se non selezioni una tipologia, le categorie di costo non verranno visualizzate.</p>`
}
,
{
  id: "costs",
  title: "Costi",
  content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La sezione Costi integra le Regole di Costo.</h3>
  <p>In questa sezione, puoi gestire tutti i costi che non seguono una regola specifica o che sono generati occasionalmente o come eventi unici.</p>
  <p>Questa sezione ti offre un controllo dettagliato della gestione dei costi, permettendoti di raggiungere un alto livello di granularità.</p>
  <p>È importante classificare, assegnare e includere ogni costo, anche quelli piccoli come una lampadina!</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Qual è la differenza tra Regole di Costo e Costi?</h3>
  <p>Le Regole di Costo sono processi automatizzati per calcolare costi basati su eventi specifici (ad esempio, prenotazioni per costi variabili) o su costi periodici noti (ad esempio, costi fissi).</p>
  <p>I Costi possono essere generati tramite regole di costo o aggiunti manualmente se non seguono regole specifiche, se non sono automatizzabili, o se si verificano in modo irregolare.</p>
  <p>Ad esempio, l'acquisto di un cuscino non è un costo ricorrente e non dipende da un evento ordinario, quindi è considerato un costo unico.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quali sono i campi obbligatori per aggiungere un costo?</h3>
  <ol class="list-decimal pl-6 mb-4">
      <li>Tipologia di Costo.</li>
      <li>Alojamientos (solo per Costi di Prenotazione e Alloggio).</li>
      <li>Categoria.</li>
      <li>Data del Costo.</li>
      <li>Tipo di Pagamento (solo per Costi di Prenotazione).</li>
      <li>Importo del Pagamento.</li>
  </ol>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Qual è la differenza tra “Data del Costo” e “Data di Pagamento”? Perché la “Data del Costo” è obbligatoria mentre la “Data di Pagamento” non lo è?</h3>
  <p>La Data del Costo è la data in cui il costo specifico è stato generato.</p>
  <p>La Data di Pagamento è la data in cui il costo sarà pagato.</p>
  <p>Questa distinzione è importante per l'analisi del flusso di cassa.</p>
  <p>Ad esempio, il 1° settembre 2024, acquisti un nuovo tavolo per la cucina con la tua carta di credito.</p>
  <p>Il costo verrà addebitato il primo giorno del mese successivo (1 ottobre 2024).</p>
  <p>La Data del Costo è obbligatoria per la registrazione temporale del costo.</p>
  <p>La Data di Pagamento non è sempre necessaria, poiché questa distinzione potrebbe non essere applicabile in tutti i casi.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Se inserisco lo stesso costo per due Alloggi, il costo viene suddiviso tra i due Alloggi o mantenuto uguale?</h3>
  <p>Il costo per due alloggi non viene condiviso; vengono creati due costi separati per ogni alloggio con lo stesso importo.</p>
  <p>Per esempio, se si aggiunge un costo di pulizia di 20 € per due alloggi diversi, avrai due costi distinti di 20 € per ciascuno.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso aggiungere lo stesso costo più volte con lo stesso importo?</h3>
  <p>Sì, puoi aggiungere più volte lo stesso costo, anche con lo stesso importo.</p>
  <p>Ad esempio, se hai acquistato due lampadine per 10 € ciascuna, puoi registrare due costi distinti di 10 €.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso modificare un costo esistente?</h3>
  <p>Sì, puoi modificare i dettagli di un costo esistente.</p>
  <p>Puoi aggiornare informazioni come l'importo, la categoria e la data, se necessario.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso eliminare un costo esistente?</h3>
  <p>Sì, è possibile eliminare un costo esistente.</p>
  <p>Attenzione: una volta eliminato, il costo non può essere ripristinato.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso aggiungere un costo per un evento passato?</h3>
  <p>No, non puoi aggiungere costi per eventi passati.</p>
  <p>Per aggiungere un costo per periodi passati, è necessario usare la funzione "Aggiungi un Nuovo Costo" nella sezione Costi.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cosa fare se una spesa è stata erroneamente registrata come costo?</h3>
  <p>Puoi correggere l'errore modificando i dettagli del costo esistente o eliminando il costo errato e creandone uno nuovo con i dati corretti.</p>
  <p>Le modifiche possono influenzare altre specifiche come la data e il pagamento.</p>`
}
,
{
  id: "bookings",
  title: "Prenotazioni",
  content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La sezione Prenotazioni è dove vengono inserite le prenotazioni, rappresentando la principale fonte di entrate per i Gestori di Proprietà.</h3>
  <p>Questo passaggio può essere considerato come l'ultimo passaggio per la raccolta dei dati di Business Intelligence.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Come fare una prenotazione?</h3>
  <p>Per aggiungere una nuova prenotazione, puoi utilizzare la funzione "Aggiungi una nuova prenotazione".</p>
  <p>È possibile importare più prenotazioni contemporaneamente caricando un file Excel.</p>
  <p>Ti consigliamo di usare la funzione "Scarica Esempio" per ottenere il formato corretto, riempirlo con le prenotazioni da inserire e importarlo tramite la funzione "Importa".</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quali sono le informazioni obbligatorie da inserire per una Prenotazione?</h3>
  <ol class="list-decimal pl-6 mb-4">
      <li>Data di Prenotazione.</li>
      <li>Date di Check-in/Check-out.</li>
      <li>Numero di Ospiti.</li>
      <li>Importo.</li>
      <li>Alloggio.</li>
  </ol>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso modificare o cancellare una prenotazione precedentemente inserita?</h3>
  <p>Sì, è sempre possibile modificare o annullare una prenotazione.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Se faccio modifiche a una prenotazione, i costi associati verranno aggiornati di conseguenza?</h3>
  <p>La risposta è sì.</p>
  <p>Modificare qualsiasi informazione relativa alle regole dei costi della prenotazione influirà sui costi generati.</p>
  <p>Se la data di check-out di una prenotazione viene modificata, la data del costo per la pulizia si adeguerà in base alla regola impostata precedentemente.</p>
  <p>Lo stesso vale per il calcolo dei costi basati su percentuali, che è influenzato dai cambiamenti negli importi delle prenotazioni.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Come posso inserire più prenotazioni contemporaneamente?</h3>
  <p>Per importare più prenotazioni contemporaneamente, utilizza un file Excel.</p>
  <p>BiLemon consente di scaricare un file Excel pre-formattato.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Perché non riesco a trovare un Canale di Prenotazione? Come aggiungere un Canale di Prenotazione?</h3>
  <p>Per rispondere a certe domande, dobbiamo fare una premessa: La Commissione OTA è un COSTO e deve essere gestita nella sezione dei costi. Prima creiamo le regole di costo per i calcoli delle commissioni.</p>
  <p>Puoi configurare un Canale di Prenotazione nella sezione OTA, ma senza creare una regola di costo, non sarà comunque visibile.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Come posso ordinare la tabella delle prenotazioni?</h3>
  <p>La tabella delle prenotazioni può essere ordinata per Data di Prenotazione o Data di Check-in.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">È possibile aggiungere una Prenotazione retroattivamente?</h3>
  <p>Sì, è sempre possibile aggiungere una Prenotazione retroattivamente.</p>`
}
,
{
  id: "ota",
  title: "OTA",
  content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La sezione OTA elenca tutti i canali di prenotazione dove gli Alloggi sono commercializzati.</h3>
  <p>Questa inserzione è utile quando si crea la regola di costo per la Commissione OTA. Permette di trovare facilmente il nome del canale dal menu appropriato.</p>
  <p>La lista degli OTA può essere popolata anche direttamente quando viene creata la regola di costo "Commissione OTA", utilizzando la funzione "Aggiungi Fornitore di Prenotazione".</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Come gestisco i canali di prenotazione in BiLemon?</h3>
  <p>I canali di prenotazione sono generatori di costi, poiché la loro commissione è un costo sostenuto dai Gestori di Proprietà per ogni nuova prenotazione.</p>
  <p>È possibile aggiungere tutti i canali di prenotazione nella sezione OTA delle impostazioni generali. Tuttavia, per generare costi di commissione per questi canali, è necessario creare una regola di costo chiamata "Commissione OTA". Durante la creazione di questa regola, puoi usare la funzione "Aggiungi Fornitore di Prenotazione" per includere il nuovo canale.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Perché non vedo i nuovi canali di prenotazione quando inserisco una Prenotazione?</h3>
  <p>Per calcolare le commissioni OTA, dobbiamo prima creare regole di costo nella sezione dei costi. Solo allora possiamo accedere alle informazioni sui canali quando inseriamo una prenotazione nella sezione OTA.</p>
  <p>Senza regole di costo, le informazioni sui canali non saranno visibili.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Posso configurare più regole di costo per lo stesso canale di prenotazione?</h3>
  <p>È possibile creare più regole di costo per lo stesso canale basandosi su percentuali di calcolo diverse per prenotazioni diverse, anche se provengono dallo stesso canale.</p>`
}


],
  fr:[
    {
      id: "accommodations",
      title: "Hébergements",
      content: `<p class="mb-4">La première étape du processus de collecte de données Business Intelligence est la configuration des hébergements.</p>
      <p class="mb-4">Insérer des hébergements est une tâche simple. Il y a seulement quelques détails obligatoires et certains facultatifs qui peuvent être importants pour l'analyse des performances.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment ajouter un nouvel Hébergement?</h3>
      <p class="mb-4">Dans la section "Hébergements", cliquez sur "Ajouter un nouvel hébergement" pour entrer les données du nouvel hébergement, avec les caractéristiques essentielles.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quelles sont les informations obligatoires?</h3>
      <ul class="list-disc pl-6 mb-4 ml-6                                  ">
          <li>Nom</li>
          <li>Rue</li>
          <li>Ville</li>
          <li>État ou Province</li>
          <li>Code Postal</li>
          <li>Propriétaire</li>
      </ul>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Que signifie la Typologie de Contrat?</h3>
      <p class="mb-4">En choisissant la "Typologie de Contrat", il est possible de sélectionner la forme de contrat qui a été conclue avec le propriétaire de l'Hébergement.</p>
      <p class="mb-4">Cette information est cruciale car le choix d'une forme de gestion pour l'Hébergement détermine le modèle d'affaires, les coûts et les stratégies de revenu.</p>
      <p class="mb-4">Prenons un exemple, si nous gérons un hébergement sous un modèle de partage de revenus, nous devrions créer une règle de coût qui attribue un pourcentage du revenu de chaque réservation au propriétaire.</p>
      <p class="mb-4">Maximiser les profits tout en offrant une juste compensation aux propriétaires est essentiel pour gérer avec succès une entreprise d'hébergement sous un modèle de partage des revenus.</p>
      <p class="mb-4">En mettant en œuvre une règle de coût qui attribue un pourcentage du revenu de chaque réservation au propriétaire, nous assurons que tout le monde profite du succès de l'entreprise. Créons une règle de coût qui soit équitable et transparente, offrant aux propriétaires la tranquillité d'esprit et favorisant une relation positive à long terme.</p>
      <p class="mb-4">Optez pour un hébergement à loyer fixe? Vous devrez configurer une règle de coût qui garantisse le paiement en temps voulu du loyer mensuel.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Voici une liste des principaux contrats :</h3>
      <p class="mb-4"><span class="text-lg text-petroil">Loyer Fixe:</span> Le gestionnaire de la propriété accepte de payer au propriétaire un loyer mensuel fixe dans cet arrangement. Le gestionnaire tente ensuite de gagner plus que ce montant en louant la propriété. Tout profit au-dessus du loyer fixe est conservé par le gestionnaire, mais celui-ci assume également le risque de toute insuffisance.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Partage des Revenus:</span> Dans le cadre d'un accord de partage des revenus, le gestionnaire de la propriété et le propriétaire partagent les revenus locatifs en fonction d'un pourcentage convenu. Par exemple, le gestionnaire pourrait conserver 30% des revenus en tant que frais de gestion, tandis que le propriétaire reçoit 70%.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Basé sur Commission:</span> Le gestionnaire de la propriété ne gagne qu'une commission pour chaque réservation qu'il assure. Cela est souvent courant avec les agences qui listent plusieurs propriétés. La commission peut varier mais se situe généralement entre 10% et 25% des revenus de la réservation.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Revenu Garanti:</span> C'est un hybride entre le loyer fixe et le partage des revenus. Le gestionnaire de la propriété garantit au propriétaire un montant minimum chaque mois (inférieur au modèle de loyer fixe) et partage ensuite tout revenu au-dessus de ce montant garanti en fonction d'un pourcentage convenu.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Seulement Agence:</span> Dans ce modèle, le gestionnaire de la propriété agit en tant qu'agent pour lister et commercialiser la propriété. Ils ne gèrent pas les opérations comme les services aux invités, l'entretien ou le nettoyage. Ils facturent soit un tarif fixe, soit une commission pour chaque réservation qu'ils assurent.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Basé sur des Honoraires:</span> Le gestionnaire de la propriété facture au propriétaire des services spécifiques, tels que le nettoyage, l'entretien ou les relations avec les invités. C'est plus un service à la carte où le propriétaire choisit les services qu'il souhaite que le gestionnaire gère.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Modèles Hybrides:</span> Parfois, des combinaisons des modèles ci-dessus sont utilisées en fonction des besoins spécifiques du propriétaire de la propriété et de l'expertise et des services offerts par le gestionnaire de la propriété.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Partage des Revenus par Paliers:</span> Au lieu d'un pourcentage fixe, le partage des revenus entre le propriétaire de la propriété et le gestionnaire change en fonction des paliers de revenus. Par exemple, pour les premiers 5 000 $ de revenus mensuels, le gestionnaire pourrait prendre une part de 20%, mais pour les revenus entre 5 001 $ et 10 000 $, la part pourrait être réduite à 15%.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Bonus Basé sur la Performance:</span> Ce modèle peut être ajouté à d'autres. Ici, le gestionnaire de la propriété reçoit un bonus si certains objectifs ou KPI (indicateurs clés de performance) sont atteints, comme maintenir un taux d'occupation moyen de 90% sur un trimestre ou obtenir un certain nombre d'avis 5 étoiles.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Modèle de Frais de Réservation:</span> Au lieu d'une commission sur le prix de la location, le gestionnaire facture un tarif fixe pour chaque réservation effectuée, indépendamment du montant de la location.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Service Complet avec Honoraires Variables:</span> Le gestionnaire s'occupe de tous les aspects de l'opération de location à court terme, de l'inscription aux services aux invités en passant par l'entretien. Les honoraires sont variables en fonction du volume de travail dans un mois particulier. Par exemple, s'il y a une plus grande occupation et plus de problèmes de maintenance, les honoraires peuvent être plus élevés.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Retainer Plus Commission:</span> Le gestionnaire de la propriété reçoit un tarif fixe pour les services de base, puis une commission supplémentaire pour chaque réservation. Cela garantit au gestionnaire un revenu stable tout en l'incitant à obtenir des réservations.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Modèle de Partage des Capitaux Propres:</span> C'est moins courant, mais dans certaines situations, notamment avec les startups ou les grands projets immobiliers, le gestionnaire peut prendre une part de capital dans la propriété ou l'entreprise au lieu (ou en plus) des paiements en espèces.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Zero-Fee avec Upsells:</span> Ici, le gestionnaire peut ne pas facturer de frais ou de commission pour la réservation, mais gagne de l'argent en vendant des services supplémentaires aux invités comme des tours, des transports ou des services internes.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Est-il possible de désactiver un Hébergement?</h3>
      <p>Absolument oui.</p>
      <p class="mb-4">Au cas où un hébergement ne serait plus temporairement géré ou sa commercialisation a été fermée pour des raisons telles que des rénovations, de la maintenance ou des marchés saisonniers, vous pouvez choisir le statut "Désactivé" dans la section "Détails de l'Hébergement".</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Pourquoi désactiver un Hébergement?</h3>
      <p class="mb-4">Les raisons pour lesquelles nous pourrions changer le "Statut" d'un Hébergement, le passant de Actif à Désactivé, peuvent être variées : par exemple, parce qu'il s'agit d'un marché saisonnier où nous ne travaillons qu'en été, et donc nous devrions changer le statut; Il peut être nécessaire de fermer pour une période spécifique afin de réaliser une maintenance exceptionnelle.</p>
      <p class="mb-4">Une raison possible pourrait être si vous redesignez votre propriété ou si vous opérez dans un marché qui ne permet qu'un nombre limité de jours pour les locations.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Les détails d'un hébergement peuvent-ils être modifiés?</h3>
      <p class="mb-4">Cliquez sur "Modifier" pour modifier les données de l'Hébergement.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Puis-je supprimer un Hébergement de la liste?</h3>
      <p class="mb-4">Non, vous ne pouvez pas supprimer un Hébergement, mais vous pouvez le désactiver. La raison en est qu'il pourrait y avoir des coûts ou des réservations associés à l'hébergement qui ne peuvent être attribués à l'hébergement annulé.</p>
      <p class="mb-4">Par conséquent, nous recommandons de le désactiver pour éviter toute perte de données potentielle.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Est-il possible d'attribuer le même propriétaire à plusieurs Hébergements?</h3>
      <p>Oui, vous pouvez le faire!</p>
      <p class="mb-4">Il est possible d'attribuer le même propriétaire à plusieurs hébergements si nécessaire. Un Hébergement ne peut pas avoir plusieurs propriétaires. En cas de plusieurs propriétaires, un double propriétaire peut être créé dans la section "Propriétaire" puis attribué à l'Hébergement.</p>`
  }
  ,
  {
    id: "costRules",
    title: "Règles de Coût",
    content: `<p class="mb-4">La deuxième étape pour configurer la collecte de données pour l'intelligence d'affaires est la section des Règles de Coût. Ne manquez pas cette étape cruciale pour configurer votre système pour le succès ! Les Gestionnaires de Propriétés peuvent configurer de manière proactive des automatismes qui génèrent presque tous les coûts liés à la gestion d'une propriété. Cette fonctionnalité innovante fait de BiLemon la solution incontournable pour une gestion efficace des propriétés.</p>
    <p class="mb-4">La configuration des règles de coût est extrêmement intuitive, les coûts étant classifiés en trois typologies : Coût d'Entreprise, Réservation et Hébergement.</p>
    <p class="mb-4">Une fois la typologie sélectionnée, le coût individuel doit être sélectionné dans le menu "CategoryRequired". Pour chaque typologie, il existe des coûts spécifiques et associés, permettant de classifier et d'allouer en fonction de la logique commerciale.</p>
    <p class="mb-4">Après avoir sélectionné la Typologie et la Catégorie, choisissez l'Hébergement (non requis pour les "Coûts d'Entreprise") et définissez les variables pour la règle de coût :</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>“Récurrence”</li>
        <li>“Date de Début”</li>
        <li>“Montant du Paiement”</li>
    </ol>
    <p class="mb-4">Dans le cas des Coûts de Réservation, la "récurrence" devient un "déclencheur", mais la logique reste la même : identifier l'événement qui génère le coût.</p>
    <p class="mb-4">Dans le cas des Coûts de Réservation, il y a trois Déclencheurs :</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Date de Réservation</li>
        <li>Date d'Arrivée</li>
        <li>Date de Départ</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Que sont les règles de coût et à quoi servent-elles ?</h3>
    <p>Les règles de coût automatisées rationalisent votre flux de travail en générant automatiquement des coûts lorsque des événements spécifiques se produisent, vous faisant gagner du temps et de l'effort.</p>
    <p>En configurant des règles spécifiques, le Gestionnaire de Propriétés peut facilement générer le coût relatif basé sur un événement donné. Rationaliser ce processus non seulement fait gagner du temps, mais assure également la précision et la cohérence du calcul des coûts.</p>
    <p>Lors de la réception d'une réservation, nous savons que des coûts supplémentaires tels que le nettoyage, l'enregistrement et les commissions OTA seront encourus.</p>
    <p>Avec les règles de coût, il n'est pas nécessaire d'entrer manuellement les coûts pour chaque réservation. Le système génère automatiquement les coûts basés sur l'événement (dans ce cas, la réservation).</p>
    <p>Grâce à cette approche innovante, il est possible d'automatiser l'entrée et la gestion des coûts.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment créer une règle de coût ?</h3>
    <p>Pour créer une nouvelle règle de coût, suivez ces étapes :</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Attribuez un nom à la règle.</li>
        <li>Choisissez la Typologie de Coût, entre Réservation/Hébergement/Entreprise.</li>
        <li>Sélectionnez un ou plusieurs Hébergements (uniquement pour les Coûts de Réservation et d'Hébergement).</li>
        <li>Choisissez la Catégorie de Coût.</li>
        <li>Attribuez une Sous-Catégorie (facultatif).</li>
        <li>Choisissez le Fournisseur (facultatif).</li>
        <li>Définissez le Déclencheur (facultatif) ou la Récurrence pour laquelle le coût doit être généré.</li>
        <li>Définissez une date de début.</li>
        <li>Établissez le Type de Paiement (uniquement pour les Coûts de Réservation).</li>
        <li>Déterminez le montant du coût.</li>
        <li>Définissez une date de fin.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quels sont les champs requis pour une règle de coût ?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Nom de la règle de coût.</li>
        <li>Typologie de coût.</li>
        <li>Hébergements (uniquement pour les Coûts de Réservation et d'Hébergement).</li>
        <li>Catégorie.</li>
        <li>Récurrence.</li>
        <li>Type de paiement (uniquement pour les Coûts de Réservation).</li>
        <li>Montant du paiement.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Que signifie le terme « Nom de la règle de coût » ?</h3>
    <p>"Nom de la règle de coût" fait référence au nom attribué par le Gestionnaire de Propriétés à sa règle de coût.</p>
    <p>Un exemple est la "Règle de Coût de Nettoyage", qui génère des coûts pour le nettoyage lors du départ. Si la règle était simplement nommée "Nettoyage Final", cela ne ferait aucune différence.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Qu'est-ce que la « Typologie de Coût » ?</h3>
    <p>Dans BiLemon, nous classifions tous les coûts en trois grandes typologies :</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Coûts d'Hébergement.</li>
        <li>Coûts de Réservation.</li>
        <li>Coûts d'Entreprise.</li>
    </ol>
    <p>En fonction de la typologie choisie, il y aura des catégories de coûts spécifiques et associées.</p>
    <p>Prenons un exemple, si le Gestionnaire de Propriétés choisit la Typologie "Réservation", le système génère une série de catégories de coûts telles que Commodités, Enregistrement/Départ, Nettoyage, Blanchisserie, Commission OTA, etc. Ce sont tous ces coûts qui sont générés lorsque vous recevez une réservation.</p>
    <p>Prenons un autre exemple, si le Gestionnaire de Propriétés choisit la typologie "Hébergement", le système génère une série de catégories de coûts, telles que Frais Communautaires, Paiement au Propriétaire, PMS, Gestionnaire de Canal, Mobilier, Assurance, Entretien, fournitures, etc.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Qu'est-ce que la « Catégorie de Coût » ?</h3>
    <p>Les catégories de coût représentent les coûts réels qui affectent toute la gestion de la propriété.</p>
    <p>Comme expliqué pour les typologies, différentes catégories de coûts se rapportent aux typologies.</p>
    <p>Chaque typologie a un ensemble de catégories de coûts qui lui sont associées.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Que signifie « Sous-Catégorie » ?</h3>
    <p>De nombreux coûts ont des sous-catégories, permettant une granularité dans l'analyse des coûts.</p>
    <p>Prenons un exemple, imaginons que nous devons couvrir les coûts de maintenance de la piscine dans une villa :</p>
    <ul class="list-disc pl-6 mb-4">
        <li>La Typologie sera "Hébergement",</li>
        <li>La Catégorie sera "Entretien" et,</li>
        <li>La Sous-Catégorie sera "Entretien de la Piscine".</li>
    </ul>
    <p>Prenons un autre exemple, nous avons un coût fixe mensuel pour fournir Internet au bureau :</p>
    <ul class="list-disc pl-6 mb-4">
        <li>La Typologie sera "Entreprise",</li>
        <li>La Catégorie sera "Dépenses de Bureau" et,</li>
        <li>La Sous-Catégorie sera "Connexion Internet Wi-Fi".</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment configurer la « Récurrence » d'une règle de coût ?</h3>
    <p>La « Récurrence » d'une règle de coût représente la périodicité avec laquelle un coût peut être généré.</p>
    <p>Considérons un exemple : les frais de location d'entrepôt peuvent être facturés sur une base mensuelle ou annuelle. De plus, il est possible de personnaliser la récurrence si elle ne se produit pas quotidiennement, hebdomadairement, mensuellement ou annuellement.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Que signifie « personnalisé » dans la configuration de la récurrence ?</h3>
    <p>Si l'événement qui déclenche le coût ne se produit pas quotidiennement, hebdomadairement, mensuellement ou annuellement, il est possible de personnaliser la fréquence.</p>
    <p>Dans ce cas, si nous payons un fournisseur tous les 2 mois, nous devons choisir « Personnalisé » pour la Récurrence et définir « Nombre de jours » à 60.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Qu'est-ce qu'un Déclencheur ?</h3>
    <p>Le terme « Déclencheur » fait référence à l'événement spécifique qui entraîne un coût de Réservation.</p>
    <p>Le Déclencheur s'applique uniquement aux coûts de Réservation et la Récurrence s'applique aux coûts d'Hébergement et aux Coûts d'Entreprise. Le Déclencheur a trois options :</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Date de Réservation.</li>
        <li>Date d'Arrivée.</li>
        <li>Date de Départ.</li>
    </ol>
    <p>Le Déclencheur n'est pas une information obligatoire. S'il n'est pas configuré, le système par défaut considère la Date de Réservation.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Qu'est-ce que le « Type de Paiement » ?</h3>
    <p>Le champ « Type de Paiement », disponible uniquement pour les Coûts de Réservation, détermine si un coût est calculé en pourcentage ou en montant fixe.</p>
    <p>Considérons deux exemples de différents types de paiement. La commission OTA est généralement un coût basé sur un pourcentage calculé sur le montant de la réservation.</p>
    <p>Cependant, le coût du nettoyage est généralement un montant fixe basé sur le temps consacré au nettoyage.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Combien de règles de coût puis-je créer ?</h3>
    <p>Il est possible de créer une structure de coût détaillée et exhaustive sans aucune limite.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Puis-je créer la même règle de coût plusieurs fois mais avec des valeurs différentes ?</h3>
    <p>La réponse est oui.</p>
    <p>Par exemple, la règle de coût génère le calcul de la commission pour différents canaux de réservation. La règle de commission OTA reste la même, mais les valeurs de pourcentage varient en fonction des canaux.</p>
    <p>Ou, les coûts de nettoyage peuvent varier en fonction des caractéristiques de l'Hébergement, nécessitant des quantités de temps différentes.</p>
    <p>Pour ces cas spécifiques, nous pouvons créer plusieurs règles avec des paramètres différents.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Que se passe-t-il lorsqu'une règle de coût est supprimée ?</h3>
    <p>La seule conséquence de la suppression d'une règle de coût est qu'elle cessera de générer des coûts basés sur cette règle.</p>
    <p>Veuillez noter que l'annulation de la règle de coût ne signifie pas que les coûts générés auparavant seront également annulés.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Puis-je appliquer une règle de coût à plusieurs Hébergements en même temps ?</h3>
    <p>La réponse est oui.</p>
    <p>Par exemple, la Commission OTA appliquée à Airbnb, le même calcul s'applique à tous les hébergements listés sur ce canal de réservation.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Est-il possible d'insérer une règle de coût avec des dates passées ?</h3>
    <p>Non, les règles de coût ne sont pas rétroactives.</p>
    <p>Il y a un risque d'encourir un nombre infini de coûts pour des événements passés.</p>
    <p>Si un Gestionnaire de Propriétés doit ajouter des coûts pour des données passées, il peut utiliser la fonction « Ajouter un nouveau coût » sous la section « Coûts ».</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Si je ne choisis pas une typologie de coût, pourquoi ne puis-je voir aucune catégorie de coût ?</h3>
    <p>Tous les coûts sont classifiés en trois typologies ; Si vous ne sélectionnez pas une typologie, alors la catégorie de coût ne sera pas affichée.</p>`
  }
  ,
  {
    id: "costs",
    title: "Coûts",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La section Coûts intègre les Règles de Coût.</h3>
    <p>Dans cette section, nous inclurons tous les coûts qui ne suivent pas une règle spécifique ou qui sont générés de manière occasionnelle ou ponctuelle.</p>
    <p>Cette section vous offre un niveau de détail significatif concernant le contrôle de gestion, vous permettant d’atteindre un niveau de granularité plus élevé.</p>
    <p>Il est important de classer, allouer et inclure le coût d’une ampoule très bon marché !</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quelle est la différence entre les Règles de Coût et les Coûts ?</h3>
    <p>Les Règles de Coût sont des processus automatisés utilisés pour calculer des coûts lorsque des événements spécifiques se produisent (par exemple, les réservations pour des coûts variables) ou lorsque des coûts sont connus pour se produire périodiquement (par exemple, les coûts fixes).</p>
    <p>Les Coûts peuvent être générés par des règles de coût ou créés immédiatement s'ils ne suivent pas de règles spécifiques, ne peuvent pas être automatisés ou se produisent de manière irrégulière.</p>
    <p>Prenons un exemple de Coût : l'achat d'un coussin, qui n'est pas un coût récurrent, ne dépend d'aucun événement ordinaire et est un achat ponctuel.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quels sont les champs obligatoires pour ajouter un coût ?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Typologie de coût.</li>
        <li>Hébergements (uniquement pour les Coûts de Réservation et d'Hébergement).</li>
        <li>Catégorie.</li>
        <li>Date de Coût.</li>
        <li>Type de Paiement (uniquement pour les Coûts de Réservation).</li>
        <li>Montant du Paiement.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quelle est la différence entre « Date de Coût » et « Date de Paiement » ? Pourquoi « Date de Coût » est-elle obligatoire et « Date de Paiement » ne l'est-elle pas ?</h3>
    <p>La Date de Coût indique la date à laquelle un coût spécifique est généré.</p>
    <p>La Date de Paiement indique la date à laquelle ce coût sera payé.</p>
    <p>Cette différenciation est principalement utilisée pour analyser les flux de trésorerie.</p>
    <p>Prenons un exemple, le 1er septembre 2024, nous avons acheté une nouvelle table de cuisine pour notre appartement en utilisant notre carte de crédit.</p>
    <p>Le coût sera facturé le premier jour du mois suivant (1er octobre 2024).</p>
    <p>La Date de Coût est un champ obligatoire requis pour l'affectation temporelle du coût.</p>
    <p>La Date de Paiement n'est pas toujours requise, car cette distinction de date peut ne pas être toujours applicable.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Lorsque j'entre le même coût pour deux Hébergements, le montant reste-t-il le même pour les deux Hébergements ou est-il réparti ?</h3>
    <p>Le coût pour deux hébergements n'est pas partagé ; au lieu de cela, deux coûts distincts du même montant sont créés pour chaque hébergement.</p>
    <p>C'est une manière d'optimiser et de gagner du temps.</p>
    <p>Supposons que nous achetons deux chaises identiques pour deux hébergements différents au même coût. Au lieu d'attribuer un coût à chaque chaise, nous attribuons un coût à chaque hébergement et sélectionnons ensuite les deux hébergements.</p>
    <p>Le système facturera automatiquement le coût de la chaise pour chaque Hébergement.</p>
    <p>Pour ce faire, nous n'avons pas besoin de créer deux coûts distincts, mais plutôt un coût unique en sélectionnant les deux Hébergements.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Est-il possible d’entrer un coût pour des dates futures ? Et pour des dates passées ?</h3>
    <p>Oui, il est possible d'entrer un coût pour des dates futures comme pour des dates passées.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Puis-je apporter des modifications ou supprimer un coût précédemment entré ?</h3>
    <p>La réponse est oui.</p>
    <p>Les coûts peuvent être ajustés ou annulés en fonction du besoin, et les modifications peuvent avoir un impact sur d’autres spécifications telles que la date et l'hébergement.</p>`
  }
  ,
  {
    id: "bookings",
    title: "Réservations",
    content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La section Réservations est l'endroit où les réservations sont saisies, représentant la principale source de revenus pour les Gestionnaires de Propriétés.</h3>
    <p>Cette étape peut être considérée comme la dernière étape pour la collecte de données Business Intelligence.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment effectuer une réservation ?</h3>
    <p>Pour ajouter une nouvelle réservation, vous pouvez utiliser la fonction "Ajouter une nouvelle réservation".</p>
    <p>Il est possible d'importer plusieurs réservations à la fois en téléchargeant un fichier Excel.</p>
    <p>Nous vous recommandons d'utiliser la fonction "Télécharger un Exemple" pour obtenir le format correct, de le remplir avec les réservations à insérer, puis de l'importer via la fonction "Importer".</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Quelles sont les informations obligatoires à saisir pour une Réservation ?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Date de Réservation.</li>
        <li>Dates d'Arrivée/Départ.</li>
        <li>Nombre d'Invités.</li>
        <li>Montant.</li>
        <li>Hébergement.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Puis-je modifier ou annuler une réservation précédemment saisie ?</h3>
    <p>Oui, il est toujours possible de modifier ou d'annuler une réservation.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Si je modifie une réservation, les coûts associés seront-ils mis à jour en conséquence ?</h3>
    <p>Oui.</p>
    <p>Modifier toute information relative aux règles de coût de réservation affectera les coûts générés.</p>
    <p>Si la date de départ d'une réservation est modifiée, la date du coût de nettoyage sera ajustée en fonction de la règle définie précédemment.</p>
    <p>Il en va de même pour le calcul des coûts basés sur des pourcentages, qui est affecté par les changements dans les montants des réservations.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment puis-je entrer plusieurs réservations en même temps ?</h3>
    <p>Pour importer plusieurs réservations en une seule fois, utilisez un fichier Excel.</p>
    <p>BiLemon permet aux utilisateurs de télécharger un fichier Excel préformaté.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Pourquoi ne puis-je pas trouver un Canal de Réservation ? Comment ajouter un Canal de Réservation ?</h3>
    <p>Pour répondre à certaines questions, nous devons faire une précision : La Commission OTA est un COÛT et doit être gérée dans la section des coûts. D'abord, nous créons des règles de coût pour les calculs des commissions.</p>
    <p>Vous pouvez configurer un Canal de Réservation dans la section OTA, mais sans créer une règle de coût, il ne sera pas visible.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment puis-je trier le tableau des réservations ?</h3>
    <p>Le tableau des réservations peut être trié par Date de Réservation ou Date d'Arrivée.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Est-il possible d'ajouter une Réservation de manière rétroactive ?</h3>
    <p>Oui, il est toujours possible d'ajouter une Réservation de manière rétroactive.</p>`
}
,
{
  id: "ota",
  title: "OTA",
  content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La section OTA liste tous les canaux de réservation où les Hébergements sont commercialisés.</h3>
  <p>Cette saisie est utile lors de la création de la règle de coût pour la Commission OTA. Elle permet de trouver facilement le nom du canal dans le menu approprié.</p>
  <p>La liste des OTA peut également être remplie directement lors de la création de la règle de coût "Commission OTA", en utilisant la fonction "Ajouter un Fournisseur de Réservation".</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Comment gérer les canaux de réservation dans BiLemon ?</h3>
  <p>Les canaux de réservation sont des générateurs de coûts, car leur commission est un coût supporté par les Gestionnaires de Propriétés pour chaque nouvelle réservation.</p>
  <p>Il est possible d'ajouter tous les canaux de réservation dans la section OTA des paramètres généraux. Cependant, pour générer des coûts de commission pour ces canaux, il est nécessaire de créer une règle de coût appelée "Commission OTA". Lors de la création de cette règle, vous pouvez utiliser la fonction "Ajouter un Fournisseur de Réservation" pour inclure le nouveau canal.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Pourquoi ne vois-je pas les nouveaux canaux de réservation lors de l'entrée d'une Réservation ?</h3>
  <p>Pour calculer les commissions OTA, nous devons d'abord créer des règles de coût dans la section des coûts. Ce n'est qu'ensuite que nous pouvons accéder aux informations sur les canaux lors de l'insertion d'une réservation dans la section OTA.</p>
  <p>Sans règles de coût, les informations sur les canaux ne seront pas visibles.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Puis-je configurer plusieurs règles de coût pour le même canal de réservation ?</h3>
  <p>Il est possible de créer plusieurs règles de coût pour le même canal en fonction de pourcentages de calcul différents pour différentes réservations, même si elles proviennent du même canal.</p>`
}


],
  es:[
    {
      "id": "accommodations",
      "title": "Alojamientos",
      "content": `<p class="mb-4">El primer paso en el proceso de recopilación de datos de Business Intelligence es configurar los alojamientos.</p>
      <p class="mb-4">Insertar alojamientos es una tarea sencilla. Solo hay unos pocos detalles obligatorios y algunos opcionales que pueden ser importantes para el análisis de rendimiento.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo añadir un nuevo Alojamiento?</h3>
      <p class="mb-4">En la sección "Alojamientos", haga clic en "Añadir un nuevo alojamiento" para ingresar los datos del nuevo alojamiento, con las características esenciales.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuál es la información obligatoria?</h3>
      <ul class="list-disc pl-6 mb-4 ml-6                                  ">
          <li>Nombre</li>
          <li>Calle</li>
          <li>Ciudad</li>
          <li>Estado o Provincia</li>
          <li>Código Postal</li>
          <li>Propietario</li>
      </ul>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué se entiende por Tipología de Contrato?</h3>
      <p class="mb-4">Al elegir la "Tipología de Contrato" es posible seleccionar la forma de contrato que se ha estipulado con el propietario del Alojamiento.</p>
      <p class="mb-4">Esta información es crucial porque elegir una forma de gestión para el Alojamiento determina el modelo de negocio, los costos y las estrategias de ingresos.</p>
      <p class="mb-4">Tomemos un ejemplo, si gestionamos un alojamiento bajo un modelo de reparto de ingresos, deberíamos crear una regla de costos que asigne un porcentaje de los ingresos de cada reserva al propietario.</p>
      <p class="mb-4">Maximizar los beneficios mientras se proporciona una compensación justa a los propietarios es esencial para gestionar con éxito un negocio de alojamiento bajo un modelo de reparto de ingresos.</p>
      <p class="mb-4">Al implementar una regla de costos que asigne un porcentaje de los ingresos de cada reserva al propietario, nos aseguramos de que todos se beneficien del éxito del negocio. Creemos una regla de costos que sea justa y transparente, brindando tranquilidad a los propietarios y fomentando una relación positiva a largo plazo.</p>
      <p class="mb-4">¿Opta por un alojamiento de alquiler fijo? Deberá configurar una regla de costos que garantice el pago puntual del alquiler mensual.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">A continuación se muestra una lista de los principales contratos:</h3>
      <p class="mb-4"><span class="text-lg text-petroil">Alquiler Fijo:</span> El administrador de la propiedad acepta pagar al propietario un alquiler mensual fijo en este acuerdo. El administrador luego intenta ganar más que esta cantidad alquilando la propiedad. Cualquier ganancia por encima del alquiler fijo es retenida por el administrador, pero el administrador también asume el riesgo de cualquier déficit.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Reparto de Ingresos:</span> En virtud de un acuerdo de reparto de ingresos, el administrador de la propiedad y el propietario dividen los ingresos por alquiler en función de un porcentaje acordado. Por ejemplo, el administrador podría quedarse con el 30% de los ingresos como tarifa de administración, mientras que el propietario recibe el 70%.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Basado en Comisión:</span> El administrador de la propiedad solo gana una comisión por cada reserva que asegura. Esto es común con las agencias que listan múltiples propiedades. La comisión puede variar, pero típicamente oscila entre el 10% y el 25% de los ingresos de la reserva.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Ingresos Garantizados:</span> Es un híbrido entre alquiler fijo y reparto de ingresos. El administrador de la propiedad garantiza al propietario una cantidad mínima cada mes (menor que el modelo de alquiler fijo) y luego comparte cualquier ingreso por encima de esa cantidad garantizada según un porcentaje acordado.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Solo Agencia:</span> En este modelo, el administrador de la propiedad actúa como agente para listar y comercializar la propiedad. No gestionan operaciones como servicios de invitados, mantenimiento o limpieza. Cobran una tarifa fija o una comisión por cada reserva que aseguran.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Basado en Tarifas:</span> El administrador de la propiedad cobra al propietario por servicios específicos, como limpieza, mantenimiento o relaciones con los huéspedes. Es más un servicio a la carta donde el propietario elige los servicios que desea que el administrador maneje.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Modelos Híbridos:</span> A veces se utilizan combinaciones de los modelos anteriores, dependiendo de las necesidades específicas del propietario de la propiedad y de la experiencia y servicios ofrecidos por el administrador de la propiedad.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Reparto de Ingresos por Tramos:</span> En lugar de un porcentaje fijo, el reparto de ingresos entre el propietario de la propiedad y el administrador cambia en función de los tramos de ingresos. Por ejemplo, para los primeros $5,000 de ingresos mensuales, el administrador podría tomar una parte del 20%, pero para los ingresos entre $5,001 y $10,000, la parte podría reducirse al 15%.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Bonificación Basada en el Rendimiento:</span> Este modelo se puede agregar a otros. Aquí, el administrador de la propiedad recibe una bonificación si se alcanzan ciertos objetivos o KPI (indicadores clave de rendimiento), como mantener una tasa de ocupación promedio del 90% durante un trimestre o obtener una cierta cantidad de opiniones de 5 estrellas.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Modelo de Tarifas de Reserva:</span> En lugar de una comisión sobre el precio del alquiler, el administrador cobra una tarifa fija por cada reserva realizada, independientemente del monto del alquiler.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Servicio Completo con Tarifas Variables:</span> El administrador maneja todos los aspectos de la operación de alquiler a corto plazo, desde la lista hasta los servicios de invitados y el mantenimiento. Las tarifas son variables en función del volumen de trabajo en un mes en particular. Por ejemplo, si hay una mayor ocupación y más problemas de mantenimiento, las tarifas pueden ser más altas.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Retainer Plus Comisión:</span> El administrador de la propiedad recibe una tarifa fija por los servicios básicos, y luego una comisión adicional por cada reserva. Esto garantiza un ingreso estable para el administrador, mientras que lo incentiva a obtener reservas.</p>
      <p class="mb-4"> <span class="text-lg text-petroil">Modelo de Participación en el Capital:</span> Esto es menos común, pero en algunas situaciones, especialmente con startups o grandes proyectos inmobiliarios, el administrador puede tomar una participación en la propiedad o negocio en lugar (o además) de pagos en efectivo.</p>
      <p class="mb-4"><span class="text-lg text-petroil">Zero-Fee con Upsells:</span> Aquí, el administrador puede no cobrar ninguna tarifa o comisión por la reserva, pero gana dinero vendiendo servicios adicionales a los huéspedes como tours, transporte o servicios internos.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Es posible desactivar un Alojamiento?</h3>
      <p>Absolutamente sí.</p>
      <p class="mb-4">En caso de que un alojamiento ya no esté temporalmente gestionado o su comercialización haya sido cerrada por razones como renovaciones, mantenimiento o mercados estacionales, puede elegir el estado "Desactivado" en la sección "Detalles del Alojamiento".</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Por qué desactivar un Alojamiento?</h3>
      <p class="mb-4">Las razones por las que podríamos cambiar el "Estado" de un Alojamiento, pasando de Activo a Desactivado, pueden ser variadas: por ejemplo, porque es un mercado estacional donde solo trabajamos en verano, y por lo tanto deberíamos cambiar el estado; puede ser necesario cerrar por un período específico para realizar un mantenimiento extraordinario.</p>
      <p class="mb-4">Una posible razón podría ser si está rediseñando la propiedad o si opera en un mercado que solo permite un número limitado de días para alquileres.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Se pueden modificar los detalles de un alojamiento?</h3>
      <p class="mb-4">Haga clic en "Editar" para modificar los datos del Alojamiento.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Puedo eliminar un Alojamiento de la lista?</h3>
      <p class="mb-4">No, no puede eliminar un Alojamiento, pero puede desactivarlo. La razón es que puede haber costos o reservas asociados con el alojamiento que no pueden ser atribuidos al alojamiento cancelado.</p>
      <p class="mb-4">Por lo tanto, recomendamos desactivarlo para evitar cualquier pérdida de datos potencial.</p>
      <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Es posible asignar el mismo propietario a varios Alojamientos?</h3>
      <p>¡Sí, puedes hacerlo!</p>
      <p class="mb-4">Es posible asignar el mismo propietario a varios alojamientos si es necesario. Un Alojamiento no puede tener múltiples propietarios. En caso de múltiples propietarios, se puede crear un propietario doble en la sección "Propietario" y luego asignarlo al Alojamiento.</p>`
  }
  ,
  {
    "id": "costRules",
    "title": "Reglas de Costos",
    "content": `<p class="mb-4">El segundo paso en la configuración de la recopilación de datos para Business Intelligence es la sección de Reglas de Costos. ¡No te pierdas este paso crucial para configurar tu sistema para el éxito! Los Administradores de Propiedades pueden configurar proactivamente automatismos que generen casi todos los costos asociados con la gestión de una propiedad. Esta característica innovadora hace que BiLemon sea la solución ideal para una gestión de propiedades eficiente.</p>
    <p class="mb-4">La configuración de las reglas de costos es extremadamente intuitiva, considerando que los costos se clasifican en tres tipologías: Costos de Alojamiento, Costos de Reserva y Costos de Empresa.</p>
    <p class="mb-4">Una vez seleccionada la tipología, debes elegir el costo individual desde el menú "Categoría Requerida". Para cada tipología, hay costos específicos y relacionados que deben ser clasificados y asignados según la lógica del negocio.</p>
    <p class="mb-4">Después de seleccionar Tipología y Categoría, elige el Alojamiento (no requerido para "Costos de Empresa") y define las variables para la regla de costo:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>“Recurrencia”</li>
        <li>“Fecha de Inicio”</li>
        <li>“Monto del Pago”</li>
    </ol>
    <p class="mb-4">En el caso de los Costos de Reserva, la "recurrencia" se convierte en un "desencadenador", pero la lógica sigue siendo la misma: identificar el evento que genera el costo.</p>
    <p class="mb-4">En el caso de los Costos de Reserva, hay tres Desencadenadores:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Fecha de Reserva</li>
        <li>Fecha de Check-in</li>
        <li>Fecha de Check-out</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué son las reglas de costo y para qué sirven?</h3>
    <p>Las reglas de costo automatizadas optimizan tu flujo de trabajo al generar automáticamente costos cuando ocurren eventos específicos, ahorrándote tiempo y esfuerzo.</p>
    <p>Al configurar reglas específicas, el Administrador de Propiedades puede generar sin esfuerzo el costo relativo basado en cualquier evento dado. Esta simplificación del proceso no solo ahorra tiempo, sino que también asegura precisión y consistencia en el cálculo de costos.</p>
    <p>Al recibir una reserva, sabemos que se incurrirán en costos adicionales como limpieza, check-in y comisiones de OTA.</p>
    <p>Con las reglas de costo, no es necesario ingresar manualmente los costos para cada reserva. El sistema genera automáticamente los costos basados en el evento (en este caso, la reserva).</p>
    <p>Gracias a este enfoque innovador, es posible automatizar la entrada y gestión de costos.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo se crea una regla de costo?</h3>
    <p>Para crear una nueva regla de costo, sigue estos pasos:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Asigna un nombre a la regla.</li>
        <li>Elige la Tipología de Costo, entre Reserva/Alojamiento/Empresa.</li>
        <li>Selecciona uno o más Alojamientos (solo para Costos de Reserva y Alojamiento).</li>
        <li>Elige la Categoría de Costo.</li>
        <li>Asigna una Subcategoría (opcional).</li>
        <li>Selecciona el Proveedor (opcional).</li>
        <li>Define el Desencadenador (opcional) o Recurrencia para el cual debe generarse el costo.</li>
        <li>Define una fecha de inicio.</li>
        <li>Establece el Tipo de Pago (solo para Costos de Reserva).</li>
        <li>Determina el monto del costo.</li>
        <li>Define una fecha de finalización.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuáles son los campos obligatorios para una regla de costo?</h3>
    <ol class="list-decimal pl-6 mb-4">
        <li>Nombre de la regla de costo.</li>
        <li>Tipología de costo.</li>
        <li>Alojamientos (solo para Costos de Reserva y Alojamiento).</li>
        <li>Categoría.</li>
        <li>Recurrencia.</li>
        <li>Tipo de pago (solo para Costos de Reserva).</li>
        <li>Monto del pago.</li>
    </ol>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué significa el término "Nombre de la Regla de Costo"?</h3>
    <p>"Nombre de la Regla de Costo" se refiere al nombre asignado por el Administrador de Propiedades a su regla de costo.</p>
    <p>Un ejemplo es "Regla de Costo de Limpieza", que genera costos para la limpieza durante el check-out. Si la regla solo se llamara "Limpieza Final", no haría ninguna diferencia.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué es la "Tipología de Costo"?</h3>
    <p>En BiLemon, clasificamos todos los costos en tres grandes tipologías:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Costos de Alojamiento.</li>
        <li>Costos de Reserva.</li>
        <li>Costos de Empresa.</li>
    </ol>
    <p>Dependiendo de la tipología elegida, habrá categorías de costo específicas y relativas.</p>
    <p>Tomemos un ejemplo: si el Administrador de Propiedades elige la Tipología "Reserva", el sistema genera una serie de categorías de costo como Amenidades, Check-in/out, Limpieza, Lavandería, Comisión de OTA, etc. Es decir, todos esos costos que se generan al recibir una reserva.</p>
    <p>Tomemos otro ejemplo: si el Administrador de Propiedades elige la tipología "Alojamiento", el sistema genera una serie de categorías de costo, como Cuota Comunitaria, Pago al Propietario, PMS, Channel Manager, Mobiliario, Seguro, Mantenimiento, suministros, etc.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué es la "Categoría de Costo"?</h3>
    <p>Las categorías de costo representan los costos reales que afectan toda la gestión de propiedades.</p>
    <p>Como ya se explicó para las tipologías, diferentes categorías de costos se relacionan con las tipologías.</p>
    <p>Cada tipología tiene un conjunto de categorías de costo asociadas a ella.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué se entiende por "Subcategoría"?</h3>
    <p>Numerosos costos tienen subcategorías, lo que permite una mayor granularidad en el análisis de costos.</p>
    <p>Tomemos un ejemplo, supongamos que debemos cubrir los costos de mantenimiento de la piscina en una villa:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>La Tipología será "Alojamiento",</li>
        <li>La Categoría será "Mantenimiento" y,</li>
        <li>La Subcategoría será "Mantenimiento de Piscina".</li>
    </ul>
    <p>Tomemos otro ejemplo, tenemos un costo mensual fijo para proporcionar internet en la oficina:</p>
    <ul class="list-disc pl-6 mb-4">
        <li>La Tipología será "Empresa",</li>
        <li>La Categoría será "Gastos de Oficina" y,</li>
        <li>La Subcategoría será "Conexión de Internet Wi-Fi".</li>
    </ul>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo se configura la "Recurrencia" de una regla de costo?</h3>
    <p>La "Recurrencia" de una regla de costo representa la periodicidad con la que se puede generar un costo.</p>
    <p>Consideremos un ejemplo: los costos de alquiler de un almacén pueden cobrarse de manera mensual o anual. Además, hay la opción de personalizar la recurrencia si no ocurre diariamente, semanalmente, mensualmente o anualmente.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué significa "personalizado" en la configuración de recurrencia?</h3>
    <p>Si el evento que desencadena el costo no ocurre de manera diaria, semanal, mensual o anual, es posible personalizar la frecuencia.</p>
    <p>En este caso, si pagamos a un proveedor cada 2 meses, deberíamos elegir "Personalizado" para la Recurrencia y establecer "Número de días" en 60.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué es un Desencadenador?</h3>
    <p>El término "Desencadenador" se refiere al evento específico que provoca un Costo de Reserva.</p>
    <p>El Desencadenador solo se aplica a los costos de Reserva y la Recurrencia se aplica tanto a Costos de Alojamiento como a Costos de Empresa. El Desencadenador tiene tres opciones:</p>
    <ol class="list-decimal pl-6 mb-4">
        <li>Fecha de Reserva.</li>
        <li>Fecha de Check-in.</li>
        <li>Fecha de Check-out.</li>
    </ol>
    <p>El Desencadenador no es un dato obligatorio. Si no se configura, el sistema considera por defecto la Fecha de Reserva.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué es el "Tipo de Pago"?</h3>
    <p>El campo "Tipo de Pago", disponible solo para Costos de Reserva, determina si un costo se calcula como un porcentaje o como una cantidad fija.</p>
    <p>Consideremos dos ejemplos de diferentes tipos de pago. La comisión de OTA es típicamente un costo basado en un porcentaje calculado sobre el monto de la reserva.</p>
    <p>Sin embargo, el costo de limpieza es típicamente un monto fijo basado en la cantidad de tiempo dedicada a la limpieza.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuántas reglas de costo puedo crear?</h3>
    <p>Es posible crear una estructura de costos detallada y exhaustiva sin limitaciones.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Puedo crear la misma regla de costo múltiples veces pero con valores diferentes?</h3>
    <p>La respuesta es sí.</p>
    <p>Por ejemplo, la regla de costo genera el cálculo de la comisión para diferentes canales de reserva. La regla de comisión de OTA permanece igual, pero los valores porcentuales varían según los canales.</p>
    <p>O, el cálculo de los costos de limpieza puede variar según las características del Alojamiento, requiriendo diferentes cantidades de tiempo.</p>
    <p>Para estos casos específicos, podemos crear múltiples reglas con diferentes parámetros.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Qué sucede cuando se elimina una regla de costo?</h3>
    <p>La única consecuencia de eliminar una regla de costo es que dejará de generar costos basados en esa regla.</p>
    <p>Ten en cuenta que cancelar la regla de costo no significa que los costos generados anteriormente también se cancelen.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Puedo aplicar una regla de costo a múltiples Alojamientos al mismo tiempo?</h3>
    <p>La respuesta es sí.</p>
    <p>Si tomamos el ejemplo de la Comisión de OTA y la aplicamos a Airbnb, el mismo cálculo se aplica a todos los alojamientos listados en este canal de reservas.</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Es posible insertar una regla de costo con fechas pasadas?</h3>
    <p>No, las reglas de costo no son retroactivas.</p>
    <p>Existe el riesgo de incurrir en un número infinito de costos para eventos pasados.</p>
    <p>Si un Administrador de Propiedades necesita agregar costos para datos pasados, puede usar la función "Agregar un nuevo costo" en la sección de "Costos".</p>
    <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Si no elijo una tipología de costo, ¿por qué no puedo ver ninguna categoría de costo?</h3>
    <p>Todos los costos se clasifican en tres tipologías; si no seleccionas una tipología, la categoría de costo no se mostrará.</p>`
}
,
{
  "id": "costs",
  "title": "Costos",
  "content": `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La sección de Costos integra las Reglas de Costo.</h3>
  <p>En esta sección, incluiremos todos los costos que no siguen una regla específica o se generan ocasionalmente o como un único evento.</p>
  <p>Esta sección te proporciona un nivel significativo de detalle en el control de gestión, permitiéndote alcanzar un mayor nivel de granularidad.</p>
  <p>Es importante clasificar, asignar e incluir el costo incluso de una bombilla muy barata!</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuál es la diferencia entre Reglas de Costo y Costos?</h3>
  <p>Las Reglas de Costo son procesos automatizados utilizados para calcular costos cuando ocurren eventos específicos (por ejemplo, reservas para costos variables) o cuando los costos se sabe que ocurren periódicamente (por ejemplo, costos fijos).</p>
  <p>Los Costos pueden ser generados a través de reglas de costo o creados de inmediato si no siguen reglas específicas, no pueden ser automatizados o ocurren de manera irregular.</p>
  <p>Tomemos el ejemplo de un Costo, la compra de una almohada, que no es un costo recurrente, no depende de ningún evento ordinario y es una compra ocasional.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuáles son los campos obligatorios para agregar un costo?</h3>
  <ol class="list-decimal pl-6 mb-4">
      <li>Tipología de Costo.</li>
      <li>Alojamientos (solo para Costos de Reserva y Alojamiento).</li>
      <li>Categoría.</li>
      <li>Fecha del Costo.</li>
      <li>Tipo de Pago (solo para Costos de Reserva).</li>
      <li>Monto del Pago.</li>
  </ol>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuál es la diferencia entre “Fecha de Costo” y “Fecha de Pago”? ¿Por qué es obligatoria la “Fecha de Costo” y no lo es la “Fecha de Pago”?</h3>
  <p>La Fecha de Costo indica la fecha en la que se genera un costo específico.</p>
  <p>La Fecha de Pago indica la fecha en la que se pagará ese costo.</p>
  <p>Esta diferenciación se utiliza principalmente para analizar el flujo de caja.</p>
  <p>Tomemos un ejemplo: el 1 de septiembre de 2024, compramos una nueva mesa para la cocina de nuestro apartamento usando nuestra tarjeta de crédito.</p>
  <p>El costo se cargará el primer día del mes siguiente (1 de octubre de 2024).</p>
  <p>La Fecha de Costo es un campo obligatorio requerido para la asignación temporal del costo.</p>
  <p>La Fecha de Pago no siempre es necesaria, ya que esta distinción de fechas puede no ser aplicable en todos los casos.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Cuando ingreso el mismo costo para dos Alojamientos, ¿el monto se mantiene igual para ambos Alojamientos o se divide?</h3>
  <p>El costo para dos alojamientos no se comparte; en su lugar, se crean dos costos separados del mismo monto para cada alojamiento.</p>
  <p>Es una forma de optimizar y ahorrar tiempo.</p>
  <p>Por ejemplo, si compramos dos sillas idénticas para dos alojamientos diferentes al mismo costo, en lugar de asignar un costo a cada silla, asignamos un costo a cada alojamiento y luego seleccionamos los dos alojamientos.</p>
  <p>El sistema cargará automáticamente el costo de la silla para cada Alojamiento.</p>
  <p>Para hacer esto no necesitamos crear dos costos distintos, sino un solo costo seleccionando los dos Alojamientos.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Es posible ingresar un costo para fechas futuras? ¿Y para fechas pasadas?</h3>
  <p>Sí, es posible ingresar un costo para fechas futuras y pasadas.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Puedo hacer cambios o eliminar un costo previamente ingresado?</h3>
  <p>La respuesta es sí.</p>
  <p>Los costos pueden ser ajustados o cancelados según sea necesario, y los cambios pueden afectar otras especificaciones como la fecha y el alojamiento.</p>`
}
,
{
  id: "bookings",
  title: "Reservas",
  content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La sección de Reservas es donde se ingresan las reservas, representando la principal fuente de ingresos para los Administradores de Propiedades.</h3>
  <p>Este paso puede considerarse como el paso final para la recopilación de datos de Inteligencia Empresarial.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo hago una reserva?</h3>
  <p>Para añadir una nueva reserva, puedes utilizar la función "Añadir una nueva reserva".</p>
  <p>Es posible importar varias reservas a la vez cargando un archivo Excel.</p>
  <p>Te recomendamos usar la función "Descargar Ejemplo" para obtener el formato correcto, rellenarlo con las reservas a insertar e importarlo a través de la función "Importar".</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cuáles son las informaciones obligatorias para una Reserva?</h3>
  <ol class="list-decimal pl-6 mb-4">
      <li>Fecha de Reserva.</li>
      <li>Fechas de Check-in/Check-out.</li>
      <li>Número de Huéspedes.</li>
      <li>Importe.</li>
      <li>Alojamiento.</li>
  </ol>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Puedo modificar o eliminar una reserva previamente ingresada?</h3>
  <p>Sí, siempre es posible modificar o cancelar una reserva.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">Si hago cambios en una reserva, ¿se actualizarán los costos asociados en consecuencia?</h3>
  <p>La respuesta es sí.</p>
  <p>Modificar cualquier información relacionada con las reglas de costos de la reserva afectará los costos generados.</p>
  <p>Si se cambia la fecha de check-out de una reserva, la fecha del costo de limpieza se ajustará en función de la regla establecida previamente.</p>
  <p>Lo mismo ocurre con el cálculo de los costos basados en porcentajes, que se ve afectado por los cambios en los montos de las reservas.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo puedo ingresar varias reservas al mismo tiempo?</h3>
  <p>Para importar varias reservas a la vez, usa un archivo Excel.</p>
  <p>BiLemon permite a los usuarios descargar un archivo Excel preformateado.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Por qué no puedo encontrar un Canal de Reserva? ¿Cómo añadir un Canal de Reserva?</h3>
  <p>Para responder a ciertas preguntas, debemos hacer una precisión: La Comisión OTA es un COSTO y debe ser gestionada en la sección de costos. Primero, creamos las reglas de costo para el cálculo de las comisiones.</p>
  <p>Puedes configurar un Canal de Reserva en la sección OTA, pero sin crear una regla de costo, no será visible.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo puedo ordenar la tabla de reservas?</h3>
  <p>La tabla de reservas puede ser ordenada por Fecha de Reserva o Fecha de Check-in.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Es posible añadir una Reserva de manera retroactiva?</h3>
  <p>Sí, siempre es posible añadir una Reserva de manera retroactiva.</p>`
}
,
{
  id: "ota",
  title: "OTA",
  content: `<h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">La sección OTA lista todos los canales de reserva donde se comercializan los Alojamientos.</h3>
  <p>Esta inserción es útil al crear la regla de costo para la Comisión OTA. Permite encontrar fácilmente el nombre del canal en el menú apropiado.</p>
  <p>La lista de OTAs también puede ser completada directamente al crear la regla de costo "Comisión OTA", usando la función "Añadir Proveedor de Reserva".</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Cómo gestiono los canales de reserva en BiLemon?</h3>
  <p>Los canales de reserva son generadores de costos, ya que su comisión es un costo asumido por los Administradores de Propiedades por cada nueva reserva.</p>
  <p>Es posible añadir todos los canales de reserva en la sección OTA de la configuración general. Sin embargo, para generar costos de comisión para estos canales, es necesario crear una regla de costo llamada "Comisión OTA". Durante la creación de esta regla, puedes usar la función "Añadir Proveedor de Reserva" para incluir el nuevo canal.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Por qué no veo los nuevos canales de reserva al ingresar una Reserva?</h3>
  <p>Para calcular las comisiones OTA, primero debemos crear reglas de costo en la sección de costos. Solo después podremos acceder a la información del canal al insertar una reserva en la sección OTA.</p>
  <p>Sin reglas de costo, la información del canal no será visible.</p>
  <h3 class="text-xl font-semibold mb-2 mt-2 text-petroil">¿Puedo configurar varias reglas de costo para el mismo canal de reserva?</h3>
  <p>Se pueden crear varias reglas de costo para el mismo canal en función de porcentajes de cálculo diferentes para distintas reservas, incluso si son del mismo canal.</p>`
}


]



}