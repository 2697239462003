import React from 'react';
import styles from './tags-input.module.css';

const TagsInput = (props) => {
  console.log(props.defaultTags)
  const [tags, setTags] = React.useState(props.defaultTags || []);

  const addTags = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setTags((prevTags) => {
        const newTags = [...prevTags, event.target.value];
        props.selectedTags(newTags);
        event.target.value = '';
        return newTags;
      });
    }
  };

  const removeTags = (index) => {
    const newTags = tags.filter((tag, i) => i !== index);
    setTags(newTags);
    props.selectedTags(newTags);
  };

  return (
    <div className="flex flex-col items-start justify-start w-full ">
      {props.label && (
        <label className={props.labelClassName + " ml-1 text-lg font-semibold text-blue w-full"}>
          {props.label}
        </label>
      )}
      <div className={`${styles.tagsInput}`}>
        <ul className="flex">
          {tags.map((tag, index) => (
            <li key={index} className={styles.tag}>
              <span className={styles.tagTitle}>{tag}</span>
              <i className={styles.tagCloseIcon} onClick={() => removeTags(index)}>X</i>
            </li>
          ))}
        </ul>
        <input
          type="text"
          onKeyUp={addTags}
          className={styles.input + ' bg-input'}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
};

export default TagsInput;
