import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {
  getCountries
} from "react-phone-number-input";
import {
  getAuth
} from "firebase/auth";

import { DataContext } from "../../App";
import { Calendar } from 'primereact/calendar';
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import PrimaryButton from '../../components/buttons/primary-button';
import { toast } from "react-toastify";
import { updateBillingData } from "../../utils/functions";
import countryList from 'react-select-country-list';

const BillingAddresses = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const auth = getAuth();
  const context = useContext(DataContext);
  
  const organization = context.organizationInfo;
  const [id, setIdOrganization] = useState(context.idOrganization);
  const [fullName, setFullName] = useState(organization && organization.billingData && organization.billingData.fullName);
  const [vatNumber, setVatNumber] = useState(organization && organization.billingData && organization.billingData.vatNumber);
  const [fiscalCode, setFiscalCode] = useState(organization && organization.billingData && organization.billingData.fiscalCode);
  const [options, setOptions] = useState();
  const [street, setStreet] = useState(organization && organization.billingData && organization.billingData.street);
  const [city, setCity] = useState(organization && organization.billingData && organization.billingData.city);
  const [zipCode, setZipCode] = useState(organization && organization.billingData && organization.billingData.zipCode);
  const [email, setEmail] = useState(organization && organization.billingData && organization.billingData.email);
  const [country, setCountry] = useState(organization && organization.billingData && organization.billingData.country);
  const [optionsCountry, setOptionsCountry] = useState(useMemo(() => countryList().getData(), []));


  const [formValues, setFormValues] = useState({
    fullName: fullName,
    city: city,
    zipCode: zipCode,
    country: country,
    street: street,
    email: email,
    fiscalCode: fiscalCode,
    vatNumber: vatNumber
  });


  const handleUpdate = () => {
    if(checkFields()){
      updateBillingData(context.idOrganization, formValues).then(async () => {
        context.updateData("organization-info")
      });
 
    }
  }


  const checkFields = () => {
    if (!fullName) {
      toast.error(t("MissingCompanyName"));
      return false;
    }
    if (!street) {
      toast.error(t("MissingAddress"));
      return false;
    }
    if (!city) {
      toast.error(t("MissingCity"));
      return false;
    }
    if (!zipCode) {
      toast.error(t("MissingZipCode"));
      return false;
    }
    if (!country) {
      toast.error(t("MissingCountry"));
      return false;
    }
    if (!vatNumber) {
      toast.error(t("MissingVatNumber"));
      return false;
    }
    if (!fiscalCode) {
      toast.error(t("MissingFiscalCode"));
      return false;
    }
    if (!email) {
      toast.error(t("MissingBillingEmail"));
      return false;
    }
    return true;
  };

  useEffect(() => {
    setOptions(
      getCountries().map((countryCode) => {
        return { label: countryCode, value: countryCode };
      })
    );
  }, []);

  return (
    
        <div className='content-company md:ml-5'>
          <h2 className="text-xl ml-1 font-semibold mt-10 md:mt-0">{t("BillingAddress")}</h2>
          <p className='mt-4 ml-1 font-semibold'>{t("BillingInfoDescription")}</p>
          <div className='mt-5 md:mt-20 ml-1'>
            <div className='mt-5 md:mt-20 ml-1'>
              <div className="billing-container grid grid-cols-12 gap-x-6 xl:gap-x-7">
                  <div className="col-span-12 xl:col-span-6">
                    <TextField
                      label={t("FullName") + " *"}
                      className={"md:max-w-xs mr-10 min-w-[27vw] mb-3"}
                      inputClassName={"p-3"}
                      placeholder={t("Insert")}
                      defaultValue={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          fullName: e.target.value
                        }))
                      }}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-6">
                    <TextField
                      label={t("Address") + " *"}
                      className={"md:max-w-xs mr-10 min-w-[27vw] mb-3"}
                      inputClassName={"p-3"}
                      placeholder={t("Insert")}
                      defaultValue={street}
                      onChange={(e) => {
                        setStreet(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          street: e.target.value
                        }))
                      }}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-6">
                    <TextField
                      label={t("City") + " *"}
                      className={"md:max-w-xs mr-10 min-w-[27vw] mb-3"}
                      inputClassName={"p-3"}
                      placeholder={t("Insert")}
                      defaultValue={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          city: e.target.value
                        }))
                      }}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-6 grid grid-cols-12">
                    <div className="col-span-5 ">
                      <TextField
                        label={t("ZipCode") + " *"}
                        className={"xl:max-w-[10vw]  mb-3"}
                        inputClassName={"p-3"}
                        placeholder={t("Insert")}
                        defaultValue={zipCode}
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          setFormValues(prevValues => ({
                            ...prevValues,
                            zipCode: e.target.value
                          }))
                        }}
                        />
                    </div>
                    <div className=" col-span-7 lg:mr-7">
                      <SelectMenu
                        label={t("State") + " *"}
                        placeholder={t("Select")}
                        className={"mb-3 mt-0 "}
                        inputClassName={"p-1"}
                        options={optionsCountry}
                        value={country && optionsCountry ? optionsCountry.find((x) => x.label === country) : country}
                        onChange={(item) => {
                          setCountry(item.label);
                          setFormValues(prevValues => ({
                            ...prevValues,
                             country: item.label
                          }))
                        }}
                        />
                    </div>
                      
                      </div>
                  <div className="col-span-12 xl:col-span-6">
                    <TextField
                      label={t("VatNumber") + " *"}
                      className={"md:max-w-xs mr-10 min-w-[27vw] mb-3"}
                      inputClassName={"p-3"}
                      placeholder={t("Insert")}
                      defaultValue={vatNumber}
                      onChange={(e) => {
                        setVatNumber(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          vatNumber: e.target.value
                        }))
                      }}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-6">
                    <TextField
                      label={t("FiscalCode") + " *"}
                      className={"md:max-w-xs mr-10 min-w-[27vw] mb-3"}
                      inputClassName={"p-3"}
                      placeholder={t("Insert")}
                      defaultValue={fiscalCode}
                      onChange={(e) => {
                        setFiscalCode(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          fiscalCode: e.target.value
                        }))
                      }}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-6">
                    <TextField
                      label={t("BillingEmail") + " *"}
                      className={"md:max-w-xs mr-10 min-w-[27vw] mb-3"}
                      inputClassName={"p-3"}
                      placeholder={t("Insert")}
                      defaultValue={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          email: e.target.value
                        }))
                      }}
                    />
                  </div>
                  
              </div>
                <div className="flex justify-end mt-10">
                   
                    <PrimaryButton
                content={t("Save")}
                className={"mt-2 p-3 bg-blue w-[7vw] md:mr-8 mb-6"}
                onClick={() => {
                  handleUpdate();
                }}
              />
                  </div>

            </div>
          </div>
        </div>
      
  );
};

export default BillingAddresses;
