import { sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { toast } from "react-toastify";
import { auth } from "../../../utils/firebaseConfig";
import PrimaryButton from "../../buttons/primary-button";
import Modal from "../dialog";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import TextField from "../../../components/input/fields/text-field";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
export const PasswordResetDialog = () => {
  
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const passwordResetHandler = async () => {
    if (!email) {
      toast.error(t("EmptyEmailField"));
      return;
    }
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success(t("ResetEmailSent"));
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(t("CheckEmail") + error);
      });
  };
  return (
    <Modal
      status={isOpen}
      primaryButton
      title={t("PasswordResetTitle")}
      subtitle={t("PasswordResetSubtitle")}
      buttonLabel={t("PasswordForget")}
      onClose={() => {setIsOpen(false); setEmail(null)}}
      buttonClassName={" text-petroil-0 bg-transparent text-xs underline hover:underline"}
      body={
        <div>
          <div className="bg-passwordfill rounded-xl">
            <h6 className="text-sm font-semibold text-blue px-3 mb-2"> {t("PasswordResetBoxTitle")}</h6>
            <p>
              <div className="text-blue text-left px-3">
              {t("PasswordFill")}

              <br/> 
                {t("IfRegisterReset")}
              </div>
            </p>
          </div>
          <div className="my-4">
          <TextField
                label={t("Email") + " *"}
                className={"max-w-xs mr-10 min-w-[21vw] mb-3"}
                inputClassName={"p-3"}
                placeholder={t("EmailPlaceholder")}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            
          </div>
          <div className="flex justify-center">
            <PrimaryButton
              className={"p-3 signup-button max-w-[10vw] login-button"}
              onClick={passwordResetHandler}
              content={t("ResetPasswordBtn")}
            />
          </div>
          <div className="flex flex-col items-center justify-center space-y-2">
            
          <div className="flex items-center">
           
            <p className="mb-10 mt-16 text-center text-sm">

              <div className="font-bold flex justify-center text-blue">
              <div>
              <HiOutlineQuestionMarkCircle size={20} />
              </div>
              <div className="ml-1">
                {t("SignupQuestion")}
              </div>
              </div>

              <br/>
              <span className="text-blue">
                {t("SignupEmail")}
              </span>
              <br/>
              <div className="text-blue ">
                 <a href="mailto:support@bilemon.com" className="hover:brightness-150">

              support@bilemon.com
                 </a>
              </div>



            </p>

          </div>
        </div>



          
        </div>
      }
    />
  );
};
