import React from "react";
import { useLocation } from 'react-router-dom';
import { ManageBookingRule } from "./ManageBookingRule";
export const EditBookingRule = () => {
  const location = useLocation();
  const { itemState } = location.state;
  const { isEditable } = location.state;

  return (
    <div className={"md:p-4 pt-5 mx-auto"}>
      <ManageBookingRule data={itemState} isEditable={isEditable} />
    </div>
  );
};
