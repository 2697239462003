import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
function DynamicPricing() {
  const context = useContext(DataContext);
  const { i18n, t } = useTranslation();

  
  return (
    <>
    test
    </>
  );

}


export default DynamicPricing;
