import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TextField from "../../../../components/input/fields/text-field";
import { SelectMenu } from "../../../../components/input/select/select-menu";
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/buttons/secondary-button";

export const PhoneStep = ({ onGoBack, onComplete, formValues }) => {
  const { i18n, t } = useTranslation();
  const [prefix, setPrefix] = useState(formValues.phoneNumber.prefix);
  const [phone, setPhone] = useState(formValues.phoneNumber.phone);
  const [optionsCallingCode, setOptionsCallingCode] = useState();


  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  }

  useEffect(() => {
    const prefixes = getCountries().map((countryCode) => `+${getCountryCallingCode(countryCode)}`);
    const uniquePrefixes = removeDuplicates(prefixes);
    setOptionsCallingCode(uniquePrefixes.map((prefix) => ({ label: prefix, value: prefix })));
  }, []);


  const submit = () => {

    if (!(phone && prefix)) {
      toast.error("PhoneNumberIsRequired");
      return;
    }

    const formattedPhoneNumber = prefix + phone.replace(/(\(|\))|\s|-/g, "");
    
    if (!isValidPhoneNumber(formattedPhoneNumber)) {
      toast.error("InvalidPhoneNumber");
      return;
    }
    onComplete({
      phoneNumber: {
        phone: phone,
        prefix: prefix
      }
    });
  }


  return (
    <div className="big-screen:ml-[10rem] m-10 container-tablet-signup">
      <div className="flex flex-col w-full my-1 space-y-2">
        <h2 className="ml-1 mb-6 text-lg font-bold text-blue">{t("PersonalDetails")} </h2>

        <div className="flex flex-col items-left w-full otp-box pt-4 pb-4 px-8 ">
          <h3 className="mb-1 text-sm font-bold text-blue">{t("OtpConfirmNumber")}</h3>
          <p className="text-sm font-semibold text-blue">{t("AddNumberToYourAccount")}</p>
        </div>

        <div className="big-screen:flex items-center justify-between w-full pt-4">
          <SelectMenu
            label={t("Prefix") + " *"}
            placeholder={t("Select")}
            className={"big-screen:max-w-[6vw] mr-5"}
            inputClassName={"p-1 bg-input"}
            labelClassName={""}
            placeholderClassName={""}
            options={optionsCallingCode}
            onChange={(item) => setPrefix(item.value)}
            value={prefix && optionsCallingCode ? optionsCallingCode.find((x) => x.value === prefix) : prefix}
          />
          <TextField
            label={t("PhoneNumber") + " *"}
            inputClassName={"p-3 bg-input"}
            labelClassName={""}
            placeholder={t("Insert")}
            onChange={(e) => setPhone(e.target.value)}
            defaultValue={phone}
          />
        </div>
      </div>

      <div className="flex justify-between items-center w-full mt-10">
        <button
          className="flex items-center bg-transparent border-none cursor-pointer p-2 previous-button"
          onClick={() => {
              onGoBack(1);
          }}
        >
          <div className="text-sm flex">{"< " + t("Previous")}</div>
        </button>

        <div className="flex justify-end ">
          <SecondaryButton
              content={t("Next") + " >"}
              className="p-2 w-[5vw]"
              onClick={submit}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneStep;
