import { Fragment, useContext, useRef, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DataContext } from "../../App";
import { NeutralButton }   from "../buttons/neutral-button";
import { logout } from "../../utils/functions";
import { 
  getAuth
} from "firebase/auth";
import { LogOut } from 'react-feather';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DropdownMenu(prop) {
    
    const { i18n, t } = useTranslation();
    const context = useContext(DataContext);
    const auth = getAuth();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuIsOpen(false);
        }
      };
      const handleOpenMenu = () => {
        setMenuIsOpen(true);
      };

      const handleCloseMenu = () => {
        setMenuIsOpen(false);
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('openMenu', handleOpenMenu);
      window.addEventListener('closeMenu', handleCloseMenu);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('openMenu', handleOpenMenu);
        window.removeEventListener('closeMenu', handleCloseMenu);
      };
    }, []);

  return (
    <Menu as="div" className="relative inline-block text-left"  ref={menuRef}>
      <div>
          <div className="" onClick={()=> {setMenuIsOpen(!menuIsOpen)}}>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold ">
            {auth && auth.currentUser ? auth.currentUser.displayName : "Mario Rossi"}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
          </div>
      </div>

      <Transition
        show={menuIsOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              <span className={'text-gray-800 font-bold block px-4 py-2 text-sm'}>{t("Account")} </span>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/profile"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => setMenuIsOpen(false)}
                >
                  {t("Profile")}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {/* {({ active }) => (
                <Link
                  to="/account/notifications"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {t("Notifications")}
                </Link>
              )} */}
              {({ active }) => (
              <span className={classNames(
                active ? '' : 'text-gray-300',
                'block px-4 py-2 text-sm pointer-events-none'
              )}>{t("Notifications")} </span>
              )} 
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              <span className={'text-gray-800 font-bold block px-4 py-2 text-sm'}>{t("Company")} </span>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/company/company-data"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => setMenuIsOpen(false)}
                >
                  {t("CompanyData")}
                </Link>
              )}
            </Menu.Item>          
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/company/plan"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => setMenuIsOpen(false)}
                >{t("Plans")}
                </Link>
              )} 
            </Menu.Item>
            <Menu.Item>
              {/* {({ active }) => (
                <Link
                  to="/company/roles"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {t("Roles")}
                </Link>
              )} */}
              {({ active }) => (
              <span className={classNames(
                active ? '' : 'text-gray-300',
                'block px-4 py-2 text-sm pointer-events-none'
              )}>{t("Roles")} </span>
              )} 
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/company/billing-address"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => setMenuIsOpen(false)}
                >
                  {t("BillingAddress")}
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              <span className={'text-gray-800 font-bold block px-4 py-2 text-sm'}>{t("Security")} </span>
              </Menu.Item>
                <Menu.Item>
              {({ active }) => (
                <Link
                  to="/security/password"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => setMenuIsOpen(false)}
                >
                  {t("Password")}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {/* {({ active }) => (
                <Link
                  to="/security/two-step-verification"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {t("TwoStepVerification")}
                </Link>
              )} */}
              {({ active }) => (
              <span className={classNames(
                active ? '' : 'text-gray-300',
                'block px-4 py-2 text-sm pointer-events-none'
              )}>{t("TwoStepVerification")} </span>
              )} 
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              <NeutralButton
                onClick={ logout }
                content={
                  <div className='flex ml-4 py-4 px-2 align-center'>
                    <span className='ml-2 mr-3 '>
                      {t("Logout")}
                    </span>
                    <LogOut size={20} />
                  </div>
                }
                className={"bg-transparent border-none"}
              />
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}