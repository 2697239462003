import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { toast } from "react-toastify";
import { auth } from "../../../../utils/firebaseConfig";
import PrimaryButton from "../../../../components/buttons/primary-button";
import { useTranslation } from "react-i18next";
import TextField from "../../../../components/input/fields/text-field";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { PasswordFieldProgress } from "../../../../components/input/fields/password-field-progress";
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { PasswordField } from "../../../../components/input/fields/password-field";

export const PasswordResetPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const currentYear = new Date().getFullYear();
  const handleSave = () => {
    if (newPassword !== newPasswordConfirm) {
      setErrorMessage(t("Passwords do not match"));
    } else {
      setErrorMessage("");
      // handleUpdate(); // Call your update function here
    }
  };


  return (
    <div className="flex flex-col items-center w-full mt-10 text-blue p-12 container-login">
      <div className="">
        <div className="flex flex-col items-center justify-center w-full text-petroil mb-10">
          <img
            className="w-full h-auto logo-login mb-6"
            alt="Bilemon logo"
            src="/Logo-Petroil.png"
          />
          <h2 className="mt-16 text-xl text-center font-bold">
            {t("ResetYourPassword")}
          </h2>
        </div>
        <div className="mt-16">
          <div className="mt-4 min-w-[20vw] relative">

            <PasswordFieldProgress
              type={isPasswordVisible ? "text" : "password"}
              label={t("NewPassword")}
              inputClassName="p-3 my-1"
              placeholder={t("Insert")}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <p className="text-xs">{t("RequirementsPassword")}</p>
          </div>
          <div className="mt-4 min-w-[20vw] relative">
            <TextField
              type={isPasswordVisible ? "text" : "password"}
              label={t("NewPasswordConfirm")}
              inputClassName="p-3 bg-input"
              value={newPasswordConfirm}
              placeholder={t("Insert")}
              checkPassword={newPassword}
              isPasswordVisible={isPasswordVisible}
              togglePasswordVisibility={() => setIsPasswordConfirmVisible(!isPasswordConfirmVisible)}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
            />
          </div>
          <div className="flex justify-center">
            <PrimaryButton
              content={t("ResetPasswordBtn")}
              className={"mt-6 p-3 bg-blue w-[7vw] mb-6"}
              onClick={handleSave}
            />

          </div>
          <div className="flex flex-col items-center justify-center space-y-2">

            <div className="flex items-center">

              <p className="mb-6 mt-16 text-center text-sm">

                <div className="font-bold flex justify-center text-blue">
                  <div>
                    <HiOutlineQuestionMarkCircle size={20} />
                  </div>
                  <div className="ml-1">
                    {t("SignupQuestion")}
                  </div>
                </div>

                <br />
                <span className="text-blue">
                  {t("SignupEmail")}
                </span>
                <br />
                <div className="text-blue">
                  <a href="mailto:support@bilemon.com" className="hover:brightness-125">

                    support@bilemon.com
                  </a>
                </div>



              </p>

            </div>
          </div>
        </div>
      </div>
      <div className="w-full pl-32 text-base font-semibold text-left">
        <p>©BiLemon {currentYear}</p>
      </div>
    </div>
  );
};
