import React, { useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const [seconds, setSeconds] = useState(5);
  const { t } = useTranslation();
  const navigate = useNavigate()
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      navigate('/')
    }
  }) 
  return (
    <div className="flex items-center justify-center h-[80vh] text-blue">
  <div className="flex flex-col items-center justify-center p-10 border border-[#dc2626] shadow-md rounded-lg w-[20%]">
    <div className="flex items-center">
      <AlertTriangle size={32} color='red'/>
    </div>
      <h1 className="text-xl mt-4">{t("Message404")}</h1>
    <p className="text-base text-center mt-2">{t("Redirect404")}</p> <p className="text-base text-center">{seconds} {t("Seconds")}</p>
  </div>
</div>

  );
}