import React, { useContext, useState, useEffect, useRef } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";

export const Transactions = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const transactionsList = context.transactions;
  const organizationInfo = context.organizationInfo;  
  const accommodations = context.accommodations;
  const symbol = organizationInfo && organizationInfo.currency;

  const getAccommodationName = (accommodationId) => {
    return accommodations.find(acc => acc.id === accommodationId);
  };

  const transactionsListWithAccommodationNames = transactionsList.map(transaction => {
    const accommodationName = transaction.idAccommodation ? transaction.idAccommodation.map(x => getAccommodationName(x).name).join(', ') : "";
    return {
      ...transaction,
      accommodationName
    };
  });
  

  const transactionTableStructure = {
    info: {
      urlUpdateData: "transactions",
      urlCreateUpdate: "transaction",
      name: "Transaction",
      header: [
        {
          field: "category",
          header: t("Category"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "type",
          header: t("Type"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "subCategory",
          header: t("SubCategory"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "costCenter",
          header: t("CostCenter"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "typology",
          header: t("Typology"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "date",
          header: t("Date"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
            field: "accommodationName",
            header: t("Accommodation"),
            sortable: true,
            type: "Text",
            show: true,
        },
        {
          field: "amount",
          header: t("Amount"),
          sortable: true,
          type: "Text",
          show: true,
          symbol: symbol
        }
      ],
      body: transactionsListWithAccommodationNames
    },

    exportable: true,
    importable: true,
    filterType: "filter"
  }

  return (

    <div className="w-full mt-8">
      <div className="content">
        <div className="table-wrapper">
          <Table
            info={transactionTableStructure.info}
            exportable={transactionTableStructure.exportable}
            importable={transactionTableStructure.importable}
            filterType={transactionTableStructure.filterType}
          />
        </div>
      </div>
    </div>

  );
}