import React, { useState, useEffect, useRef, useContext } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import Modal from "../dialogs/dialogInfo";
import { Info } from "../../resources/info";
import TertiaryButton from "./tertiary-button";
import { useTranslation } from "react-i18next";

const InfoButton = ({ nameComponent, language }) => {
    const { i18n, t } = useTranslation();
    const [viewModal, setViewModal] = useState(false);
    const modalRef = useRef(null);
    const [activeId, setActiveId] = useState(nameComponent);
    const [section, setSection] = useState("accommodations");
    const [infoContent, setInfoContent] = useState("");
    const infoTranslated = Info[language];

    useEffect(() => {

        switch (activeId) {
                case "accommodations":
                    setSection("Accommodations");
                    break;
                case "ota":
                    setSection("Ota");
                    break;
                case "costRules":
                    setSection("PaymentRules");
                    break;
                case "bookings":
                    setSection("Bookings");
                    break;
                case "costs":
                    setSection("Costs");
                    break;
                default:
                    setSection("Accommodations");
                    setActiveId("accommodations");
                    break;
            }

    }, [nameComponent, activeId]);

    const handleSectionClick = (id) => {
        setActiveId(id);

    };

    const handleClick = () => {
        setViewModal(true);
    };



    return (
        <>
            <button
                onClick={handleClick}
                className="top-10 flex items-center justify-center text-petroil hover:brightness-125 rounded-full w-12 h-12 bg-transparent"
            >
                <HiOutlineInformationCircle size={30} />
            </button>

            <Modal
                externalControl={true}
                status={viewModal}
                title={t("Information") + ' - ' + t(section)}
                icon={<HiOutlineInformationCircle size={30} />}
                className=""
                body={
                    <>
                        <div className='flex flex-col md:flex-row mt-12' ref={modalRef}>
    {/* Lista laterale o sopra in base allo schermo */}
    <div className='w-full md:w-[30%] min-w-[10vw] cuteBorder px-2 mb-8'>
        <ul className="navLeft pr-12 pl-4 customNavLeft md:mb-0 mb-4 flex flex-row md:flex-col space-x-4 md:space-x-0 overflow-x-auto">
            {infoTranslated.map(section => (
                <li className="pb-2 min-w-fit md:w-auto pr-2 md:pr-0" key={section.id}>
                    <a
                        className={`list-group-item list-group-item-action text-lg ${activeId === section.id ? 'active' : ''}`}
                        onClick={() => handleSectionClick(section.id)}
                    >
                        {section.title}
                    </a>
                </li>
            ))}
        </ul>
    </div>

    {/* Contenuto principale */}
    <div className='w-full h-[60vh]  overflow-y-auto'>
        <div className="px-6">
            {infoTranslated.map((item, index) => {
                if (item.id === activeId) {
                    return (
                        <p className="text-base mb-5" key={index} dangerouslySetInnerHTML={{ __html: item.content }} />
                    );
                }
            })}
        </div>
    </div>
</div>
                        <div className="flex row justify-end">
                            <TertiaryButton
                                onClick={() => {
                                    setViewModal(false);
                                }}
                                className={"bg-red p-2 w-fit mt-10 max-w-[50px]"}
                                content={t("Close")}
                            />
                        </div>
                    </>
                }
                onClose={() => {
                    setViewModal(false);
                }}

            />
        </>
    );
};

export default InfoButton;
