import React, { useEffect, useState } from "react";
import { MdClose, MdOutlineSegment } from "react-icons/md";
import { HiOutlineChat, HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../appRoutes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const menu = AppRoutes;
export const BurgerSidebar = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const location = useMatch("/*");
  const [activePath, setActivePath] = useState(location?.params["*"] || "");

  useEffect(() => {
    if (location) {
      setActivePath(location.params["*"]);
    }
  }, [location]);

  return (
    <>
      {isOpen && (<div className="desktop-hidden p-2 fixed bottom-2 right-2 z-50 w-12 h-12 rounded-full cursor-pointer bg-petroil">
        
        <button onClick={() => setIsOpen(!isOpen)} className="text-blue-500">
        <MdClose className="w-8 h-8 text-white" />
        </button>
      </div>)}
      {!isOpen && (<div className="desktop-hidden p-2 fixed bottom-2 left-2 z-50 w-12 h-12 rounded-full cursor-pointer bg-petroil">
        
        <button onClick={() => setIsOpen(!isOpen)} className="text-blue-500">
          
        <MdOutlineSegment className="w-8 h-8 text-white" />
        </button>
      </div>)}
      

      <div
        className={classNames(
          className,
          "fixed top-0 left-0 h-full bg-white transition-transform duration-300 ease-in-out",
          isOpen ? "translate-x-0 w-full" : "-translate-x-full w-0"
        )}
      >
        <div className="relative flex flex-col items-start h-full my-5 text-blue overflow-hidden">          
          
          {isOpen && (
            <>
              <Link className="flex items-center justify-center w-full h-2 my-10 lg:my-0" to="/">
                <img src="/Logo-Petroil.png" className="h-[5rem] pt-3 pb-4" alt="logo" />
              </Link>
              <div className="h-full w-full">
                <div className="mt-10 space-y-5 items-center hover:text-blue" id="selectionmenu">
                  {menu.map((item, index) => {
                    return (
                      item.isInSidebar && (
                        <div
                          key={index}
                          className={classNames(
                            item.path === activePath ? "border-l-4 text-blue" : "border-0",
                            "flex flex-row items-center border-petroil",
                            item.disabled ? " cursor-not-allowed" : ""
                          )}
                        >
                          <div className="flex items-center ml-2 mr-4">
                            {item.disabled ? (
                              <div className="ml-2 cursor-not-allowed color-disabled font-semibold text-lg flex items-center">
                                {item.icon}
                                <span className="ml-2 premium-required-tooltip" data-tooltip={t("PremiumRequired")}>
                                  {t(item.name)}
                                </span>
                              </div>
                            ) : (
                              <Link
                                to={item.path}
                                className={classNames(
                                  item.path === activePath
                                    ? "font-bold text-blue"
                                    : "font-bold opacity-40",
                                  "flex ml-2 cursor-pointer hover:text-blue hover:opacity-100 text-md"
                                )}
                              >
                                {item.icon}
                                <span className="ml-2">{t(item.name)}</span>
                              </Link>
                            )}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>

              <div className="items-center mt-8 h-auto justify-start w-full mb-5">
                <Link
                  to={"faq"}
                  className={classNames(
                    "font-semibold opacity-80",
                    "flex ml-5 cursor-pointer hover:text-blue hover:opacity-100 text-lg"
                  )}
                >
                  <div className="flex items-center">
                    <HiOutlineQuestionMarkCircle className={"w-6 h-auto font-bold hover:text-blue "} />
                    <div className="ml-2">              
                      {"FAQ"}
                    </div>
                  </div>
                </Link>
                <a href="mailto:support@bilemon.com"
                  className={classNames(
                    "font-semibold opacity-80",
                    "flex ml-5 cursor-pointer hover:text-blue hover:opacity-100 text-lg"
                  )}
                >
                  <div className="flex items-center">
                    <HiOutlineChat className={"w-6 mr-2 h-auto font-bold hover:text-blue "} />
                    {t("SignupQuestion")}
                  </div>
                </a>
                <p className="font-semibold text-sm mt-10 ml-5">&copy; BiLemon {new Date().getFullYear()}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
