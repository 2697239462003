import React, { useContext, Fragment, useState, useRef, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Button from '../buttons/tertiary-button';
import ButtonGreen from '../buttons/secondary-button';
import TertiaryButton from '../buttons/tertiary-button';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import TextField from "../../components/input/fields/text-field";
import { DataContext } from "../../App";
import './table.css';
import { Link, NavLink, useMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import Modal from 'react-modal';
import { Calendar } from 'primereact/calendar';
import { Filter, Trash, AlertTriangle, Upload, Download } from 'react-feather';
import { deleteData, deleteMultipleData, Import } from '../../utils/functions';
import DragNdrop from '../DragNDrop/DragNDrop.js';
import { utils, writeFile } from 'xlsx';
import { Slider } from "primereact/slider";
import { SelectMenu } from "../../components/input/select/select-menu";
import { format, parse } from "date-fns";
import { GeneralOptions } from "../../resources/selectOptions";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import readXlsxFile from "read-excel-file";
import FilterComponent from './filter.js';
import TableSmall from '../tableSmall/tableSmall.js';
import PrimaryButton from '../buttons/primary-button.js';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Table = ({ info, exportable, importable, filterType, notDeletable, searchable }) => {
    const { t } = useTranslation();
    const context = useContext(DataContext);
    const exportColumns = info.header.map((col) => ({ title: col.header, dataKey: col.field }));
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [importModal, setImportModal] = useState(false);
    const [completedImportModal, setCompletedImportModal] = useState(false);
    const [completedImportMessages, setCompletedImportMessages] = useState(false);
    const [deleteCollectionDialog, setDeleteCollectionDialog] = useState(false);
    const [viewItemDialog, setViewItemDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const dt = useRef(null);
    const [files, setFiles] = useState([]);
    const [filterModal, setFilterModal] = useState(false);
    const [lazyParams, setLazyParams] = useState({ first: 0, rows: 10 });
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [dataTableValue, setDataTableValue] = useState(info.body);
    const contractOptions = GeneralOptions("contract");
    const costCategoriesList = context.costCategories;
    const costTypologyOptions = GeneralOptions("costTypology");
    const statusOptions = GeneralOptions("status");
    const locationAccommodation = useMatch("accommodations/");
    const locationBooking = useMatch("bookings/");
    const locationTransaction = useMatch("transactions/");

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredData = dataTableValue.filter((row) => {
        return Object.values(row).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const formatDate = (timestamp) => {
        if (timestamp === 0) return '';
        const date = new Date(timestamp * 1000);
        return !isNaN(date.getTime()) && date.getTime() > 0 ? date.toLocaleDateString() : String(timestamp);
    };
    const accommodationOptions = context.accommodations &&
        context.accommodations.map((item) => {
            return { label: item.name, value: item.id };
        });

    const errorImportTableStructure = {
        info: {
            header: [
            {
                field: "errors",
                header: t("Error"),
                type: "Text",
                show: true,
            }],
            body: completedImportMessages.errors
        }
    }

    const renderDetail = (key, value) => {
        let dataToReturn = null;
        const numericKeys = ["touristTax", "basePrice", "extraServiceFee", "occupancyRate", "annualRevenue"];
        const euroKeys = ["touristTax", "basePrice", "extraServiceFee", "annualRevenue", "monthlyFee", "amount"];

        if (key === "idAccommodations" && info.name === "BookingRule") {
            return null;
        } else if (key === "idBookingRule" && info.name === "Transaction") {
            return null;
        } else if (key === "idBooking" && info.name === "Transaction") {
            return null;
        } else if (key === "recurrencyCustom" && info.name === "Transaction" && value === null) {
            return null;
        } else if (key === "recurrencyInternal" && info.name === "Transaction" && (value === 'regular' || value === "tantum")) {
            return null;
        } else if (key === "accommodationName" && info.name === "Booking") {
            return null;
        }
        if (numericKeys.includes(key) && (value === null || value === undefined || value === "")) {
            value = 0;
        } else {
            value = value === null || value === undefined ? "" : value;
        }

        if (key !== "id") {
            if (value && value instanceof Date) {
                dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + formatDate(value._seconds);
            }

            if (key.toLowerCase().includes("date")) {
                dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + (value && format(new Date(value), "dd/MM/yyyy"));
            }

            if (typeof value === 'object' && value !== null) {
                let data = Object.values(value).filter(v => v !== null && v !== undefined && v !== "").map(v => v).join(', ');
                dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + data;
            }

            switch (key) {
                case "idOwner":
                    const ownerName = value === null || value === undefined
                        ? ""
                        : context.owners.find(option => option.id === value)?.fullNameCompanyName || "";
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + ownerName;
                    break;
                case "contractTypology":
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + (contractOptions.find(option => option.value === value)?.label ?? '');
                    break;
                case "status":
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + statusOptions.find(option => option.value === value)?.label;
                    break;
                case "idAccommodation":
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + (context.accommodations.find(option => option.id === value)?.name ?? "");
                    break;
                case "rulesCustom":
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + value.length;
                    break;
                case "idBooking":
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + context.bookings.find(option => option.id === value)?.bookingReference;
                    break;
                case "idSupplier":
                    const supplierName = value === null || value === undefined
                        ? ""
                        : context.suppliers.find(option => option.id === value)?.fullNameCompanyName || "";
                    dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + supplierName;
                    break;
                    

                case "accommodationNames":
                    const accommodationsArray = typeof value === 'string' ? value.split(', ') : [];
                    const accommodationCount = accommodationsArray.length;
                    dataToReturn = t("AccommodationNumber") + ": " + accommodationCount;
                    break;
                case "accommodationPayments":
                    let accommodationsPaymentsArray = []
                    if (typeof value === 'string') {
                        accommodationsPaymentsArray = value.split(', ');
                    } else {
                        accommodationsPaymentsArray = value;
                    }
                    const accommodationPaymentsCount = (value && value !== "") ? accommodationsPaymentsArray.length : 0;
                    dataToReturn = t("AccommodationPaymentsNumber") + ": " + accommodationPaymentsCount;
                    break;

            }

            if (!dataToReturn) {
                let formattedValue;
                if (key === "amount" && selectedItem.paymentType === "percentage") {
                    formattedValue = `${value} %`;
                } else if (euroKeys.includes(key)) {
                    formattedValue = `${value} €`;
                } else {
                    formattedValue = value;
                }
                dataToReturn = t(typeof key === 'string' && key.toString().charAt(0).toUpperCase() + key.toString().slice(1)) + ": " + formattedValue;
            }
        }

        return dataToReturn;
    };







    async function ImportData() {
        if (locationAccommodation) {
            const schema = {
                'Name*': {
                    prop: 'name',
                    type: String,
                    required: true
                },
                'Owner*': {
                    prop: 'idOwner',
                    type: String,
                    required: true
                },
                'Street': {
                    prop: 'streetAddress',
                    type: String,
                    required: false
                },
                'City*': {
                    prop: 'city',
                    type: String,
                    required: true
                },
                'Zip Code': {
                    prop: 'zipCode',
                    type: String,
                    required: false
                },
                'Country': {
                    prop: 'country',
                    type: String,
                    required: false
                },
                'Tourist Tax': {
                    prop: 'touristTax',
                    type: Number,
                    required: false
                },
                'Base Price': {
                    prop: 'basePrice',
                    type: Number,
                    required: false
                },
                'Extras/Service Fee': {
                    prop: 'extraServiceFee',
                    type: Number,
                    required: false
                },
                'Occupancy rate': {
                    prop: 'occupancyRate',
                    type: Number,
                    required: false
                },
                'Annual revenue': {
                    prop: 'annualRevenue',
                    type: Number,
                    required: false
                }
            }
            readXlsxFile(files[0], { schema }).then(async ({ rows, errors }) => {
                const accomodations = rows.map(x => {
                    return {
                        name: x.name,
                        address: {
                            street: x.streetAddress,
                            city: x.city,
                            zipCode: x.zipCode,
                            country: x.country
                        },
                        touristTax: x.touristTax,
                        basePrice: x.basePrice,
                        extraServiceFee: x.extraServiceFee,
                        occupancyRate: x.occupancyRate,
                        annualRevenue: x.annualRevenue,
                        contractTypology: x.contractTypology,
                        idOwner: x.idOwner
                    }
                });
                const result = await Import("Accommodation", context.idOrganization, accomodations);
                setCompletedImportModal(true);
                setCompletedImportMessages(result);
                
            })
        }
        else if (locationTransaction) {
            const schema = {
                'Type *': {
                    prop: 'type',
                    type: String,
                    required: true
                },
                'Typology *': {
                    prop: 'typology',
                    type: String,
                    required: true
                },
                'Accommodation': {
                    prop: 'idAccommodation',
                    type: String,
                    required: false
                },
                'Booking': {
                    prop: 'idBooking',
                    type: String,
                    required: false
                },
                'Category': {
                    prop: 'category',
                    type: String,
                    required: false
                },
                'Sub-Category': {
                    prop: 'subCategory',
                    type: String,
                    required: false
                },
                'Cost Center': {
                    prop: 'costCenter',
                    type: String,
                    required: false
                },
                'Supplier': {
                    prop: 'idSupplier',
                    type: String,
                    required: false
                },
                'Date *': {
                    prop: 'date',
                    type: Date,
                    required: true
                },
                'Payment Date': {
                    prop: 'paymentDate',
                    type: Date,
                    required: false
                },
                'Amount *': {
                    prop: 'amount',
                    type: Number,
                    required: true
                }
            }
            readXlsxFile(files[0], { schema }).then(async ({ rows, errors }) => {
                const transactions = rows.map(x => {
                    return {
                        id: x.id,
                        typology: x.typology,
                        type: x.type, 
                        recurrencyInternal: "tantum",
                        idBookingRule: null,
                        idBooking: x.idBooking,
                        category: x.category,
                        subCategory: x.subCategory,
                        costCenter: x.costCenter,
                        idSupplier: x.idSupplier,
                        date: x.date,
                        paymentDate: x.paymentDate,
                        paymentType: "fixed",
                        amount: x.amount,
                        idAccommodation: [x.idAccommodation],
                        recurrency: null,
                        startDate: null,
                        endDate: null,
                        recurrencyCustom: null
                    }
                });
                const result = await Import("Transaction", context.idOrganization, transactions);
                setCompletedImportModal(true);
                setCompletedImportMessages(result);
            })
        }
        else if (locationBooking) {
            const schema = {
                'Accomodation Name*': {
                    prop: 'idAccommodation',
                    type: String,
                    required: true
                },
                'Booking Date*': {
                    prop: 'bookingDate',
                    required: true
                },
                'Check-In Date*': {
                    prop: 'checkInDate',
                    type: String,
                    required: true
                },
                'Check-Out Date*': {
                    prop: 'checkOutDate',
                    required: true
                },
                'Channel (Leave empty if is direct)': {
                    prop: 'channel',
                    type: String,
                    required: false
                },
                'Total Amount*': {
                    prop: 'amount',
                    type: Number,
                    required: true
                },
                'Guests': {
                    prop: 'numberOfGuest',
                    type: Number,
                    required: false
                },
                'Country': {
                    prop: 'country',
                    type: String,
                    required: true
                },
                'Reference': {
                    prop: 'bookingReference',
                    type: String,
                    required: false
                },
                'Extras / Service fee': {
                    prop: 'extraServiceFee',
                    type: String,
                    required: false
                },
                'Status': {
                    prop: 'status',
                    type: String,
                    required: false
                }
            }
            readXlsxFile(files[0], { schema }).then(async ({ rows, errors }) => {
                const bookings = rows.map(x => {
                    return {
                        status: x.status,
                        bookingDate: ExcelDateToJavascript(x.bookingDate),
                        checkInDate: ExcelDateToJavascript(x.checkInDate),
                        checkOutDate: ExcelDateToJavascript(x.checkOutDate),
                        country: x.country,
                        numberOfGuest: x.numberOfGuest,
                        amount: x.amount,
                        extraServiceFee: x.extraServiceFee,
                        accommodationFare: null,
                        idAccommodation: x.idAccommodation,
                        channel: {
                            commissionChannel: x.channel,
                            commissionPercentage: null,
                            commissionAmount: 0,
                            commissionType: null
                        },
                        bookingReference: x.bookingReference
                    }
                });
                const result = await Import("Booking", context.idOrganization, bookings);
                setCompletedImportModal(true);
                setCompletedImportMessages(result);
            })
        }
    }

    function ExcelDateToJavascript(date) {
        if (date) {
            if (typeof (date) == "string") {
                return parse(date, 'dd/MM/yyyy', new Date());
            }
            else if (typeof (date) == "number") {
                return new Date(date * 1000);
            }
            else if (typeof (date) == "object" && date instanceof Date) {
                return date;
            }
            return null;
        }
    }

    function afterOpenModal() {
        Modal.defaultStyles.overlay.backgroundColor = "cornsilk";
    }

    function closeModal() {
        setCompletedImportModal(false);
        setIsViewModalOpen(false);
        setViewItemDialog(false);
        setDeleteCollectionDialog(false);
        setImportModal(false);
        setFilterModal(false);
    }


    useEffect(() => {
        if (dataTableValue.length > 0) {
            setFilterModal(false);
        }
    }, [dataTableValue]);

    useEffect(() => {
        // loadLazyData();
    }, [lazyParams, selectedItems]);

    const exportCSV = () => {

        // funzione per rimuovere le colonne indesiderate
        const removeColumns = (data, columnsToRemove) => {
            return data.map(item => {
                const filteredItem = { ...item };
                columnsToRemove.forEach(column => delete filteredItem[column]);
                return filteredItem;
            });
        };

        // funzione per tradurre i valori delle colonne
        const translateColumns = (data) => {
            return data.map(item => {
                const translatedItem = {};
                for (const key in item) {
                    if (key === 'idAccommodation' && item[key] && Array.isArray(item[key])) {
                        translatedItem[t(key)] = item[key] ? item[key].map(x => context.accommodations.find(acc => acc.id === x).name).join(', ') : "";
                    } else if (key === 'idAccommodation' && item[key] && !Array.isArray(item[key])) {
                        translatedItem[t("IdAccommodation")] = context.accommodations.find(acc => acc.id === item[key])?.name ?? "";
                    } else if (key === 'idOwner') {
                        translatedItem[t("Owner")] = context.owners.find(owner => owner.id === item[key])?.fullNameCompanyName ?? "";
                    } else if (key === 'idSupplier') {
                        translatedItem[t("Supplier")] = context.suppliers.find(supplier => supplier.id === item[key])?.fullNameCompanyName ?? "";
                    } else if (key === 'address' && item[key]) {
                        translatedItem[t(key)] = Object.values(item.address).filter(v => v !== null && v !== undefined && v !== "").join(", ");
                    } else if (key === 'channel' && item[key]?.commissionChannel) {
                        translatedItem[t(key)] = item[key].commissionChannel;
                    } else if (typeof item[key] === 'string' && item[key].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/)) {
                        const formattedDate = formatDateString(item[key]);
                        translatedItem[t(key)] = formattedDate;
                    } else {
                        translatedItem[t(key)] = t(item[key]);
                    }
                }
                return translatedItem;
            });
        };

        const formatDateString = (isoDateString) => {
            const dateObject = new Date(isoDateString);
            const day = String(dateObject.getUTCDate()).padStart(2, '0');
            const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
            const year = dateObject.getUTCFullYear();
            return `${day}/${month}/${year}`;
        };

        // colonne da escludere
        const columnsToRemove = ['id', info.name !== 'Booking' && 'status', 'accommodationName', 'idBookingRule', 'idBooking'];

        // filtra i dati rimuovendo le colonne specificate
        let filteredData = removeColumns(info.body, columnsToRemove);

        // traduci i valori delle colonne
        filteredData = translateColumns(filteredData);

        // crea il foglio di lavoro con i dati modificati
        const ws = utils.json_to_sheet(filteredData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, info.name + "s");

        // scrivi il file Excel
        writeFile(wb, info.name + "s" + '.xlsx');
    };






    const confirmDeleteSelected = () => {
        setDeleteCollectionDialog(true);

    };

    const rightToolbarTemplate = () => {
        return (
            <>
                {importable && (
                    <Button
                        id={"importCsvBtn"}
                        className={"text-petroil-0 mr-5 "}
                        onClick={() => setImportModal(true)}
                        content={
                            <div className='flex'>

                                <Upload size={24} className='text-blue' />
                                <span className='ml-2 text-blue'>
                                    {t("TableImport")}
                                </span>
                            </div>
                        }
                        isDisabled={false}
                    />
                )}
                {importModal && (
                    <Modal
                        isOpen={importModal}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={{
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                width: "30%",
                                transform: 'translate(-50%, -50%)',
                                borderRadius: '20px',
                                padding: ' 1rem 2rem 2rem 2rem'
                            }
                        }}
                        contentLabel="update csv"
                    >
                        <div className="flex justify-end mb-2">
                            <div className="cursor-pointer" onClick={closeModal}>
                                <FontAwesomeIcon icon={faTimes} size='lg' />
                            </div>
                        </div>

                        <DragNdrop onFilesSelected={setFiles} onUpdate={ImportData} />

                    </Modal>
                )}
                {exportable && (
                    <>
                        <Button
                            id={"exportCsvBtn"}
                            className={"text-petroil-0 mr-5 ml-5"}
                            onClick={exportCSV}
                            content={
                                <div className='flex'>

                                    <Download size={24} className='text-blue' />
                                    <span className='ml-2 text-blue'>
                                        {t("Export")}
                                    </span>
                                </div>
                            }
                            isDisabled={false}
                        />
                    </>
                )}
            </>
        );
    };

    const ActionBodyTemplate = (rowData) => {
        const dataToPass = info.body.find(element => element.id === rowData.id);
        return (
            <>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold rotate-90 ">
                            <span className='text-[24px]'>...</span>
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items id={"menu-table"} className="absolute right-0 z-10 mt-2 w-[8rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm linkTable'
                                            )}
                                            onClick={() => {
                                                setSelectedItem(dataToPass);
                                                setViewItemDialog(true)
                                            }
                                            }
                                        >
                                            {t("Details")}
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm linkTable'
                                            )}
                                            to={"edit-" + info.urlCreateUpdate}
                                            state={{ itemState: dataToPass, isEditable: true }}
                                        >
                                            {t("Update")}
                                        </NavLink>
                                    )}
                                </Menu.Item>


                                {!notDeletable && <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm linkTable'
                                            )}
                                            onClick={() => {
                                                setSelectedItemId(dataToPass.id);
                                                confirmDeleteSelected();
                                            }}
                                            to={"/" + info.urlUpdateData}
                                        >
                                            {t("Delete")}
                                        </NavLink>
                                    )}
                                </Menu.Item>}

                            </div>


                        </Menu.Items>
                    </Transition>
                </Menu>
            </>
        );
    };

    const header = (
        <div className="flex d-flex justify-between w-full">
            <div className="flex d-flex align-items-center">
                <div className="mt-4">
                    {/* propertyManager */}
                    {((context.organizationInfo.subscription.plan === 'host' && context.organizationInfo.subscription.properties < 4) || (context.organizationInfo.subscription.plan === 'propertyManager')) ?
                        (
                            <NavLink
                                to={"new-" + info.urlCreateUpdate}
                                state={{ pageState: info }}
                                className={'px-4 btn btn-primary bg-blue rounded-lg p-3 mr-2 text-white hover:bg-petroil'}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <span className='ml-1 font-semibold'>
                                    {t("AddNew")}
                                </span>
                            </NavLink>
                        )
                        :
                        (
                            <NavLink

                                className={'px-4 cursor-not-allowed bg-input rounded-lg p-3 mr-2 text-white'}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <span className='ml-1 font-semibold'>
                                    {t("AddNew")}
                                </span>
                            </NavLink>
                        )
                    }
                </div>

                {!notDeletable && <div className="mt-2">
                    <TertiaryButton
                        id={"deleteSelectedBtn"}
                        className={"deleteSelectedBtn p-2 ml-4 px-4 hover:brightness-100"}
                        onClick={confirmDeleteSelected}
                        content={<Trash />}
                        isDisabled={!selectedItems || selectedItems.length < 1}
                    />
                </div>}
            </div>
            {filterType && (
                <div className="flex d-flex align-items-center text-blue pt-4 ml-4 px-4 mb-2">
                    {filterType === "filter" && (
                        <Filter onClick={() => setFilterModal(true)} />
                    )}
                </div>
            )}
            {searchable && (<div className="">

                <TextField
                    filterInput={true}
                    inputClassName={"p-1 font-normal text-blue"}
                    className={"max-w-[13vw] text-blue"}
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder={t("Search")}
                />
            </div>)}
        </div>
    );

    const isTableDisabled = importModal || filterModal || isViewModalOpen;

    const customBodyTemplate = (rowData, column) => {
        const row = rowData;
        const field = column.field;
        let fieldValue = "";
        if (field.includes(".")) {
            const dataSplitted = field.split('.');
            fieldValue = row[dataSplitted[0]] ? row[dataSplitted[0]][dataSplitted[1]] : row[dataSplitted[0]];
        } else {
            if (row[column.field]) {
                if (field === 'status') {
                    if (typeof row[column.field] === 'string' && row[column.field].toLowerCase() === 'inactive') {
                        fieldValue = "<span class='text-red'>" + t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1)) + "</span>"
                    } else if (typeof row[column.field] === 'string' && row[column.field].toLowerCase() === 'active') {
                        fieldValue = "<span class='text-[#03c03c]'>" + t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1)) + "</span>"
                    } else if (typeof row[column.field] === 'string' && row[column.field].toLowerCase() === 'paid') {
                        fieldValue = "<span class='text-[#03c03c]'>" + t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1)) + "</span>"
                    } else if (typeof row[column.field] === 'string' && row[column.field].toLowerCase() === 'canceled') {
                        fieldValue = "<span class='text-red'>" + t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1)) + "</span>"
                    } else {
                        fieldValue = t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1));
                    }
                } else if (field === 'type') {
                    if (typeof row[column.field] === 'string' && row[column.field].toLowerCase() === 'cost') {
                        fieldValue = "<span class='text-red'>" + t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1)) + "</span>"
                    } else if (typeof row[column.field] === 'string' && row[column.field].toLowerCase() === 'earning') {
                        fieldValue = "<span class='text-[#03c03c]'>" + t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1)) + "</span>"
                    }

                } else if (field === 'category') {
                    fieldValue = t((costCategoriesList.find(option => option.category === row[column.field])?.category) ?? row[column.field]);
                    if (fieldValue === "") {
                        fieldValue = t(row[column.field].charAt(0).toUpperCase() + row[column.field].toString().slice(1));
                    }
                } else if (field === 'contractTypology') {
                    fieldValue = (t(contractOptions.find(option => option.value === row[column.field])?.label));
                } else if (field === 'typology') {
                    fieldValue = t(costTypologyOptions.find(option => option.value === row[column.field])?.label);
                } else if (field === 'accommodationName') {
                    const accommodations = row.accommodationName;

                    const accommodationsArray = typeof accommodations === 'string' ? accommodations.split(', ') : [];

                    if (accommodationsArray.length > 1) {
                        return (
                            <div
                                className='accommodations-count'
                                data-items={JSON.stringify(accommodationsArray)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    const modalContent = accommodationsArray.map(item => `<li>${item}</li>`).join('');
                                    document.getElementById('modal-content').innerHTML = `<ul>${modalContent}</ul>`;
                                    document.getElementById('modal').style.display = 'block';
                                }}
                            >
                                {accommodationsArray.length}
                            </div>
                        );
                    } else {
                        fieldValue = accommodationsArray.join(', ');
                    }
                } else if (field === 'accommodationNames') {
                    let accommodations = [];
                    if (row && row.idAccommodations) {
                        accommodations = row.idAccommodations;
                        if (accommodations.length > 1) {
                            return (
                                <div
                                    className='accommodations-count'
                                    data-items={JSON.stringify(accommodations)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        const modalContent = accommodations.map(item => `<li>${context.accommodations.find(option => option.id === item)?.name ?? ""}</li>`).join('');
                                        document.getElementById('modal-content').innerHTML = `<ul>${modalContent}</ul>`;
                                        document.getElementById('modal').style.display = 'block';
                                    }}
                                >
                                    {accommodations.length}
                                </div>
                            );
                        } else {
                            fieldValue = context.accommodations.find(option => option.id === accommodations[0])?.name ?? "";
                        }
                    } else {
                        accommodations = typeof row.accommodationNames === 'string'
                            ? row.accommodationNames.split(', ')
                            : [];

                        if (accommodations.length > 1) {
                            return (
                                <div
                                    className='accommodations-count'
                                    data-items={JSON.stringify(accommodations)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        const modalContent = accommodations
                                            .map(item => `<li>${item}</li>`)
                                            .join('');
                                        document.getElementById('modal-content').innerHTML = `<ul>${modalContent}</ul>`;
                                        document.getElementById('modal').style.display = 'block';
                                    }}
                                >
                                    {accommodations.length}
                                </div>
                            );
                        } else {
                            fieldValue = accommodations[0] ?? "";
                        }
                    }

                } else if (field === 'idOwner') {
                    fieldValue = t(context.owners.find(option => option.id === row[column.field])?.fullNameCompanyName);
                } else if (field.toLowerCase().includes("date")) {
                    fieldValue = format(new Date(row[column.field]), "dd/MM/yyyy");
                } else if (field === 'subCategory') {

                    if(Array.isArray(row[column.field])) {

                        fieldValue = row[column.field].map((subCategory) => {
                            return " " + subCategory;
                        })
                    } else {
                        fieldValue = row[column.field];
                    }
                } else if (field === 'accommodationPayments') { 
                    const accommodationIds = row[column.field].map(payment => payment.idAccommodation);
                    const accommodationNames = accommodationIds.map(id => context.accommodations.find(option => option.id === id)?.name ?? "");
                    if (accommodationNames.length > 1) {
                        return (
                            <div
                                className='accommodations-count'
                                data-items={JSON.stringify(accommodationNames)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    const modalContent = accommodationNames.map(item => `<li>${item}</li>`).join('');
                                    document.getElementById('modal-content').innerHTML = `<ul>${modalContent}</ul>`;
                                    document.getElementById('modal').style.display = 'block';
                                }}
                            >
                                {accommodationNames.length}
                            </div>
                        );
                    }
                } else {
                    fieldValue = t(row[column.field]);
                }
            }

        }

        let fieldSymbol = column.symbol;
        if (column.symbol && row.paymentType === 'percentage') {
            fieldSymbol = '%';
        }
        if (fieldValue && typeof fieldValue === 'object' && fieldValue._seconds) {
            let date = new Date(fieldValue._seconds * 1000);
            let dateString = date.toLocaleDateString();
            fieldValue = dateString;
        }

        const shouldHide = fieldValue === 0 || fieldValue === "0";

        return (
            <>
                {!shouldHide && (
                    <div className='flex'>
                        <div className='mr-1' dangerouslySetInnerHTML={{ __html: fieldValue }}></div>
                        {fieldValue !== null && fieldValue !== undefined && fieldValue !== "" && fieldSymbol && ` ${fieldSymbol}`}
                    </div>
                )}
            </>
        );
    };




    document.addEventListener('click', function (event) {
        if (event.target.matches('.accommodations-count')) {
            event.stopPropagation();
            const items = JSON.parse(event.target.getAttribute('data-items'));
            const modalContent = items.map(item => `<li>${item}</li>`).join('');
            document.getElementById('modal-content').innerHTML = `<ul>${modalContent}</ul>`;
            document.getElementById('modal').style.display = 'block';
        }
    });

    document.addEventListener('click', function (event) {
        if (event.target.matches('.modal-close')) {
            document.getElementById('modal').style.display = 'none';
        }
    });


    const modalHTML = `
    
    <div id='modal' class='modal' style='display: none;'>
    <div class='modal-content'>
        <div class='modal-header'>
            <h2 class='ml-3 pt-2 font-bold text-petroil-1'>Accommodations</h2>
            <div class='modal-close'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="modal-close svg-inline--fa fa-xmark fa-lg" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                </svg>
            </div>
        </div>
        <div id='modal-content' class='modal-body font-semibold text-petroil-1'></div>
    </div>
</div>
    `;

    document.body.insertAdjacentHTML('beforeend', modalHTML);


    return (
        <div className={`table-wrapper ${isTableDisabled ? 'table-disabled' : ''}`}>
            {((exportable && importable) || (exportable && !importable) || (!exportable && importable)) && (
                <Toolbar className="mb-4 p-5" right={rightToolbarTemplate}></Toolbar>
            )}
            <DataTable
                emptyMessage={t("NoAvailableOptions")}
                ref={dt}
                value={dataTableValue}
                selection={selectedItems}
                onSelectionChange={(e) => setSelectedItems(e.value)}
                dragSelection
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={t("PageReportTemplate")}
                header={header}
                selectionMode="multiple"
                globalFilter={searchTerm}
            >
                {!notDeletable && 
                
                <Column selectionMode="multiple" exportable={false}></Column>
                }
                {info.header.map((col) => (
                    <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={(rowData) => customBodyTemplate(rowData, col)} />
                ))}
                <Column body={ActionBodyTemplate} exportable={false} style={{ minWidth: '2rem' }} />
            </DataTable>
            {filterModal && (
                <Modal
                    isOpen={filterModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Filter"
                    className="custom-filter-modal"
                >
                    <div className="flex justify-between pb-3 pl-[1vw]">
                        <div className="align-items-center">
                            <h4>{t("FilterOptions")}</h4>
                        </div>
                        <div className=''>
                            <Button
                                id={"closeModal"}
                                className={"pr-[1vw]"}
                                onClick={closeModal}
                                content={<FontAwesomeIcon icon={faTimes} color='grey' size='lg' />}
                                isDisabled={false}
                            />
                        </div>
                    </div>
                    <hr className='ml-5 mr-7 mb-6'></hr>
                    <FilterComponent
                        typeFilter={info.name}
                        values={dataTableValue}
                        onComplete={(values) => {
                            setDataTableValue([...values]);
                        }}
                    />

                </Modal>
            )}
            {viewItemDialog && (
                <Modal
                    isOpen={viewItemDialog}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            width: "25%",
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            padding: "0px"
                        }
                    }}
                >
                    <div className="font-bold text-petroil-1 flex justify-between bg-input pl-6 pr-4 pt-4 pb-2">
                        <h2>{t(info.name)}</h2>
                        <div className="cursor-pointer" onClick={closeModal}>
                            <FontAwesomeIcon icon={faTimes} size='lg' />
                        </div>
                    </div>
                    <div>
                        <div className='pt-8'>
                            {Object.entries(selectedItem).map(([key, value], index) => {
                                const dataToRender = renderDetail(key, value);

                                return (

                                    <>
                                        {index % 3 === 0 && index !== 0 && <hr className='mx-8 my-4' />}
                                        {dataToRender && (
                                            <li className='px-7 font-semibold text-petroil-1' key={index}>
                                                {dataToRender}
                                            </li>
                                        )}
                                    </>
                                )
                            }
                            )}


                            <div className='flex justify-center pb-8 pt-6'>
                                <NavLink
                                    className={classNames(
                                        'px-4 btn btn-primary bg-petroil rounded-lg p-3 mr-2 text-white hover:bg-petroil/90'
                                    )}
                                    to={"edit-" + info.urlCreateUpdate}
                                    state={{ itemState: selectedItem, isEditable: true }}
                                >
                                    {t("Edit")} {t(info.name)}
                                </NavLink>
                            </div>
                        </div>

                    </div>
                </Modal>
            )}
            {deleteCollectionDialog && (
                <Modal
                    isOpen={deleteCollectionDialog}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            width: "20%",
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            border: '1px solid red'
                        }
                    }}
                >

                    <div className="flex justify-between">
                        <div></div>
                        <div className='mt-2 ml-4'><AlertTriangle size={32} color='red' /></div>
                        <div className="">
                            <Button
                                id={"closeModal"}
                                className={"btn text-petroil-1"}
                                onClick={closeModal}
                                content={<FontAwesomeIcon icon={faTimes} size='lg' />}
                                isDisabled={false}
                            />
                        </div>

                    </div>
                    <div className='d-flex flex-column text-center h-100'>
                        <h4 className='pb-2 mt-3 text-petroil-1 font-semibold'>{selectedItems.length > 1 ? t("DeleteQuestionMulti") : t("DeleteQuestionSingle")}</h4>
                        <p className="text-warning font-normal text-petroil-0 mb-4 "><small>{t("DeleteQuestionParagraph")}</small></p>
                        {selectedItems.length > 1 && (
                            <p className="text-warning"><small>{selectedItems.length > 1 ? `(${selectedItems.length} ${t("Items")})` : ""}</small></p>
                        )}
                        <div className="d-flex justify-content-around mt-auto">

                            {!notDeletable && selectedItemId && selectedItems.length == 0 ? (
                                <Button
                                    id={"deleteSelectedItems"}
                                    className={"btn btn-danger font-bold text-petroil-1"}
                                    onClick={() => deleteData(info.name, context.idOrganization, selectedItemId).then(context.updateData(info.urlUpdateData)).then(() => closeModal())}
                                    content={t("DeleteItemsConfirmationModal")}
                                    isDisabled={false}
                                />

                            ) : (
                                <Button
                                    id={"deleteSelectedItems"}
                                    className={"btn btn-danger font-bold text-petroil-1"}
                                    onClick={() => deleteMultipleData(info.name, context.idOrganization, (selectedItems && selectedItems.map((item) => item.id))).then(context.updateData(info.urlUpdateData)).then(() => closeModal())}
                                    content={t("DeleteItemsConfirmationModal")}
                                    isDisabled={false}
                                />

                            )}
                        </div>
                    </div>
                </Modal>
            )}
            {completedImportModal && (
                <Modal
                isOpen={completedImportModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        width: "40%",
                        minHeight: "40%",
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column', 
                    }
                }}
            >
                <div className="flex-grow">
                    <h1 className="text-2xl font-bold text-blue mb-5">{t("ResultsImport")}</h1>
            
                    {completedImportMessages.countOk > 0 && 
                    <p className='mb-3 text-[#03c03c]'>{t("SuccessfullyAddedElements")}: {completedImportMessages.countOk}</p>}
            
                    {completedImportMessages.errors.length > 0 && 
                    <TableSmall
                        info={errorImportTableStructure.info}
                    />
                    }
            
                    {completedImportMessages.countOk === 0 && 
                        <p className='mb-3 text-blue'>{t("NoAddedElements")}</p>
                    }
                </div>
            
                <div className="flex justify-between mt-5">
                    <PrimaryButton
                        className={'w-fit p-2 min-w-[7vw]'}
                        content={t("AddAgain")}
                        onClick={() => {
                            setCompletedImportModal(false);
                        }}
                    />
                    <PrimaryButton
                        className={'w-fit p-2 min-w-[7vw]'}
                        content={t("Close")}
                        onClick={() => {
                            setCompletedImportModal(false);
                            setImportModal(false);
                        }}
                    />
                </div>
            </Modal>
            
            )}
        </div>
    );
}

export default Table;