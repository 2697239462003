import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryButton from "../../../components/buttons/primary-button";
import { PasswordResetDialog } from "../../../components/dialogs/presets/passwordResetDialog";
import { EmailField } from "../../../components/input/fields/email-filed";
import TextField from "../../../components/input/fields/text-field";
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { i18n, t } = useTranslation();
  const auth = getAuth();
  const navigator = useNavigate();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const submit = () => {
    if (!(email && password)) {
      toast.error("Empty fields");
      return;
    }
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error("Invalid email field");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        toast.success("Login success");
        navigator("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        switch (errorCode) {
          case "auth/user-not-found":
            toast.error("User not registered");
            break;
          case "auth/wrong-password":
            toast.error("Invalid credentials");
            break;
          case "auth/too-many-requests":
            toast.error("Too many request, try again in a bit");
            break;
          default:
            toast.error("Something went wrong:" + errorMessage);
            break;
        }
      });
  };
  return (
    <div className="flex flex-col items-center w-full mt-10 text-2xl text-blue p-12 pb-4 container-login">
      <div className="">
        <div className="flex flex-col items-center justify-center w-full text-petroil mb-10">
          <img
            className="w-full h-auto logo-login mb-6"
            alt="Bilemon logo"
            src="/Logo-Petroil.png"
          />
          <p className="mt-4 text-xl text-center font-bold">
            {t("AccountWelcome")}
          </p>
          <p className="text-xl mb-2text-center font-bold">
            {t("AccountRequest")}
          </p>
        </div>

        <div className="flex flex-col items-center  container-fields">
          <EmailField
            onChange={(e) => setEmail(e.target.value)}
            label={t("Email") + " *"}
            placeholder={t("EmailPlaceholder")}
            inputClassName={"p-3 bg-input "}
            labelClassName={""}
          />
          <div className="w-full">
            <div className="relative w-full mt-6">
              <TextField
                type={isPasswordVisible ? "text" : "password"}
                label={t("Password") + " *"}
                placeholder={t("PasswordPlaceholder")}
                className={""}
                inputClassName={"p-3 bg-input"}
                labelClassName={""}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                isPasswordVisible={isPasswordVisible}
                togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
                showPasswordToggle={true}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center mt-6">
          <PrimaryButton
            className={"flex px-8 py-3  items-center justify-center space-x-3 login-button"}
            content={t("Login")}
            onClick={submit}
          />
        </div>

        <div className="flex items-center mb-[6vh] justify-center my-1 mt-4 text-petroil">
          <PasswordResetDialog />
        </div>

      
      </div>

      <div className="flex flex-col items-center justify-center mt-[16vh] mb-4 space-y-2 container-footer-login">
          <div className="flex items-center w-full">
            <hr className="flex-grow border-t border-[rgb(10,109,103)]" />
            <p className="mx-4 text-center text-lg text-blue font-semibold">
              {t("AccountQuestion")}
              <br />
              <Link className="hover:underline  text-blue font-semibold mx-[10vw]" to="/signup">
                {t("Signup")}
              </Link>
            </p>
            <hr className="flex-grow border-t border-[rgb(10,109,103)]" />
          </div>
        </div>


      <div className="w-full ml-20 pl-10 text-base font-semibold text-left">
        <p>©BiLemon {currentYear}</p>
      </div>
    </div>

  );
};
