import React, { useState } from 'react';
import TextField from './text-field';
import { useTranslation } from "react-i18next";
import { IoEye, IoEyeOff } from 'react-icons/io5';

export const PasswordFieldProgress = ({
  id,
  inputClassName,
  labelClassName,
  onFocusEffect,
  onClick,
  onChange,
  onMouseOver,
  label,
  value,
  className,
  type
}) => {
  const [passwordStrength, setPasswordStrength] = useState('Invalid');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { i18n, t } = useTranslation();

  const evaluatePasswordStrength = (password) => {
    const weakRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const averageRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{9,}$/;
    const strongRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{10,}$/;
    const excellentRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{12,}$/;

    if (excellentRegex.test(password)) {
      return 'Excellent';
    } else if (strongRegex.test(password)) {
      return 'Strong';
    } else if (averageRegex.test(password)) {
      return 'Average';
    } else if (weakRegex.test(password)) {
      return 'Weak';
    } else {
      return 'Invalid';
    }
  };

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    setPasswordStrength(evaluatePasswordStrength(newValue));
    if (onChange) {
      onChange(e);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const getProgressBarClasses = () => {
    switch (passwordStrength) {
      case 'Weak':
        return 'w-1/4 bg-[#dc2626]';
      case 'Average':
        return 'w-1/2 bg-[#facc15]';
      case 'Strong':
        return 'w-3/4 bg-[#0ea5e9]';
      case 'Excellent':
        return 'w-full bg-[#22c55e]';
      default:
        return 'w-0 bg-gray';
    }
  };

  const getTextColorClasses = () => {
    switch (passwordStrength) {
      case 'Weak':
        return 'text-[#dc2626]';
      case 'Average':
        return 'text-[#facc15]';
      case 'Strong':
        return 'text-[#0ea5e9]';
      case 'Excellent':
        return 'text-[#22c55e]';
      default:
        return 'gray';
    }
  };

  return (
    <div className={`relative ${className}`}>
      <div className="flex justify-between mb-2">
        <label className={`ml-1 text-lg font-semibold text-blue w-full ${labelClassName}`}>{label}</label>
        <div className="flex items-center">
          <span className={`text-xs mr-4 capitalize ${getTextColorClasses()}`}>
            {t(`${passwordStrength}`)}
          </span>
          <div className="flex-grow rounded-xl h-1 bg-gray-300 w-16 ">
            <div className={`h-full rounded-xl ${getProgressBarClasses()}`}></div>
          </div>
        </div>
      </div>
      
      <div className="relative">
        <TextField
          value={value}
          id={id}
          inputClassName={inputClassName}
          placeholder={t("Insert")}
          type={isPasswordVisible ? "text" : "password"}
          labelClassName={labelClassName}
          onFocusEffect={onFocusEffect}
          onClick={onClick}
          onChange={handlePasswordChange}
          onMouseOver={onMouseOver}
          autoComplete="new-password"
        />
        <button
          className="absolute right-3 top-1/2 transform -translate-y-1/2"
          onClick={togglePasswordVisibility}
        >
          {isPasswordVisible ? <IoEyeOff className="text-base text-petroil" /> : <IoEye className="text-base text-grey" />}
        </button>
      </div>
      
    </div>
  );
};
