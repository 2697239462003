import React, { useContext, useState } from "react";
import { useLocation } from 'react-router-dom';

import { ManageCostCenter } from "./ManageCostCenter";

export const EditCostCenter = () => {
  const location = useLocation();
  const { itemState } = location.state;
  const { isEditable } = location.state;

  return (
    <div className={"md:p-4 pt-5 mx-auto"}>
      <ManageCostCenter data={itemState} isEditable={isEditable} />
    </div>
  );
};
