import React, { useContext, useState } from "react";
import { useLocation } from 'react-router-dom';

import { ManageSupplier } from "./ManageSupplier";

export const EditSupplier = () => {
  const location = useLocation();
  const { itemState } = location.state;
  const { isEditable } = location.state;

  return (
    <div className={"md:p-4 pt-5 mx-auto"}>
          
          <ManageSupplier data={itemState} isEditable={isEditable} />
      </div>
  );
};
