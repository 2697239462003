import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {
  getCountryCallingCode,
  getCountries,
  isValidPhoneNumber
} from "react-phone-number-input";
import {
  getAuth
} from "firebase/auth";
import { DataContext } from "../../App";
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import SecondaryButton from "../../components/buttons/secondary-button";
import { toast } from "react-toastify";
import { updateOrganization } from "../../utils/functions";
import { GeneralOptions } from "../../resources/selectOptions";
import countryList from 'react-select-country-list';
import PrimaryButton from '../../components/buttons/primary-button';

const CompanyData = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const auth = getAuth();
  const context = useContext(DataContext)
const organization = context.organizationInfo;
const currencyOptions = GeneralOptions("currency");
  const [companyName, setCompanyName] = useState(organization && organization.companyName);
  const [id, setIdOrganization] = useState(context.idOrganization);

  const [street, setStreet] = useState(organization && organization.address && organization.address.street);
  const [city, setCity] = useState(organization && organization.address && organization.address.city);
  const [zipCode, setZipCode] = useState(organization && organization.address && organization.address.zipCode);
  const [country, setCountry] = useState(organization && organization.address && organization.address.country);
  const [email, setEmail] = useState(organization && organization.email);
  const [phone, setPhone] = useState(organization && organization.phoneNumber && organization.phoneNumber.phone);
  const [prefix, setPrefix] = useState(organization && organization.phoneNumber && organization.phoneNumber.prefix);
  const [currency, setCurrency] = useState(organization && organization.currency);
  const [optionsCallingCode, setOptionsCallingCode] = useState();
  const [optionsCountry, setOptionsCountry] = useState(useMemo(() => countryList().getData(), []));

  const checkFields = () => {
    if(!companyName){
      toast.error(t("MissingCompanyName"));
      return false;
    }

    if(!street){
      toast.error(t("MissingAddress"));
      return false;
    }

    if(!city){
      toast.error(t("MissingCity"));
      return false;
    }

    if(!zipCode){
      toast.error(t("MissingZipCode"));
      return false;
    }

    if(!country){
      toast.error(t("MissingCountry"));
      return false;
    }

    if(phone && prefix){
      const formattedPhoneNumber = prefix + phone.replace(/(\(|\))|\s|-/g, "");
      if (!isValidPhoneNumber(formattedPhoneNumber)) {
        toast.error("InvalidPhoneNumber");
        return false;
      }
    }

    return true;
  }

  const handleUpdate = () => {
    if(checkFields){
      updateOrganization(formValues).then(async () => {
        context.updateData("organization-info")
        context.updateData("user-info")
      });
      
    }
  }


  const [formValues, setFormValues] = useState({
    id: id,
    companyName: companyName,
    address: {
      city: city,
      zipCode: zipCode,
      country: country,
      street: street
    },
    email: email,
    phoneNumber: {
      phone: phone,
      prefix: prefix
    },
    currency: currency
  });

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  }

  useEffect(() => {
    const prefixes = getCountries().map((countryCode) => `+${getCountryCallingCode(countryCode)}`);
    const uniquePrefixes = removeDuplicates(prefixes);
    setOptionsCallingCode(uniquePrefixes.map((prefix) => ({ label: prefix, value: prefix })));
  }, []);

  return (
    
    <div className='content-company md:ml-5'>
          <h2 className="text-xl ml-1 font-semibold mt-10 md:mt-0">{t("EditCompanyProfile")}</h2>
          <div className='mt-5 md:mt-20 billing-container grid grid-cols-12 gap-x-6 md:gap-x-7'>            
              <div className="col-span-12 md:col-span-6">
                <TextField
                  label={t("CompanyName") + " *"}
                  className={"md:max-w-xs md:mr-10 ml-1 min-w-[27vw] mb-3"}
                  inputClassName={"p-3"}
                  placeholder={t("Insert")}
                  defaultValue={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                    setFormValues(prevValues => ({
                      ...prevValues,
                      companyName: e.target.value
                    }))
                  }}
                />
              </div>
              <div className="col-span-12 xl:col-span-6">
                <TextField
                  label={t("Street") + " *"}
                  className={"md:max-w-xs md:mr-10 min-w-[27vw] mb-3"}
                  inputClassName={"p-3"}
                  placeholder={t("Insert")}
                  defaultValue={street}
                  onChange={(e) => {
                    setStreet(e.target.value);
                    setFormValues(prevValues => ({
                      ...prevValues,
                      address: {...prevValues, street: e.target.value }
                    }))
                  }}
                />
              </div>
                <div className="col-span-12 xl:col-span-6">
                  <TextField
                    label={t("City") + " *"}
                    className={"md:max-w-xs md:mr-10 xl:ml-1 min-w-[27vw] mb-3"}
                    inputClassName={"p-3"}
                    placeholder={t("Insert")}
                    defaultValue={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setFormValues(prevValues => ({
                        ...prevValues,
                        address: {...prevValues, city: e.target.value }
                      }))
                    }}
                  />
                </div>
            
              <div className="col-span-12 md:col-span-6">
                
                  <div className="grid grid-cols-12 md:gap-x-2">
                    <div className="col-span-4 md:col-span-3">
                      <TextField
                        label={t("ZipCode") + " *"}
                        className={"md:max-w-[8vw] mb-3"}
                        inputClassName={"p-3"}
                        placeholder={t("Insert")}
                        defaultValue={zipCode}
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          setFormValues(prevValues => ({
                            ...prevValues,
                            address: {...prevValues, zipCode: e.target.value }
                          }))
                        }}
                      />
                    </div>
                    <div className="ml-2 col-span-8">
                      <SelectMenu
                        label={t("State") + " *"}
                        placeholder={t("Select")}
                        className={"mb-3 md:ml-4 mt-0 "}
                        inputClassName={"p-1"}
                        options={optionsCountry}
                        onChange={(item) => {
                          setCountry(item.label);
                          setFormValues(prevValues => ({
                            ...prevValues,
                            address: {...prevValues, country: item.label }
                          }))
                        }}
                        value={country && optionsCountry ? optionsCountry.find((x) => x.label === country) : country}
                      />
                    </div>
                  </div>
                
              </div>
              <div className="col-span-12 xl:col-span-6">
                <div className="grid grid-cols-12 md:gap-x-2">
                  <div className="col-span-4 xl:col-span-3">
                    <SelectMenu
                      label={t("Prefix") + " *"}
                      placeholder={t("Select")}
                      className={"xl:mr-4 mb-3 mt-1"}
                      inputClassName={"p-1"}
                      options={optionsCallingCode}
                      onChange={(item) => {
                        setPrefix(item.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          phoneNumber: {...prevValues, prefix: item.value}
                        }))
                      }}
                      value={prefix && optionsCallingCode ? optionsCallingCode.find((x) => x.value === prefix) : prefix}
                    />
                  </div>
                  <div className="col-span-8">
                    <TextField
                      label={t("PhoneNumber") + " *"}
                      inputClassName={"p-3 "}
                      className={"mt-1 mb-3 pl-2 md:ml-4 md:min-w-[16vw]"}
                      placeholder={t("Insert")}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          phoneNumber: {...prevValues, phone: e.target.value}
                        }))
                      }}
                      defaultValue={phone}
                    />
                  </div>
                </div>
              </div>
                <div className="col-span-12 xl:col-span-6">
                  <TextField
                    label={t("CommercialEmail")}
                    inputClassName={"p-3"}
                    className={"mt-1 mb-3 xl:max-w-[27vw]"}
                    placeholder={t("Insert")}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        email: e.target.value
                      }))
                    }}
                    defaultValue={email}
                  />
                </div>
                  <div className="xl:col-span-2 col-span-12">
                    <SelectMenu
                      label={t("Currency") + " *"}
                      placeholder={t("Select")}
                      className={"mr-4 mb-3 "}
                      inputClassName={"p-1"}
                      options={currencyOptions}
                      onChange={(item) => {
                        setCurrency(item.value);
                        setFormValues(prevValues => ({
                          ...prevValues,
                          currency: item.value
                        }))
                      }}
                      value={currency && currencyOptions ? currencyOptions.find((x) => x.value === currency) : currency}
                    />
                  </div>
          </div>
            <div className="flex justify-end">
              <PrimaryButton
                content={t("Save")}
                className={"mt-2 p-3 bg-blue w-[7vw] md:mr-8 mb-6"}
                onClick={() => {
                  handleUpdate();
                }}
              />
            </div>
    </div>
  );
};

export default CompanyData;
