import React, { useContext } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";

import Table from "../../components/table/table";

export const Accommodations = () => {
  const context = useContext(DataContext);
  const accommodations = context.accommodations;
  const organizationInfo = context.organizationInfo;
  const symbol = organizationInfo && organizationInfo.currency;
  const { i18n, t } = useTranslation();

  const accommodationTableStructure = {
    info: {
      urlUpdateData: "accommodations",
      urlCreateUpdate: "accommodation",
      name: "Accommodation",
      header: [
        {
          field: "name",
          header: t("Name"),
          sortable: true,
          type: "Text",
          show: false,
        },
        {
          field: "contractTypology",
          header: t("ContractTypology"),
          sortable: true,
          type: "Text",
          show: false,
        },
        {
          field: "idOwner",
          header: t("Owner"),
          sortable: true,
          type: "Text",
          show: false,
        },
        {
          field: "address.city",
          header: t("City"),
          sortable: true,
          type: "Text",
          show: false,
        },
        {
          field: "basePrice",
          header: t("BasePrice"),
          sortable: true,
          type: "Number",
          show: false,
          symbol: symbol
        },
        {
          field: "extraServiceFee",
          header: t("ExtraServiceFee"),
          sortable: true,
          type: "Number",
          show: false,
          symbol: symbol
        },
        {
          field: "status",
          header: t("Status"),
          sortable: false,
          type: "Text",
          show: false,
        },
      ],
      body: accommodations
    },

    exportable: true,
    importable: true,
    filterType: "filter"
  }

  return (

    <div className="w-full mt-8">
      <div className="content">

        <div className="">
          <Table
            info={accommodationTableStructure.info}
            exportable={accommodationTableStructure.exportable}
            importable={accommodationTableStructure.importable}
            filterType={accommodationTableStructure.filterType}
            notDeletable={true}
          />
        </div>
      </div>

    </div>

  );
};
