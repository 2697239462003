import { useState, useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { create, update } from "../../../utils/functions";
import ModalCreate from "../../../components/dialogs/dialogCreate";
import { FaCheck } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { SelectMenu } from "../../../components/input/select/select-menu";
import { CurrencyField } from "../../../components/input/fields/currency-field";
import { NewDatePicker } from "../../../components/input/fields/date-pickers/newDatePicker";
import { UnitFieldSymbols } from "../../../components/input/fields/unit-field-with-symbols";
import { NewSupplier } from "../../../components/modals/new-supplier";
import { getTime } from "date-fns";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from "../../../App";
import { toast } from "react-toastify";
import { NewCategoryForm } from "../../../components/modals/new-category";
import { GeneralOptions } from "../../../resources/selectOptions";
import PrimaryButton from "../../../components/buttons/primary-button";
import { NewSubCategoryForm } from "../../../components/modals/new-subCategory";

const ProceedsStep = ({ onComplete, onGoBack, values }) => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [idAccommodations, setIdAccommodations] = useState((values && values.idAccommodations) ?? null);

  const paymentTypeOptions = GeneralOptions("paymentType");
  const triggerOptions = GeneralOptions("costDate");
  const bookingCategoryOptions = context.costCategories.filter((item) => item.typology === "booking").map((item) => {
    return { label: item.category, value: item.id, subCategory: item.subCategory };
  });
  const costCenterOptions = context.costCenters.map((item) => {
    return { label: item.name, value: item.id };
  });
  const [commissionChannelSel, setCommissionChannelSel] = useState((values && values.channel && values.channel.commissionChannel) ?? null);
  const [costCenter, setCostCenter] = useState((values && values.costCenter) ? costCenterOptions.find(costCenter => costCenter.label === values.costCenter) : null);
  const initialCategory = bookingCategoryOptions.find(option => option.label === values.category) || null;
  const [category, setCategory] = useState(initialCategory);
  const [idSupplier, setIdSupplier] = useState((values && values.idSupplier) ?? null);
  const [startDate, setStartDate] = useState((values && values.startDate) ?? null);
  const [modal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [trigger, setTrigger] = useState((values && triggerOptions.find((item) => item.value === values.trigger)) ?? null);
  const [amount, setAmount] = useState((values && values.amount) ?? null);
  const [amountPrePayment, setAmountPrePayment] = useState((values && values.amountPrePayment) ?? null);
  const [amountBalanceDue, setAmountBalanceDue] = useState((values && values.amountBalanceDue) ?? null);
  const [paymentType, setPaymentType] = useState((values && paymentTypeOptions.find((item) => item.value === values.paymentType)) ?? null);
  const [paymentTypePrePayment, setPaymentTypePrePayment] = useState((values && paymentTypeOptions.find((item) => item.value === values.paymentTypePrePayment)) ?? null);
  const [paymentTypeBalanceDue, setPaymentTypeBalanceDue] = useState((values && paymentTypeOptions.find((item) => item.value === values.paymentTypeBalanceDue)) ?? null);
  const [subCategory, setSubCategory] = useState((values && values.subCategory) ?? null);
  const [endDate, setEndDate] = useState((values && values.endDate) ?? null);
  const [recurrencyCustom, setRecurrencyCustom] = useState((values && values.recurrencyCustom) ?? null);
  const [recurrencyCustomPrePayment, setRecurrencyCustomPrePayment] = useState((values && values.recurrencyCustomPrePayment) ?? null);
  const [recurrencyCustomPrePaymentDays, setRecurrencyCustomPrePaymentDays] = useState((values && values.recurrencyCustomPrePaymentDays) ?? null);
  const [prePaymentTrigger, setPrePaymentTrigger] = useState((values && values.prePaymentTrigger) ?? null);
  const [balanceDueTrigger, setBalanceDueTrigger] = useState((values && values.balanceDueTrigger) ?? null);
  const [recurrencyCustomBalanceDue, setRecurrencyCustomBalanceDue] = useState((values && values.recurrencyCustomBalanceDue) ?? null);
  const [recurrency, setRecurrency] = useState((values && values.recurrency) ?? null);
  const [recurrencyPrePayment, setRecurrencyPrePayment] = useState((values && values.recurrencyPrePayment) ?? null);
  const [recurrencyBalanceDue, setRecurrencyBalanceDue] = useState((values && values.recurrencyBalanceDue) ?? null);
  const [formValues, setFormValues] = useState({
    name: values.name,
    type: values.type,
    costCenter: costCenter,
    category: category,
    subCategory: subCategory,
    idAccommodations: idAccommodations,
    idSupplier: idSupplier,
    paymentType: paymentType,
    amount: amount,
    startDate: startDate,
    endDate: endDate,
    trigger: trigger,
    prePayment: {
      paymentType: paymentTypePrePayment, 
      amount: amountPrePayment,
      recurrency: recurrencyCustomPrePayment, 
      trigger: prePaymentTrigger, 
      numberDays: recurrencyCustomPrePaymentDays 
    },
    balanceDue: {
      paymentType: paymentTypeBalanceDue, 
      amount: amountBalanceDue,
      recurrency: recurrencyBalanceDue, 
      trigger: balanceDueTrigger, 
      numberDays: recurrencyCustomBalanceDue 
    }
  });


  const accommodationsOptions = context.accommodations &&
    context.accommodations.map((item) => {
      return { label: item.name, value: item.id };
    });

  const paymentTriggersOptions = GeneralOptions("paymentDateTriggers");
  const recurrencyOptions = GeneralOptions("paymentDate");
  const whenOptions = GeneralOptions("when");

  const BookingChannelOptions = context.otas ? context.otas.map(x => { return { label: x.name, value: x.id } }) : [];


  let subCategoryOptions = category && category.subCategory && Array.isArray(category.subCategory) ? category.subCategory.map((item) => {
    return { label: item, value: item }
  }) : [];

  useEffect(() => {
    subCategoryOptions = category && category.subCategory && Array.isArray(category.subCategory) ? category.subCategory.map((item) => {
      return { label: item, value: item }
    }) : [];
  }, [category])

  const onCloseModal = () => {
    setOpenModal(false)
  };

  const checkValues = () => {

    if (!formValues.idAccommodations) {
      toast.error(t("AccommodationIsRequired"));
      return false;
    }

    if (!formValues.category) {
      toast.error(t("CategoryIsRequired"));
      return false;
    }

    if (!formValues.startDate) {
      toast.error(t("StartDateIsRequired"));
      return false;
    }

    if (!formValues.endDate) {
      toast.error(t("EndDateIsRequired"));
      return false;
    }

    if (!formValues.paymentType) {
      toast.error(t("PaymentTypeIsRequired"));
      return false;
    }

    if (!formValues.trigger) {
      toast.error(t("TriggerIsRequired"));
      return false;
    }

    if (!formValues.amount) {
      toast.error(t("AmountIsRequired"));
      return false;
    }


    if (values.name && values.type) {
      onComplete(formValues)
    }
    return true;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="text-petroil mb-24 w-full px-4 md:px-14">
          <div className="flex items-center justify-center text-petroil mb-12">
            <div className="">
              <div className="bg-white rounded-full items-center">
                <div className="flex justify-center mb-4 mt-8">
                  <FaCheck className="text-white bg-petroil rounded-full w-9 h-9 p-1" />
                </div>
                <div className="">
                  <p className={`font-bold text-center text-base text-petroil max-w-[100px] min-h-[45px]`}>{t("Typology")}</p>
                </div>
              </div>
            </div>
            <div className="h-[2px] w-[100px] md:w-[200px] bg-blue mb-8"></div>
            <div className="text-center items-center justify-center ml-2">

              <div className="flex justify-center mb-4 mt-8">
                <FaCheck className="text-white bg-petroil rounded-full w-9 h-9 p-1" />
              </div>
              <div className="">
                <p className={`font-bold text-center text-base text-petroil min-h-[45px] max-w-[100px]`}>{t("Details")}</p>
              </div>

            </div>
          </div>


          <div className="grid xl:grid-cols-2 gap-x-20 gap-y-2">

            <div className="">
              <SelectMenu
                label={t("Accommodations") + " *"}
                placeholder={t("SelectPlaceholder")}
                className={""}
                inputClassName={"p-1"}
                options={accommodationsOptions}
                isMulti={true}
                onChange={(item) => {
                  setIdAccommodations(item.value)
                  setFormValues(prevValues => ({
                    ...prevValues,
                    idAccommodations: item.value
                  }));
                }}
                value={idAccommodations}
              />
            </div>
            <div className="">
              <SelectMenu
                label={<div className="flex justify-between cursor-pointer">
                  <div>
                    {t("Category") + " *"}
                  </div>
                  <div
                    className="text-sm mt-1"
                    onClick={() => {
                      setOpenModal(true)
                      setModalType("Category")
                    }
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} size='sm' />
                    {t("AddCategory")}
                  </div>

                </div>
                }
                placeholder={t("SelectPlaceholder")}
                className={""}
                inputClassName={"p-1"}
                options={bookingCategoryOptions}
                onChange={(item) => {
                  setCategory(item);
                  setSubCategory(null);
                  setFormValues(prevValues => ({
                    ...prevValues,
                    category: item.label
                  }));
                }}
                defaultValue={category}
              />
            </div>
            <div className="">
              <SelectMenu
                label={<div className="flex justify-between cursor-pointer">
                  <div>
                    {t("SubCategory")}
                  </div>
                  <div
                    className="text-sm mt-1 text-right"
                    onClick={() => {
                      setOpenModal(true)
                      setModalType("SubCategory")
                    }
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} size='sm' />
                    {t("AddSubCategory")}
                  </div>

                </div>
                }
                placeholder={t("SelectPlaceholder")}
                className={""}
                inputClassName={"p-1"}
                options={subCategoryOptions}
                onChange={(item) => {
                  setSubCategory(item.value);
                  setFormValues(prevValues => ({
                    ...prevValues,
                    subCategory: item.value
                  }));
                }}
                value={subCategory && subCategoryOptions ? subCategoryOptions.find(x => x.label === subCategory.label) : null}
              />
            </div>
            <div className="">
              <SelectMenu
                label={t("CostCenter")}
                placeholder={t("SelectPlaceholder")}
                className={""}
                inputClassName={"p-1"}
                options={costCenterOptions}
                onChange={(item) => {
                  setCostCenter(item.label);
                  setFormValues(prevValues => ({
                    ...prevValues,
                    costCenter: item.label
                  }));
                }}
                defaultValue={costCenter}
              />
            </div>

            <div className="mt-1">
              <NewDatePicker
                label={t("StartDate") + " *"}
                defaultValue={
                  (startDate && getTime(new Date(startDate)))
                }
                onCompleted={(el) => {
                  setStartDate(el);
                  setFormValues(prevValues => ({
                    ...prevValues,
                    startDate: el
                  }));
                }}
              />
            </div>
            <div className="flex">
              <div className="w-full">
                <NewDatePicker
                  label={t("EndDate") + " *"}
                  defaultValue={
                    (endDate && getTime(new Date(endDate)))
                  }
                  onCompleted={(el) => {
                    setEndDate(el);
                    setFormValues(prevValues => ({
                      ...prevValues,
                      endDate: el
                    }));
                  }}
                />
              </div>
            </div>
            <div className="">
              <SelectMenu
                className={"md:mr-16"}
                label={t("BookingChannel")}
                isClearable
                defaultValue={commissionChannelSel && BookingChannelOptions ?
                  BookingChannelOptions.find((value) => {

                    if (commissionChannelSel === value.label) {
                      return value;
                    }
                  }) : commissionChannelSel
                }
                options={BookingChannelOptions}
                onChange={(el) => {
                  if (el) {
                    setCommissionChannelSel(el);
                    formValues.channel = {};
                    formValues.channel.commissionChannel = el.label;
                    formValues.channel.commissionType = el.value;
                  } else {
                    setCommissionChannelSel(null);
                    formValues.channel = null;
                  }
                }}
                inputClassName={"min-w-[250px] p-2"}

              />
            </div>

          </div>
          <hr className="mt-5 mb-5" />

          <h2 className="text-xl mb-4">{t("PrePayment")}</h2>

          <div className="flex flex-col md:flex-row">
            <SelectMenu
              label={t("PaymentType") + " *"}
              placeholder={t("SelectPlaceholder")}
              className={"mr-5"}
              inputClassName={"p-1"}
              options={paymentTypeOptions}
              onChange={(item) => {
                setPaymentTypePrePayment(item.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  prePayment: {
                    ...prevValues.prePayment,
                    paymentType: item.value
                  }
                }))
              }}
              defaultValue={paymentTypePrePayment}
            />
            <CurrencyField
              label={t("TotalAmount") + " *"}
              placeholder={"0"}
              className={"min-w-[10vw] mt-2 md:mt-0"}
              labelClassName={'mb-1'}
              currency={paymentTypePrePayment !== "percentage" ? (context.organizationInfo?.currency) : null}
              value={amountPrePayment}
              onChange={(el) => {
                const formattedValue = Number(el.target.value)
                setFormValues(prevValues => ({
                  ...prevValues,
                  prePayment: {
                    ...prevValues.prePayment,
                    amount: formattedValue
                  }
                }));
                setAmountPrePayment(formattedValue)
              }}
              inputClassName={"px-1 py-3 max-w-[100px]"}
            />

            <SelectMenu
              label={t("When") + " *"}
              placeholder={t("SelectPlaceholder")}
              className={"md:max-w-[8vw] mb-3 mr-4 m-0"}
              inputClassName={"p-1"}
              options={whenOptions}
              onChange={(item) => {
                setRecurrencyPrePayment(item.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  prePayment: {
                    ...prevValues.prePayment,
                    recurrency: item.value
                  }
                }));
                
              }}
              value={recurrencyPrePayment && recurrencyOptions ? recurrencyOptions.find(x => x.value === recurrencyPrePayment) : null}
            />
            
            <SelectMenu
              label={t("Trigger") + " *"}
              placeholder={t("SelectPlaceholder")}
              className={"md:ml-5 md:mr-5"}
              inputClassName={"p-1"}
              options={paymentTriggersOptions}
              onChange={(item) => {
                setPrePaymentTrigger(item.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  prePayment: {
                    ...prevValues.prePayment,
                    trigger: item.value
                  }
                }))
              }}
              defaultValue={prePaymentTrigger}
            />
            <UnitFieldSymbols
              label={t("NumberOfDays")}
              disabled={!recurrencyPrePayment || (recurrencyPrePayment === "equals")}
              className={""}
              inputClassName={"md:min-w-[35px] px-0"}
              value={recurrencyCustomPrePaymentDays}
              placeholder={"0"}
              onChange={(e) => {
                setRecurrencyCustomPrePaymentDays(e.target.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  prePayment: {
                    ...prevValues.prePayment,
                    numberDays: e.target.value
                  }
                }));
              }}
            />
          </div>

          <h2 className="text-xl mb-4">{t("BalanceDue")}</h2>

          <div className="flex flex-col md:flex-row">
            <SelectMenu
              label={t("PaymentType") + " *"}
              placeholder={t("SelectPlaceholder")}
              className={"mr-5"}
              inputClassName={"p-1"}
              options={paymentTypeOptions}
              onChange={(item) => {
                setPaymentTypeBalanceDue(item.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  balanceDue: {
                    ...prevValues.balanceDue,
                    paymentType: item.value
                  }
                }))
              }}
              defaultValue={paymentTypeBalanceDue}
            />
            <CurrencyField
              label={t("TotalAmount") + " *"}
              placeholder={"0"}
              className={"min-w-[10vw] mt-2 md:mt-0"}
              labelClassName={'mb-1'}
              currency={paymentTypeBalanceDue !== "percentage" ? (context.organizationInfo?.currency) : null}
              value={amountBalanceDue}
              onChange={(el) => {
                const formattedValue = Number(el.target.value)
                setFormValues(prevValues => ({
                  ...prevValues,
                  balanceDue: {
                    ...prevValues.balanceDue,
                    amount: formattedValue
                  }
                }));
                setAmountBalanceDue(formattedValue)
              }}
              inputClassName={"px-1 py-3 max-w-[100px]"}
            />

            <SelectMenu
              label={t("When") + " *"}
              placeholder={t("SelectPlaceholder")}
              className={"md:max-w-[8vw] mb-3 m-0 mr-4"}
              inputClassName={"p-1"}
              options={whenOptions}
              onChange={(item) => {
                setRecurrencyBalanceDue(item.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  balanceDue: {
                    ...prevValues.balanceDue,
                    recurrency: item.value
                  }
                }));
              }}
              value={recurrencyBalanceDue && recurrencyOptions ? recurrencyOptions.find(x => x.value === recurrencyBalanceDue) : null}
            />
            <SelectMenu
              label={t("Trigger") + " *"}
              placeholder={t("SelectPlaceholder")}
              className={"md:ml-5 md:mr-5"}
              inputClassName={"p-1"}
              options={paymentTriggersOptions}
              onChange={(item) => {
                setBalanceDueTrigger(item.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  balanceDue: {
                    ...prevValues.balanceDue,
                    trigger: item.value
                  }
                }))
              }}
              defaultValue={balanceDueTrigger}
            />
            <UnitFieldSymbols
              label={t("NumberOfDays")}
              disabled={!recurrencyBalanceDue || (recurrencyBalanceDue === "equals")}
              className={""}
              inputClassName={"md:min-w-[35px] px-0"}
              value={recurrencyCustomBalanceDue}
              placeholder={"0"}
              onChange={(e) => {
                setRecurrencyCustomBalanceDue(e.target.value);
                setFormValues(prevValues => ({
                  ...prevValues,
                  balanceDue: {
                    ...prevValues.balanceDue,
                    numberDays: e.target.value
                  }
                }));
              }}
            />
          </div>



          <div className="flex justify-between items-center mt-8">

            <div
              className={`font-bold cursor-pointer ${values.idAccommodations ? "opacity-60 pointer-events-none " : ""}`}
              onClick={() => { onGoBack(formValues) }}
            >
              <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
              {t("Previous")}
            </div>
            <PrimaryButton
              content={t("Save")}
              className={"p-3 bg-blue w-[7vw]"}
              onClick={() => {
                checkValues();
              }}
            />
          </div>
        </div>
      </div>
      {modalType === "Category" && <ModalCreate
        externalControl
        status={modal}
        body={
          <>
            <NewCategoryForm
              onCloseModal={onCloseModal}
              isDisabled={true}
              categoryData={{
                typology: 'booking',
                category: formValues.category ?? null,
                subCategory: null
              }}
              onComplete={(categoryData) => {
                create("CostCategory", context.idOrganization, categoryData)
                  .then(() => {
                    context.updateData("cost-categories");
                    setOpenModal(false);
                  })
                  .catch((err) => {
                    toast.error(t("ErrorOccurred") + err);
                  }
                  );
              }}
            />

          </>}
        title={t("NewCategory")}
        className={""}
        onClose={() => setOpenModal(false)}
      />
      }
      {modalType === "SubCategory" && <ModalCreate
        externalControl
        status={modal}
        body={
          <NewSubCategoryForm
            onCloseModal={onCloseModal}
            isDisabled={true}
            categoryData={{
              typology: 'booking',
              category: formValues.category ?? null,
              subCategory: subCategoryOptions
            }}
            onComplete={(categoryData) => {
              const existingCategory = context.costCategories.find(
                (cat) => cat.category === categoryData.category
              );
              if (existingCategory) {
                update("CostCategory", context.idOrganization, categoryData)
                  .then(() => {
                    context.updateData("cost-categories");
                    setOpenModal(false);
                  })
                  .catch((err) => {
                    toast.error(t("ErrorOccurred") + err);
                  });
              } else {
                create("CostCategory", context.idOrganization, categoryData)
                  .then(() => {
                    context.updateData("cost-categories");
                    setOpenModal(false);
                  })
                  .catch((err) => {
                    toast.error(t("ErrorOccurred") + err);
                  }
                  );
              }
            }}
          />
        }
        title={t("NewSubCategory")}
        className={""}
        onClose={() => setOpenModal(false)}
      />}
      {modalType === "Supplier" && <ModalCreate
        externalControl
        status={modal}
        body={
          <NewSupplier
            onCloseModal={onCloseModal}
            onComplete={(supplier) => {
              create("Supplier", context.idOrganization, supplier)
                .then(() => {
                  context.updateData("supplier");
                  setOpenModal(false);
                })
                .catch((err) => {
                  toast.error(t("ErrorOccurred") + err);
                }
                );
            }}
          />
        }
        title={t("NewSupplier")}
        className={""}
        onClose={() => setOpenModal(false)}
      />}
    </>
  );
};

export default ProceedsStep;