import { useState } from "react";
import { SubscriptionCard } from "../../../../components/buttons/subscription-card";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/buttons/secondary-button"
import { toast } from "react-toastify";

const SubscriptionStep = ({ onComplete, formValues, onBack }) => {
  const { t } = useTranslation();
  // Change here if you need to change price

  const descriptionHost = t("DescriptionHost");
  const descriptionPropertyManager = t("DescriptionPropertyManager");
  const amountHost = 25.99;
  const amountPropertyManager = 32.45;
  const amountExtraProperty = 6.49;

  const [selectedPlan, setSelectedPlan] = useState(formValues.subscription?.plan ?? "");
  const [subscription, setSubscription] = useState(formValues.subscription);

  const handleCardClick = (plan, formValues) => {
    setSelectedPlan(plan);
    setSubscription(formValues);
  };

  const submit = () => {
    if (!subscription) {
      toast.error(t("ChoosePlan"));
      return;
    }
    onComplete({ subscription: subscription })
  }

  return (
    <div className="big-screen:ml-[10rem] mt-10 container-subscription-plan">
      <div className="flex flex-col w-full my-1 space-y-2 container-subplans">
        <h2 className="text-lg font-bold text-blue big-screen:ml-20 ml-10"> {t("SubscriptionTitle")} </h2>
        <p className="text-sm font-semibold text-blue big-screen:ml-20 ml-10"> {t("SubscriptionSelectText")} </p>
        <div className="flex justify-center container-cards">
          <div className="pt-12 px-12 pb-2 padding-0-responsive">
            <SubscriptionCard
              numberProperty={4}
              amount={amountHost}
              plan={"host"}
              description={descriptionHost}
              onClick={() => handleCardClick('host', {
                expired: new Date(),
                properties: 0,
                plan: "host",
                amount: amountHost,
                recurrency: "monthly",
                amountExtra: 0,
                propertiesExtra: 0,
              })}
              isSelected={selectedPlan === 'host'}
            />
          </div>
          <div className="pt-12 px-12 pb-2 padding-0-responsive">
            <SubscriptionCard
              numberProperty={5}
              amount={amountPropertyManager}
              plan={"propertyManager"}
              description={descriptionPropertyManager}
              amountExtra={amountExtraProperty}
              onClick={() => handleCardClick('propertyManager', {
                expired: new Date(),
                properties: 0,
                plan: "propertyManager",
                amount: amountPropertyManager,
                recurrency: "monthly",
                amountExtra: amountExtraProperty,
                propertiesExtra: 0,
              })}
              isSelected={selectedPlan === 'propertyManager'}
            />
          </div>
        </div>

      </div>
        <div className="flex justify-between align-center items-center w-full pt-6 px-6 big-screen:px-0">
          <button
            className="flex items-center bg-transparent border-none cursor-pointer p-2 text-sm"
            onClick={() => onBack(2)}>
            {"< " + t("Previous")}
          </button>

          <div className="flex justify-end ">
            <SecondaryButton
              content={t("Next") + " >"}
              className="p-2 w-[5vw]"
              onClick={submit}
            />
          </div>

        </div>
    </div>
  );
};

export default SubscriptionStep;
