import React, { useRef, useState, useImperativeHandle, forwardRef } from "react";
import { HiOutlineCalendar } from "react-icons/hi";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "./style.css";
import { useTranslation } from "react-i18next";

export const NewDatePicker = forwardRef(({
  inputClassName,
  className,
  defaultValue,
  placeholder,
  label,
  labelClassName,
  onClick,
  menu,
  onCompleted,
  isFilter,
  isRange 
}, ref) => {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : (isRange ? { from: null, to: null } : null));
  const [isOpen, setIsOpen] = useState(false);
  const innerRef = useRef(null);

  const { i18n, t } = useTranslation();
  useImperativeHandle(ref, () => ({
    clearDate: () => {
      setSelected(isRange ? { from: null, to: null } : null);
    }
  }));

  let footer = <p>{t("PickDates")}</p>;
  if (selected) {
    if (isRange && selected.from && selected.to) {
      footer = <p>{`${format(selected.from, "dd-MM-yyyy")} - ${format(selected.to, "dd-MM-yyyy")}`}</p>;
    } else if (!isRange && selected) {
      footer = <p>{format(selected, "dd-MM-yyyy")}</p>;
    }
  }

  const clickOutside = (e) => {
    if (innerRef.current && isOpen && !innerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  document.addEventListener("mousedown", clickOutside);

  return (
    <div
      ref={innerRef}
      className={className + " flex flex-col items-start justify-start w-full "}
    >
      {label && (
        <label className={labelClassName + " text-lg font-semibold text-blue w-full"}>
          {label}
        </label>
      )}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={
          inputClassName + 
          " " + (isFilter ? " bg-white border-slate-300 border-solid border-[1px] py-1 px-3 " : " bg-input py-3 px-2 ") +
          "w-full h-fit my-1 text-base rounded-md outline-none indent-2 text-blue text-left min-h-[25px] flex items-center"
        }
      >
        <HiOutlineCalendar className="ml-1 text-xl" />
        {selected ? footer : (placeholder || t("SelectDate"))}
      </button>
      {isOpen && (
        <div className="custom-calendar">
          <DayPicker
            mode={isRange ? "range" : "single"}
            selected={selected}
            onSelect={(date) => {
              setSelected(date);              
              if(!isRange) {
                onCompleted && onCompleted(date);
                setIsOpen(false);
              } else if (date?.from && date?.to) {
                setIsOpen(false);
                onCompleted && onCompleted(date);
              }
             
              
            }}
          />
        </div>
      )}
    </div>
  );
});

export default NewDatePicker;
