import { React, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import PrimaryButton from "../buttons/primary-button";
import TertiaryButton from "../buttons/tertiary-button";
import TextField from "../input/fields/text-field";
import { useTranslation } from "react-i18next";
import { SelectMenu } from "../../components/input/select/select-menu";
import { GeneralOptions } from "../../resources/selectOptions";

export const NewCategoryForm = ({ onComplete, categoryData, onCloseModal, isDisabled }) => {
  const { i18n, t } = useTranslation();
  const [typology, setTypology] = useState(categoryData ? categoryData.typology : '');
  const [category, setCategory] = useState('');

  const costTypologyOptions = GeneralOptions("costTypology");
  const checkValues = () => {
    if (!category.trim()) {
      setCategory('');
      toast.error(t("CategoryIsRequired"));
      return false;
    }    
    return true;
  };
  return (
    <div className="flex flex-col justify-start w-full">

        <SelectMenu
          label={t("Typology")}
          isDisabled={isDisabled}
          className={isDisabled ? "cursor-not-allowed"  : ""}

          isSearchable={true}
          options={costTypologyOptions}
          defaultValue={costTypologyOptions && typology ? costTypologyOptions.find((item) => {
            if (typology === item.value) {
              return item;
            }
          }) : typology}
          placeholder={t("Select")}
          onChange={(value) => {
            setTypology(value.value)
          }}
        /> 
        <TextField
          label={t("Category")}
          defaultValue={category}
          placeholder={t("Select")}
          inputClassName={"p-3 w-fit"}
          className={"mb-3"}
          onChange={(e) => setCategory(e.target.value)}
        />
        
        
      
      <div className="flex justify-end mt-4">
      <PrimaryButton
          className={"w-fit p-2 w-[7vw]"}
          content={t("Add")}
          onClick={() => {
            if (checkValues()) {
              onComplete({
                typology: typology,
                category: category
              });
            }
          }}
        />
      </div>
    </div>
  );
};
