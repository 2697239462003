import { useState, useMemo } from "react";
import TextField from "../../../../components/input/fields/text-field";
import { SelectMenu } from "../../../../components/input/select/select-menu";
import { useTranslation } from "react-i18next";
import { GeneralOptions } from "../../../../resources/selectOptions";
import countryList from 'react-select-country-list';
import { toast } from "react-toastify";
import SecondaryButton from "../../../../components/buttons/secondary-button";

const CompanyStep = ({ onComplete, formValues, onBack }) => {
  const { i18n, t } = useTranslation();

  const optionsLanguage = GeneralOptions("language");
  const [optionsCountry, setOptionsCountry] = useState(useMemo(() => countryList().getData(), []));

  const [language, setLanguage] = useState(formValues.language);
  const [companyName, setCompanyName] = useState(formValues.companyName);
  const [country, setCountry] = useState(formValues.address.country);

  const submit = () => {

    if (!(companyName)) {
      toast.error(t("CompanyNameIsRequired"));
      return;
    }

    if (!(country)) {
      toast.error(t("CountryIsRequired"));
      return;
    }

    if (!(language)) {
      toast.error(t("LanguageIsRequired"));
      return;
    }

    onComplete({
      language: language,
      companyName: companyName,
      address: {
        street: "",
        city: "",
        zipCode: "",
        country: country
      },
    });
  }

  return (
    <div className="big-screen:ml-[10rem] m-10 container-tablet-signup">
      <div className="flex flex-col w-full my-1 space-y-2">
        <h2 className=" text-lg font-bold text-blue mb-10"> {t("CompanyDetails")} </h2>
        <TextField
          inputClassName={"p-3 bg-input"}
          labelClassName={""}
          onFocusEffect={true}
          label={t("QuestionCompanyNameLabel") + " *"}
          placeholder={t("Insert")}
          onChange={(e) => setCompanyName(e.target.value)}
          defaultValue={companyName}
        />
        <SelectMenu
          label={t("QuestionCompanyCountry") + " *"}
          placeholder={t("Select")}
          className={"w-full mr-5"}
          inputClassName={"p-2"}
          options={optionsCountry}
          defaultValue={optionsCountry && country ? optionsCountry.find((item) => {
            if (country === item.label) {
              return item;
            }
          }) : country}
          onChange={(item) => setCountry(item.label)}
        />
        <SelectMenu
          label={t("LanguageSelection")}
          options={optionsLanguage}
          defaultValue={""}
          placeholder={t("Select")}
          inputClassName={"p-2 bg-input"}
          labelClassName={""}
          className={"w-50 mr-5"}
          value={optionsLanguage && language ? optionsLanguage.find(x => x.value === language) : language}
          onChange={(e) => setLanguage(e.value)}
        />
        <div className="flex justify-between items-center w-full btn-container">
          <button
            className="flex items-center bg-transparent border-none cursor-pointer p-2 text-sm"
            onClick={() => onBack(3)}>
      
              {"< " + t("Previous")}
      
          </button>
          <div className="flex justify-end ">
            <SecondaryButton
              content={t("Next") + " >"}
              className="p-2 w-[5vw]"
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyStep;