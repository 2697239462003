import React, { useContext, Fragment, useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './tableSmall.css';
import { utils, writeFile } from 'xlsx';


const TableSmall = ({ info }) => {
    const { t } = useTranslation();
    
    const [selectedItems, setSelectedItems] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const dt = useRef(null);
    const [lazyParams, setLazyParams] = useState({ first: 0, rows: 10 });

    useEffect(() => {
    }, [lazyParams, selectedItems]);
   
    const customBodyTemplate = (rowData, column) => {

        const row = rowData;
        const field = column.field;
        const fieldSymbol = column.symbol;
        console.log(row)
        console.log(field)
        
        let fieldValue = "";
        
    
        if (field.includes(".")) {
            const dataSplitted = field.split('.pdf');
            fieldValue = row[dataSplitted[0]][dataSplitted[1]];

        } else {
            fieldValue = row;
        }

        if (fieldValue && typeof fieldValue === 'object' && fieldValue._seconds) {
            let date = new Date(fieldValue._seconds * 1000);
            let dateString = date.toLocaleDateString();
            fieldValue = dateString;
        }

        if (column.field && column.type === 'Link') {
            const extension = ".pdf";
            const slash = "/";
            let invoice = fieldValue.split(slash);
            invoice = invoice[invoice.length-1].split(extension)[0];

            return (<>
                <a href={fieldValue} download={invoice} target='_blank'>{invoice}</a>
                
            </>)
        }
        return (
            <>
                {fieldValue}
                {fieldSymbol && ` ${fieldSymbol}`}
            </>
        );
    };

    return (
        <div className={`table-wrapper`}>
            
            <DataTable
                emptyMessage={t("NoAvailableOptions")}
                ref={dt}
                value={info.body}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={t("PageReportTemplate")}
                globalFilter={globalFilter}
                selectionMode="none"
            >
                {info.header.map((col) => (
                    <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={(rowData) => customBodyTemplate(rowData, col)} />
                ))}                
            </DataTable>
            
            
        </div>
    );
}

export default TableSmall;
