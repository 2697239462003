import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createOrganization
} from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import { FaCheck } from 'react-icons/fa';
import UserStep from "./steps/userStep";
import PhoneStep from "./steps/phoneStep";
import SubscriptionStep from "../signup/steps/subscriptionStep";
import CompanyStep from "../signup/steps/companyStep";
import TermsAndConditionsStep from "../signup/steps/termsAndConditionsStep";
import {
  HiArrowLeft
} from "react-icons/hi";

export const SignUp = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const navigator = useNavigate();
  const [step, setStep] = useState(1);  
  const isMobileTablet = window.matchMedia("(max-width: 991px)").matches;
  const [formValues, setFormValues] = useState({
    accountEmail: "",
    accountPassword: "",
    accountConfirmPassword: "",
    accountDisplayName: "",
    companyName: "",
    language: "",
    phoneNumber: {
      phone: "",
      prefix: "",
    },
    email: "",
    address: {
      street: "",
      city: "",
      zipCode: "",
      country: "",
    },
    currency: "€",
    tutorialIsActive: false,
    subscription: {
      amount: 0,
      expired: null,
      amountExtra: 0,
      plan: "",
      properties: 0,
      recurrency: "",
      propertiesExtra: 0,
    },
    termsAndConditions: {
      dateAccept: null, 
      accepted: false,
    },
    powerBiWorkspaceId: "",
    powerBiDataSetId: "",
    powerBiReportId: ""
  });

  const handleSubmit = () => {
    createOrganization(formValues).then((id) => {
      if (id) {
        window.location.replace('/')
      }
    });
  };

  return (
    <div>
      <div className="flex flex-row container-signup big-screen:my-10 text-2xl text-blue px-20">

        <div className="steps-container pt-10 pb-10 px-1 big-screen:rounded-l-xl">
          <div className="flex flex-col px-[10%] text-petroil items-center">
            <img
              className="h-auto w-fit logo-signup"
              alt="Bilemon logo"
              src="/Logo-Petroil.png"
            />
            <p className="text-base text-center big-screen:text-left mt-4 big-screen:mt-10 font-bold">{t("SignupWelcome")} </p>
            <p className="ml-1 mb-6 text-sm text-center big-screen:text-left font-semibold text-blue">{t("SignupTrial")} </p>
          </div>
          {!isMobileTablet && <div className="flex flex-col px-[10%] container-liststeps">
            <div className={`relative  flex items-center ${step >= 1 ? 'text-petroil' : 'text-gray-200'}`}>
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-8 h-8 rounded-full flex items-center justify-center">
                <FaCheck className="text-white bg-petroil rounded-full w-6 h-6 p-1" />
              </div>
              <p className={`ml-10 font-bold text-left text-base ${step >= 1 ? 'text-petroil' : 'text-gray-400'}`}>{t("PersonalDetails")}</p>
              <hr className={`border-${step >= 1 ? 'petroil' : 'gray-200'} w-px my-2 h-16`} />
            </div>

            <div className={`relative  flex items-center ${step >= 2 ? 'text-petroil' : 'text-gray-200'}`}>
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-8 h-8 rounded-full flex items-center justify-center">
                {step >= 2 ? <FaCheck className="text-white bg-petroil rounded-full flex items-center justify-center w-6 h-6 p-1" /> : <span className="gray-400 bg-gray-400 rounded-full flex items-center justify-center w-6 h-6 circle-number">2</span>}
              </div>
              <p className={`ml-10 font-bold text-left text-base text-${step >= 2 ? 'petroil' : 'gray-400'}`}>{t("AdditionalInfo")}</p>
              <hr className={`border-${step >= 2 ? 'petroil' : 'gray-200'} w-px my-2 h-16`} />
            </div>

            <div className={`relative flex items-center ${step >= 3 ? 'text-petroil' : 'text-gray-200'}`}>
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-8 h-8 rounded-full flex items-center justify-center">
                {step >= 3 ? <FaCheck className="text-white bg-petroil rounded-full flex items-center justify-center w-6 h-6 p-1" /> : <span className="gray-400 bg-gray-400 rounded-full flex items-center justify-center w-6 h-6 circle-number">3</span>}
              </div>
              <p className={`ml-10 font-bold text-left text-base text-${step >= 3 ? 'petroil' : 'gray-400'}`}>{t("SubscriptionPlan")}</p>
              <hr className={`border-${step >= 3 ? 'petroil' : 'gray-200'} w-px my-2 h-16`} />
            </div>

            <div className={`relative mb-7 flex items-center ${step >= 4 ? 'text-petroil' : 'text-gray-200'}`}>
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-8 h-8 rounded-full flex items-center justify-center">
                {step >= 4 ? <FaCheck className="text-white bg-petroil rounded-full flex items-center justify-center w-6 h-6 p-1" /> : <span className="gray-400 bg-gray-400 rounded-full flex items-center justify-center w-6 h-6 circle-number">4</span>}
              </div>
              <p className={`ml-10 font-bold text-left text-base text-${step >= 4 ? 'petroil' : 'gray-400'}`}>{t("CompanyDetails")}</p> <hr className={`border-${step >= 4 ? 'petroil' : 'gray-200'} w-px my-2 h-16`} />
            </div>

            <div className={`relative flex items-center ${step >= 5 ? 'text-petroil' : 'text-gray-200'}`}>
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-8 h-8 rounded-full flex items-center justify-center">
                {step >= 5 ? <FaCheck className="text-white bg-petroil rounded-full flex items-center justify-center w-6 h-6 p-1" /> : <span className="gray-400 bg-gray-400 rounded-full flex items-center justify-center w-6 h-6 circle-number">5</span>}
              </div>
              <p className={`ml-10 font-bold text-left text-base text-${step >= 5 ? 'petroil' : 'gray-400'}`}>{t("TermsAndConditions")}</p>
            </div>
          </div>}
        </div>
        {!isMobileTablet && <div onClick={() => navigator("../login")} className="absolute left-[25vw] top-12 flex cursor-pointer max-w-[250px]">
          <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
          <h1 className="text-xl font-bold">{t("BackLogin")}</h1>
        </div>}
        {isMobileTablet && <div onClick={() => navigator("../login")} className="absolute left-[5vw] top-[32vh] flex cursor-pointer max-w-[250px]">
          <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
          <h1 className="text-xl font-bold">{t("BackLogin")}</h1>
        </div>}
        <div className="flex  justify-center h-fit container-form-signup">
          
            {step === 1 && (
              <UserStep
                onComplete={(values) => {
                  setFormValues({ ...formValues, ...values });
                  setStep(2);
                }}
                formValues={formValues}
              />
            )}
            {step === 2 && (
              <PhoneStep
                onGoBack={(value) => setStep(value)}
                onComplete={(values) => {
                  setFormValues({ ...formValues, ...values });
                  setStep(3);
                }}
                formValues={formValues}
              />
            )}
            {step === 3 && (
              <SubscriptionStep
                onBack={(value) => setStep(value)}
                onComplete={(values) => {
                  setFormValues({ ...formValues, ...values });
                  setStep(4);
                }}
                formValues={formValues}
              />
            )}
            {step === 4 && (
              <CompanyStep
                onBack={(value) => setStep(value)}
                onComplete={(values) => {
                  setFormValues({ ...formValues, ...values });
                  setStep(5);
                }}
                formValues={formValues}
              />
            )}
            {step === 5 && (
              <TermsAndConditionsStep
                onBack={(value) => setStep(value)}
                onComplete={(values) => {
                  setFormValues({ ...formValues, ...values });
                  handleSubmit();
                }}
                formValues={formValues}
              />
            )}
          
        </div>
      </div>

      {!isMobileTablet && <div className="text-[rgb(10,57,69)]">
        <div className="flex flex-col items-center justify-center mt-20 my-10 space-y-2 container-footer-signup">
          <div className="flex items-center w-full">
            <hr className="flex-grow border-t border-grey" />
            <p className="mx-32 text-center text-sm">

              <span className="font-bold ">

                {t("SignupQuestion")}
              </span>

              <br></br>

              {t("SignupEmail")}
              <br></br>
              <a href="mailto:info@bilemon.com" className="hover:brightness-125">info@bilemon.com</a>



            </p>

            <hr className="flex-grow border-t border-[rgb(10,109,103)]" />
          </div>
        </div>

        <div className="w-full pl-32 text-base font-semibold text-left">        
        <p>©BiLemon {currentYear}</p>
      </div>
        

      </div>}

      {isMobileTablet && <>
        <p className="text-center text-sm mt-4">

<span className="font-bold ">

  {t("SignupQuestion")}
</span>

<br></br>

{t("SignupEmail")}
<br></br>
<a href="mailto:info@bilemon.com" className="hover:brightness-125">info@bilemon.com</a>



</p>
<div className="w-full p-4 text-base font-semibold text-center">        
        <p>©BiLemon {currentYear}</p>
      </div>
      </>}


      
      
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default SignUp;