import React from "react";

const PrimaryButton = ({ id, className, onClick, content, isDisabled }) => {
  return (
    <button
      id={id}
      onClick={onClick}      
      disabled={isDisabled ? "disabled" : ""}
      className={
        "h-fit min-w-fit text-white bg-blue rounded-lg text-base font-semibold hover:bg-petroil" +
        " " +
        className
      }
    >
        {content}
    </button>
  );
};

export default PrimaryButton;
