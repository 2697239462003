import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import { Tab } from "@headlessui/react";
import SecondaryButton from "../../components/buttons/secondary-button";

import { toast } from "react-toastify";
import TextField from "../../components/input/fields/text-field";

import { create, update } from "../../utils/functions";

import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/primary-button";

export const ManageSupplier = (data, isEditable) => {
    const itemToUpdate = data.data;

    const { i18n, t } = useTranslation();
    const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
    const Id = itemToUpdate && (itemToUpdate.id ?? null);
    const [index, setIndex] = useState(0);
    const context = useContext(DataContext);
    const navigator = useNavigate();
    const classNames = (...classes) => {
        return classes.filter(Boolean).join(" ");
    };
    const [fullNameCompanyName, setFullNameCompanyName] = useState((itemToUpdate && itemToUpdate.fullNameCompanyName) ?? null)
    const [vatNumber, setVatNumber] = useState((itemToUpdate && itemToUpdate.vatNumber) ?? null)
    const [category, setCategory] = useState((itemToUpdate && itemToUpdate.category) ?? null)
    
    const [formValues, setFormValues] = useState({
      id: Id,
      fullNameCompanyName: fullNameCompanyName,
      vatNumber: vatNumber,
      category: category,
      
    })
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const checkFields = () => {
      if (!formValues.fullNameCompanyName || formValues.fullNameCompanyName === "") {
        toast.error(t("FullNameCompanyNameRequired"));
        return false;
      }
  
      return true;
    };

    const checkChanges = () => {
      if (
        formValues.fullNameCompanyName === itemToUpdate.fullNameCompanyName &&
        formValues.vatNumber === itemToUpdate.vatNumber &&
        formValues.category === itemToUpdate.category 
        
      ) {
        toast.error(t("NoChangesMade"));
        return false;
      }
      return true;
    }

    const handleCreate = () => {
      if (checkFields()) {
      create("Supplier", context.idOrganization, formValues).then(async () => {
        context.updateData("suppliers");
        navigator("../suppliers");
      });
    }
    }

    const handleUpdate = () => {
      if (checkFields() && checkChanges()) {
      update("Supplier", context.idOrganization, formValues).then(async () => {
        context.updateData("suppliers")
        navigator("../suppliers");
      });      
    }
    }

    return (
        <div className="w-full">
          <div onClick={() => navigator("../suppliers")} className="mb-10 flex cursor-pointer max-w-[250px]">
            <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
            <h1 className="text-2xl font-bold">{t("Suppliers")}</h1>
          </div>
    
          <div className="w-full md:pl-24 mt-16">
            <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
            {!isMobile && (<Tab.List className="flex px-1 space-x-1 text-blue">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-8 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("SuppliersDetails")}</span>
                  </div>
                </Tab>
              </div>
            </Tab.List>)}
            {isMobile && (<Tab.List className="flex px-1 space-y-2 text-blue flex-col mb-2">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "  pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("SuppliersDetails")}</span>
                  </div>
                </Tab>
              </div>
            </Tab.List>)}
              {/** Tabs */}
              <Tab.Panels className=" min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7 md:px-12 px-1">
                {/** Details */}
                <Tab.Panel
                  key={0}
                  className={classNames(
                    "rounded-xl bg-white p-3",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
                  )}
                >
                  <h1 className={"ml-1 text-lg font-semibold text-blue w-full"
                  }
                  >
                    {t("General")}
                  </h1>
                  <div className="md:grid md:grid-cols-2 md:gap-5 md:gap-x-12">
                    <div className="w-full mt-1">
                       <TextField
                        label={t("FullNameCompanyName")}
                        inputClassName={"py-3 px-1"}
                        placeholder={t("Insert")}
                        defaultValue={fullNameCompanyName ?? ""}
                        onChange={(e) => {
                          setFullNameCompanyName(e.target.value)
                          setFormValues(prevValues => ({
                            ...prevValues,
                            fullNameCompanyName: (e.target.value)
                          }));
                        }
                        }
                      /> 
                    </div>
                    <div className="w-full mt-1">
                       <TextField
                        label={t("VatNumber")}
                        inputClassName={"py-3 px-1"}
                        placeholder={t("Insert")}
                        defaultValue={vatNumber ?? ""}
                        onChange={(e) => {
                          setVatNumber(e.target.value)
                          setFormValues(prevValues => ({
                            ...prevValues,
                            vatNumber: (e.target.value)
                          }));
                        }
                        }
                      /> 
                    </div>
                    <div className="w-full mt-1">
                       <TextField
                        label={t("Category")}
                        inputClassName={"py-3 px-1"}
                        placeholder={t("Insert")}
                        defaultValue={category ?? ""}
                        onChange={(e) => {
                          setCategory(e.target.value)
                          setFormValues(prevValues => ({
                            ...prevValues,
                            category: (e.target.value)
                          }));
                        }
                        }
                      /> 
                    </div>
                    
                    
                  </div>
    
                  <div className="grid grid-cols-12 gap-5">
                    <div className="col-span-3">
                      {/* <NewDatePicker
                        label={t("BookingDate") + " *"}
                        defaultValue={
                          (bookingDate && bookingDate._seconds && new Date(bookingDate._seconds * 1000)) ??
                          (booking.bookingDate && booking.bookingDate._seconds && new Date(booking.bookingDate._seconds * 1000)) ??
                          null
                        }
                        onCompleted={(el) => {
                          setBookingDate(el);
                          setFormValues(prevValues => ({
                            ...prevValues,
                            bookingDate: el
                          }));
                        }}
                      /> */}
                    </div>
                    <div className="col-span-2">
                      {/* <NewDatePicker
                        label={t("CheckInDate") + " *"}
                        defaultValue={
                          (checkInDate && checkInDate._seconds && new Date(checkInDate._seconds * 1000)) ??
                          (booking.checkInDate && booking.checkInDate._seconds && new Date(booking.checkInDate._seconds * 1000)) ??
                          null
                        }
                        onCompleted={(el) => {
                          setCheckInDate(el);
                          setFormValues(prevValues => ({
                            ...prevValues,
                            checkInDate: el
                          }));
                        }}
                      /> */}
                    </div>
                    <div className="col-span-2">
                      {/* <NewDatePicker
                        label={t("CheckOutDate") + " *"}
                        defaultValue={
                          (checkOutDate && checkOutDate._seconds && new Date(checkOutDate._seconds * 1000)) ??
                          (booking.checkOutDate && booking.checkOutDate._seconds && new Date(booking.checkOutDate._seconds * 1000)) ??
                          null
                        }
                        onCompleted={(el) => {
                          setCheckOutDate(el);
                          setFormValues(prevValues => ({
                            ...prevValues,
                            checkOutDate: el
                          }));
                        }}
                      /> */}
                    </div>
                    <div className="col-span-2">
                      {/* <UnitFieldSymbols
                        label={t("NumberofGuests")}
                        className={"w-full"}
                        placeholder={numberOfGuest}
                        value={numberOfGuest ?? 0}
                        defaultValue={numberOfGuest ?? 0}
                        onChange={(el) => {
                          setNumberOfGuest(Number(el.target.value))
                          setFormValues(prevValues => ({
                            ...prevValues,
                            numberOfGuest: (Number(el.target.value))
                          }));
                        }}
                        inputClassName={"m-1 p-1"}
                      /> */}
                    </div>
                  </div>
    
    
    
    
                </Tab.Panel>
    
                
                <div className="flex justify-end w-full pr-3 pb-10">
    
                  {
                    IsEditable && (
                      Id ? (
                        <PrimaryButton
                          content={t("Save")}
                          className={"mt-10 p-3 bg-blue w-[7vw]"}
                          onClick={() => {
                            handleUpdate();
                          }}
                        />
                      ) :  (
                        <PrimaryButton
                          content={t("Save")}
                          className={"mt-10 p-3 bg-blue w-[7vw]"}
                          onClick={() => {
                            handleCreate();
                          }}
                        />
                      )
                    )
                  }
                </div>
              </Tab.Panels>
    
            </Tab.Group>
    
          </div>
    
        </div>
      );
}