import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import { Tab } from "@headlessui/react";
import PrimaryButton from "../../components/buttons/primary-button";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import TextField from "../../components/input/fields/text-field";
import TagsInput from "../../components/input/select/tags/tags-input";

import { SelectMenu } from "../../components/input/select/select-menu";
import { GeneralOptions } from "../../resources/selectOptions";
import { create, update } from "../../utils/functions";

export const ManageCostCategory = (data, isEditable) => {
  const itemToUpdate = data.data;
  const { i18n, t } = useTranslation();
  const [IsEditable, setIsEditable] = useState(isEditable ? true : false);
  const Id = itemToUpdate && (itemToUpdate.id ?? null);
  const context = useContext(DataContext);
  const [typology, setTypology] = useState((itemToUpdate && itemToUpdate.typology) ?? null);
  const [category, setCategory] = useState((itemToUpdate && itemToUpdate.category) ?? null);
  const [subCategory, setSubCategory] = useState((itemToUpdate && itemToUpdate.subCategory) ?? null);
  const costCategoryList = context.costCategories;
  const [index, setIndex] = useState(0);
  const navigator = useNavigate();
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  const selectedTags = (tags) => {
    setSubCategory((prevSubCategory) => {
      const newSubCategory = [...tags];
      setFormValues((prevValues) => ({
        ...prevValues,
        subCategory: newSubCategory,
      }));
      return newSubCategory;
    });
  };
  

  const costTypologyOptions = GeneralOptions("costTypology");

  const [formValues, setFormValues] = useState({
    id: Id,
    typology: typology,
    category: category,
    subCategory: subCategory,

  })

  const checkFields = () => {
    if (!formValues.typology || formValues.typology === "") {
      toast.error(t("TypologyRequired"));
      return false;
    }

    if (!formValues.category || formValues.category === "") {
      toast.error(t("CategoryRequired"));
      return false;
    }

    return true;
  };

  const checkChanges = () => {
    if (
      formValues.typology === itemToUpdate.typology &&
      formValues.subCategory === itemToUpdate.subCategory &&
      formValues.category === itemToUpdate.category

    ) {
      toast.error(t("NoChangesMade"));
      return false;
    }
    return true;
  }

  const handleCreate = () => {
    if (checkFields()) {
      create("CostCategory", context.idOrganization, formValues).then(async () => {
        context.updateData("cost-categories");
        navigator("../cost-categories");
      });
    }
  }

  const handleUpdate = () => {
    if (checkFields() && checkChanges()) {
      update("CostCategory", context.idOrganization, formValues).then(async () => {
        context.updateData("cost-categories")
        navigator("../cost-categories");
      });
    }
  }
  
  console.log(subCategory)
  console.log(selectedTags)

  return (
    <div className="w-full">
      <div onClick={() => navigator("../cost-categories")} className="mb-10 flex cursor-pointer max-w-[250px]">
        <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
        <h1 className="text-2xl font-bold">{t("CostCategories")}</h1>
      </div>
      <div className="w-full md:pl-24 mt-16">
        <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        {!isMobile && (<Tab.List className="flex px-1 space-x-1 text-blue">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-8 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("CostCategoriesDetails")}</span>
                  </div>
                </Tab>
              </div>
            </Tab.List>)}
            {isMobile && (<Tab.List className="flex px-1 space-y-2 text-blue flex-col mb-2">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "  pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("CostCategoriesDetails")}</span>
                  </div>
                </Tab>
              </div>
            </Tab.List>)}
          {/** Tabs */}
          <Tab.Panels className=" min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7 md:px-12 px-1">
            {/** Details */}
            <Tab.Panel
              key={0}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
              )}
            >
              <h1 className={"ml-1 text-lg font-semibold text-blue w-full"
              }
              >
                {t("General")}
              </h1>
              <div className="md:grid md:grid-cols-2 md:gap-5 md:gap-x-12">
                <div className="w-full mt-1">
                <SelectMenu
                    label={t("Typology")}
                    className={"max-w-xs mr-10 min-w-[27.8vw]"}
                    isSearchable={true}
                    options={costTypologyOptions}
                    defaultValue={costTypologyOptions && typology ? costTypologyOptions.find((item) => {
                      if (typology === item.value) {
                        return item;
                      }
                    }) : typology}
                    placeholder={t("Insert")}
                    onChange={(value) => {
                      setTypology(value.value)
                      setFormValues(prevValues => ({ 
                        ...prevValues, 
                        typology: value.value }));
                    }}
                  />                  
                </div>
                <div className="w-full mt-1">
                  <TextField
                    label={t("Category")}
                    inputClassName={"py-3 px-1"}
                    placeholder={t("Insert")}
                    defaultValue={category}
                    onChange={(e) => {
                      setCategory(e.target.value)
                      setFormValues(prevValues => ({
                        ...prevValues,
                        category: (e.target.value)
                      }));
                    }
                    }
                  />
                </div>
                <div className="w-full mt-2 md:pr-1">
                <TagsInput 
                  label={t("SubCategory")}                  
                  placeholder={t("TagInputPlaceholder")}
                  selectedTags={selectedTags}
                  defaultTags={subCategory}
                />
                </div>
              </div>
            </Tab.Panel>
            <div className="flex justify-end w-full pr-3 pb-10">
              {
                IsEditable && (
                  Id ? (
                    <PrimaryButton
                      content={t("Save")}
                      className={"mt-10 p-3 bg-blue w-[7vw]"}
                      onClick={() => {
                        handleUpdate();
                      }}
                    />
                  ) : (
                    <PrimaryButton
                      content={t("Save")}
                      className={"mt-10 p-3 bg-blue w-[7vw]"}
                      onClick={() => {
                        handleCreate();
                      }}
                    />
                  )
                )
              }
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}