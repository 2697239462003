import React, { useContext } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";

export const Bookings = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);

  const bookings = context.bookings;
  const accommodations = context.accommodations;
  const organizationInfo = context.organizationInfo;
  const symbol = organizationInfo && organizationInfo.currency;

  const getAccommodationName = (accommodationId) => {
    return accommodations.find(acc => acc.id === accommodationId);
  };

  const transactionsListWithAccommodationNames = bookings.map(booking => {
    const accommodationName = getAccommodationName(booking.idAccommodation);
    return {
      ...booking,
      accommodationName
    };
  });

  const bookingTableStructure = {
    info: {
      urlUpdateData: "bookings",
      urlCreateUpdate: "booking",
      name: "Booking",
      header: [
        {
          field: "bookingReference",
          header: t("ReferenceCode"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "accommodationName.name",
          header: t("Accommodation"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "bookingDate",
          header: t("BookingDate"),
          sortable: true,
          type: "Date",
          show: true,
        },
        {
          field: "checkInDate",
          header: t("CheckInDate"),
          sortable: true,
          type: "Date",
          show: true,
        },
        {
          field: "checkOutDate",
          header: t("CheckOutDate"),
          sortable: true,
          type: "Date",
          show: true,
        },
        {
          field: "amount",
          header: t("amount"),
          sortable: true,
          type: "Text",
          show: true,
          symbol: symbol
        },
        {
          field: "channel.commissionChannel",
          header: t("channel"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "channel.commissionAmount",
          header: t("ChannelCommission"),
          sortable: true,
          type: "Text",
          show: true,          
          symbol: symbol
        },
        {
          field: "status",
          header: t("Status"),
          sortable: true,
          type: "Text",
          show: true
        }
      ],
      infoFilters: [
        {
          field: "bookingReference",
          header: t("ReferenceCode"),
          filterType: "text",
          filterData: ["prova", "test"]
        },
        {
          field: "accommodation",
          header: t("Accommodation"),
          filterType: "select",
          filterOptions: ["prova", "test"]
        },
        {
          field: "checkInDate",
          header: t("CheckInDate"),
          filterType: "date"
        },
        {
          field: "checkOutDate",
          header: t("CheckOutDate"),
          filterType: "date"
        },
        {
          field: "bookingDate",
          header: t("BookingDate"),
          filterType: "date"
        },
        {
          field: "amount",
          header: t("Amount"),
          filterType: "range"
        },
        {
          field: "accommodation",
          header: t("Amount"),
          filterType: "checkbox"
        },

      ],
      body: transactionsListWithAccommodationNames
    },

    exportable: true,
    importable: true,
    filterType: "filter"
  }

  return (
    <>

      <div className="w-full mt-8">

        <div className="content">
          <div className="table-wrapper">
            <Table
              info={bookingTableStructure.info}
              exportable={bookingTableStructure.exportable}
              importable={bookingTableStructure.importable}
              filterType={bookingTableStructure.filterType}
            />
          </div>
        </div>
        {

        }

      </div>
    </>
  );
}