import React, { useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import TertiaryButton from "../buttons/tertiary-button";

const Modal = ({
  externalControl,
  className,
  status,
  title,
  icon,
  subtitle,
  body,
  primaryButton,
  buttonLabel,
  buttonClassName,
  bodyButton,
  bodyButtonLabel,
  onClose,
  onOpen,
  bodyButtonClassName,
}) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    if (onClose != null) {
      onClose();
    }
  }

  function openModal() {
    setIsOpen(true);
    if (onOpen != null) {
      onOpen();
    }
  }
  useEffect(() => {
    if (externalControl == true) {
      status ? openModal() : closeModal();
    }
  });

  return (
    <>
      {!externalControl && (
        <>
          {primaryButton ? (
            <TertiaryButton
              onClick={openModal}
              content={buttonLabel}
              className={buttonClassName}
            />
          ) : (
            <TertiaryButton
              onClick={openModal}
              content={buttonLabel}
              className={buttonClassName}
            />
          )}
        </>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">

            <div className="flex items-center justify-center min-h-full p-4 text-center">

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >

                <Dialog.Panel
                  className={
                    className +
                    " forgotPass-modal  w-fit py-4 pt-10 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                  }


                >
                  <img
                    className="logo-bilemon w-60 h-auto mx-auto pb-10 "
                    alt="Bilemon logo"
                    src="/Logo-Petroil.png"
                  />
                  <Dialog.Title
                    as="h2"
                    className="flex items-center justify-center text-base font-bold leading-6 text-petroil"
                  >
                    <div className="flex ">{icon}<span className="">{title}</span></div>
                    {/* {!bodyButton && (
                      <button
                        type="button"
                        className={
                          externalControl
                            ? "hidden "
                            : "inline-flex justify-center w-10 h-full text-sm font-medium border border-transparent rounded-md text-blue hover:brightness-95 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        }
                        onClick={closeModal}
                      >
                        <IoClose className="w-full h-auto" />
                      </button>
                    )} */}
                  </Dialog.Title>
                  {subtitle && (
                    <div className="mt-2">
                      <p className="text-base font-semibold text-blue text-center">
                        {subtitle}
                      </p>
                    </div>
                  )}
                  <div className="mt-6">
                    <div className="text-sm text-gray-500">{body}</div>
                  </div>
                  {bodyButton && (
                    <div className={bodyButtonClassName + " mt-4 w-full"}>
                      <button
                        type="button"
                        className={
                          (primaryButton
                            ? "bg-petroil text-white"
                            : "bg-green text-petroil") +
                          " " +
                          "inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md  hover:brightness-95 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        }
                        onClick={closeModal}
                      >
                        {bodyButtonLabel}
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
