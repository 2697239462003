import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';
import { useTranslation } from 'react-i18next';


const TagsSelect = ({ options, selectedTags, placeholder, label, labelClassName, clearTrigger }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const { t } = useTranslation();

    const onChange = (e) => {
        setSelectedItems(e.value);
        selectedTags(e.value); 
    };

    useEffect(() => {
        if (clearTrigger) {
            setSelectedItems([]);
        }
    }, [clearTrigger]);

    return (
        <div className="flex flex-col items-start justify-start w-full">
            {label && (
                <label className={labelClassName + " ml-1 text-lg font-semibold text-blue w-full"}>
                    {label}
                </label>
            )}
            <MultiSelect
                value={selectedItems}
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                display={selectedItems.length < 3 ? "chip" : "default"} 
                className="w-full multiselect-tags-custom"
                maxSelectedLabels={2}
                selectAllLabel={t('SelectAll')}
            />
        </div>
    );
};


export default TagsSelect;
