import React, { useEffect, useState } from "react";
import { HiChat, HiOutlineChat, HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../appRoutes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const menu = AppRoutes;

export function SideBar({ className, setIsOpen, i18n }) {
  const { t } = useTranslation();
  const location = useMatch("/*");
  const [activePath, setActivePath] = useState(location?.params["*"] || "");

  useEffect(() => {
    if (location) {
      setActivePath(location.params["*"]);
    }
  }, [location]);

  return (
    <>
      <div className={className + " flex-col pr-5 border-r-2 h-screen bg-white flex"}>
        <div className="flex flex-col items-start h-full my-5 text-blue">
          <Link className="flex items-center justify-center w-full h-2 my-10" to="/">
            <img src="/Logo-Petroil.png" className="items-center h-[5rem] pt-3 pb-4" alt="logo" />
          </Link>
          <div className="h-full">
            <div className="mt-10 space-y-5 items-center hover:text-blue" id="selectionmenu">
              {menu.map((item, index) => (
                item.isInSidebar && (
                  <div
                    key={index}
                    className={classNames(
                      item.path === activePath ? "border-l-4 text-blue" : "border-0",
                      "flex flex-row items-center border-petroil",
                      item.disabled ? "cursor-not-allowed" : "",
                      item.isNotReady ? "cursor-not-allowed" : ""
                    )}
                  >
                    <div className="flex items-center mx-8">
                      {item.disabled || item.isNotReady ? (
                        <div
                          className="ml-2 cursor-not-allowed color-disabled font-semibold text-md flex items-center"
                          data-tooltip={item.isNotReady ? t("ComingSoon") : t("PremiumRequired")}
                        >
                          <span className="premium-required-tooltip flex-shrink-0">
                            {item.icon}
                          </span>
                          <span className="ml-2 premium-required-tooltip text-md overflow-hidden whitespace-nowrap">
                            {t(item.name)}
                          </span>
                        </div>
                      ) : (
                        <Link
                          to={item.path}
                          onClick={() => setActivePath(item.path)}
                          className={classNames(
                            item.path === activePath
                              ? "font-bold text-blue"
                              : "font-bold opacity-40",
                            "flex ml-2 cursor-pointer hover:text-blue hover:opacity-100 text-md"
                          )}
                        >
                          <span className="flex-shrink-0">
                            {item.icon}
                          </span>
                          <span className="ml-2 text-md overflow-hidden whitespace-nowrap">
                            {t(item.name)}
                          </span>
                        </Link>
                      )}
                    </div>

                  </div>
                )
              ))}
            </div>
          </div>

          <div className="items-center justify-start w-full mx-5 mb-5">
            <Link
              to={"faq"}
              className={classNames(
                "font-semibold opacity-80",
                "flex ml-5 cursor-pointer hover:text-blue hover:opacity-100 text-lg"
              )}
            >
              <div className="flex items-center">
                <HiOutlineQuestionMarkCircle className={"w-6 h-auto font-bold hover:text-blue "} />
                <div className="ml-2">
                  {"FAQ"}
                </div>
              </div>
            </Link>
            <a href="mailto:support@bilemon.com"
              className={classNames(
                "font-semibold opacity-80",
                "flex ml-5 cursor-pointer hover:text-blue hover:opacity-100 text-lg"
              )}
            >
              <div className="flex items-center">
                <HiOutlineChat className={"w-6 h-auto font-bold hover:text-blue"} />
                <div className="ml-2">
                  {t("NeedSupport")}
                </div>
              </div>
            </a>
            <p className="font-semibold text-sm mt-10 ml-5">&copy; BiLemon {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </>
  );
}
