import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";

export const Suppliers = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const supplier = context.suppliers;

  const supplierTableStructure = {
    info: {
      urlUpdateData: "suppliers",
      urlCreateUpdate: "supplier",
      name: "Supplier",
      header: [
        {
          field: "fullNameCompanyName",
          header: t("FullNameCompanyName"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "vatNumber",
          header: t("VatNumber"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "category",
          header: t("Category"),
          sortable: true,
          type: "Text",
          show: true,
        }
      ],
      body: supplier
    },

    exportable: false,
    importable: false,
    filterType: "filter"
  }


  return (
    <div className="w-full mt-8">

      <div className="content">

        <div className="table-wrapper">
          <Table
            info={supplierTableStructure.info}
            exportable={supplierTableStructure.exportable}
            importable={supplierTableStructure.importable}
            searchable={true}

          />
        </div>
      </div>
    </div>
  );
};
