import React from "react";
import "./buttons.css";

const SecondaryButton = ({ id, className, onClick, content }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={
        className +
        " " +
        "w-full min-w-fit p-3 h-fit bg-input rounded-lg text-base font-semibold secondary"
      }
    >
      {content}
    </button>
  );
};

export default SecondaryButton;
