import React, { useContext } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";

export const BookingRules = () => {
    const context = useContext(DataContext);
    const { i18n, t } = useTranslation();
    let bookingRulesList = context.bookingRules;
    
    const accommodations = context.accommodations;
    const organizationInfo = context.organizationInfo;
    const symbol = organizationInfo && organizationInfo.currency;

    const getAccommodationName = (accommodationIds) => {
        return accommodationIds.map(accommodationId => {
            const accommodation = accommodations.find(acc => acc.id === accommodationId);
            return accommodation ? accommodation.name : "";
        }).join(", "); 
    };

    const transactionsListWithAccommodationNames = bookingRulesList.map(bookingRule => {
        const accommodationNames = getAccommodationName(bookingRule.idAccommodations);
        return {
          ...bookingRule,
          accommodationNames 
        };
    });

    const bookingRuleTableStructure = {
        info: {
            urlUpdateData: "booking-rules",
            urlCreateUpdate: "booking-rule",
            name: "BookingRule",
            header: [
                {
                    field: "name",
                    header: t("RuleName"),
                    sortable: true,
                    type: "Text",
                    show: true,
                },
                {
                    field: "type",
                    header: t("Type"),
                    sortable: true,
                    type: "Text",
                    show: true,
                },
                {
                    field: "category",
                    header: t("Category"),
                    sortable: true,
                    type: "Text",
                    show: true,
                },
                {
                    field: "subCategory",
                    header: t("SubCategory"),
                    sortable: true,
                    type: "Text",
                    show: true,
                },
                {
                    field: "amount",
                    header: t("Amount"),
                    sortable: true,
                    type: "Text",
                    show: true,
                    symbol: symbol
                },
                {
                    field: "startDate",
                    header: t("StartDate"),
                    sortable: true,
                    type: "Text",
                    show: true,
                },
                {
                    field: "endDate",
                    header: t("EndDate"),
                    sortable: true,
                    type: "Text",
                    show: true,
                },
                {
                    field: "accommodationNames", 
                    header: t("Accommodations"),
                    sortable: true,
                    type: "Text",
                    show: true,
                }
            ],
            body: transactionsListWithAccommodationNames
        },

        exportable: false,
        importable: false,
        filterType: "filter"
    };

    return (
        <>
            <div className="w-full mt-8">
                <div className="content">
                    <div className="table-wrapper">
                        <Table
                            info={bookingRuleTableStructure.info}
                            exportable={bookingRuleTableStructure.exportable}
                            importable={bookingRuleTableStructure.importable}
                            filterType={bookingRuleTableStructure.filterType}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
