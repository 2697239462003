import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import Table from "../../components/table/table";

export const Owners = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const ownersList = context.owners;
  const organizationInfo = context.organizationInfo;
  const symbol = organizationInfo && organizationInfo.currency;
  
  const ownerTableStructure = {
    info: {
      urlUpdateData: "owners",
      urlCreateUpdate: "owner",
      name: "Owner",
      header: [
        {
          field: "fullNameCompanyName",
          header: t("FullNameCompanyName"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "vatNumber",
          header: t("VatNumber"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "accommodationPayments",
          header: t("Accommodations"),
          sortable: true,
          type: "Text",
          show: true,
        },
        {
          field: "monthlyFee",
          header: t("MonthlyFee"),
          sortable: true,
          type: "Text",
          show: true,
          symbol: symbol
        },
      ],
      body: ownersList
    },

    exportable: false,
    importable: false,
    filterType: "filter"
  }

  return (
    <>
      <div className="w-full mt-8">
        <div className="content">
          <div className="table-wrapper">
            <Table
              info={ownerTableStructure.info}
              exportable={ownerTableStructure.exportable}
              importable={ownerTableStructure.importable}
              searchable={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
