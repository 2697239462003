import React from "react";
import Select from "react-select";
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';

export const SelectMenu = ({
  label,
  labelClassName,
  className,
  options,
  isMulti,
  placeholder,
  isSearchable,
  isLoading,
  isClearable,
  isDisabled,
  onChange,
  value,
  defaultValue,
  isSelect,
  inputClassName,
  onClick
}) => {

  
  const { t } = useTranslation();
  return (
    <div
      className={
        "flex flex-col items-start justify-start w-full flex-nowrap  " +
        className
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      {isMulti ? (
        <MultiSelect
          value={value}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          disabled={isDisabled}          
          className={"w-full my-1 text-blue text-base font-normal ps-1 py-[4px] rounded-[0.375rem]"}          
          panelClassName={" rounded-[0.375rem]"}
          selectAllLabel={t('SelectAll')}
          
        />
      ) : (
        <Select
          isLoading={isLoading}
          isClearable={isClearable}
          isDisabled={isDisabled}
          options={options ? options : undefined}
          isSearchable={isSearchable}
          placeholder={placeholder}
          onChange={onChange}
          onMenuOpen={onClick}
          value={value}
          defaultValue={defaultValue}
          className={"w-full my-1 text-base font-normal text-blue" }
          styles={{
            control: (base) => ({
              ...base,
              border: isSelect ? "1px solid rgb(226 232 240)" : "none",
              boxShadow: "none",
              backgroundColor: isSelect ? "#FFFFFF" : "#EEF1F2",
            })
          }}          
          theme={(theme) => ({
            ...theme,
            borderRadius: "0.375rem",
            colors: {
              primary: "#0a6d67",
              danger: "#dc2626",
              neutral0: "rgb(245, 245, 245)",
            },
          })}
        />
      )}
    </div>
  );
};
