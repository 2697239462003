import React, { useState, useEffect } from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import TextField from "./text-field";

export const UnitFieldSymbols = ({
  id,
  label,
  labelClassName,
  inputClassName,
  placeholder,
  onChange,
  value,
  onFocusEffect,
  onFocusEffectColor,
  onClick,
  onMouseOver,
  type,
  maxLength,
  autoComplete,
  name,
  spellCheck,
  disabled,
  className,
  min,
  defaultValue,
  accept
}) => {
  const [inputValue, setInputValue] = useState(value || defaultValue || 0);

  useEffect(() => {
    setInputValue(inputValue);
  }, [inputValue]);

  const handleStepDown = () => {
    if(!disabled) {

      setInputValue(prevValue => {
        let newValue = 0;
        if (prevValue == 0) {
          newValue = Number(prevValue);
        } else {
          newValue = Number(prevValue) - 1;
        }
        
        if (onChange) onChange({ target: { value: newValue } });
        return Number(newValue);
      });
    }
    return;
  };

  const handleStepUp = () => {
    if(!disabled) {
    setInputValue(prevValue => {
      const newValue = Number(prevValue) + 1;
      if (onChange) onChange({ target: { value: newValue } });
      return Number(newValue);
    });
  }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (onChange) onChange(e);
  };

  return (
    <div className={`flex md:flex-col items-center md:items-start ${disabled ? " opacity-60 pointer-events-none" : ""} justify-start`}>
      {label && (
        <label
          className={
            labelClassName + " " + "ml-1 text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <div className="flex mt-1 ml-1 items-center w-full bg-input rounded-md px-2 md:px-4 max-w-[140px]">
        <button
          className="mr-2"
          onClick={handleStepDown}
        >
          <HiMinus className="text-lg" />
        </button>
        <input
          id={id}
          className={
            inputClassName +
            " " +
            "w-full h-fit ml-0 text-center bg-input outline-none indent-2 text-base text-blue py-3 px-2" +
            " " +
            (onFocusEffect
              ? onFocusEffectColor
                ? "focus:border-" + onFocusEffectColor
                : "focus:border-petroil"
              : "")
          }
          disabled={disabled}
          placeholder={placeholder}
          type={type ? type : "text"}
          onChange={handleChange}
          onClick={onClick}
          onMouseOver={onMouseOver}
          maxLength={maxLength ? maxLength : null}
          autoComplete={autoComplete}
          value={inputValue}
          name={name}
          spellCheck={spellCheck}
          min={min}
          accept={accept}
        />
        <button
          className="ml-2"
          onClick={handleStepUp}
        >
          <HiPlus className="text-lg" />
        </button>
      </div>
    </div>
  );
};
